<app-modal  i18n-title title="{{isEditMode ?
'Nutzer bearbeiten - ' + editUserFormGroup.controls.firstName.value + ' ' + editUserFormGroup.controls.lastName.value + ' (' + this.pharmacies?.length + ' Filialen)' :
'Nutzer hinzufügen'}}">
    <ng-container headerActions></ng-container>
    <ng-container content>
        <div *ngIf="!pharmacies || pharmacies?.length === 0" class="default-loader">
            <ion-spinner name="crescent" color="primary"></ion-spinner>
        </div>
        <ion-grid *ngIf="pharmacies && pharmacies.length > 0" class="content-grid">
            <ion-row>
                <ion-col class="ion-no-padding" [size]="24">
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <div class="form-container">
                                <form class="form ion-padding input-section" [formGroup]="editUserFormGroup" (ngSubmit)="onSubmit(editUserFormGroup.value)">
                                    <ion-item class="background-input salutation">
                                        <ion-label slot="start" i18n>Anrede</ion-label>
                                        <div class="ion-select-container">
                                            <ion-select formControlName="salutation" class="select-filter" interface="popover" aria-label="Anrede">
                                                <ion-select-option value="Frau" aria-label="Frau">Frau</ion-select-option>
                                                <ion-select-option value="Herr" aria-label="Herr">Herr</ion-select-option>
                                            </ion-select>
                                        </div>
                                    </ion-item>
                                    <ion-item class="background-input title">
                                        <ion-input
                                            i18n-label
                                            type="text"
                                            formControlName="title"
                                            label="Title"
                                        ></ion-input>
                                    </ion-item>
                                    <ion-item class="background-input firstName">
                                        <ion-input
                                            i18n-label
                                            type="text"
                                            formControlName="firstName"
                                            label="Vorname"
                                            class="required"
                                            [ngClass]="{
                                                'invalid': editUserFormGroup.controls.firstName?.dirty | formInputIsInvalid : editUserFormGroup.controls.firstName?.valid,
                                            }"
                                            #firstField></ion-input>
                                        <ion-icon
                                            name="alert-circle"
                                            color="primary"
                                            *ngIf="editUserFormGroup.controls.firstName?.dirty | formInputIsInvalid : editUserFormGroup.controls.firstName?.valid"
                                            appTooltip
                                            [tt]="editUserFormGroup.controls.firstName | formInputErrorMessage : editUserFormGroup.controls.firstName.value"
                                            ttp="right">
                                        </ion-icon>
                                    </ion-item>
                                    <ion-item class="background-input lastName">
                                        <ion-input
                                            i18n-label
                                            type="text"
                                            formControlName="lastName"
                                            label="Nachname"
                                            class="required"
                                            [ngClass]="{
                                                'invalid': editUserFormGroup.controls.lastName?.dirty | formInputIsInvalid : editUserFormGroup.controls.lastName?.valid,
                                            }"
                                        ></ion-input>
                                        <ion-icon
                                            name="alert-circle"
                                            color="primary"
                                            *ngIf="editUserFormGroup.controls.lastName?.dirty | formInputIsInvalid : editUserFormGroup.controls.lastName?.valid"
                                            appTooltip
                                            [tt]="editUserFormGroup.controls.lastName | formInputErrorMessage : editUserFormGroup.controls.lastName.value"
                                            ttp="right">
                                        </ion-icon>
                                    </ion-item>
                                    <ion-item class="background-input email">
                                        <ion-input
                                            i18n-label
                                            type="text"
                                            inputmode="email"
                                            formControlName="email"
                                            label="Email"
                                            class="required"
                                            [ngClass]="{
                                                'invalid': editUserFormGroup.controls.email?.dirty | formInputIsInvalid : editUserFormGroup.controls.email?.valid,
                                            }"
                                        ></ion-input>
                                        <ion-icon
                                            name="alert-circle"
                                            color="primary"
                                            *ngIf="editUserFormGroup.controls.email?.dirty | formInputIsInvalid : editUserFormGroup.controls.email?.valid"
                                            appTooltip
                                            [tt]="editUserFormGroup.controls.email | formInputErrorMessage : editUserFormGroup.controls.email.value"
                                            ttp="right">
                                        </ion-icon>
                                    </ion-item>
                                </form>
                                <div class="description-wrapper">
                                    <ion-text i18n>Berechtigungen</ion-text>
                                    <ion-text i18n>
                                        Definieren Sie, welche Anwendungen dieser Nutzer verwenden darf und vergeben Sie ggf. Einzelrechte wie z. B. "Zugriff auf e-Rechnungen" etc.
                                        Die Einstellungen müssen je Apotheke (bzw. Kundennummer) separat vorgenommen werden.
                                    </ion-text>
                                </div>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngFor="let pharmacy of pharmacies" class="pharmacy-wrapper ion-padding">
                        <ion-text class="ion-padding-bottom">{{pharmacy.name}} - {{pharmacy.apiUser}}</ion-text>
                        <div *ngFor="let module of pharmacy && pharmacy.rights || []" class="badge-wrapper ion-padding-bottom-half" [class.disabled]="module['isDisabled']">
                            <ion-toggle mode="md"
                                    [checked]="module['isActivated']"
                                    [disabled]="module['isDisabled'] || !(module | userAdminCanActivate: pharmacies: module['pipeRefresh'])"
                                    (ionChange)="onToggleClick($event, module['accessModule'], pharmacy.apiUser)">
                            </ion-toggle>
                            <access-rights-badge
                                    [badgeType]="module['accessModule']"
                                    [accessRights]="module['accessRights']"
                                    (accessRightsClick)="onAccessRightsClick($event, module['accessModule'], pharmacy.apiUser)"
                                    [disabled]="module['isDisabled'] && !(module | userAdminCanActivate: pharmacies: module['pipeRefresh'])"
                                    [deactivated]="!module['isActivated']"></access-rights-badge>
                        </div>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
    <ng-container footer *ngIf="pharmacies && pharmacies.length > 0">
        <div class="display-flex ion-justify-content-end">
            <ion-button *ngIf="isEditMode" class="button-tertiary" (click)="onDelete()" i18n>Nutzer löschen</ion-button>
            <ion-button class="button-tertiary" (click)="onCancel()" i18n>Abbrechen</ion-button>
            <ion-button color="primary" disabled="{{editUserFormGroup.invalid}}" (click)="onSubmit(editUserFormGroup.value)" i18n>{{isEditMode ? 'Änderungen speichern' : 'Nutzer anlegen'}}</ion-button>
        </div>
    </ng-container>
</app-modal>
