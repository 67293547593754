import { Injectable } from '@angular/core';
import { NavigationRoutesEnum } from '../../navigation/navigation.routes';

interface LocationMappingInterface {
    locationKey?: string;
    routeMap: Record<string, string>;
    defaultRoute: string;
    forceOverwrite?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    setReturnUrl(url: string) {
        localStorage.setItem('navigation_return_url', url);
    }

    getReturnUrl(): string {
        return localStorage.getItem('navigation_return_url') || NavigationRoutesEnum.dashboard;
    }

    clearReturnUrl() {
        localStorage.removeItem('navigation_return_url');
    }

    /**
     * Sets the return URL with optional force overwrite behavior.
     *
     * This method checks if there is an existing return URL stored in the local storage.
     * If there is no existing URL or if the `forceOverwrite` flag is set to true,
     * it will set the provided return URL.
     *
     * @param returnUrl - The return URL to be set
     * @param forceOverwrite - Flag indicating whether to overwrite the existing return URL
     * @returns - The return URL that was set or retrieved
     */
    updateReturnUrl(returnUrl: string, forceOverwrite: boolean = false): string {
        const hasExistingUrl = localStorage.getItem('navigation_return_url');

        if (!hasExistingUrl || forceOverwrite) {
            this.setReturnUrl(returnUrl);
            return returnUrl;
        }

        return this.getReturnUrl();
    }
}
