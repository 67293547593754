import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../attributes';

export default gql`
  mutation addConversationProgressEventToShopUserMessage($id: ID!, $status: ConversationProgressStatus!, $subStatus: String) {
    addConversationProgressEventToShopUserMessage(id: $id, status: $status, subStatus: $subStatus) {
        ${shopUserMessageAttributes}
    }
  }
`;
