@if (isLoading) {
    <ion-spinner name="crescent" color="primary"></ion-spinner>
} @else {
    <p>{{ formattedMessage }}</p>
    @if (pdfViewerData) {
        <app-pdf-viewer
                [type]="pdfViewerType"
                [payload]="pdfViewerData"
                [extendedLoader]="true"
                [useGeneralTooltips]="true"
        ></app-pdf-viewer>
    }
}

