import { Component, HostBinding, inject, Input, OnInit } from '@angular/core';
import { AlertController, PopoverController } from '@ionic/angular';
import { MaskitoElementPredicate } from '@maskito/core';

import timeMask from '../../../../core/masks/timeMask';
import { ModalService } from '../../../../core/services/modal.service';
import { LocaleDateFormat } from '../../../../core/config/formats.config';

@Component({
    selector: 'app-calendar-popover',
    templateUrl: 'calendar-popover.component.html',
    styleUrls: ['calendar-popover.component.scss'],
})
export class CalendarPopoverComponent implements OnInit {
    private popoverController = inject(PopoverController);
    private alertController = inject(AlertController);
    private _modalService = inject(ModalService);

    @Input() dateFrom = new Date();
    @Input() dateTo = new Date();
    @Input() id = '';
    @Input() title = '';
    @Input() description = '';
    @Input() formId = '';
    @Input() formButton = '';
    @Input() formAlreadySubmitted = false;
    @Input() icon = 'ban';
    @Input() lockedBySanacorp = false;
    @Input() isPharmacy = false;
    @Input() isAllDay = false;
    @Input() isBiggerPopover = false;

    @HostBinding('class.is-bigger-popover') get addOutsidePopoverClass() {
        return this.isBiggerPopover;
    }

    dateFromValue= '';
    timeFromValue= '';
    dateToValue= '';
    timeToValue= '';

    errorMsg = undefined;

    icons = [
        'ban',
        'briefcase',
        'call',
        'document-text',
        'notifications'
    ];

    date = '';
    isEdit = false;
    isEditable = false;
    isDateSelectExpanded = false;
    openModal = false;
    isFormAlreadySubmitted = false;

    dateFormat: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
    timeFormat: Intl.DateTimeFormatOptions =  { hour: '2-digit', minute: '2-digit' };

    timeMask = timeMask;
    readonly maskPredicate: MaskitoElementPredicate = async (el) => (el as HTMLIonInputElement).getInputElement();


    ngOnInit() {
        this.isEdit = !!this.id;
        this.isEditable = !this.lockedBySanacorp;
        this.isFormAlreadySubmitted = this.formAlreadySubmitted;

        // check if dateFrom and dateTo are on the same day
        if(this.isAllDay) {
            if(this.dateFrom.getDate() === this.dateTo.getDate()) {
                this.date = this.dateFrom.toLocaleDateString(LocaleDateFormat, this.dateFormat);
            } else {
                this.date = this.dateFrom.toLocaleDateString(LocaleDateFormat, this.dateFormat)
                    + ' bis '
                    + this.dateTo.toLocaleDateString(LocaleDateFormat, this.dateFormat);
            }
        } else if(this.dateFrom.getDate() === this.dateTo.getDate()) {
            this.date = this.dateFrom.toLocaleDateString(LocaleDateFormat, this.dateFormat) + '&nbsp;&nbsp;'
                + this.dateFrom.toLocaleTimeString(LocaleDateFormat, this.timeFormat)
                + ' bis '
                + this.dateTo.toLocaleTimeString(LocaleDateFormat, this.timeFormat)
                + ' Uhr';
        } else {
            this.date = this.dateFrom.toLocaleDateString(LocaleDateFormat, this.dateFormat) + '&nbsp;&nbsp;'
                + this.dateFrom.toLocaleTimeString(LocaleDateFormat, this.timeFormat)
                + ' Uhr bis <br>'
                + this.dateTo.toLocaleDateString(LocaleDateFormat,this.dateFormat)
                + '&nbsp;&nbsp;'
                + this.dateTo.toLocaleTimeString(LocaleDateFormat, this.timeFormat)
                + ' Uhr';
        }
        const pad = (num: number) => num.toString().padStart(2, '0');

        this.dateFromValue  = `${this.dateFrom.getFullYear()}-${pad(this.dateFrom.getMonth() + 1)}-${pad(this.dateFrom.getDate())}`;
        this.dateToValue    = `${this.dateTo.getFullYear()}-${pad(this.dateTo.getMonth() + 1)}-${pad(this.dateTo.getDate())}`;
        this.timeFromValue  = this.dateFrom.toLocaleTimeString(LocaleDateFormat, this.timeFormat);
        this.timeToValue    = this.dateTo.toLocaleTimeString(LocaleDateFormat, this.timeFormat);

    }

    expandDateSelect() {
        if(this.isEditable) {
            this.isDateSelectExpanded = true;
        }
    }


    /**
     * Update from and to date after changing the date to a not valid combination
     * @param isToDate - true if the date is the to date
     */
    onDateChange(isToDate = false) {
        const dateFrom = new Date(this.dateFromValue + 'T' + this.timeFromValue);
        const dateTo = new Date(this.dateToValue + 'T' + this.timeToValue);
        if(isToDate) {
            if(dateFrom.getTime() > dateTo.getTime()) {
                this.dateFromValue = this.dateToValue;
                this.timeFromValue = this.timeToValue;
            }
        } else {
            if(dateFrom.getTime() > dateTo.getTime()) {
                this.dateToValue = this.dateFromValue;
                this.timeToValue = this.timeFromValue;
            }
        }
    }

    /**
     * Validate and Save the event
     */
    onSave() {
        if(!this.isEditable) {
            return;
        }

        this.errorMsg = undefined;

        // Reset time if all day is selected
        if(this.isAllDay) {
            this.timeFromValue = '00:00';
            this.timeToValue = '00:00';
        }

        const dateFrom = new Date(this.dateFromValue + 'T' + this.timeFromValue);
        const dateTo = new Date(this.dateToValue + 'T' + this.timeToValue);


        // Validate inputs. DateFrom must be before DateTo, Title must be at least 3 characters long
        if(dateFrom.getTime() > dateTo.getTime()) {
            this.errorMsg = 'Das Startdatum muss vor dem Enddatum liegen.';
            return;
        }
        if(this.title.length < 3) {
            this.errorMsg = 'Der Titel muss mindestens 3 Zeichen lang sein.';
            return;
        }


        void this.popoverController.dismiss({
            id: this.id || null,
            title: this.title,
            description: this.description,
            dateFrom,
            dateTo,
            dateFromValue: this.dateFromValue,
            dateToValue: this.dateToValue,
            timeFromValue: this.timeFromValue,
            timeToValue: this.timeToValue,
            isPharmacy: this.isPharmacy,
            isAllDay: this.isAllDay,
            icon: this.icon === this.icons[0] ? '' : this.icon
        });
    }

    /**
     * Remove the event
     */
    async onRemove() {
        if(!this.isEditable) {
            return;
        }

        const removeAlert = await this.alertController.create({
            header: 'Termin löschen',
            cssClass: 'medium',
            message: `Möchten Sie den Termin "${this.title}" wirklich löschen?`,
            buttons: [{
                text: 'Abbrechen',
                handler: () => {
                }
            }, {
                text: 'Löschen',
                handler: () => {
                    void this.popoverController.dismiss({
                        id: this.id,
                        remove: true
                    });
                }
            }]
        });

        await removeAlert.present();
    }

    /**
     * Close the popover
     */
    onCancel() {
        void this.popoverController.dismiss();
    }


    /**
     * show appointment details modal
     */
    async showAppointmentDetailsModal() {
        if(!this.isFormAlreadySubmitted) {
            this.openModal = true;
        }
    }


    async onAppointmentFormModalClose(){
        this.openModal = false;
    }

    async onFormSubmitted(event: boolean) {
        if (event) {
            this.isFormAlreadySubmitted = true;
            this._modalService.dismiss();
        }
    }
}
