import { NgClass } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { IconNamePipe } from '../../../../../essentials/util/src/pipes/icon-name.pipe';
import { assertUnreachable } from '../../../../../essentials/util/src/typescript.util';

type ButtonStyle =
  | 'primary'
  | 'primary-inverted'
  | 'secondary'
  | 'danger'
  | 'danger-outline'
  | 'clear-danger'
  | 'clear'
  | 'clear-bold'
  | 'clear-black-outline'
  | 'sanacorp'
  | 'sanacorp-outline';

@Component({
  selector: 'mea-button',
  templateUrl: './mea-button.component.html',
  styleUrls: ['./mea-button.component.scss'],
  standalone: true,
  imports: [IonicModule, IconNamePipe, NgClass],
})
export class MeaButtonComponent {
  buttonClick = output<MouseEvent>();

  icon = input<string>();
  expand = input<'block' | 'full' | 'fancy' | undefined>();
  disabled = input(false);
  loading = input(false);
  type = input<'submit' | 'button' | 'reset'>('button');
  buttonStyle = input<ButtonStyle | undefined>('primary');

  fill = computed(() => {
    const buttonStyle = this.buttonStyle();
    switch (buttonStyle) {
      case undefined:
      case 'primary':
      case 'danger':
      case 'sanacorp':
      case 'primary-inverted':
        return 'solid';
      case 'secondary':
      case 'danger-outline':
      case 'sanacorp-outline':
      case 'clear-black-outline':
        return 'outline';
      case 'clear':
      case 'clear-danger':
      case 'clear-bold':
        return 'clear';
      default:
        return assertUnreachable(buttonStyle);
    }
  });

  onClick(event: MouseEvent) {
    event.stopPropagation();
    this.buttonClick.emit(event);
  }
}
