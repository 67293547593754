import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  setSsoPharmacies,
  setUnreadMessagesForInactiveSsoPharmacy,
  updateUnreadMessagesForInactiveSsoPharmacy,
} from './sso-pharmacies.actions';
import { SsoPharmaciesState } from './sso-pharmacies.state';

export const initialSsoPharmacyState: SsoPharmaciesState = {
  ssoPharmacies: undefined,
  unreadMessagesForInactiveSsoPharmacies: {},
};

const _ssoPharmacyReducer = createReducer(
  initialSsoPharmacyState,
  on(setSsoPharmacies, (state, { ssoPharmacies }) => update(state, { ssoPharmacies: { $set: ssoPharmacies } })),
  on(setUnreadMessagesForInactiveSsoPharmacy, (state, { cognitoId, unreadMessagesIds }) =>
    update(state, { unreadMessagesForInactiveSsoPharmacies: { [cognitoId]: { $set: unreadMessagesIds } } })
  ),
  on(updateUnreadMessagesForInactiveSsoPharmacy, (state, { cognitoId, messageId, isNew }) => {
    const unreadMessagesIds = state.unreadMessagesForInactiveSsoPharmacies[cognitoId] ?? new Set<string>();
    const updatedUnreadMessagesIds = cloneDeep(unreadMessagesIds);
    if (isNew) {
      updatedUnreadMessagesIds.add(messageId);
    } else if (updatedUnreadMessagesIds.has(messageId)) {
      updatedUnreadMessagesIds.delete(messageId);
    }
    return update(state, {
      unreadMessagesForInactiveSsoPharmacies: { [cognitoId]: { $set: updatedUnreadMessagesIds } },
    });
  })
);

export function ssoPharmaciesReducer(state: SsoPharmaciesState | undefined, action: Action): SsoPharmaciesState {
  return _ssoPharmacyReducer(state, action);
}
