import gql from 'graphql-tag';

export default gql`
  mutation addConversationProgressEvent(
    $id: ID!
    $status: ConversationProgressStatus!
    $subStatus: String
    $data: ConversationProgressEventDataInput
  ) {
    addConversationProgressEvent(id: $id, status: $status, subStatus: $subStatus, data: $data) {
      __typename
      id
    }
  }
`;
