import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';

import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { ApiService } from '../../../services/api.service';

export const UpdateCustomizableGridMutation = gql`
    mutation updateCustomizableGrid($type: customizableGridType_enum, $content: json) {
        insert_customizableGrid(
            objects: {type: $type, content: $content},
            on_conflict: {
                constraint: customizableGrid_userId_type_pharmacyStoreId_key,
                update_columns: [content]
            }) {
            affected_rows
        }
    }
`;



export const AllCustomizableGridMutations = [
    UpdateCustomizableGridMutation,
];
@Injectable()
export class CustomizableGridMutations {

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ) {}


    upsertCustomizableGrid(type: string, content: string) {
        // void this.dataChangedForceState.setForceState({[DCK.noteByUserChanged]: null});
        this.apollo.mutate({
            mutation: UpdateCustomizableGridMutation,
            variables: {
                type,
                content
            }
        }).subscribe({
            next: () => {/* Do nothing */},
            error: error => this.apiService.presentErrorToast(error, 'Die Notiz konnte nicht gespeichert werden.')
        });

    }
}
