import { Component, inject, Input } from '@angular/core';
import { take } from 'rxjs';
import { BusinessFigureLoadingInterface, NotificationInterface, PdfViewerPayloadInterface } from '../../../../../core/core.interfaces';
import { MediaDownloadTypeEnum, NotificationsEnum, PdfViewerType } from '../../../../../core/core.enums';
import { formatDateToCustomFormat } from '../../../../../core/formatting/date.formatting';
import { BusinessFigureQueries } from '../../../../../core/store/graphql/queries/business-figure.graphql';
import { MediaService } from '../../../../../core/services/media.service';
import { StatisticsAssortmentService } from '../../../../../pages/statistics/pages/statistics-assortment/statistics-assortment.service';
import { NotificationMessageConfig } from '../../../../../core/config/notification-center.config';

@Component({
    selector: 'app-notification-statistic-content',
    templateUrl: './notification-statistic-content.component.html',
    styleUrls: ['./notification-statistic-content.component.scss'],
})
export class NotificationStatisticContentComponent {
    private _businessFigureQueries = inject(BusinessFigureQueries);
    private _statisticAssortmentService = inject(StatisticsAssortmentService);
    private _mediaService = inject(MediaService);

    businessFigureLoadingStatus: BusinessFigureLoadingInterface;
    formattedMessage: string;
    pdfViewerType: PdfViewerType = PdfViewerType.statistics;
    pdfViewerData: PdfViewerPayloadInterface;
    isLoading = true;

    @Input()
    set notificationPayloadId(value: string) {
        // reset the component
        this.businessFigureLoadingStatus = null;
        this.formattedMessage = '';
        this.pdfViewerData = null;
        this.isLoading = true;

        // sales or coop statistics
        if (!value) {
            return;
        }

        this._businessFigureQueries
            .getBusinessFigureLoadingStatusById(value)
            .pipe(take(1))
            .subscribe((businessFigureLoadingStatus) => {
                this.businessFigureLoadingStatus = businessFigureLoadingStatus;
                this.pdfViewerData = {
                    filename: businessFigureLoadingStatus.filename,
                    id: businessFigureLoadingStatus.id,
                    url: this._mediaService.getMediaUrl(MediaDownloadTypeEnum.businessFigure, businessFigureLoadingStatus.filename),
                };
                this.updateFormattedMessageBusinessFigure();
                this.isLoading = false;
            });
    }

    private _notification: NotificationInterface;
    @Input()
    set notification(value: NotificationInterface) {
        this._notification = value;
        if (value?.type === NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR) {
            this.updateFormattedMessageCoopBonus();
            this.isLoading = false;
        } else if (value?.type === NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED) {
            this.updateFormattedMessageSalesVolume();
            this.isLoading = false;
        }
    }
    get notification(): NotificationInterface {
        return this._notification;
    }

    private updateFormattedMessageBusinessFigure(): void {
        let message = NotificationMessageConfig[this.notification?.type];
        if (message) {
            // Replace the {{type}} placeholder
            if (this.notification?.payload?.loadingStatusType) {
                const translatedType = this._statisticAssortmentService.getTranslatedType(this.notification.payload.loadingStatusType);
                message = message.replace('{{type}}', translatedType);
            } else {
                message = message.replace('{{type}}', '');
            }

            // Replace the {{timespan}} placeholder
            if (this.businessFigureLoadingStatus && this.businessFigureLoadingStatus.fromDate && this.businessFigureLoadingStatus.toDate) {
                const formattedFromDate = formatDateToCustomFormat(this.businessFigureLoadingStatus.fromDate, 'DD.MM.YYYY');
                const formattedToDate = formatDateToCustomFormat(this.businessFigureLoadingStatus.toDate, 'DD.MM.YYYY');
                const timespan = `${formattedFromDate} bis ${formattedToDate}`;
                message = message.replace('{{timespan}}', timespan);
            } else {
                message = message.replace('{{timespan}}', '');
            }

            this.formattedMessage = message;
        }
    }

    private updateFormattedMessageSalesVolume(): void {
        let message = NotificationMessageConfig[this.notification?.type];
        if (message) {
            this.formattedMessage = message;
        }
    }

    private updateFormattedMessageCoopBonus(): void {
        let message = NotificationMessageConfig[this.notification?.type];
        if (message) {
            // Replace the {{currentYear}} placeholder
            if (this.notification?.payload?.yearMonth) {
                const currentYear = this.notification.payload.yearMonth.slice(0, -2);
                message = message.replace('{{currentYear}}', currentYear);
            } else {
                message = message.replace('{{currentYear}}', '');
            }

            this.formattedMessage = message;
        }
    }
}
