import { gql } from '@apollo/client/core';
import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
    NotificationsSettingInterface,
    NotificationsSettingHasuraInterface,
    StatisticsSettingInterface,
    SecuritySettingInterface,
    OrdersUserSettingsInterface,
    NewsletterSettingInterface,
} from '../../../interfaces/settings.interface';
import { AuthStorageKeyEnum, DataChangedKeys as DCK, FetchPolicyKeys as FPK } from '../../../core.enums';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { DefaultSoundIntervals } from '../../../config/sound-interval-in-seconds.config';
import { UserSettingKeysEnum } from '../../../enums/user-setting-keys.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { SecurityStateVar } from '../../locals/securityState.var';
import { PharmacySettingsInterface } from '../../../interfaces/customer.interface';

export const GetNotificationSettings = (queryName) => gql`
    query ${queryName}($limit: Int) {
        settingsNotification(limit: $limit) {
            id
            notificationSoundEnabled
            notificationSoundInterval
            chatSoundEnabled
            chatSoundInterval
        }
    }
`;

export const GetNewsletterSettings = gql`
    query getNewsletterSubscriptions {
        GetNewsletterSubscriptions {
            listId
            name
            isSubscribed
        }
    }
`;

export const GetPharmacySettings = (queryName) => gql`
    query ${queryName}($limit: Int) {
        pharmacyStoreSettings(limit: $limit) {
            id
            targetSales
        }
    }
`;

export const GetSecuritySettings = (queryName) => gql`
    query ${queryName}($limit: Int) {
        settingsSecurity(limit: $limit) {
            id
            pin
        }
    }
`;

export const GetUserSettingsByKeys = (queryName) => gql`
    query ${queryName}($limit: Int, $keys: [String!]) {
        userSettings(limit: $limit, where: {key: {_in: $keys}}) {
            id
            key
            value
        }
    }
`;

export const GetPharmacyWithSettings = (queryName) => gql`
    query ${queryName}($limit: Int)  {
        pharmacyStoreSettings(limit: $limit) {
            id
            trackingEnabled
        }
    }
`;

export const AllSettingsQueries = [
    GetNotificationSettings('test'),
    GetNewsletterSettings,
    GetPharmacySettings('test'),
    GetSecuritySettings('test'),
    GetUserSettingsByKeys('test'),
    GetPharmacyWithSettings('test'),
];


@Injectable()
export class SettingsQueries extends QueryWrapper {
    private securityStateVar = inject(SecurityStateVar);

    fetchPolicies = {
        [FPK.getNotificationSettings]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getPharmacySettings]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getSecuritySettings]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getUserSettingsByKeys]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getPharmacyWithSettings]: QueryFetchPolicy.NETWORK_ONLY,
    };
    constructor(private apollo: Apollo, private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.pharmacyStoreSettingsChanged]: [FPK.getPharmacySettings, FPK.getPharmacyWithSettings],
            [DCK.settingsNotificationChanged]: [FPK.getNotificationSettings],
            [DCK.settingsSecurityChanged]: [FPK.getSecuritySettings],
            [DCK.userSettingsChanged]: [FPK.getUserSettingsByKeys],
        });
    }

    /**
     * Turn array of notification settings into single settings object
     *
     * @param notificationSettingsArray - Array of notification settings
     */
    private static mapNotificationSettings(notificationSettingsArray: Array<NotificationsSettingHasuraInterface>) {
        const notificationSettings = notificationSettingsArray ? notificationSettingsArray[0] : null;
        const notificationSettingsExist = notificationSettings && notificationSettings.id > 0;

        return {
            id: notificationSettingsExist ? notificationSettings.id : null,
            common: {
                soundEnabled: notificationSettingsExist ? notificationSettings.notificationSoundEnabled : true,
                soundInterval: notificationSettingsExist ? notificationSettings.notificationSoundInterval : DefaultSoundIntervals.common,
            },
            chat: {
                soundEnabled: notificationSettingsExist ? notificationSettings.chatSoundEnabled : true,
                soundInterval: notificationSettingsExist ? notificationSettings.chatSoundInterval : DefaultSoundIntervals.chat,
            },
        };
    }

    public getSoundNotificationSettings(): Observable<NotificationsSettingInterface> {
        const fetchPolicyKey = FPK.getNotificationSettings;
        return this.apollo
            .watchQuery({
                query: GetNotificationSettings(fetchPolicyKey),
                variables: { limit: GraphQLLimits.defaultLimit },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges.pipe(map((d) => d?.data && d?.data['settingsNotification']))
            .pipe(map(SettingsQueries.mapNotificationSettings))
            .pipe(
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<NotificationsSettingInterface>;
    }

    public getNewsletterSettings(
        fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.NETWORK_ONLY
    ): Observable<Array<NewsletterSettingInterface>> {
        return this.apollo
            .watchQuery({
                query: GetNewsletterSettings,
                variables: {},
                fetchPolicy
            })
            .valueChanges.pipe(map((d) => d?.data?.['GetNewsletterSubscriptions'])) as Observable<Array<NewsletterSettingInterface>>;
    }

    public getPharmacySettings(): Observable<Array<StatisticsSettingInterface>> {
        const fetchPolicyKey = FPK.getPharmacySettings;
        return this.apollo
            .watchQuery({
                query: GetPharmacySettings(fetchPolicyKey),
                variables: { limit: GraphQLLimits.defaultLimit },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges.pipe(map((d) => d?.data && d?.data['pharmacyStoreSettings']))
            .pipe(
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<Array<StatisticsSettingInterface>>;
    }

    /**
     * Get pharmacy data with all its settings
     */
    public getPharmacyWithSettings(): Observable<PharmacySettingsInterface[]> {
        const variables = { limit: GraphQLLimits.defaultLimit, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy) };
        const fetchPolicyKey = FPK.getPharmacyWithSettings;
        return this.apollo
            .watchQuery({
                query: GetPharmacyWithSettings(fetchPolicyKey),
                variables,
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges.pipe(map((d) => d?.data && d?.data['pharmacyStoreSettings']))
            .pipe(
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<PharmacySettingsInterface[]>;
    }

    public getSecuritySettings(): Observable<Array<SecuritySettingInterface>> {
        const fetchPolicyKey = FPK.getSecuritySettings;
        return this.apollo
            .watchQuery({
                query: GetSecuritySettings(fetchPolicyKey),
                variables: { limit: GraphQLLimits.defaultLimit },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges.pipe(map((d) => d?.data && d?.data['settingsSecurity']))
            .pipe(
                map((settingsSecurity: Array<SecuritySettingInterface>) => {
                    if (
                        settingsSecurity &&
                        (!settingsSecurity[0] || settingsSecurity[0].pin.length === 0 || settingsSecurity[0].pin === '')
                    ) {
                        this.securityStateVar.set(false, false);
                    }
                    return settingsSecurity;
                })
            )
            .pipe(
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<Array<SecuritySettingInterface>>;
    }

    public getUserSettingsByKeys(keys: UserSettingKeysEnum[]): Observable<Array<OrdersUserSettingsInterface>> {
        const fetchPolicyKey = FPK.getUserSettingsByKeys;
        return this.apollo
            .watchQuery({
                query: GetUserSettingsByKeys(fetchPolicyKey),
                variables: { limit: GraphQLLimits.defaultLimit, keys },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges.pipe(map((d) => d?.data && d?.data['userSettings']))
            .pipe(
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<Array<OrdersUserSettingsInterface>>;
    }
}
