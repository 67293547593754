import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import { firstValueFrom } from 'rxjs';
import { filter, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { Dictionary } from 'ts-essentials';
import { AppsyncMessageService } from '../../../../../common/services/src/appsync/appsync-message.service';
import { MessageCacheService } from '../../../../../common/services/src/message-cache/message-cache.service';
import { PaginatedBackendMessages } from '../../../../../essentials/types/src/backendMessage';
import { ConversationSegment } from '../../../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import { Logger } from '../../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../common.state';
import { selectCognitoId } from '../../user-store/selectors/user.selectors';
import {
  addInitialMessagesForConversation,
  addMoreMessagesForConversation,
  deleteMessage,
  initMessagesForConversation,
  loadInitialMessagesForConversation,
  loadInitialMessagesForConversationFailure,
  loadInitialMessagesForConversationSuccess,
  loadMoreMessagesForConversation,
  updateMessage,
} from '../actions/chat-message.actions';
import { selectConversation, selectMessages, selectMessagesInitializationStatus } from '../selectors/chat.selectors';

const logger = new Logger('ChatMessageEffects');

@Injectable()
export class ChatMessageEffects {
  messageLimit = 20;

  initMessagesForConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initMessagesForConversation),
        mergeMap(({ conversationId }) =>
          this.store.select(selectMessagesInitializationStatus(conversationId)).pipe(
            isNotNullOrUndefined(),
            take(1),
            tap((messagesInitializationStatus) => {
              if (
                messagesInitializationStatus === LoadStatus.Init ||
                messagesInitializationStatus === LoadStatus.Stale
              ) {
                this.store.dispatch(loadInitialMessagesForConversation({ conversationId }));
              }
            })
          )
        )
      ),
    { dispatch: false }
  );

  loadInitialMessagesForConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadInitialMessagesForConversation),
      mergeMap(async ({ conversationId }) => {
        try {
          const segmentMessages = await this.loadInitialMessagesForConversation(conversationId);
          this.store.dispatch(
            addInitialMessagesForConversation({
              conversationId,
              segmentMessages,
            })
          );
          return loadInitialMessagesForConversationSuccess({ conversationId });
        } catch (e) {
          logger.error('Error loading initial messages for conversation', e);
          return loadInitialMessagesForConversationFailure({ conversationId });
        }
      })
    )
  );

  loadMoreMessagesForConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMoreMessagesForConversation),
        mergeMap(async ({ conversationId }) => {
          try {
            const segmentMessages = await this.loadMoreMessagesForConversation(conversationId);
            this.store.dispatch(
              addMoreMessagesForConversation({
                conversationId,
                segmentMessages,
              })
            );
          } catch (e) {
            logger.error('Error loading more messages for conversation', e);
          }
        })
      ),
    { dispatch: false }
  );

  deleteMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteMessage),
        switchMap(async ({ message }) => {
          await this.appsyncMessageService.deleteMessage(message.id);
        })
      ),
    { dispatch: false }
  );

  removeDeletedMessageFromCacheOnUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateMessage),
        filter(({ message }) => !!message.isDeleted),
        withLatestFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())),
        mergeMap(([{ message }, cognitoId]) =>
          this.messageCacheService.removeFromCache(cognitoId, message.id).catch((_ignored) => {})
        )
      ),
    { dispatch: false }
  );

  removeDeletedMessagesFromCacheOnLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addInitialMessagesForConversation, addMoreMessagesForConversation),
        withLatestFrom(this.store.select(selectCognitoId).pipe(isNotNullOrUndefined())),
        mergeMap(async ([{ segmentMessages }, cognitoId]) => {
          for (const { messages } of segmentMessages) {
            const deletedMessages = messages.messages.filter((message) => message.isDeleted);
            for (const message of deletedMessages) {
              await this.messageCacheService.removeFromCache(cognitoId, message.id).catch((_ignored) => {});
            }
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private appsyncMessageService: AppsyncMessageService,
    private messageCacheService: MessageCacheService,
    private store: Store<CommonState>
  ) {}

  private async loadInitialMessagesForConversation(
    conversationId: string
  ): Promise<{ segmentId: string; messages: PaginatedBackendMessages }[]> {
    const conversation = await firstValueFrom(this.store.select(selectConversation(conversationId)));
    if (isNil(conversation)) {
      throw new Error('Tried to load messages for nil conversation');
    }
    let messageCount = 0;
    const segmentMessagesDictionary: Dictionary<PaginatedBackendMessages> = conversation.segments.reduce(
      (dict, segment) => ({ ...dict, [segment.id]: { messages: [], nextToken: '' } }),
      {}
    );
    for (let i = conversation.segments.length - 1; i >= 0; i--) {
      const segment = conversation.segments[i] as ConversationSegment;
      const segmentId = segment.id;
      const paginatedBackendMessages = await this.appsyncMessageService.loadMessagesForSegmentFromBackend(segmentId);
      segmentMessagesDictionary[segmentId] = paginatedBackendMessages;

      if (messageCount + paginatedBackendMessages.messages.length >= this.messageLimit) {
        break;
      } else {
        messageCount += paginatedBackendMessages.messages.length;
      }
    }
    return Object.entries(segmentMessagesDictionary).map(([segmentId, messages]) => ({
      segmentId,
      messages,
    }));
  }

  private async loadMoreMessagesForConversation(
    conversationId: string
  ): Promise<{ segmentId: string; messages: PaginatedBackendMessages }[]> {
    const conversation = await firstValueFrom(this.store.select(selectConversation(conversationId)));
    // TODO move nextToken and hasMoreMessages into segment to not need the messages here
    const messages = await firstValueFrom(this.store.select(selectMessages));
    if (isNil(conversation)) {
      throw new Error('Tried to load messages for nil conversation');
    }
    let messageCount = 0;
    const segmentMessages: { segmentId: string; messages: PaginatedBackendMessages }[] = [];
    for (let i = conversation.segments.length - 1; i >= 0; i--) {
      const segment = conversation.segments[i] as ConversationSegment;
      const segmentId = segment.id;

      const paginatedMessages = messages[segmentId];
      if (!paginatedMessages?.hasMoreMessages) {
        continue;
      }
      const nextToken = paginatedMessages.nextToken;
      const newMessages = await this.appsyncMessageService.loadMessagesForSegmentFromBackend(segmentId, nextToken);
      segmentMessages.push({ segmentId, messages: newMessages });

      if (messageCount + newMessages.messages.length >= this.messageLimit) {
        break;
      } else {
        messageCount += newMessages.messages.length;
      }
    }
    return segmentMessages;
  }
}
