import { Component, Input, ChangeDetectionStrategy, inject, signal, effect } from '@angular/core';
import { ModalClassEnum } from '../../core/enums/modal-class.enum';
import { ModalService } from '../../core/services/modal.service';

/**
 *
 * Usage:
 * <sconnect-alert-modal
 *      [title]="<This is the title>"
 *      [description]="<This is the description>"
 *      [showDoNotShowAgain]="<Show a checkbox to prevent the alert from appear again (true/false)>"
 *      [doNotShowAgainKey]="<Key to store the checkbox value in local storage>"
 * </sconnect-alert-modal>
 */
@Component({
    selector: 'sconnect-alert-modal',
    templateUrl: './alert-modal.component.html',
    styleUrls: ['./alert-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertModalComponent {
    static modalClass = ModalClassEnum.alertModal;
    private modalService = inject(ModalService);

    /**
     * Title of the alert modal
     */
    @Input() title = '';

    /**
     * Description of the alert modal.
     * line breaks can be added with '\n'
     */
    @Input() description = '';

    /**
     * Show a checkbox to prevent the alert from appear again
     */
    @Input() showDoNotShowAgain = false;

    /**
     * Key to store the checkbox value in local storage
     */
    @Input() doNotShowAgainKey = '';

    showAlertInFuture = signal(false);

    constructor() {
        effect(() => {
            const showInFuture = this.showAlertInFuture();
            localStorage.setItem(this.doNotShowAgainKey, showInFuture.toString());
        });
    }

    onCheckboxChange(event: boolean) {
        this.showAlertInFuture.set(event);
    }

    close() {
        void this.modalService.dismiss(true);
    }
}
