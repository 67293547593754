import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import isEqual from 'lodash-es/isEqual';
import last from 'lodash-es/last';
import { combineLatest, debounce, interval, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isPharmacyChatUser } from '../../../essentials/types/src/chatUser';
import { Conversation } from '../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../essentials/types/src/loadStatus';
import Message from '../../../essentials/types/src/message';
import { UnreadMessagesUtil } from '../../../essentials/util/src/unread-messages.util';
import { initMessagesForConversation } from '../../../store/src/common-store/chat-store/actions/chat-message.actions';
import {
  selectConversations,
  selectMessagesInitializationStatus,
  selectMessagesOfConversation,
} from '../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../store/src/common-store/common.state';
import { selectIsVisible } from '../../../store/src/common-store/device-store/selectors/device.selectors';
import { selectCognitoId } from '../../../store/src/common-store/user-store/selectors/user.selectors';

interface UnreadMessagesPerConversation {
  [conversationId: string]: { unreadMessagesIds: string[]; isEnduserConversation: boolean };
}

interface UnreadMessageCountPerConversation {
  [conversationId: string]: number;
}

interface UnreadMessages {
  conversationId: string;
  unreadMessagesIds: string[];
  isEnduserConversation: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UnreadMessagesCountService implements OnDestroy {
  unreadEnduserMessagesIds$ = new ReplaySubject<Set<string>>(1);
  unreadPharmacyMessagesIds$ = new ReplaySubject<Set<string>>(1);
  unreadMessagesTotalCount$ = new ReplaySubject<number>(1);
  unreadMessageCountPerConversation$ = new ReplaySubject<UnreadMessageCountPerConversation>(1);

  private messageObservables: {
    [conversationId: string]: Observable<Message[]>;
  } = {};

  private unsubscribe$ = new Subject<void>();

  constructor(private store: Store<CommonState>) {
    combineLatest([this.store.select(selectConversations), this.store.select(selectCognitoId)])
      .pipe(
        distinctUntilChanged(isEqual),
        map(([conversations, cognitoId]) => {
          if (cognitoId) {
            return conversations.map((conversation: Conversation) => this.getUnreadMessages$(conversation, cognitoId));
          } else {
            return of([]);
          }
        }),
        switchMap(this.waitForAllMessageCountObservables),
        debounce(() => interval(200)),
        map(this.collectUnreadMessagesByConversation),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((unreadMessagesPerConversation: UnreadMessagesPerConversation) => {
        const unreadEnduserMessagesIds = new Set<string>(
          Object.values(unreadMessagesPerConversation)
            .filter(({ isEnduserConversation }) => isEnduserConversation)
            .flatMap(({ unreadMessagesIds }) => unreadMessagesIds)
        );
        const unreadPharmacyMessagesIds = new Set<string>(
          Object.values(unreadMessagesPerConversation)
            .filter(({ isEnduserConversation }) => !isEnduserConversation)
            .flatMap(({ unreadMessagesIds }) => unreadMessagesIds)
        );
        const unreadMessageCountPerConversation = Object.keys(unreadMessagesPerConversation).reduce(
          (count, conversationId) => {
            count[conversationId] = unreadMessagesPerConversation[conversationId]?.unreadMessagesIds.length ?? 0;
            return count;
          },
          {} as UnreadMessageCountPerConversation
        );
        const unreadMessagesTotalCount = unreadEnduserMessagesIds.size + unreadPharmacyMessagesIds.size;
        this.unreadMessagesTotalCount$.next(unreadMessagesTotalCount);
        this.unreadEnduserMessagesIds$.next(unreadEnduserMessagesIds);
        this.unreadPharmacyMessagesIds$.next(unreadPharmacyMessagesIds);
        this.unreadMessageCountPerConversation$.next(unreadMessageCountPerConversation);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getUnreadMessages$(conversation: Conversation, cognitoId: string): Observable<UnreadMessages> {
    const conversationId = conversation.id;
    let messageObservable = this.messageObservables[conversationId];
    if (!messageObservable) {
      messageObservable = combineLatest([
        this.store.select(selectMessagesOfConversation(conversationId)),
        this.store.select(selectMessagesInitializationStatus(conversationId)),
        this.store.select(selectIsVisible),
      ]).pipe(
        distinctUntilChanged(isEqual),
        filter(([_, __, isVisible]) => isVisible),
        tap(([messages, messagesInitializationStatus]) =>
          this.getConversationMessagesIfLastMessageNotRead(
            messages,
            cognitoId,
            messagesInitializationStatus,
            conversationId
          )
        ),
        map(([messages]) => messages),
        shareReplay(1)
      );
      this.messageObservables[conversationId] = messageObservable;
    }
    return messageObservable.pipe(
      map((messages) => ({
        conversationId,
        unreadMessagesIds: UnreadMessagesUtil.getUnreadMessagesIds(cognitoId, messages),
        isEnduserConversation: !isPharmacyChatUser(conversation.chatPartner),
      }))
    );
  }

  /**
   * Get the messages for a conversation.
   * Trigger initial message download from the server if the last message is not yet read by the user.
   */
  private getConversationMessagesIfLastMessageNotRead(
    messages: Message[],
    cognitoId: string,
    messagesInitializationStatus: LoadStatus,
    conversationId: string
  ) {
    const lastMessage = last(messages);
    const lastMessageIsNotReadByUser = !!lastMessage && UnreadMessagesUtil.messageIsUnread(cognitoId, lastMessage);
    const messagesCanBeInitialized = [LoadStatus.Init, LoadStatus.Stale].includes(messagesInitializationStatus);
    if (lastMessageIsNotReadByUser && messagesCanBeInitialized) {
      this.store.dispatch(initMessagesForConversation({ conversationId }));
    }
  }

  private waitForAllMessageCountObservables = (
    messageCountObservables: Observable<UnreadMessages>[]
  ): Observable<UnreadMessages[]> => {
    if (!messageCountObservables.length) {
      return of([]);
    }
    return combineLatest(messageCountObservables);
  };

  private collectUnreadMessagesByConversation = (messageCounts: UnreadMessages[]): UnreadMessagesPerConversation =>
    messageCounts.reduce(
      (acc, { conversationId, unreadMessagesIds, isEnduserConversation }) => ({
        ...acc,
        [conversationId]: { unreadMessagesIds, isEnduserConversation },
      }),
      {}
    );
}
