import { Component, inject, Input } from '@angular/core';
import { take } from 'rxjs';
import { formatDateToCustomFormat } from '../../../../../core/core.formatting';
import { NotificationInterface, SurveyInterface } from '../../../../../core/core.interfaces';
import { SurveyQueries } from '../../../../../core/core.store';
import { NotificationMessageConfig } from '../../../../../core/config/notification-center.config';

@Component({
    selector: 'app-notification-survey-content',
    templateUrl: './notification-survey-content.component.html',
    styleUrls: ['./notification-survey-content.component.scss'],
})
export class NotificationSurveyContentComponent {
    private _surveyQueries = inject(SurveyQueries);

    formattedMessage: string;
    survey: SurveyInterface;

    @Input()
    set notificationPayloadId(value: string) {
        this.survey = null;
        this.formattedMessage = '';

        this._surveyQueries
            .getSurveyById(value)
            .pipe(take(1))
            .subscribe((surveyData) => {
                this.survey = surveyData;
                this.updateFormattedMessage();
            });
    }
    @Input() notification: NotificationInterface;

    private updateFormattedMessage(): void {
        let message = NotificationMessageConfig[this.notification?.type];
        if (message) {
            // Replace the {{fromDate}}, {{toDate}} and {{introductionText}} placeholders
            if (this.survey && this.survey?.fromDate && this.survey?.toDate && this.survey?.introduction) {
                const formattedFromDate = formatDateToCustomFormat(this.survey.fromDate, 'DD.MM.YYYY');
                const formattedToDate = formatDateToCustomFormat(this.survey.toDate, 'DD.MM.YYYY');
                message = message.replace('{{fromDate}}', formattedFromDate);
                message = message.replace('{{toDate}}', formattedToDate);
                message = message.replace('{{introductionText}}', this.survey.introduction);
            } else {
                message = message.replace('{{fromDate}}', '');
                message = message.replace('{{toDate}}', '');
                message = message.replace('{{introductionText}}', '');
            }

            this.formattedMessage = message;
        }
    }
}
