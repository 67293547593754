/* eslint-disable @typescript-eslint/member-ordering */
import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  BackendChatPartnerMetadata,
  ChatPartnerMetadataInput,
} from '../../../../essentials/types/src/chatPartnerMetadata';
import { CONFIG } from '../../../../essentials/types/src/mea-config';
import { Logger } from '../../../../essentials/util/src/logger';
import deleteAllChatPartnerMetadata from '../../../resources/src/graphql/mutations/deleteAllChatPartnerMetadata';
import updateChatPartnerMetadata from '../../../resources/src/graphql/mutations/updateChatPartnerMetadata';
import getAllChatPartnerMetadataForEnduser from '../../../resources/src/graphql/queries/getAllChatPartnerMetadataForEnduser';
import getAllChatPartnerMetadataForPharmacy from '../../../resources/src/graphql/queries/getAllChatPartnerMetadataForPharmacy';
import updatedChatPartnerMetadataForEnduser from '../../../resources/src/graphql/subscriptions/updatedChatPartnerMetadataForEnduser';
import updatedChatPartnerMetadataForPharmacy from '../../../resources/src/graphql/subscriptions/updatedChatPartnerMetadataForPharmacy';
import { AppsyncService, AppsyncServiceClient } from './appsync.service';

const logger = new Logger('AppsyncChatPartnerMetadataService');

@Injectable({ providedIn: 'root' })
export class AppsyncChatPartnerMetadataService {
  private config = inject(CONFIG);
  private appSync = inject(AppsyncService);

  private readonly isPharmacy = !this.config.clientApp;

  // ************* Queries *************

  async getAllChatPartnerMetadata(): Promise<BackendChatPartnerMetadata[]> {
    const client = await this.appSync.getClient();

    let next: string | undefined;
    const allChatPartnerMetadata: BackendChatPartnerMetadata[] = [];
    do {
      const { chatPartnerMetadata, nextToken } = await this.getPaginatedChatPartnerMetadata(client, next);
      allChatPartnerMetadata.push(...chatPartnerMetadata);
      next = nextToken;
    } while (next);
    return allChatPartnerMetadata;
  }

  private getPaginatedChatPartnerMetadata = async (
    client: AppsyncServiceClient,
    nextToken: string | undefined
  ): Promise<{ chatPartnerMetadata: BackendChatPartnerMetadata[]; nextToken: string | undefined }> => {
    try {
      const data = (
        await client.query({
          query: this.isPharmacy ? getAllChatPartnerMetadataForPharmacy : getAllChatPartnerMetadataForEnduser,
          variables: { nextToken },
        })
      ).data;
      const metadataQueryResult = this.isPharmacy
        ? data.getAllChatPartnerMetadataForPharmacy
        : data.getAllChatPartnerMetadataForEnduser;
      return {
        chatPartnerMetadata: metadataQueryResult.chatPartnerMetadata,
        nextToken: metadataQueryResult.nextToken,
      };
    } catch (e) {
      logger.error('Unexpected error retrieving chat partner metadata', e);
      return { chatPartnerMetadata: [], nextToken: undefined };
    }
  };

  // ************* Mutations *************

  async updateChatPartnerMetadata(
    chatPartnerId: string,
    chatPartnerMetadata: ChatPartnerMetadataInput
  ): Promise<BackendChatPartnerMetadata> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: updateChatPartnerMetadata,
      variables: { chatPartnerId, chatPartnerMetadata },
    });
    return data.updateChatPartnerMetadata;
  }

  async deleteAllChatPartnerMetadata(): Promise<boolean> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: deleteAllChatPartnerMetadata,
    });
    if (data.deleteAllChatPartnerMetadata) {
      return true;
    } else {
      logger.error('Failed to delete all chat partner metadata, lambda returned false');
      return false;
    }
  }

  // ************* Subscriptions *************

  updatedChatPartnerMetadataForPharmacy(cognitoId: string): Observable<BackendChatPartnerMetadata> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedChatPartnerMetadataForPharmacy,
            variables: { cognitoId },
          })
          .pipe(map(({ data: { updatedChatPartnerMetadataForPharmacy: chatPartnerMetadata } }) => chatPartnerMetadata))
      )
    );
  }

  updatedChatPartnerMetadataForEnduser(chatPartnerId: string): Observable<BackendChatPartnerMetadata> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedChatPartnerMetadataForEnduser,
            variables: { chatPartnerId },
          })
          .pipe(map(({ data: { updatedChatPartnerMetadataForEnduser: chatPartnerMetadata } }) => chatPartnerMetadata))
      )
    );
  }
}
