import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../attributes';

export default gql`
  mutation updateArchiveShopUserMessage($id: ID!, $archivedByPharmacy: Boolean!) {
      updateArchiveShopUserMessage(id: $id, archivedByPharmacy: $archivedByPharmacy) {
        ${shopUserMessageAttributes}
    }
  }
`;
