import Geo from './geo';
import { MessageMediaType } from './messageMediaType';
import { MimeTypeEnum } from './mimeTypeEnum';
import { Product } from './product';

interface AbstractMessageMedia {
  id?: string; // missing in old images, old files, video calls, single shop products
  mediaType: MessageMediaType;
}

export type ExifOrientation = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

export interface ImageMedia extends AbstractMessageMedia {
  mediaType: 'IMAGE';
  encryptedPreviewLink?: string;
  exifOrientation?: ExifOrientation;
  contentType?: MimeTypeEnum;
  encryptedFilename?: string;
  encryptedLink?: string;
}

export interface FileMedia extends AbstractMessageMedia {
  mediaType: 'FILE';
  encryptedPreviewLink?: string;
  contentType?: MimeTypeEnum;
  encryptedFilename?: string;
  encryptedLink?: string;
}

export interface VideoCallMedia extends AbstractMessageMedia {
  id: undefined;
  mediaType: 'VIDEO_CALL';
  videoCallUrls: VideoCallUrls;
}

export interface ShopProductMedia extends AbstractMessageMedia {
  mediaType: 'SHOP_PRODUCT';
  encryptedShopProduct?: string;
}

export interface ImageBase64Media extends AbstractMessageMedia {
  id: string;
  mediaType: 'IMAGE_BASE64';
  encryptedText?: string;
  encryptedImage?: string;
}

export interface ERezeptMedia extends AbstractMessageMedia {
  id: string;
  mediaType: 'E_REZEPT';
  encryptedERezept?: string;
}

export interface AppointmentMedia extends AbstractMessageMedia {
  id: string;
  mediaType: 'APPOINTMENT';
  encryptedText?: string;
  encryptedCalendarEvent?: string;
}

export type MessageMedia =
  | ImageMedia
  | FileMedia
  | VideoCallMedia
  | ShopProductMedia
  | ERezeptMedia
  | ImageBase64Media
  | AppointmentMedia;

export interface DecryptedImageMedia extends ImageMedia {
  decryptedFilename?: string;
  decryptedContent?: string;
  decryptedPreview?: string;
  error?: 'FAILED_TO_DOWNLOAD' | 'IMAGES_NOT_FOUND';
}

export interface DecryptedFileMedia extends FileMedia {
  decryptedFilename?: string;
  decryptedContent?: string;
  decryptedPreview?: string;
  error?: 'FAILED_TO_DOWNLOAD' | 'FILES_NOT_FOUND';
}

export interface DecryptedShopProductMedia extends ShopProductMedia {
  decryptedShopProduct?: Product;
  error?: 'PRODUCT_CARD_REMOVED';
}

export interface DecryptedImageBase64Media extends ImageBase64Media {
  decryptedImage?: string;
  decryptedText?: string;
}

export interface DecryptedAppointmentMedia extends AppointmentMedia {
  decryptedCalendarEvent?: DecryptedCalendarEvent;
  decryptedText?: string;
}

export interface DecryptedCalendarEvent {
  pharmacyName: string;
  userName: string;
  geo: Geo;
  formattedAddress: string;
  formattedAppointmentType: string;
  dateTime: string;
  durationMinutes: number;
}

export interface DecryptedERezeptMedia extends ERezeptMedia {
  decryptedERezept?: string[];
}

export type DecryptedMessageMedia =
  | DecryptedImageMedia
  | DecryptedFileMedia
  | VideoCallMedia
  | DecryptedShopProductMedia
  | DecryptedERezeptMedia
  | DecryptedImageBase64Media
  | DecryptedAppointmentMedia;

export interface VideoCallUrls {
  senderUrl: string;
  recipientUrl: string;
}

export const isImageOrFile = (media: MessageMedia): media is ImageMedia | FileMedia =>
  media.mediaType === 'IMAGE' || media.mediaType === 'FILE';

export const areSameMedia = (media1: ImageMedia | FileMedia, media2: ImageMedia | FileMedia) =>
  media1.id ? media1.id === media2.id : media1.encryptedLink === media2.encryptedLink;

export const mediaHasError = (media: DecryptedMessageMedia): boolean => mediaCanHaveError(media) && !!media.error;

export const mediaCanHaveError = (
  media: DecryptedMessageMedia
): media is DecryptedImageMedia | DecryptedFileMedia | DecryptedShopProductMedia =>
  media.mediaType === 'IMAGE' || media.mediaType === 'FILE' || media.mediaType === 'SHOP_PRODUCT';

export default MessageMedia;
