import gql from 'graphql-tag';
import { messageAttributes } from '../attributes';

export default gql`
  query getUnreadMessagesForPharmacy($cognitoId: ID!, $nextToken: String) {
    getUnreadMessagesForPharmacy(cognitoId: $cognitoId, nextToken: $nextToken) {
      __typename
      messages {
        ${messageAttributes}
      }
      nextToken
    }
  }
`;
