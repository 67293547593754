import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { UserInterface } from '../../../interfaces/user.interface';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import {
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK,
    QueryFetchPolicy
} from '../../../core.enums';
import { QueryWrapper } from '../query.wrapper';


export const GetUsers = (queryName) => gql`
    query ${queryName} {
        userDetails(limit: 100) {
            id
            firstName
            lastName
        }
    }
`;

export const AllUsersQueries = [
    GetUsers('test')
];

@Injectable()
export class UserQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getUserDetails]: QueryFetchPolicy.NETWORK_ONLY,
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.userDetailChanged]: [FPK.getUserDetails]
        });
    }

    public getUsers(): Observable<UserInterface[]> {
        const fetchPolicyKey = FPK.getUserDetails;
        return this.apollo.watchQuery({
            query: GetUsers(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            errorPolicy: 'all'
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['userDetails']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<UserInterface[]>;
    }
}
