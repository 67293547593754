import { Component, inject, Input } from '@angular/core';
import { take } from 'rxjs';
import { DocumentInterface, PdfViewerPayloadInterface } from '../../../../../core/core.interfaces';
import { PdfViewerType } from '../../../../../core/core.enums';
import { DocumentService } from '../../../../../core/core.services';
import { DocumentQueries } from '../../../../../core/core.store';
import { NotificationInterface } from '../../../../../core/interfaces/notification.interface';
import { NotificationMessageConfig } from '../../../../../core/config/notification-center.config';

@Component({
    selector: 'app-notification-document-content',
    templateUrl: './notification-document-content.component.html',
    styleUrls: ['./notification-document-content.component.scss'],
})
export class NotificationDocumentContentComponent {
    private _documentQueries = inject(DocumentQueries);
    private _documentService = inject(DocumentService);

    formattedMessage: string;
    document: DocumentInterface;
    pdfViewerType: PdfViewerType = PdfViewerType.invoice;
    pdfViewerData: PdfViewerPayloadInterface;

    @Input()
    set notificationPayloadId(value: string) {
        this.document = null;
        this.formattedMessage = '';
        this.pdfViewerData = null;

        const id = parseInt(value, 10);
        this._documentQueries
            .getDocumentById(id)
            .pipe(take(1))
            .subscribe((document) => {
                this.document = document;
                this.pdfViewerData = {
                    filename: this._documentService.getDocumentDownloadFilename(document),
                    id: document.id,
                };
                this.updateFormattedMessage();
            });
    }
    @Input() notification: NotificationInterface;

    private updateFormattedMessage() {
        let message = NotificationMessageConfig[this.notification?.type];
        if (message) {
            // replace {{yearMonth}} with the formatted date
            if (this.notification?.payload['recDateFormatted']) {
                message = message.replace('{{yearMonth}}', this.notification.payload['recDateFormatted']);
            } else {
                message = message.replace('{{yearMonth}}', '');
            }

            this.formattedMessage = message;
        }
    }
}
