import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';


/**
 *
 * Usage:
 * <app-widget>
 *   <div header></div>
 *   <div content></div>
 *   <div footer></div>
 * </app-widget>
 */
@Component({
    selector: 'app-modal2',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() color = '';
    @Input() fullScale = false;
    @Input() contentPadding = true;
    @Input() contentBG = true;
    @Input() contentFlex = false;
    @Input() contentOverflowHidden = false;
    @Input() useScrollListener = false;
    @Output() scrolledToEnd = new EventEmitter<boolean>();

    constructor(private modalService: ModalController) { }

    ngOnInit() {
        this.scrolledToEnd.emit(false);
    }

    /**
     * closes the modal window
     */
    async closeModal() {
        await this.modalService.dismiss();
    }

    scrolled(event) {
        const position = event.target.scrollTop + event.target.offsetHeight;
        const max = event.target.scrollHeight;

        this.scrolledToEnd.emit(position === max);
    }

}
