import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'app-mea-downloads-info-modal',
    templateUrl: './downloads-info-modal.component.html',
    styleUrls: ['./downloads-info-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadsInfoModalComponent {

}
