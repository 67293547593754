import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../attributes';

export default gql`
  mutation deleteShopUserMessage($id: ID!) {
    deleteShopUserMessage(id: $id) {
      ${shopUserMessageAttributes}
    }
  }
`;
