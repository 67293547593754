import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { SubscriptionManagementService } from '../../../../common/services/src/subscriptions/subscription-management.service';
import {
  BackendShopUserMessage,
  PaginatedShopUserMessages,
  ShopUserMessage,
} from '../../../../essentials/types/src/shopUserMessage';
import { selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import { MeaPharmacyState } from '../mea-pharmacy.state';
import { AppsyncShopUserMessageService } from './appsync-shop-user-message.service';
import { ShopUserMessagesDecryptionService } from './shop-user-messages-decryption.service';
import {
  addCreatedShopUserMessage,
  deleteShopUserMessage,
  updateUpdatedShopUserMessage,
} from './shop-user-messages.actions';

@Injectable({ providedIn: 'root' })
export class ShopUserMessagesService {
  private appsyncShopUserMessageService = inject(AppsyncShopUserMessageService);
  private shopUserMessagesDecryptionService = inject(ShopUserMessagesDecryptionService);
  private store: Store<MeaPharmacyState> = inject(Store);
  private subscriptionManagementService = inject(SubscriptionManagementService);

  public async loadRecentShopUserMessages(): Promise<ShopUserMessage[]> {
    const backendShopUserMessages = await this.loadPaginatedShopUserMessages((nextToken) =>
      this.appsyncShopUserMessageService.getRecentShopUserMessages(nextToken)
    );
    return this.shopUserMessagesDecryptionService.decryptShopUserMessages(backendShopUserMessages);
  }

  public async loadAllShopUserMessages(): Promise<ShopUserMessage[]> {
    const backendShopUserMessages = await this.loadPaginatedShopUserMessages((nextToken) =>
      this.appsyncShopUserMessageService.getAllShopUserMessages(nextToken)
    );
    return this.shopUserMessagesDecryptionService.decryptShopUserMessages(backendShopUserMessages);
  }

  public async loadUnreadShopUserMessagesForPharmacy(cognitoId: string): Promise<ShopUserMessage[]> {
    const backendShopUserMessages = await this.loadPaginatedShopUserMessages((nextToken) =>
      this.appsyncShopUserMessageService.getUnreadShopUserMessagesForPharmacy(cognitoId, nextToken)
    );
    return this.shopUserMessagesDecryptionService.decryptShopUserMessages(backendShopUserMessages);
  }

  public async subscribeToNewShopUserMessages() {
    const user = await firstValueFrom(this.store.select(selectUser));
    if (!user) {
      throw new Error('Can not subscribe to new shop user messages without user');
    }
    const privateKey = user?.privateKey;
    if (!privateKey) {
      throw new Error('Can not decrypt new shop user messages without private key');
    }
    this.subscriptionManagementService.subscribe(
      'createdShopUserMessage',
      this.appsyncShopUserMessageService.createdShopUserMessage(user.cognitoId),
      async (createdShopUserMessage) => {
        const shopUserMessage = await this.shopUserMessagesDecryptionService.getDecryptedShopUserMessage(
          createdShopUserMessage,
          user.cognitoId,
          privateKey
        );
        this.store.dispatch(addCreatedShopUserMessage({ shopUserMessage }));
      }
    );
  }

  public async subscribeToUpdatedShopUserMessages() {
    const user = await firstValueFrom(this.store.select(selectUser));
    if (!user) {
      throw new Error('Can not subscribe to updated shop user messages without user');
    }
    const privateKey = user?.privateKey;
    if (!privateKey) {
      throw new Error('Can not decrypt updated shop user messages without private key');
    }
    this.subscriptionManagementService.subscribe(
      'updatedShopUserMessage',
      this.appsyncShopUserMessageService.updatedShopUserMessage(user.cognitoId),
      async (updatedShopUserMessage) => {
        const shopUserMessage = await this.shopUserMessagesDecryptionService.getDecryptedShopUserMessage(
          updatedShopUserMessage,
          user.cognitoId,
          privateKey
        );
        this.store.dispatch(updateUpdatedShopUserMessage({ shopUserMessage }));
      }
    );
  }

  public async subscribeToDeletedShopUserMessages() {
    const user = await firstValueFrom(this.store.select(selectUser));
    if (!user) {
      throw new Error('Can not subscribe to deleted shop user messages without user');
    }
    this.subscriptionManagementService.subscribe(
      'deletedShopUserMessage',
      this.appsyncShopUserMessageService.deletedShopUserMessage(user.cognitoId),
      (deletedShopUserMessage) => {
        this.store.dispatch(deleteShopUserMessage({ id: deletedShopUserMessage.id }));
      }
    );
  }

  private async loadPaginatedShopUserMessages(
    method: (nextToken?: string) => Promise<PaginatedShopUserMessages>
  ): Promise<BackendShopUserMessage[]> {
    let next: string | undefined;
    const backendShopUserMessages: BackendShopUserMessage[] = [];
    do {
      const { shopUserMessages, nextToken } = await method(next);
      backendShopUserMessages.push(...(shopUserMessages || []));
      next = nextToken || undefined;
    } while (next);
    return backendShopUserMessages;
  }
}
