import { inject, Injectable } from '@angular/core';
import { Amplify } from '@aws-amplify/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';
import { LogoutService } from '../../../common/services/src/logout.service';
import { LoadStatus } from '../../../essentials/types/src/loadStatus';
import { LogoutType } from '../../../essentials/types/src/logoutType';
import { CONFIG } from '../../../essentials/types/src/mea-config';
import { getMeaPharmacyWebDeeplink } from '../../../essentials/types/src/web-deeplinks';
import { isNotNullOrUndefined } from '../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { MeaPharmacyState } from '../../../mea-pharmacy-only/src/store/mea-pharmacy.state';
import { selectShopUserMessagesLoadStatus } from '../../../mea-pharmacy-only/src/store/shop-user-messages/shop-user-messages.selectors';
import { selectChatData } from '../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { selectUser } from '../../../store/src/common-store/user-store/selectors/user.selectors';
import { MeaChatConversationsService } from './mea-chat-conversations.service';
import { MeaChatSignInService } from './mea-chat-signin.service';
import { LoginStatus } from './types/loginStatus';
import { SconnectConversation } from './types/sconnectConversation';

@Injectable({ providedIn: 'root' })
export class MeaChatSconnectService {
  private logoutService = inject(LogoutService);
  private meaChatConversationsService = inject(MeaChatConversationsService);
  private meaChatSignInService = inject(MeaChatSignInService);
  private store = inject<Store<MeaPharmacyState>>(Store);
  private translate = inject(TranslateService);
  private config = inject(CONFIG);

  /** indicator if there are unread messages from endusers or pharmacies */
  hasUnreadMessages$: Observable<boolean> = this.meaChatConversationsService.hasUnreadMessages$;

  /** messages from the pharmacy's online shop */
  meaShopMessages$: Observable<SconnectConversation[]> = this.meaChatConversationsService.meaShopMessages$;

  /** messages from the pharmacy's online shop that are not archived by the pharmacy */
  openMeaShopMessages$: Observable<SconnectConversation[]> = this.meaChatConversationsService.openMeaShopMessages$;

  /** conversations with mea app users */
  meaAppConversations$: Observable<SconnectConversation[]> = this.meaChatConversationsService.meaAppConversations$;

  /** conversations with mea app users that are not archived by the pharmacy */
  openMeaAppConversations$: Observable<SconnectConversation[]> =
    this.meaChatConversationsService.openMeaAppConversations$;

  /** meaAppConversations and meaShopMails combined and sorted by timestamp */
  enduserConversations$: Observable<SconnectConversation[]> = this.meaChatConversationsService.enduserConversations$;

  /** enduserConversations that are not archived by the pharmacy */
  openEnduserConversations$: Observable<SconnectConversation[]> =
    this.meaChatConversationsService.openEnduserConversations$;

  /** conversations with pharmacies */
  pharmacyConversations$: Observable<SconnectConversation[]> = this.meaChatConversationsService.pharmacyConversations$;

  /** user is set in store after successful login to mea chat, triggers loading of conversations */
  userIsSet$: Observable<boolean> = this.store.select(selectUser).pipe(map((user) => !!user));

  /** conversations can be displayed once they are loaded */
  chatDataIsUpToDate$: Observable<boolean> = combineLatest([
    this.store.select(selectChatData).pipe(isNotNullOrUndefined()),
    this.store.select(selectShopUserMessagesLoadStatus),
  ]).pipe(
    map(
      ([chatData, shopMessagesLoadStatus]) =>
        chatData.conversationsLoadStatus === LoadStatus.UpToDate && shopMessagesLoadStatus === LoadStatus.UpToDate
    )
  );

  /** must be called once in Sconnect AppComponent constructor */
  init() {
    Amplify.configure({ ...this.config.aws_config, Analytics: { disabled: true } });
    this.translate.setDefaultLang('de');
  }

  /** login pharmacy to mea chat with keycloak bearer token */
  async loginToMeaChat(sanacorpCustomerId: string | undefined, bearerToken: string | undefined): Promise<LoginStatus> {
    if (sanacorpCustomerId && bearerToken) {
      return this.meaChatSignInService.amplifySignInWithSsoToken(sanacorpCustomerId, bearerToken);
    }
    return LoginStatus.LoginError;
  }

  /** restore user's private key in case login returns LoginStatus.RestorePrivateKey */
  async restorePrivateKey(restorePassword: string): Promise<LoginStatus> {
    return this.meaChatSignInService.restorePrivateKeyAndContinueSignIn(restorePassword);
  }

  /** logout pharmacy from mea chat */
  async logoutFromMeaChat(): Promise<void> {
    await this.logoutService.logout(LogoutType.ActiveSconnectLogout);
  }

  /** get redirect link to conversation in mea chat frontend */
  getMeaChatRedirectUrl(sanacorpCustomerId: string, { id: orderId, chatPartner }: SconnectConversation): string {
    const queryUrl = getMeaPharmacyWebDeeplink({ sanacorpCustomerId, orderId, chatPartner });
    return this.config.meaChatUrl + queryUrl;
  }
}
