import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { appointmentV2Reducer } from '../../../store/src/pharmacy/appointmentsV2/appointmentV2.reducer';
import { pharmacyChatPartnerReducer } from '../../../store/src/pharmacy/pharmacy-chat-partner-store/pharmacy-chat-partner.reducer';
import { textblockReducer } from '../../../store/src/pharmacy/textblocks/reducers/textblock.reducer';
import { userConsentReducer } from '../../../store/src/pharmacy/user-consent/user-consent.reducer';
import { AppointmentV2Effects } from './appointments/appointmentV2.effects';
import { HomeEffects } from './home/effects/home.effects';
import { homeReducer } from './home/reducers/home.reducer';
import { PharmacyChatPartnerEffects } from './pharmacy-chat-partner-store/pharmacy-chat-partner.effects';
import { PharmacySubscriptionEffects } from './pharmacy-subscription/pharmacy-subscription.effects';
import { ShopUserMessagesEffects } from './shop-user-messages/shop-user-messages.effects';
import { shopUserMessagesReducer } from './shop-user-messages/shop-user-messages.reducer';
import { SsoPharmaciesEffects } from './sso-pharmacies/sso-pharmacies.effects';
import { ssoPharmaciesReducer } from './sso-pharmacies/sso-pharmacies.reducer';
import { TextblockEffects } from './textblocks/textblock.effects';
import { UserConsentEffects } from './user-consent/user-consent.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('appointmentV2', appointmentV2Reducer),
    StoreModule.forFeature('shopUserMessages', shopUserMessagesReducer),
    StoreModule.forFeature('ssoPharmacies', ssoPharmaciesReducer),
    StoreModule.forFeature('textblockData', textblockReducer),
    StoreModule.forFeature('home', homeReducer),
    StoreModule.forFeature('pharmacyChatPartnerMetadata', pharmacyChatPartnerReducer),
    StoreModule.forFeature('userConsent', userConsentReducer),
    EffectsModule.forFeature([
      AppointmentV2Effects,
      ShopUserMessagesEffects,
      SsoPharmaciesEffects,
      TextblockEffects,
      PharmacySubscriptionEffects,
      HomeEffects,
      PharmacyChatPartnerEffects,
      UserConsentEffects,
    ]),
  ],
})
export class MeaPharmacyStoreModule {}
