import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { TableModule } from 'primeng/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxBarcode6Module } from 'ngx-barcode6';

import { CoreModule } from '../../../core/core.module';
import { OffersBannerComponent } from './offers-banner/offers-banner.component';
import { OffersCardComponent } from './offers-card/offers-card.component';
import { OffersActionsComponent } from './offers-action/offers-actions.component';
import { OffersCartComponent } from './offers-cart/offers-cart.component';
import { OffersInfoComponent } from './offers-info/offers-info.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MarkdownModule,
        ReactiveFormsModule,
        CoreModule,
        TableModule,
        IonicModule,
        ClipboardModule,
        NgxBarcode6Module
    ],
    declarations: [
        OffersBannerComponent,
        OffersCardComponent,
        OffersActionsComponent,
        OffersCartComponent,
        OffersInfoComponent
    ],
    exports: [
        OffersBannerComponent,
        OffersCardComponent,
        OffersActionsComponent,
        OffersCartComponent,
        OffersInfoComponent
    ]
})
export class OffersWidgetsModule {
}
