import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppsyncConversationService } from '../../../common/services/src/appsync/appsync-conversation.service';
import { CustomModalController } from '../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { ChatOrder, ChatOrderWithLastMessage, orderIsShopOrder, ShopOrder } from '../../../essentials/types/src/order';
import { ConversationPopoverOptionsServiceInterface } from '../../../essentials/types/src/service-interfaces/conversation-popover-options-service.interface';
import { Logger } from '../../../essentials/util/src/logger';
import { updateArchivedByPharmacyConversation } from '../../../store/src/common-store/chat-store/actions/chat-conversation-update.actions';
import { CommonState } from '../../../store/src/common-store/common.state';
import { DeleteOrderModalComponent } from '../components/chat/common/delete-conversation-modal/delete-order-modal.component';
import { AppsyncShopUserMessageService } from '../store/shop-user-messages/appsync-shop-user-message.service';

const logger = new Logger('ConversationPopoverOptionsService');

@Injectable({
  providedIn: 'root',
})
export class ConversationPopoverOptionsService implements ConversationPopoverOptionsServiceInterface {
  private appsyncConversationService = inject(AppsyncConversationService);
  private appsyncShopUserMessageService = inject(AppsyncShopUserMessageService);
  private modalController = inject(CustomModalController);
  private store: Store<CommonState> = inject(Store);

  getPopoverOptions(order: ChatOrder | ChatOrderWithLastMessage | ShopOrder, deleteOption = false) {
    const orderId = order.orderId;
    const isShopOrder = orderIsShopOrder(order);
    const currentlyArchived = isShopOrder
      ? !!order.shopUserMessage.archivedByPharmacy
      : !!order.conversation.archivedByPharmacy;
    const hasShoppingCart = !isShopOrder && !!order.conversation.encryptedShoppingCart;

    const popoverOptions = [
      {
        text: currentlyArchived ? 'CHAT.UNARCHIVE' : 'CHAT.ARCHIVE',
        handler: async () =>
          isShopOrder
            ? await this.setArchiveTagInShopUserMessage(orderId, !currentlyArchived)
            : await this.setArchiveTagInConversation(orderId, !currentlyArchived),
      },
    ];
    if (deleteOption) {
      popoverOptions.push({
        text: hasShoppingCart ? 'DELETE_CHAT.ORDER_HEADER' : 'DELETE_CHAT.QUESTION_HEADER',
        handler: () => this.openDeleteOrderModal(order),
      });
    }
    return popoverOptions;
  }

  async setArchiveTagInConversation(conversationId: string, newStatus: boolean) {
    try {
      await this.appsyncConversationService.updateArchivedByPharmacy(conversationId, newStatus);
      this.store.dispatch(updateArchivedByPharmacyConversation({ conversationId, archivedByPharmacy: newStatus }));
    } catch (e) {
      logger.error('Error updating archivedByPharmacy in conversation', e);
    }
  }

  async setArchiveTagInShopUserMessage(shopUserMessageId: string, newStatus: boolean) {
    try {
      await this.appsyncShopUserMessageService.updateArchivedByPharmacy(shopUserMessageId, newStatus);
    } catch (e) {
      logger.error('Error updating archivedByPharmacy in shopUserMessage', e);
    }
  }

  async openDeleteOrderModal(order: ChatOrder | ChatOrderWithLastMessage | ShopOrder) {
    const modal = await this.modalController.create({
      component: DeleteOrderModalComponent,
      componentProps: { order },
      cssClass: 'fancy-small',
    });
    await modal.present();
  }
}
