export enum OffersOrderTypeEnum {
    SCHEDULED_ORDER = 'Terminauftrag',
    IMMEDIATE_ORDER = 'Sofortauftrag',
    BROKEN_GOODS = 'Bruchware',
    EXTRAS = 'Sonstiges'
}

export enum SortableOffersFieldsEnum {
    productName = 'productName',
    dosageForm = 'dosageForm',
    pzn = 'pzn'
}
