import { inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, map, Subject, Subscription, switchMap, tap } from 'rxjs';
import * as dayjs from 'dayjs';

import { BusinessFigureQueries } from '../../../../core/store/graphql/queries/business-figure.graphql';
import { ProducerQueries } from '../../../../core/store/graphql/queries/producer.graphql';
import { StatisticsMutations } from '../../../../core/store/graphql/mutations/statistics.graphql';
import { getDatePartOfDate, getLastOfTheMonth } from '../../../../core/formatting/date.formatting';
import { DateEnum } from '../../../../core/enums/date.enum';
import { BusinessFigurePdfTypeEnum, StatisticsAssortmentAlertEnum } from '../../../../core/enums/statistics.enum';
import { MediaDownloadTypeEnum } from '../../../../core/enums/media.enum';
import { ToastService } from '../../../../core/services/toast.service';
import { ModalService } from '../../../../core/services/modal.service';
import { MediaService } from '../../../../core/services/media.service';

import { DocumentListInterface, DocumentListItemInterface } from '../../../../ui/document-list/interfaces/document-list-item.interface';
import { AlertModalComponent } from '../../../../ui/alert-modal/alert-modal.component';


const TitleByType = {
    [BusinessFigurePdfTypeEnum.BTM]: 'BTM',
    [BusinessFigurePdfTypeEnum.TFG]: 'Charge/TFG',
    [BusinessFigurePdfTypeEnum.VETERINARY]: 'Tierarzneimittel',
    [BusinessFigurePdfTypeEnum.REIMPORTS]: 'Reimporte',
    [BusinessFigurePdfTypeEnum.DEFECT_REIMPORTS]: 'Reimporte defekt',
    [BusinessFigurePdfTypeEnum.DEFECT_CONTRACT_ITEMS]: 'Vertragsartikel defekt',
    [BusinessFigurePdfTypeEnum.PRODUCER]: 'Hersteller',
    [BusinessFigurePdfTypeEnum.HIGH_PRICED_ITEMS]: 'Hochpreisige Artikel'
};

const DescriptionByType = {
    [BusinessFigurePdfTypeEnum.BTM]: 'Die Statistik weist alle Betäubungsmittel aus, die Sie in dem auszuwählenden Zeitraum bei der Sanacorp bezogen haben.',
    [BusinessFigurePdfTypeEnum.TFG]: 'Ausweis der von Ihnen bezogenen Artikel, die dem Transfusionsgesetz unterliegen.',
    [BusinessFigurePdfTypeEnum.VETERINARY]: 'Die Statistik weist alle Tierarzneimittel aus, die Sie in dem auszuwählenden Zeitraum bei der Sanacorp bezogen haben.',
    [BusinessFigurePdfTypeEnum.REIMPORTS]: 'Die Statistik zeigt alle Reimporte, die Sie in dem auszuwählenden Zeitraum bei der Sanacorp bezogen haben.',
    [BusinessFigurePdfTypeEnum.DEFECT_REIMPORTS]: 'Die Statistik zeigt alle defekten Reimporte, die Sie in dem auszuwählenden Zeitraum bei der Sanacorp bezogen haben.',
    [BusinessFigurePdfTypeEnum.DEFECT_CONTRACT_ITEMS]: 'Die Statistik zeigt alle defekten Vertragsartikel, die Sie in dem auszuwählenden Zeitraum bei der Sanacorp bezogen haben.',
    [BusinessFigurePdfTypeEnum.PRODUCER]: 'Die Statistik zeigt alle Artikel eines auszuwählenden Herstellers, die Sie in dem auszuwählenden Zeitraum bei der Sanacorp bezogen haben. Nur für ausgewählte Hersteller verfügbar.',
    [BusinessFigurePdfTypeEnum.HIGH_PRICED_ITEMS]: 'Die Statistik zeigt alle hochpreisigen Artikel, die Sie in dem auszuwählenden Zeitraum bei der Sanacorp bezogen haben.'
};

@Injectable({
    providedIn: 'root'
})
export class StatisticsAssortmentService {
    private businessFigureQueries = inject(BusinessFigureQueries);
    private producerQueries = inject(ProducerQueries);
    private statisticsMutations = inject(StatisticsMutations);
    private toastService = inject(ToastService);
    private modalService = inject(ModalService);
    private mediaService = inject(MediaService);

    private subscriptionsStatus: {[type: string]: boolean} = {};
    private subscriptionChangeBuffer = new Subject<{
        checkboxState: boolean,
        businessFigureType: string
    }>();

    constructor() {
        this.subscriptionChangeBuffer.pipe(
            debounceTime(2000)
        ).subscribe(() => {
            const classifiedTypes =
                Object.entries(this.subscriptionsStatus)
                    .reduce((accumulator, [type, checked]) => {
                            const convertedType = type as BusinessFigurePdfTypeEnum;
                            checked
                                ? accumulator.checked.push(convertedType)
                                : accumulator.unchecked.push(convertedType);
                            return accumulator;
                        }, {checked: [], unchecked: []});
            if (classifiedTypes.checked.length > 0) {
                this.insertBusinessFigureSubscriptions(classifiedTypes.checked);
            }
            if (classifiedTypes.unchecked.length > 0) {
                this.deleteBusinessFigureSubscription(classifiedTypes.unchecked);
            }
            // Clear the checkboxStatus now that updates have been made
            this.subscriptionsStatus = {};
        });

        this.subscriptionChangeBuffer.pipe(
            tap(event => {
                this.subscriptionsStatus[event.businessFigureType] = event.checkboxState;
            })
        ).subscribe();
    }

    private _loadingDocumentTypes = signal<BusinessFigurePdfTypeEnum[]>([]);
    public loadingDocumentTypes = this._loadingDocumentTypes.asReadonly();

    private _documentsByType = combineLatest([
        this.businessFigureQueries.getBusinessFigureSubscriptions().pipe(
            map((data: any[]) => data.map(item => item.subType))
        ),
        this.businessFigureQueries.getBusinessFiguresLoading(),
        toObservable(this.loadingDocumentTypes)
    ]).pipe(
        map(([subscriptions, loadingStatus, loadingTypes]) => {
            // map loading status and subscription to a map of type
            return Object.values(BusinessFigurePdfTypeEnum).map(key => ({
                title: TitleByType[key],
                description: DescriptionByType[key],
                type: key,
                isLoading: loadingTypes.includes(key),
                isSubscribed: subscriptions.includes(key),
                hasSubscriptionFeature: true,
                subscriptionLabel: key === BusinessFigurePdfTypeEnum.PRODUCER ? 'monatliche PDF-Generierung für alle verfügbaren Hersteller aktivieren' : 'monatliche PDF-Generierung aktivieren',
                documents: loadingStatus?.filter(doc => doc.type === key).map(doc => ({
                    id: doc.id,
                    createdAt: doc.created_at,
                    title: this._formatDate(doc.fromDate) + ' - ' + this._formatDate(doc.toDate),
                    subtitle: key === BusinessFigurePdfTypeEnum.PRODUCER ? doc.producer.producerName : null,
                    filename: doc.filename,
                    url: this.mediaService.getMediaUrl(MediaDownloadTypeEnum.businessFigure, doc.filename),
                    isFinished: doc.isFinished,
                    fromDate: dayjs(doc.fromDate).toDate(),
                    toDate: dayjs(doc.toDate).toDate(),
                    producer: doc.producer
                }))
            }));
        })
    );

    public documentsByType = toSignal<DocumentListInterface[]>(this._documentsByType, {initialValue: null});



    // PRODUCER
    // ----------------------------------------------------------------------------------------
    producerSubscription : Subscription;
    producers = toSignal(this.producerQueries.getProducer().pipe(
        map(producers => producers || [])
    ));
    producerId = signal<number>(null);
    producerInternalId = signal<number>(null);

    existingItemIndex  = signal<{type: string, index: number}>(null);

    /**
     * Formate date output
     *
     * @param date
     * @private
     */
    private _formatDate(date){
        return getDatePartOfDate(date, DateEnum.monthLabelLong).substring(0, 3)
            + ' '
            + getDatePartOfDate(date, DateEnum.year);
    }

    /**
     * Reset the existing item index
     *
     * @param type
     */
    resetExistingItem(type: string) {
        if (this.existingItemIndex()?.type === type) {
            this.existingItemIndex.set(null);
        }
    }

    /**
     * User selects a producer from the popover
     * @param id
     */
    onProducerPopoverItemSelect(id: number) {
        if (!id) {
            return;
        }
        const producer = this.producers()?.find(element => element.id === id);
        this.producerId.set(producer?.producerId ?? null);
        this.producerInternalId.set(producer?.id ?? null);
    }

    /**
     * Update the subscription status of a document type
     *
     * @param type
     * @param checked
     */
    updateSubscription (type: string, checked: boolean) {
        this.subscriptionChangeBuffer.next({
            checkboxState: checked,
            businessFigureType: type,
        });
    }

    /**
     * User requests document for a specific date.
     * Check if the user has already requested the same document
     * @param type
     * @param date
     */
    async requestDocument(type: string, date: Date[]) {
        const maxDate = new Date();

        if (date?.length <= 0 || !date) {
            void this.toastService.presentError('Bitte wählen Sie einen Zeitraum aus.');
            return;
        }
        if (date[0] > maxDate) {
            void this.toastService.presentError('Das Datum darf nicht in der Zukunft liegen.');
            return;
        }
        if (date[0] && !date[1]) {
            date[1] = getLastOfTheMonth(date[0]);
        }
        if (date[0] && date[1]) {
            date[1] = getLastOfTheMonth(date[1]);
        }

        const itemExists = this.checkIfItemExists(type, date);
        if (itemExists) {
            return;
        }

        const hideAlert = localStorage.getItem(StatisticsAssortmentAlertEnum.SHOW_IN_FUTURE) === 'true';
        if (hideAlert) {
            this.getPdf(type as BusinessFigurePdfTypeEnum, date);
            return;
        }
        const modal = await this.modalService.create(AlertModalComponent, {
            title: 'Hinweis',
            description:
                'Die Bereitstellung der PDF-Datei kann einige Zeit in Anspruch nehmen. ' +
                'Sie werden darüber informiert, sobald die Datei verfügbar ist.',
            showDoNotShowAgain: true,
            doNotShowAgainKey: StatisticsAssortmentAlertEnum.SHOW_IN_FUTURE,
        });
        await modal.present();
        modal.onDidDismiss().then((response) => {
            if (response.data) {
                this.getPdf(type as BusinessFigurePdfTypeEnum, date);
            }
        });
    }

    /**
     *
     * @param type
     * @param date
     */
    getPdf(type: BusinessFigurePdfTypeEnum, date: Date[]){
      const businessFigureType = type === BusinessFigurePdfTypeEnum.PRODUCER ? this.producerId() : null;
      this._loadingDocumentTypes.update(types => [...types, type]);

      this.statisticsMutations.requestBusinessFigurePdf(type,
          this.parseDate(date[0]),
          this.parseDate(date[1]),
          businessFigureType
      ).then(() => {
          this._loadingDocumentTypes.update(types => types.filter(t => t !== type));
      });
    }


    /**
     * Check if the document already exists
     * @param type
     * @param date
     */
    checkIfItemExists(type: string, date: Date[]) {
        const fromDate = this.parseDate(date[0]);
        const toDate = this.parseDate(date[1]);
        this.existingItemIndex.set(null);

        const documents = this.documentsByType();
        const documentsByType = documents.find(doc => doc.type === type);
        // If no documents are loaded yet, return false
        if (!documentsByType) {
            return false;
        }

        let index: number;
        if(type === BusinessFigurePdfTypeEnum.PRODUCER) {
            const producerInternalId = this.producerInternalId();
            index = documentsByType.documents.findIndex(doc =>
                this.parseDate(doc.fromDate) === fromDate && this.parseDate(doc.toDate) === toDate && doc.producer.id === producerInternalId
            );
        } else {
            index = documentsByType.documents.findIndex(doc =>
                this.parseDate(doc.fromDate) === fromDate && this.parseDate(doc.toDate) === toDate
            );
        }


        if (index !== -1) {
            this.existingItemIndex.set({type, index});
        }
        return this.existingItemIndex() !== null;
    }

    parseDate(date: Date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }



    insertBusinessFigureSubscriptions(types: BusinessFigurePdfTypeEnum[]) {
        this.statisticsMutations.insertBusinessFigureSubscriptions(types);
    }

    deleteBusinessFigureSubscription(types: BusinessFigurePdfTypeEnum[]) {
        this.statisticsMutations.deleteBusinessFigureSubscriptions(types);
    }

    getTranslatedType(type: BusinessFigurePdfTypeEnum) {
        return TitleByType[type];
    }

    markDocumentAsDownloaded(document: DocumentListItemInterface){
        this.statisticsMutations.markDocumentAsDownloaded(document.id);
    }
}
