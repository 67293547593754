<app-modal2 [title]="title" i18n-title>
    <ng-container content>
        <ion-text i18n>
            <p [innerHTML]="description | convertLineBreak"></p>
        </ion-text>
        <ion-checkbox
                *ngIf="showDoNotShowAgain"
                (ionChange)="onCheckboxChange($event.detail.checked)" labelPlacement="end" i18n>Diesen Hinweis nicht mehr anzeigen</ion-checkbox>
    </ng-container>
    <ng-container footer>
        <ion-button (click)="close()" i18n>Verstanden</ion-button>
    </ng-container>
</app-modal2>
