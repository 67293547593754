import { Component, Input, OnInit, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';

// Core Files
import { KeyCodesEnum, ModalClassEnum, QueryFetchPolicy, UserAccessModulesEnum, UserAccessRightsEnum } from '../../../../../../core/core.enums';
import { UserAdministrationMutations, UserAdministrationQueries } from '../../../../../../core/core.store';
import { InputValidationService, ModalService, ToastService } from '../../../../../../core/core.services';
import {
    PharmaciesEditModalInterface,
    PharmaciesFromKeyCloakInterface,
    PharmaciesInterface,
    PharmacyRights,
    UserAdministrationInterface
} from '../../../../../../core/interfaces/user-administration.interface';
import { UserAdministrationService } from '../../../../../../core/services/user-administration.service';
import { PharmacyStoreStateVar } from '../../../../../../core/store/locals/pharmacyStoreState.var';
import { unsubscribe, unsubscribeAll } from '../../../../../../core/core.utils';

@Component({
    selector: 'app-user-edit-modal',
    templateUrl: './user-edit-modal.component.html',
    styleUrls: ['./user-edit-modal.component.scss'],
})
export class UserEditModalComponent implements OnInit{
    // Adds class to modal
    static modalClass = ModalClassEnum.autoWidthlargeHigh;

    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    @Input() user: UserAdministrationInterface;

    @ViewChild('firstField') firstField: any;

    owner: UserAdministrationInterface;
    isEditMode: boolean;
    isSacademyActivated;
    pharmacyStores = this.pharmacyStoreStateVar.pharmacyStoresState;
    pharmacies: PharmaciesEditModalInterface[];
    submitPharmacies: PharmaciesInterface[];

    editUserFormGroup: FormGroup;
    userAdminSubscription: Subscription;


    constructor(
        private toastService: ToastService,
        private alertController: AlertController,
        private formBuilder: FormBuilder,
        private modalService: ModalService,
        private userAdminQueries: UserAdministrationQueries,
        private userAdministrationMutations: UserAdministrationMutations,
        private userAdministrationService: UserAdministrationService
    ) {
    }

    ngOnInit() {
        this.isEditMode = !!this.user;
        this.isSacademyActivated = false;
        this.setValidationFormGroup();

        unsubscribe(this.userAdminSubscription);
        this.userAdminSubscription = this.userAdminQueries.getAllUsers(QueryFetchPolicy.NETWORK_ONLY).subscribe(usersObject => {
            if (usersObject && usersObject.error) {
                void this.toastService.presentError(usersObject.error);
                void this.onCancel();
                return;
            }
            const users = usersObject.data;
            // load all pharmacy names according to apiUsers to avoid loading time when expanding row
                this.pharmacies = [];
                this.owner = users.find(user =>
                    user.pharmacies.filter(pharmacy =>
                        pharmacy.sconnect && pharmacy.sconnect.includes(UserAccessRightsEnum.PHARMACY_OWNER)
                    )?.length > 0
                );

                // show all possible access rights as default true for a newly created user
                this.pharmacies = this.pharmacyStores().map(pharmacy => ({
                    ...pharmacy,
                    rights: this.getRights(pharmacy)
                }));
                this.userAdministrationService.userDataChanged.next(false);
            });
    }

    ionViewDidEnter() {
        this.firstField.setFocus();
    }

    ionViewDidLeave() {
        unsubscribeAll([
            this.userAdminSubscription
        ]);
    }

    setValidationFormGroup() {
        const user = this.user;
        this.editUserFormGroup = this.formBuilder.group({
            salutation: [user && user.salutation ? user.salutation?.trim() : '',
                [InputValidationService.noWhitespaceValidator]],
            title: [user && user.title ? user.title?.trim() : '',
                [InputValidationService.noWhitespaceValidator, InputValidationService.noNumberValidator]],
            firstName: [user && user.firstName ? user.firstName?.trim() : '',
                [InputValidationService.noWhitespaceValidator, InputValidationService.noNumberValidator, Validators.required]],
            lastName: [user && user.lastName ? user.lastName?.trim() : '',
                [InputValidationService.noWhitespaceValidator, InputValidationService.noNumberValidator, Validators.required]],
            email: [user && user.email ? user.email?.trim() : '',
                [InputValidationService.noWhitespaceValidator, Validators.required, Validators.email]]
        });
    }

    getRights(pharmacy) : Array<PharmacyRights> {
        const ownerRights: PharmaciesFromKeyCloakInterface =
            this.owner ? this.owner.pharmacies.find(pharmacyOwner => pharmacyOwner.apiUser === pharmacy.apiUser): {};
        const userRights: PharmaciesFromKeyCloakInterface =
            this.user ? this.user.pharmacies.find(pharmacyOwner => pharmacyOwner.apiUser === pharmacy.apiUser) : ownerRights;
        const defaultRights = [
            UserAccessModulesEnum.SCONNECT,
            UserAccessModulesEnum.CHAT,
            UserAccessModulesEnum.SHOP,
            UserAccessModulesEnum.SACADEMY,
            UserAccessModulesEnum.SANAVENDI
        ];

        return defaultRights.map((defaultRight) => ({
            accessModule: defaultRight,
            accessRights: userRights && userRights[defaultRight] && userRights[defaultRight].length > 0 ?
                userRights[defaultRight]
                    .map(right => right === UserAccessRightsEnum.PHARMACY_OWNER ? UserAccessRightsEnum.PHARMACY_MEMBER : right)
                    .filter(right => right !== UserAccessRightsEnum.USER_ADMINISTRATION) as UserAccessRightsEnum[] :
                [],
            isActivated: !!(userRights && userRights[defaultRight] && userRights[defaultRight].length > 0 &&
                this.checkSacademyActivated(ownerRights, userRights, defaultRight)),
            isDisabled: !!(!ownerRights || !ownerRights[defaultRight] || ownerRights[defaultRight].length === 0),
            pipeRefresh: Math.random()
        }));
    }

    /**
     * Determine whether sacademy access right is activated or not.
     * @param ownerRights PharmaciesFromKeyCloakInterface
     * @param userRights PharmaciesFromKeyCloakInterface
     * @param right UserAccessModulesEnum
     */
    checkSacademyActivated(
        ownerRights: PharmaciesFromKeyCloakInterface,
        userRights: PharmaciesFromKeyCloakInterface,
        right: UserAccessModulesEnum): boolean {
        // Only one pharmacy is allowed to have sacademy access rights.
        // For new users, this should be activated the first pharmacy, where the owner has the corresponding access right.
        if (!this.isEditMode && right === UserAccessModulesEnum.SACADEMY && ownerRights[right].length > 0) {
            if (this.isSacademyActivated === false) {
                this.isSacademyActivated = true;
                return true;
            } else {
                return false;
            }
        } else {
            // return always true, if access right is not sacademy
            return true;
        }
    }

    onToggleClick(event, module, apiUser) {
        const pharmacy = this.pharmacies.find(p => p.apiUser === apiUser);
        const isChecked = event && event.detail && event.detail.checked;
        if (pharmacy) {
            const pharmacyModule = pharmacy.rights.find(m => m.accessModule === module);
            pharmacyModule.isActivated = isChecked;
            pharmacyModule.accessRights = isChecked ? [UserAccessRightsEnum.PHARMACY_MEMBER] : [];
            if (module === UserAccessModulesEnum.SACADEMY) {
                // trigger UserAdminCanActivatePipe on toggle change (pipeRefresh is our manual trigger for the pipe)
                this.pharmacies.map(p => {
                    const sacademyRight = p.rights.find(m => m.accessModule === UserAccessModulesEnum.SACADEMY);
                    sacademyRight.pipeRefresh = Math.random();
                });
            }
        }
    }

    onAccessRightsClick(event, module, apiUser) {
        const pharmacy = this.pharmacies.find(p => p.apiUser === apiUser);
        if (pharmacy && event) {
            const pharmacyModule = pharmacy.rights.find(m => m.accessModule === module);
            if (event.isChecked) {
                pharmacyModule.accessRights.push(event.accessRight);
            } else {
                const accessRightIndex = pharmacyModule.accessRights.findIndex(right => right === event.accessRight);
                pharmacyModule.accessRights.splice(accessRightIndex, 1);
            }
        }
    }

    /**
     * Save new user or changes to existing user.
     *
     * @param value - Values to submit
     */
    async onSubmit(value: UserAdministrationInterface) {
        const user: UserAdministrationInterface = this.user && this.user.id ? {
            id: this.user.id,
            ...value
        } : value;
        // map access rights from PharmaciesEditModalInterface to PharmaciesInterface
        this.submitPharmacies = this.pharmacies.map(pharmacy => ({
            apiUser: pharmacy.apiUser,
            sconnect: pharmacy.rights[0].accessRights,
            chat: pharmacy.rights[1].accessRights,
            shop: pharmacy.rights[2].accessRights,
            sacademy: pharmacy.rights[3].accessRights,
            sanavendi: pharmacy.rights[4].accessRights
        }));

        this.userAdministrationService.userDataSetLoading.next(true);
        this.userAdministrationMutations.upsertUser(this.isEditMode, user, this.submitPharmacies);
        await this.modalService.dismiss();
    }

    async onCancel() {
        await this.modalService.dismiss();
    }

    async onDelete() {
        const alert = await this.alertController.create({
            header: 'Benutzer löschen',
            message: `Soll Nutzer <b>${this.user.firstName} ${this.user.lastName}</b> aus allen Sanacorp-Anwendungen entfernt werden?`,
            buttons: [
                {
                    text: 'Nein',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        // No action required at the moment
                    }
                },{
                    text: 'Ja',
                    handler: () => {
                        this.userAdministrationMutations.deleteSingleUser(this.user.id);
                        this.userAdministrationService.userDataSetLoading.next(true);
                        this.modalService.dismiss();
                    }
                }
            ]
        });


        alert.addEventListener('keyup', async (event) => {
            if(event.key === KeyCodesEnum.ENTER && event.currentTarget instanceof HTMLElement) {
                event.currentTarget.getElementsByTagName('button')[1].click();
            }

            if(event.key === KeyCodesEnum.ESCAPE) {
                void alert.dismiss();
            }
        });

        await alert.present();
    }
}
