import { createSelector } from '@ngrx/store';
import { MeaPharmacyState } from '../../mea-pharmacy.state';
import { ExtensionsState, HomeState, SettingsState } from '../state/home.state';

const selectHomeState = (state: MeaPharmacyState) => state.home;

export const selectErrorMessage = createSelector(selectHomeState, (state: HomeState) => state.errorMessage);

export const selectSettingsState = createSelector(selectHomeState, (state: HomeState) => state.settings);

export const selectSoundNotification = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.soundNotification
);

export const selectSoundRepetition = createSelector(
  selectSettingsState,
  (state: SettingsState) => state.soundRepetition
);

export const selectExtensionsState = createSelector(selectHomeState, (state: HomeState) => state.extensions);

export const selectWidgetFeatures = createSelector(
  selectExtensionsState,
  (state: ExtensionsState) => state.widget.features
);

export const selectWidgetWebsiteMaintainer = createSelector(
  selectExtensionsState,
  (state: ExtensionsState) => state.widget.websiteMaintainer
);
