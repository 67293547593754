import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
    subscription createdShopUserMessage($pharmacyCognitoId: ID!) {
        createdShopUserMessage(pharmacyCognitoId: $pharmacyCognitoId) {
            ${shopUserMessageAttributes}
        }
    }
`;
