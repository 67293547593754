import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { MaintenanceStateInterface } from '../../interfaces/maintenance-state.interface';

export const GetMaintenanceState = gql`
    query GetMaintenanceState {
        maintenanceState @client {
            isMaintenance
            duration
            maintenanceText
        }
    }
`;

@Injectable({
    providedIn: 'root',
})
export class MaintenanceStateVar {
    private defaultValue = {
        isMaintenance: false,
        duration: '60 Minuten',
        maintenanceText: ''
    };

    private maintenanceStateObservable: Observable<MaintenanceStateInterface> = new Observable<MaintenanceStateInterface>();
    private apollo: Apollo;
    private cache;

    constructor(apollo: Apollo) {
        this.apollo = apollo;
    }

    init() {
        if(this.apollo.client) {
            this.cache = this.apollo.client.cache;
            this.maintenanceStateObservable = new Observable<MaintenanceStateInterface>(subscriber => {
                this.cache.watch({
                    query: GetMaintenanceState,
                    callback: res => {
                        subscriber.next(res.result && res.result.maintenanceState || this.defaultValue);
                    },
                    immediate: true,
                    optimistic: true
                });
            });
        }
    }

    get(): Observable<MaintenanceStateInterface> {
        this.init();
        return this.maintenanceStateObservable;
    }

    set(isMaintenance: boolean, duration: string, maintenanceText: string) {
        this.init();
        if (this.cache) {
            this.cache.writeQuery({
                query: GetMaintenanceState,
                data: {
                    maintenanceState : {isMaintenance, duration, maintenanceText}
                }
            });
        }
    }
}
