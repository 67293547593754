import gql from 'graphql-tag';

export default gql`
  subscription updatedUserConversation($ownerId: ID!) {
    updatedUserConversation(ownerId: $ownerId) {
      __typename
      id
      ownerId
      conversationId
      participants {
        __typename
        cognitoId
        chatname
        isGuest
        isAppUser
        userType
        userStatus
        publicKey
      }
      conversation {
        firstSegmentId
        archivedByEnduser
        archivedByPharmacy
        deletionRecord {
          cognitoId
          deletedAt
        }
        earliestExpirationTimestamp
        encryptedAppointment
        encryptedShoppingCart
        hasPrescriptions
        appointmentId
        progressHistory {
          timestamp
          status
          subStatus
          data {
            pickupDate
            pickupTime
            pickupNow
          }
        }
        ticketHistory {
          timestamp
          updatedStatus
          updatedBy
          accepted
        }
      }
      conversationLink
      encryptedChatPartnerNickname
      encryptedNoteHistory {
        encryptedText
        id
        timestamp
      }
    }
  }
`;
