import { inject, Pipe, PipeTransform } from '@angular/core';
import { UtilService } from '../services/util.service';

@Pipe({
    name: 'arrayPrioritySort'
})
export class ArrayPrioritySortPipe implements PipeTransform {

    private utilService = inject(UtilService);

    /**
     * Sorts an array of objects based on a certain property.
     * If a value is provided, objects with that value are prioritized and put at
     * the beginning of the sorted array regardless.
     * @param value - the array to sort
     * @param priorityProperty - the property to sort by
     * @param priorityValue - the optional value that gets prioritized when being sorted
     */
    transform<T>(value: Array<T>, priorityProperty: any, priorityValue?: any): T[] {
        return this.utilService.prioritySortArray(value, priorityProperty, priorityValue);
    }

}