import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'breakAtSlash',
    standalone: true
})
export class BreakAtSlashPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) return value;

        // Replace slashes with slash + zero-width space
        return value.replace(/\//g, '/\u200B');
    }
}