export const MeaPharmacyWebDeepLink = {
  route: 'deeplink-chat',
  queryParamNames: {
    customerId: 'customerId',
    orderId: 'orderId',
    chatPartner: 'chatPartner',
  },
} as const;

export const MeaPharmacyWebChatPartner = {
  Patient: 'patient',
  ShopMail: 'shopmail',
  Pharmacy: 'pharmacy',
} as const;
export type MeaPharmacyWebChatPartner = (typeof MeaPharmacyWebChatPartner)[keyof typeof MeaPharmacyWebChatPartner];

export const getMeaPharmacyWebDeeplink = ({
  sanacorpCustomerId,
  orderId,
  chatPartner,
}: {
  sanacorpCustomerId: string;
  orderId: string;
  chatPartner: MeaPharmacyWebChatPartner;
}) => {
  const customerIdQueryParam = `${MeaPharmacyWebDeepLink.queryParamNames.customerId}=${sanacorpCustomerId}`;
  const orderIdQueryParam = `${MeaPharmacyWebDeepLink.queryParamNames.orderId}=${orderId}`;
  const chatPartnerQueryParam = `${MeaPharmacyWebDeepLink.queryParamNames.chatPartner}=${chatPartner}`;
  return `/${MeaPharmacyWebDeepLink.route}?${customerIdQueryParam}&${orderIdQueryParam}&${chatPartnerQueryParam}`;
};
