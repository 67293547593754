import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { EncryptionService } from '../../../../common/services/src/encryption/encryption.service';
import { StorageService } from '../../../../common/services/src/storage.service';
import { InternalNote } from '../../../../essentials/types/src/internalNote';
import {
  BackendShopUserMessage,
  ShopUserMessage,
  ShopUserMessageDecryptionResult,
} from '../../../../essentials/types/src/shopUserMessage';
import { Logger } from '../../../../essentials/util/src/logger';
import { selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import { MeaPharmacyState } from '../mea-pharmacy.state';

const logger = new Logger('ShopUserMessagesDecryptionService');

@Injectable({ providedIn: 'root' })
export class ShopUserMessagesDecryptionService {
  private encryptionService = inject(EncryptionService);
  private storageService = inject(StorageService);
  private store: Store<MeaPharmacyState> = inject(Store);

  public async decryptShopUserMessages(backendShopUserMessages: BackendShopUserMessage[]): Promise<ShopUserMessage[]> {
    const user = await firstValueFrom(this.store.select(selectUser));
    if (!user) {
      throw new Error('Can not decrypt shop user messages without user');
    }
    const privateKey = user?.privateKey;
    if (!privateKey) {
      throw new Error('Can not decrypt shop user messages without private key');
    }
    return Promise.all(
      backendShopUserMessages.map((backendShopUserMessage) =>
        this.getDecryptedShopUserMessage(backendShopUserMessage, user.cognitoId, privateKey)
      )
    );
  }

  public async getDecryptedShopUserMessage(
    backendShopUserMessage: BackendShopUserMessage,
    cognitoId: string,
    privateKey: string
  ): Promise<ShopUserMessage> {
    const decryptionResult = await this.getDecryptedUnchangeableFields(backendShopUserMessage, cognitoId, privateKey);
    return this.decryptChangeableFields({ ...backendShopUserMessage, ...decryptionResult });
  }

  private async getDecryptedUnchangeableFields(
    backendShopUserMessage: BackendShopUserMessage,
    cognitoId: string,
    privateKey: string
  ) {
    const decryptionResultFromStorage = await this.getShopUserMessageDecryptionResultFromStorage(
      cognitoId,
      backendShopUserMessage.id
    );
    if (decryptionResultFromStorage?.decryptionStatus === 'decrypted') {
      return decryptionResultFromStorage;
    }
    const decryptionResult = this.decryptShopUserMessage(
      backendShopUserMessage,
      privateKey,
      decryptionResultFromStorage?.decryptionStatus === 'failed'
    );
    await this.setShopUserMessageDecryptionResultInStorage(cognitoId, backendShopUserMessage.id, decryptionResult);
    return decryptionResult;
  }

  private decryptShopUserMessage(
    backendShopUserMessage: BackendShopUserMessage,
    privateKey: string,
    decryptionHasFailedPreviously: boolean
  ): ShopUserMessageDecryptionResult {
    try {
      const decryptedPassword = this.encryptionService.decryptUsingPrivateKey(
        backendShopUserMessage.encryptedPassword,
        privateKey
      );
      if (!decryptedPassword) {
        if (!decryptionHasFailedPreviously) {
          logger.error(
            'Could not decrypt shop user message',
            `decrypting password failed for id ${backendShopUserMessage.id}`
          );
        }
        return { decryptionStatus: 'failed' };
      }
      return {
        decryptionStatus: 'decrypted',
        password: decryptedPassword,
        firstName: this.encryptionService.decryptUsingPassword(
          backendShopUserMessage.encryptedFirstName,
          decryptedPassword
        ),
        lastName: this.encryptionService.decryptUsingPassword(
          backendShopUserMessage.encryptedLastName,
          decryptedPassword
        ),
        email: this.encryptionService.decryptUsingPassword(backendShopUserMessage.encryptedEmail, decryptedPassword),
        telephone:
          backendShopUserMessage.encryptedTelephone &&
          this.encryptionService.decryptUsingPassword(backendShopUserMessage.encryptedTelephone, decryptedPassword),
        message: this.encryptionService.decryptUsingPassword(
          backendShopUserMessage.encryptedMessage,
          decryptedPassword
        ),
      };
    } catch (e) {
      if (!decryptionHasFailedPreviously) {
        logger.error('Could not decrypt shop user message', e);
      }
      return { decryptionStatus: 'failed' };
    }
  }

  private decryptChangeableFields(shopUserMessage: ShopUserMessage): ShopUserMessage {
    if (!shopUserMessage.encryptedNoteHistory) {
      return shopUserMessage;
    }
    const noteHistory = shopUserMessage.encryptedNoteHistory
      .map(({ id, timestamp, encryptedText }): InternalNote => {
        if (shopUserMessage.decryptionStatus === 'decrypted') {
          try {
            const note = this.encryptionService.decryptUsingPassword(encryptedText, shopUserMessage.password);
            return { id, timestamp, note };
          } catch {}
        }
        return { id, timestamp, decryptionStatus: 'failed' };
      })
      .sort((a, b) => b.timestamp - a.timestamp);
    return { ...shopUserMessage, noteHistory };
  }

  private async setShopUserMessageDecryptionResultInStorage(
    cognitoId: string,
    shopUserMessageId: string,
    decryptionResult: ShopUserMessageDecryptionResult
  ) {
    const key = this.getDecryptedShopUserMessageStorageKey(cognitoId, shopUserMessageId);
    try {
      await this.storageService.set(key, decryptionResult);
    } catch (_) {}
  }

  private async getShopUserMessageDecryptionResultFromStorage(cognitoId: string, shopUserMessageId: string) {
    const key = this.getDecryptedShopUserMessageStorageKey(cognitoId, shopUserMessageId);
    try {
      return await this.storageService.get<ShopUserMessageDecryptionResult>(key);
    } catch (_) {
      return null;
    }
  }

  private getDecryptedShopUserMessageStorageKey(cognitoId: string, shopUserMessageId: string) {
    return `${cognitoId}_shopUserMessageDecryptionResult_${shopUserMessageId}`;
  }
}
