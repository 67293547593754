import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { EncryptionResult } from '../../../../essentials/types/src/encryptionResult';
import UserKey from '../../../../essentials/types/src/userKey';
import { Logger } from '../../../../essentials/util/src/logger';
import createUserKey from '../../../resources/src/graphql/mutations/createUserKey';
import deleteUserKey from '../../../resources/src/graphql/mutations/deleteUserKey';
import updateEndUserEncryptionSalt from '../../../resources/src/graphql/mutations/updateEndUserEncryptionSalt';
import getUserKey from '../../../resources/src/graphql/queries/getUserKey';
import { AppsyncService } from '../appsync/appsync.service';
import { EncryptionService } from './encryption.service';

const logger = new Logger('PrivateKeyBackupService');

@Injectable({ providedIn: 'root' })
export class PrivateKeyBackupService {
  private _iterations = 5000;

  constructor(
    private encryptionService: EncryptionService,
    private appsyncService: AppsyncService
  ) {}

  public set iterations(value: number) {
    this._iterations = value;
  }

  public encryptPrivateKey(password: string, privateKey: string): EncryptionResult {
    const salt = CryptoJS.lib.WordArray.random(128 / 8).toString();
    const key = this.generateKey(password, salt);

    const encryptedPrivateKey = this.encryptionService.encryptUsingPassword(privateKey, key);
    return {
      encryptedPrivateKey,
      salt,
    };
  }

  public decryptPrivateKey(password: string, encryptedPrivateKey: EncryptionResult): string | undefined {
    const key = this.generateKey(password, encryptedPrivateKey.salt);
    try {
      return this.encryptionService.decryptUsingPassword(encryptedPrivateKey.encryptedPrivateKey, key);
    } catch (error) {
      return undefined;
    }
  }

  public decryptEndUserPrivateKey(userKey: UserKey, password: string): string | undefined {
    logger.info('restoring private key of enduser');
    return this.decryptPrivateKey(password, {
      encryptedPrivateKey: userKey.key,
      salt: userKey.salt,
    });
  }

  public async backupPrivateKeyEndUser(
    cognitoId: string,
    password: string,
    privateKey: string,
    currentEncryptionSalt?: string
  ): Promise<string | undefined> {
    const { userKey, encryptionSalt } = this.createEndUserBackup(
      cognitoId,
      password,
      privateKey,
      currentEncryptionSalt
    );

    const client = await this.appsyncService.getClient();
    try {
      const { data: createUserKeyData } = await client.mutate({
        mutation: createUserKey,
        variables: userKey,
      });
      const { data: addSaltData } = await client.mutate({
        mutation: updateEndUserEncryptionSalt,
        variables: { cognitoId, encryptionSalt },
      });
      if (createUserKeyData && addSaltData) {
        return encryptionSalt;
      } else {
        logger.info('no private key backup found');
        return undefined;
      }
    } catch (errs) {
      logger.error('Could not create user key backup', errs);
      return undefined;
    }
  }

  public async deletePrivateKeyBackupEndUser(
    cognitoId: string,
    password: string,
    encryptionSalt: string
  ): Promise<UserKey | null | undefined> {
    const userIdentifier = this.getEndUserIdentifier(cognitoId, password, encryptionSalt);

    const client = await this.appsyncService.getClient();
    try {
      const { data } = await client.mutate({
        mutation: deleteUserKey,
        variables: { user: userIdentifier },
      });
      if (data) {
        return data.deleteUserKey;
      } else {
        logger.info('no private key for deletion found');
        return null;
      }
    } catch (errs) {
      logger.error('Error deleting user key', errs);
      return null;
    }
  }

  public createEndUserBackup(
    cognitoId: string,
    password: string,
    privateKey: string,
    encryptionSalt?: string
  ): { userKey: UserKey; encryptionSalt: string } {
    logger.info('Creating end user backup');

    if (!encryptionSalt) {
      encryptionSalt = CryptoJS.lib.WordArray.random(128 / 8).toString();
    }

    const hashedIdentifier = this.getEndUserIdentifier(cognitoId, password, encryptionSalt);
    const { encryptedPrivateKey, salt } = this.encryptPrivateKey(password, privateKey);
    return { userKey: { user: hashedIdentifier, salt, key: encryptedPrivateKey }, encryptionSalt };
  }

  public async getEndUserPrivateKeyBackup(
    cognitoId: string,
    password: string,
    encryptionSalt: string
  ): Promise<UserKey | null> {
    const userIdentifier = this.getEndUserIdentifier(cognitoId, password, encryptionSalt);

    const client = await this.appsyncService.getClient();
    try {
      const { data } = await client.query({
        query: getUserKey,
        variables: { user: userIdentifier },
      });
      if (data && data.getUserKey) {
        return data.getUserKey;
      } else {
        logger.info('no private key backup found');
        return null;
      }
    } catch (errs) {
      logger.error('Error getting user key', errs);
      return null;
    }
  }

  private generateKey(puk: string, salt: string): string {
    const key = CryptoJS.PBKDF2(puk, salt, {
      keySize: 256 / 8,
      iterations: this._iterations,
      hasher: CryptoJS.algo.SHA256,
    });
    return key.toString();
  }

  private getEndUserIdentifier(cognitoId: string, password: string, encryptionSalt: string): string {
    return this.generateKey(cognitoId + password, encryptionSalt);
  }
}
