@if(appointment) {
    <div>
        @if(appointment.payload?.icon) {
            <ion-icon [name]="appointment.payload?.icon"></ion-icon>
        }
        <ion-text class="title" *ngIf="showAppointmentTitle">{{appointment.title}}</ion-text>
    </div>
    <div>
        <ion-icon name="calendar-outline" size="small"></ion-icon>
        <ion-text [innerHTML]="appointment.dateTimeFrom | formatDateTimeRange:appointment.dateTimeTo:3"></ion-text>

    </div>
    <div class="footer">
        @if(!smallVersion) {
            <div>
                <ion-icon name="time-outline" size="small"></ion-icon>
                @if(appointment.dateTimeFrom | isAllDay: appointment.dateTimeTo) {
                    <ion-text i18n>Ganztägig</ion-text>
                } @else {
                    <ion-text [innerHTML]="appointment.dateTimeFrom | formatDateTimeRange:appointment.dateTimeTo:2"></ion-text>
                }
            </div>
        }
        @if(!smallVersion && appointment.lockedBySanacorp && !(appointment.formId && appointment.formButton && !(appointment.dateTimeFrom | isDateSameOrBeforeToday))) {
            <div class="creator-badge">Sanacorp</div>
        }
    </div>
    @if(appointment.lockedBySanacorp && appointment.formId && appointment.formButton && !(appointment.dateTimeFrom | isDateSameOrBeforeToday)) {
        <div class="footer">
            <div>
                <ion-text
                    class="form-button"
                    [innerHTML]="appointment.formButton"></ion-text>
                <ion-icon name="arrow-forward-outline" class="form-button-arrow"></ion-icon>
            </div>

            @if(!smallVersion) {
                <div class="creator-badge">Sanacorp</div>
            }
        </div>
    }
}
