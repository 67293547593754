import { inject, Injectable } from '@angular/core';
import {
  ChatPartnerChatnameEntry,
  DecryptedChatnameUpdate,
  isFullChatnameEntry,
} from '../../../../essentials/types/src/chatPartnerMetadata';
import { EncryptionService } from './encryption.service';

@Injectable({ providedIn: 'root' })
export class ChatnameHistoryDecryptionService {
  private encryptionService = inject(EncryptionService);

  getDecryptedChatnameHistory(
    encryptedChatnameHistory: ChatPartnerChatnameEntry[],
    chatname: string,
    privateKey?: string,
    firstMessageTimestamp?: number
  ): DecryptedChatnameUpdate[] {
    if (!privateKey) {
      return [];
    }
    const encryptedChatnameHistoryForConversation = firstMessageTimestamp
      ? encryptedChatnameHistory.filter((entry) => entry.timestamp > firstMessageTimestamp)
      : encryptedChatnameHistory;

    const lastUsedEncryptedChatnameEntry = firstMessageTimestamp
      ? encryptedChatnameHistory.findLast((entry) => entry.timestamp <= firstMessageTimestamp)
      : undefined;
    let oldDecryptedChatname = lastUsedEncryptedChatnameEntry
      ? this.getDecryptedChatname(lastUsedEncryptedChatnameEntry, privateKey) || chatname
      : chatname;

    const decryptedChatnameHistory: DecryptedChatnameUpdate[] = [];
    for (const entry of encryptedChatnameHistoryForConversation) {
      const newDecryptedChatname = this.getDecryptedChatname(entry, privateKey) ?? chatname;
      if (newDecryptedChatname !== false && oldDecryptedChatname !== newDecryptedChatname) {
        decryptedChatnameHistory.push({ oldDecryptedChatname, newDecryptedChatname, timestamp: entry.timestamp });
        oldDecryptedChatname = newDecryptedChatname;
      }
    }
    return decryptedChatnameHistory;
  }

  getDecryptedChatname(encryptedChatNameEntry: ChatPartnerChatnameEntry, privateKey: string): string | false | null {
    if (isFullChatnameEntry(encryptedChatNameEntry)) {
      const decryptedFirstName = this.encryptionService.decryptUsingPrivateKey(
        encryptedChatNameEntry.encryptedFirstName,
        privateKey
      );
      const decryptedLastName = this.encryptionService.decryptUsingPrivateKey(
        encryptedChatNameEntry.encryptedLastName,
        privateKey
      );
      return decryptedFirstName && decryptedLastName && `${decryptedFirstName} ${decryptedLastName}`;
    } else {
      return encryptedChatNameEntry.encryptedChatname
        ? this.encryptionService.decryptUsingPrivateKey(encryptedChatNameEntry.encryptedChatname, privateKey)
        : null;
    }
  }

  getDecryptedFirstName(encryptedChatNameEntry: ChatPartnerChatnameEntry, privateKey: string) {
    return isFullChatnameEntry(encryptedChatNameEntry)
      ? this.encryptionService.decryptUsingPrivateKey(encryptedChatNameEntry.encryptedFirstName, privateKey) ||
          undefined
      : undefined;
  }

  getDecryptedLastName(encryptedChatNameEntry: ChatPartnerChatnameEntry, privateKey: string) {
    return isFullChatnameEntry(encryptedChatNameEntry)
      ? this.encryptionService.decryptUsingPrivateKey(encryptedChatNameEntry.encryptedLastName, privateKey) || undefined
      : undefined;
  }
}
