import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import update from 'immutability-helper';
import { firstValueFrom } from 'rxjs';
import Message, {
  DecryptedMessage,
  DecryptedMessageWithMedia,
  MessageWithMedia,
} from '../../../../essentials/types/src/message';
import { DecryptedImageMedia, ImageMedia } from '../../../../essentials/types/src/messageMedia';
import { Logger } from '../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { selectCognitoId } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import { MessageDecryptionService } from './message-decryption.service';
import { MessageMediaDecryptionService } from './message-media-decryption.service';
import { MessageStoreService } from './message-store.service';

const logger = new Logger('MessageCacheService');

@Injectable({ providedIn: 'root' })
export class MessageCacheService {
  private messageDecryptionService = inject(MessageDecryptionService);
  private messageMediaDecryptionService = inject(MessageMediaDecryptionService);
  private messageStoreService = inject(MessageStoreService);
  private store: Store<CommonState> = inject(Store);

  cognitoId$ = this.store.select(selectCognitoId).pipe(isNotNullOrUndefined());

  public async getDecryptedMessage(
    conversationPassword: string | undefined,
    message: Message,
    ignoreCache = false
  ): Promise<DecryptedMessage> {
    const cognitoId = await firstValueFrom(this.cognitoId$);
    const storageKey = this.createStorageKey(cognitoId, message.id);
    const messageFromStorage = await this.messageStoreService.getFromStorage(storageKey, message);
    if (messageFromStorage && !ignoreCache) {
      return messageFromStorage;
    } else {
      return this.decryptAndStore(storageKey, message, conversationPassword);
    }
  }

  public async getDecryptedImage(
    conversationPassword: string | undefined,
    message: DecryptedMessageWithMedia,
    mediaIndex: number
  ): Promise<DecryptedImageMedia | null> {
    try {
      const cognitoId = await firstValueFrom(this.cognitoId$);
      const storageKey = this.createStorageKey(cognitoId, message.id);
      const messageWithStorageData = await this.messageStoreService.getFromStorage(storageKey, message);
      if (!messageWithStorageData) {
        return await this.decryptAndStoreImage(message, mediaIndex, conversationPassword);
      }
      const imageWithStorageData = messageWithStorageData?.media?.[mediaIndex];
      if (imageWithStorageData?.mediaType !== 'IMAGE') {
        return null;
      }
      if (imageWithStorageData.decryptedContent) {
        return imageWithStorageData;
      } else {
        return await this.decryptAndStoreImage(messageWithStorageData, mediaIndex, conversationPassword);
      }
    } catch (e) {
      logger.error('error getting decrypted image', e);
      return null;
    }
  }

  async decryptAndStoreImage(
    message: DecryptedMessage & MessageWithMedia,
    selectedIndex: number,
    conversationPassword: string | undefined
  ): Promise<DecryptedImageMedia> {
    const cognitoId = await firstValueFrom(this.cognitoId$);
    const image = message.media[selectedIndex] as ImageMedia | undefined;
    const decryptedImageContent = await this.messageMediaDecryptionService.downloadAndDecryptImage(
      image?.encryptedLink,
      conversationPassword,
      'decryptedContent'
    );
    const updatedMessage = update(message, { media: { [selectedIndex]: { $merge: decryptedImageContent } } });
    await this.messageStoreService.store(this.createStorageKey(cognitoId, message.id), updatedMessage);
    return updatedMessage.media[selectedIndex] as DecryptedImageMedia;
  }

  async removeFromCache(cognitoId: string, messageId: string) {
    return this.messageStoreService.removeFromStore(this.createStorageKey(cognitoId, messageId));
  }

  private async decryptAndStore(
    storageKey: string,
    message: Message,
    conversationPassword: string | undefined
  ): Promise<DecryptedMessage> {
    const decryptedMessage = await this.messageDecryptionService.decryptMessage(message, conversationPassword);
    if (decryptedMessage.decryptionStatus !== 'failed') {
      await this.messageStoreService.store(storageKey, decryptedMessage);
    }
    return decryptedMessage;
  }

  private createStorageKey = (cognitoId: string, msgId: string): string => `${cognitoId}:decrypted:${msgId}`;
}
