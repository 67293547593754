import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import last from 'lodash-es/last';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppsyncConversationService } from '../../../../../common/services/src/appsync/appsync-conversation.service';
import { AlertComponent } from '../../../../../common/ui-components/src/ionic/alert/alert.component';
import { CustomModalController } from '../../../../../common/ui-components/src/ionic/controllers/custom-modal.controller';
import { CustomToastController } from '../../../../../common/ui-components/src/ionic/controllers/custom-toast.controller';
import { RemoveCommonChatDuettFields } from '../../../../../essentials/types/src/common-chat-duett-fields';
import { DeleteConversation } from '../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/delete_conversation/jsonschema/1-1-0';
import { CONFIG } from '../../../../../essentials/types/src/mea-config';
import { CONVERSATION_POPOVER_OPTIONS_SERVICE } from '../../../../../essentials/types/src/service-interfaces/conversation-popover-options-service.interface';
import { ChatStatusUtil } from '../../../../../essentials/util/src/chat-status.util';
import { Logger } from '../../../../../essentials/util/src/logger';
import { CommonState } from '../../common.state';
import { analyticsDuettEvent } from '../../other/actions/common-analytics.actions';
import { redirectAfterDeletedConversation } from '../../other/actions/navigation.actions';
import { selectCognitoId } from '../../user-store/selectors/user.selectors';
import {
  openDeleteChatModal,
  revertProgressUpdate,
  updateDeletionRecordForConversation,
  updateProgressHistory,
} from '../actions/chat-conversation-update.actions';
import { selectActiveConversationId } from '../selectors/chat.selectors';

enum Roles {
  'CANCEL' = 'cancel',
  'DELETE' = 'delete',
}

const logger = new Logger('ChatConversationUpdateEffects');

@Injectable()
export class ChatConversationUpdateEffects {
  private config = inject(CONFIG);
  private actions$ = inject(Actions);
  private modalCtrl = inject(CustomModalController);
  private toastController = inject(CustomToastController);
  private translationService = inject(TranslateService);
  private appsyncConversationService = inject(AppsyncConversationService);
  private conversationPopoverOptionsService = inject(CONVERSATION_POPOVER_OPTIONS_SERVICE, { optional: true });
  private store: Store<CommonState> = inject(Store);

  private readonly isPharmacy = !this.config.clientApp;

  openDeleteChatModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openDeleteChatModal),
        switchMap(async (action) => {
          const translations = this.translationService.instant('DELETE_CHAT');
          const conversationId = action.conversationId;

          const modal = await this.modalCtrl.create({
            component: AlertComponent,
            cssClass: 'mea-alert',
            componentProps: {
              header: translations.HEADER,
              body: translations.BODY,
              buttons: [
                { type: 'secondary', text: translations.CANCEL, role: Roles.CANCEL },
                {
                  type: 'danger',
                  text: translations.DELETE,
                  role: Roles.DELETE,
                  handler: () => this.deleteConversation(conversationId),
                },
              ],
            },
            showBackdrop: true,
            backdropDismiss: true,
          });
          await modal.present();
        })
      ),
    { dispatch: false }
  );

  updateDeletionRecordForConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateDeletionRecordForConversation),
        withLatestFrom(this.store.select(selectActiveConversationId), this.store.select(selectCognitoId)),
        tap(([{ conversationId, deletionRecord }, activeConversationId, cognitoId]) => {
          if (
            this.isPharmacy &&
            conversationId === activeConversationId &&
            last(deletionRecord)?.cognitoId === cognitoId
          ) {
            this.store.dispatch(redirectAfterDeletedConversation({ deletedConversationId: conversationId }));
          }
        })
      ),
    { dispatch: false }
  );

  updateProgressHistory$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateProgressHistory),
        switchMap(async ({ conversation, newProgressEvent }) => {
          try {
            const wasArchivedByPharmacy = !!conversation.archivedByPharmacy;
            const newArchiveStatus = ChatStatusUtil.isCompleted(newProgressEvent.status);
            await this.appsyncConversationService.updateProgressStatus(conversation.id, newProgressEvent);

            if (wasArchivedByPharmacy !== newArchiveStatus) {
              await this.conversationPopoverOptionsService?.setArchiveTagInConversation(
                conversation.id,
                newArchiveStatus
              );
            }
          } catch (e) {
            logger.error('Error while updating ProgressHistory', e);
            this.store.dispatch(
              revertProgressUpdate({ conversationId: conversation.id, progressEventToRemove: newProgressEvent })
            );
            await this.toastController.createAndPresentToast({ message: 'CHAT_UPDATES.PROGRESS_STATUS_CHANGE_FAILED' });
          }
        })
      ),
    { dispatch: false }
  );

  private async deleteConversation(conversationId: string) {
    await this.appsyncConversationService.deleteConversation(conversationId);
    this.store.dispatch(redirectAfterDeletedConversation({ deletedConversationId: conversationId }));
    this.trackDeleteConversationEvent(conversationId);
  }

  private trackDeleteConversationEvent(conversationId: string) {
    const event: RemoveCommonChatDuettFields<DeleteConversation> = {
      event_type: 'delete_conversation',
      frontend_conversation_id: conversationId,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }
}
