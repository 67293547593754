import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'arrayReduce'
})
export class ArrayReducePipe implements PipeTransform {

    /**
     * Transforms an array of objects by extracting a specified property, optionally splicing and
     * replacing substrings within the resulting string.
     *
     * @param value - The input array of objects to be transformed.
     * @param objectKey - The key of the object property to extract from each element of the array.
     * @param [spliceStart=0] - The starting index for the splice operation. Defaults to 0.
     * @param [replace] - An optional array of objects specifying
     *   substrings to search for and their replacements within the resultant string. Each object contains:
     *     - `search`: The substring to search for.
     *     - `replace`: The substring to replace it with.
     * @return - The transformed string, constructed by extracting the specified property values,
     *   optionally splicing, and applying replacements, if provided.
     */
    transform(
        value: Array<any>,
        objectKey: string,
        spliceStart = 0,
        replace?: {search: string, replace: string}[]
    ): string {
        if (!value) {
            return '';
        }

        const reducedArray = value.map((val) => (val[objectKey]));
        let reduceString = reducedArray.splice(spliceStart).join(', ');
        if (replace && replace.length) {
            for (const replaceItem of replace) {
                if (reduceString.indexOf(replaceItem.search) !== -1) {
                    reduceString = reduceString.replace(replaceItem.search, replaceItem.replace);
                }
            }
        }
        return reduceString;
    }

}
