import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import { QueryFetchPolicy } from '../../../enums/api.enum';
import { formatFormResult, formatStrapiForm } from '../utils';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryWrapper } from '../query.wrapper';
import {
    FetchPolicyKeys as FPK
} from '../../../enums/fetch-policy-keys.enum';
import{
    DataChangedKeys as DCK
} from '../../../enums/data-changed-keys.enum';
import { FormInterface } from '../../../interfaces/form.interface';
import { FormResultInterface } from '../../../interfaces/formResult.interface';

export const GetForm = (queryName) => gql`
    query ${queryName}($id: ID!) {
        form(documentId: $id) {
            id : documentId
            title
            formFields {
                id
                label
                isRequired
            }
        }
    }
`;

export const GetFormResult = (queryName) => gql`
    query ${queryName}($formId: String!) {
        formResult(limit: 10, where: {formId: {_eq: $formId}}) {
            id
        }
    }
`;


export const AllFormQueries = [
    GetForm('test'),
    GetFormResult('test')
];

@Injectable()
export class FormQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getForm]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getFormResult]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.formChanged]:[FPK.getForm],
            [DCK.formResultChanged]:[FPK.getFormResult]
        });
    }

    public getFormData(formId: string): Observable<FormInterface> {
        const fetchPolicyKey = FPK.getForm;
        return this.apollo.watchQuery({
            query: GetForm(fetchPolicyKey),
            variables: {id: formId},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['form']))
            .pipe(map(formData => formData.id ? formData : null))
            .pipe(map(formData => formatStrapiForm(formData)))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<FormInterface>;
    }

    public getFormResult(formId: string): Observable<FormResultInterface> {
        const fetchPolicyKey = FPK.getFormResult;
        return this.apollo.watchQuery({
            query: GetFormResult(fetchPolicyKey),
            variables: {formId},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['formResult']))
            .pipe(map(formResultData => formatFormResult(formResultData)))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<FormResultInterface>;
    }
}
