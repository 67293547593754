<app-modal i18n-title title="Nachrichten" [contentBG]="true" [contentFlex]="true">
    <ng-container titleExtension><span class="ion-margin-start fs-subheadline-bigger fw-text">{{ totalRows || allNotifications.length }} Ergebnisse gefunden</span></ng-container>
    <ng-container content>
        <sconnect-filters
                [searchText]="filterText"
                [filters]="filters"
                [filterAsSegment]="true"
                [sortOptions]="sortOptions"
                [viewOptions]="viewOptions"
                [isBulkActive]="isBulkActive"
                [bulkOptions]="bulkOptions"
                [filterBarTmpl]="filterBar"
                [isStandalone]="true"
                (filterChange)="onFilterChange($event)"
                (searchChange)="onSearchChange($event)"
                (sortChange)="onSortChange($event)"
                (viewChange)="onViewChange($event)"
                (bulkOptionChange)="onBulkOptionChange($event)">
            <ng-template #filterBar>
                <div class="display-flex">
                    <app-date-selection-widget [title]="'Datum'"
                                               [dateRangeOptions]="dateRangeOption"
                                               [selectedDateRangeId]="selectedDateRangeOption"
                                               [defaultFromDate]="selectedFromDate"
                                               [defaultToDate]="selectedToDate"
                                               [displayOnlyFutureDates]="false"
                                               [displayOnlyPastDates]="true"
                                               [displayIncludingToday]="true"
                                               (datesEvent)="updateDateFilter($event)"
                                               class="ion-margin-end"
                    ></app-date-selection-widget>
                    <div>
                        <ion-text i18n class="filter-label">Typ</ion-text>
                        <ion-text class="filter-text" (click)="onTypeFilterClick($event)">{{selectedType.value}} <ion-icon name="caret-down-sharp"></ion-icon></ion-text>
                    </div>

                    <ion-icon src="assets/icons/settings.svg"
                              size="large"
                              color="medium"
                              class="clickable ion-align-self-center margin-left-auto settings-icon"
                              [routerLink]="'/app/settings/notifications'"
                    ></ion-icon>
                </div>
            </ng-template>
        </sconnect-filters>
        <ion-grid class="ion-no-padding ion-padding-top">
            <ion-row class="ion-no-padding full-height">
                <ion-col size-xl="10" size-lg="11" size-md="11" class="ion-no-padding ion-padding-end">
                    <app-list #notificationList
                            class="colorset-meamind"
                            [items]="allNotifications"
                            [isLoading]="isLoading"
                            [isBulkActive]="isBulkActive"
                            [bulkSelectedItems]="bulkSelectedItems"
                            (changeBulkSelectedItems)="onChangeBulkSelectedItems($event)"
                            [activeId]="activeNotificationId"
                            [titleTmpl]="listTitle"
                            [contentAdditionalTmpl]="listContentAdditional"
                            [noResultsTmpl]="noResultsTmpl"
                            [viewType]="selectedView"
                            (changeActiveId)="onActiveNotificationIdChange($event, true)"
                            (loadMore)="onLoadMoreNotifications($event)">
                        <ng-template #listTitle let-item>
                            @if(selectedView === listViewType) {
                                <div class="dot" [style.visibility]="!item.isRead ? 'visible' : 'hidden'"></div>
                                <span [outerHTML]="item | notificationLabelByType : true | textMinTwoLines:30 | sliceText: 100"></span>
                                @if (item?.payload['productName'] || item?.payload['packageSize']) {
                                    <p class="ion-no-margin">{{ item?.payload['productName'] }} {{ '-' }} {{item?.payload['packageSize']}}</p>
                                }
                                <app-badge [code]="item.type" [badgeType]="badgeType"></app-badge>
                            }
                            <div *ngIf="selectedView !== listViewType" class="item-table">
                                <span>
                                    <span [innerHTML]="item | notificationLabelByType : false : false : true"></span>
                                    @if (item?.payload['productName'] || item?.payload['packageSize']) {
                                        <p class="ion-no-margin">{{ item?.payload['productName'] }} {{ '-' }} {{item?.payload['packageSize']}}</p>
                                    }
                                </span>
                                <div>
                                    <app-badge [code]="item.type" [badgeType]="badgeType" [noMargin]="true"></app-badge>
                                    <span class="fs-extra-small-text">{{item['created_at'] | formatDateInComparisonToTime : true}}</span>
                                </div>
                                <div class="dot" [style.visibility]="!item.isRead ? 'visible' : 'hidden'"></div>
                            </div>
                        </ng-template>
                        <ng-template #listContentAdditional let-item>
                            <span class="fs-extra-small-text">{{item | notificationLabelByType:false : false : true}}</span>
                            <span class="fs-extra-small-text">{{item['created_at'] | formatDateInComparisonToTime : true}}</span>
                        </ng-template>
                        <ng-template #noResultsTmpl>
                            <ion-text class="h2" i18n color="medium">Keine Nachrichten vorhanden</ion-text>
                        </ng-template>
                    </app-list>
                </ion-col>
                <ion-col size-xl="14" size-lg="13" size-md="13" class="ion-no-padding">
                    <app-content-card
                            [item]="notification"
                            [isLoading]="isLoadingDetails"
                            [topBarTmpl]="contentTopBar"
                            [titleTmpl]="contentTitle"
                            [infoTmpl]="contentInfo"
                            [contentTmpl]="contentDesc">
                        <ng-template #contentTopBar let-item>
                            <span i18n>{{item | notificationLabelByType:false : false : true}}</span>
                            <ion-icon class="clickable options" name="ellipsis-horizontal-sharp"
                                      (click)="onNotificationOptionClick()"></ion-icon>
                        </ng-template>
                        <ng-template #contentTitle let-item>{{item | notificationLabelByType : true }}</ng-template>
                        <ng-template #contentInfo let-item>
                            <ion-text>{{item['created_at'] | dateTimeToDate : true}}</ion-text>
                            <app-badge [code]="item.type" [badgeType]="badgeType"></app-badge>
                        </ng-template>
                        <ng-template #contentDesc let-item>
                            <app-notification-center-content
                                *ngIf="activeNotificationId"
                                [notificationPayloadId]="item.payload?.id"
                                [notificationType]="item.type"
                                [notification]="notification"
                            >
                            </app-notification-center-content>
                        </ng-template>
                    </app-content-card>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
    <!-- Footer -->
    <ng-container footer>

        <div [@bulkVisible]="isBulkActive ? 'visible' : 'hidden'" class="display-flex ion-align-items-center">
            <ion-text class="ion-margin-end" i18n>{{bulkSelectedItems.length}} {{bulkSelectedItems.length === 1 ? "ausgewählte Benachrichtigung" : "ausgewählte Benachrichtigungen"}}:</ion-text>
            <ion-button *ngFor="let action of bulkActions" [class]="action.class" (click)="onBulkActionClick(action.code)" [disabled]="!bulkSelectedItems || bulkSelectedItems.length === 0" i18n>{{action.label}}</ion-button>
        </div>

        <div [@bulkVisible]="!isBulkActive ? 'visible' : 'hidden'">
            <ion-button class="button-tertiary" i18n (click)="onDeleteClick()">Löschen</ion-button>
            <ion-button class="button-tertiary" i18n (click)="onChangeArchiveStatusClick()" [disabled]="!notification || (notification && notification.isArchive)">Archivieren</ion-button>
            <ion-button class="button-tertiary" i18n (click)="onChangeReadStatusClick(false)" [disabled]="!notification || (notification && !notification.isRead)">Als ungelesen markieren</ion-button>
            <div appTooltip
                 ttp="bottom"
                 [ttk]="notification && notification.type | notificationGoToEntryPermission"
                 class="clickable"
            >
                       <ion-button i18n (click)="onToEntryClick()"
                          [disabled]="!notification || (notification && !(notification.type | notificationHasEntry)) || (notification.type | notificationGoToEntryPermission :true)"
                       >{{(notification && notification.type || null) | notificationButtonLabelByType}}</ion-button>
            </div>
            <ion-button i18n *ngIf="notification && (notification.type | notificationAdditionalActionLabel : notification : order)" (click)="onAdditionalActionsClick()">
                {{notification.type | notificationAdditionalActionLabel : notification : order}}
            </ion-button>
        </div>
    </ng-container>
</app-modal>
