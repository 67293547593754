import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, mergeMap } from 'rxjs/operators';
import { saveExifOrientationChange } from '../actions/chat-message.actions';
import { UserOptionsUpdateService } from './services/user-options-update.service';

@Injectable()
export class ImageOrientationEffects {
  private actions$ = inject(Actions);
  private userOptionsUpdateService = inject(UserOptionsUpdateService);

  saveExifOrientationChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveExifOrientationChange),
        debounceTime(750),
        mergeMap(async ({ image, exifOrientation, messageId }) => {
          await this.userOptionsUpdateService.updateExifOrientation(image, exifOrientation, messageId);
        })
      ),
    { dispatch: false }
  );
}
