import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalClassEnum } from '../../enums/modal-class.enum';
import { unsubscribe, unsubscribeAll } from '../../util/subscriptions.util';
import { FormInterface } from '../../interfaces/form.interface';
import { FormQueries } from '../../store/graphql/queries/form.graphql';
import { InputValidationService } from '../../services/inputValidation.service';

import { FormsMutations } from '../../store/graphql/mutations/form.graphql';

@Component({
    selector: 'app-form-modal',
    templateUrl: './form-modal.component.html',
    styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnInit {
    static modalClass = ModalClassEnum.autoHeightMedium;

    private formQueries = inject(FormQueries);
    private formBuilder = inject(FormBuilder);
    private formsMutations = inject(FormsMutations);
    private cdr = inject(ChangeDetectorRef);

    @Input()
    formId = '';
    @Input()
    buttonText = '';

    @Output()
    isFormSubmitted = new EventEmitter<boolean>();

    form: FormInterface;
    formSubscription: Subscription;

    formModalGroup: FormGroup;
    isLoading = true;


    ngOnInit() {
        this.formModalGroup = this.formBuilder.group({});
        if (this.formId) {
            unsubscribe(this.formSubscription);
            this.formSubscription = this.formQueries.getFormData(this.formId).subscribe(newForm => {
                this.form = newForm;
                this.setFormModalGroup();
                this.isLoading = false;
                this.cdr.detectChanges();
            });
        }
    }

    setFormModalGroup() {
        if (this.form?.formFields?.length) {
            this.form.formFields.forEach(field => {
                const validators = [];
                if (field.isRequired) {
                    validators.push(Validators.required);
                }
                validators.push(InputValidationService.noWhitespaceValidator);
                this.formModalGroup.addControl(
                    field.id,
                    this.formBuilder.control('', validators)
                );
            });
        }
        this.cdr.detectChanges();
    }

    ionViewWillLeave(): void {
        unsubscribeAll([
            this.formSubscription,
        ]);
    }

    async onFormSubmit(values:any) {
        const dataArray = [];
        for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
                const id = parseInt(key, 10);
                if (typeof values[key] === 'string') {
                    values[key] = values[key].trim();
                }
                const value = values[key];
                dataArray.push({id, value});
            }
        }
        await this.formsMutations.sendForm(this.formId, dataArray);
        this.isFormSubmitted.emit(true);
        this.cdr.detectChanges();
    }
}
