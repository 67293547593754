import { inject, Pipe, PipeTransform } from '@angular/core';
import { DocumentInterface } from '../interfaces/document.interface';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { DocumentPdfFilenamePrefix, DocumentSubType, DocumentType } from '../enums/documents.enum';
import { DocumentService } from '../services/document.service';

@Pipe({
    name: 'documentDownloadFilename'
})
export class DocumentDownloadFilenamePipe implements PipeTransform {

    private documentService = inject(DocumentService);
    transform(document: DocumentInterface, childRecDate: string = null): string {
        return this.documentService.getDocumentDownloadFilename(document, childRecDate);
    }
}
