import { inject, Injectable } from '@angular/core';
import { DocumentNode, gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { DataChangedKeys as DCK } from '../../../core.enums';
import {
    AppointmentUpdatetInterface
} from '../../../interfaces/appointments.interface';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { ProfileSettingsVar } from '../../locals/profileSettings.var';

export const CreateAppointmentMutation = gql`
    mutation createAppointment(
        $appointment: [appointment_insert_input!]!,
        $firstName: String!,
        $lastName: String!) {
        insert_appointment(objects: $appointment) {
            affected_rows
        }

        insert_userDetails_one(object: {
             firstName: $firstName,
            lastName: $lastName
        }, on_conflict: {
            constraint: user_id_key,
            update_columns: [firstName, lastName]
        }) {
            firstName
            lastName
        }
    }
`;

export const UpdateAppointmentMutation = gql`
    mutation updateAppointment($id:Int!, $appointment: appointment_set_input!) {
        update_appointment(where: {id: {_eq:$id}}, _set: $appointment) {
            affected_rows
        }
    }
`;

export const DeleteAppointmentNotification = gql`
   mutation deleteAppointment($id:Int!) {
        DeleteNotificationByReference(referenceId: $id, tableName: appointment) {
            status
        }
    }
`;

export const DeleteAppointmentMutation = gql`
   mutation deleteAppointment($id:Int!) {
        delete_appointment(where: {id: {_eq: $id}}) {
            affected_rows
        }
    }
`;


export const AllAppointmentMutations = [
    CreateAppointmentMutation,
    UpdateAppointmentMutation,
    DeleteAppointmentMutation,
    DeleteAppointmentNotification
];

@Injectable()
export class AppointmentMutations {
    private _profileSettingsVar = inject(ProfileSettingsVar);

    constructor(
        private apollo: Apollo,
        private apiService: ApiService,
        private dataChangedForceState: DataChangedForceStateVar
    ){}

    /**
     * trim values
     * @param data - Data to trim (string or null)
     * @return string|null
     */
    formatInputs = (data) => {
        if (typeof data === 'string') {
            return data.trim().length > 0 ? data.trim() : null;
        }
        return data;
    }


    createAppointment(appointment: AppointmentUpdatetInterface) {
        void this.dataChangedForceState.setForceState({[DCK.appointmentChanged]: null});
        Object.keys(appointment).forEach(key => {
            appointment[key] = this.formatInputs(appointment[key]);
        });
        const {firstName, lastName} = this._profileSettingsVar.profileSettings().user;

        this.apollo.mutate({
            mutation: CreateAppointmentMutation,
            variables: {
                appointment,
                firstName,
                lastName
            }
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Der Termin wurde angelegt.'),
            error: error => this.apiService.presentErrorToast(error, 'Der Termin konnte nicht angelegt werden.')
        });
    }

    updateAppointment(
        id: number,
        appointment: AppointmentUpdatetInterface,
    ) {
        void this.dataChangedForceState.setForceState({[DCK.appointmentChanged]: null});
        Object.keys(appointment).forEach(key => {
            appointment[key] = this.formatInputs(appointment[key]);
        });
        this.apollo.mutate({
            mutation: UpdateAppointmentMutation,
            variables: { id, appointment }
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['update_appointment'] && d?.data['update_appointment']['affected_rows']))
                  .subscribe((affectedRows: number) => {
                      if (affectedRows >= 1 && !result['errors']) {
                          void this.apiService.presentSuccessToast('Der Termin wurde gespeichert.');
                      } else {
                          void this.apiService.presentErrorToast(null, 'Der Termin konnte nicht gespeichert werden.');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, 'Der Termin konnte nicht gespeichert werden.')
        });
    }

    async deleteAppointment(id: number) {
        // needs to be called one by one to guarantee the appointment is not deleted before the notifications
        await this.dataChangedForceState.setForceState({[DCK.appointmentChanged]: null});
        await this.performAppointmentDeletion(id, DeleteAppointmentNotification);
        return await this.performAppointmentDeletion(id, DeleteAppointmentMutation);
    }

    performAppointmentDeletion(id: number, mutationQuery: DocumentNode) {
        return new Promise((resolve, reject) => {
            this.apollo.mutate({
                mutation: mutationQuery,
                variables: {id}
            }).subscribe({
                next: () => {
                    void this.apiService.presentSuccessToast('Der Termin wurde gelöscht.');
                    resolve(true);
                },
                error: error => {
                    void this.apiService.presentErrorToast(error, 'Der Termin konnte nicht gelöscht werden.');
                    reject();
                }
            });
        });
    }
}
