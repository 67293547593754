import { TranslateLoader } from '@ngx-translate/core';
import merge from 'lodash-es/merge';
import { Observable, of } from 'rxjs';
import translationsCommon from '../../../common/resources/src/translations/de.json';
import translationsDe from '../../../mea-pharmacy-only/src/assets/i18n/de.json';

const TRANSLATIONS: { [key: string]: any } = {
  de: translationsDe,
};

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    const mergedTranslations = merge(translationsCommon, TRANSLATIONS[lang]);
    return of(mergedTranslations);
  }
}
