import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';

const SubscribeToFacadeNewsletterMutation = gql`
    mutation subscribeToFacadeNewsletter($type: String!) {
        SubscribeToFacadeNewsletter(type: $type) {
            code
            message
            status
        }
    }
`;

const UnsubscribeToFacadeNewsletterMutation = gql`
    mutation unsubscribeToFacadeNewsletter($type: String!) {
        UnsubscribeFromFacadeNewsletter(type: $type) {
            status
            message
            code
        }
    }
`;

export const AllNewsletterMutations = [
    SubscribeToFacadeNewsletterMutation,
    UnsubscribeToFacadeNewsletterMutation
];

@Injectable()
export class NewsletterMutations {
    private apiService =  inject(ApiService);
    private apollo = inject(Apollo);

    subscribeToNewsletter(type: string) {
        this.apollo.mutate({
            mutation: SubscribeToFacadeNewsletterMutation,
            variables: {type}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['SubscribeToFacadeNewsletter']
                    && d?.data['SubscribeToFacadeNewsletter']['status']))
                  .subscribe((status: string) => {
                          if (status.toLowerCase() === 'success') {
                              void this.apiService.presentSuccessToast('Newsletteranmeldung erfolgreich.');
                          } else {
                              void this.apiService.presentErrorToast(
                                  null,
                                  'Newsletteranmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut'
                              );
                          }
                  });
            },
            error: (err) => {
                void this.apiService.presentErrorToast(
                    null,
                    'Newsletteranmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut'
                );
            }
        });
    }

    unsubscribeToNewsletter(type: string) {
        this.apollo.mutate({
            mutation: UnsubscribeToFacadeNewsletterMutation,
            variables: {type}
        }).subscribe({
            next: result => {
                from([result])
                    .pipe(map(d => d?.data && d?.data['UnsubscribeFromFacadeNewsletter']
                        && d?.data['UnsubscribeFromFacadeNewsletter']['status']))
                    .subscribe((status: string) => {
                        if (status.toLowerCase() === 'success') {
                            void this.apiService.presentSuccessToast('Newsletterabmeldung erfolgreich.');

                        } else {
                            void this.apiService.presentErrorToast(
                                null,
                                'Newsletterabmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut'
                            );
                        }
                    });
            },
            error: (err) => {
                void this.apiService.presentErrorToast(
                    null,
                    'Newsletterabmeldung fehlgeschlagen. Bitte versuchen Sie es später erneut'
                );
            }
        });
    }
}
