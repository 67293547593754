import { ConversationProgressEvent } from '../../../types/src/backendConversation';
import { BackendMessage, BackendMessageUpdate } from '../../../types/src/backendMessage';
import { DecryptedChatnameUpdate } from '../../../types/src/chatPartnerMetadata';
import Message, { DecryptedMessage, deletedMessageReplacementText } from '../../../types/src/message';
import { MessageOrEvent } from '../../../types/src/messageOrEvent';

export class MessageUtil {
  static isMessage(messageOrEvent: MessageOrEvent): messageOrEvent is Message {
    return messageOrEvent.hasOwnProperty('id');
  }

  static isDecryptedChatnameUpdate(messageOrEvent: MessageOrEvent): messageOrEvent is DecryptedChatnameUpdate {
    return (
      messageOrEvent.hasOwnProperty('oldDecryptedChatname') && messageOrEvent.hasOwnProperty('newDecryptedChatname')
    );
  }

  static isConversationProgressEvent(messageOrEvent: MessageOrEvent): messageOrEvent is ConversationProgressEvent {
    return messageOrEvent.hasOwnProperty('status');
  }

  static isAppointmentMessage(message: Message) {
    return message?.media?.find((messageMedia) => messageMedia.mediaType === 'APPOINTMENT') || false;
  }

  static messagesAreTheSame(messages1: Message[], messages2: Message[]): boolean {
    if (messages1.length !== messages2.length) {
      return false;
    }
    for (const index in messages1) {
      if (messages1[index]?.id !== messages2[index]?.id) {
        return false;
      }
    }
    return true;
  }

  static mapToDeletedMessageUpdate(message: BackendMessageUpdate): Partial<DecryptedMessage> {
    return {
      ...message,
      decryptionStatus: 'decrypted',
      decryptedTextContent: deletedMessageReplacementText,
      media: [],
    };
  }

  static setDecryptionStatusForMessage(message: BackendMessage): Message {
    const decryptionStatus = message.decryptedTextContent ? 'decrypted' : 'encrypted';
    return { ...message, decryptionStatus };
  }

  static hydrateBackendMessage(message: BackendMessage): Message {
    if (message.isDeleted) {
      return MessageUtil.mapToDeletedMessage(message);
    } else {
      return MessageUtil.setDecryptionStatusForMessage(message);
    }
  }

  private static mapToDeletedMessage(message: BackendMessage): DecryptedMessage {
    return {
      ...message,
      decryptionStatus: 'decrypted',
      decryptedTextContent: deletedMessageReplacementText,
      media: [],
    };
  }
}
