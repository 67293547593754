import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap, take } from 'rxjs';
import {
    FreeTextSurveyInterface,
    MultipleChoiceSurveyInterface,
    SendSurveyInterface,
    SurveyInterface,
    SurveyOverviewInterface
} from '../interfaces/survey.interface';
import { SurveyMutations, SurveyQueries } from '../core.store';
import { SurveyOtherAnswerId, SurveyQuestionTypeEnum } from '../enums/survey.enum';

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    private surveyById$ = new BehaviorSubject<SurveyInterface>(null);
    /**
     * Used to track which slides already have a valid answer.
     * @private
     */
    private answerTracker$ = new BehaviorSubject<boolean[]>(null);

    constructor(
        private surveyQueries: SurveyQueries,
        private surveyMutations: SurveyMutations,
    ) {
    }

    /**
     * Get all surveys sorted by their due date.
     */
    getSurveys(): Observable<SurveyOverviewInterface[]> {
        return this.surveyQueries.getSurveyUser().pipe(
            switchMap(surveyUser => {
                return this.surveyQueries.getSurveys().pipe(
                    map(surveys => {
                        return surveys.map(survey => {
                            return {...survey, isFilledOut: surveyUser.findIndex(item => item.toString() === survey.id.toString()) > -1};
                        });
                    })
                );
            })
        );
    }

    /**
     * Get a survey by its id.
     */
    getSurveyByIdWithUserCheck(id: string): Observable<SurveyInterface> {
        return this.surveyQueries.getSurveyUserBySurveyId(id).pipe(
            switchMap(surveyUser => {
                if (surveyUser) {
                    return  new Observable(observer => observer.next(null));
                }
                return this.surveyQueries.getSurveyById(id).pipe(
                    map((survey: SurveyInterface) => {
                        if (!survey) {
                            return survey;
                        }
                        const array = new Array(survey.questions.length + 1).fill(true, 0, 1).fill(false, 1);
                        survey.questions.forEach((question, index) => {
                            if (!question.isAnswerRequired) {
                                array[index + 1] = true;
                            }
                        });
                        return survey;
                    })
                );
            })
        ) as Observable<SurveyInterface>;
    }

    /**
     * Get a survey by its id.
     */
    getSurveyById(id: string): Observable<SurveyInterface> {
        this.surveyQueries.getSurveyById(id).pipe(
            take(1),
        ).subscribe(survey => {
            this.surveyById$.next(survey);
            if (survey) {
                const array = new Array(survey.questions.length + 1).fill(true, 0, 1).fill(false, 1);
                survey.questions.forEach((question, index) => {
                    if (!question.isAnswerRequired) {
                        array[index + 1] = true;
                    }
                });
                this.answerTracker$.next(array);
            }
        });

        return this.surveyById$.asObservable();
    }

    /**
     * Get a tracker Observable to track which slides already have a valid answer.
     */
    getAnswerTracker(): Observable<boolean[]> {
        return this.answerTracker$.asObservable();
    }

    /**
     * Change the isSelected value of an answer.
     */
    setAnswerSelected(
        questionId: string,
        answerId: string,
        isSelected: boolean,
        isMultipleChoice: boolean,
        slideIndex: number
    ) {
        const question = this.surveyById$.value.questions
            .find((quest: MultipleChoiceSurveyInterface) => (
                quest.id === questionId &&
                quest.__typename === SurveyQuestionTypeEnum.multipleChoice
            )) as MultipleChoiceSurveyInterface;

        if (question.id === questionId) {
            question.answers.map(answer => {
                if (!isMultipleChoice) {
                    answer.isSelected = false;
                }
                if (answer.id === answerId) {
                    answer.isSelected = isSelected;
                }
                return answer;
            });
        }

        const tracker = this.answerTracker$.value;
        if (isMultipleChoice) {
            const otherAnswer = question.answers.find(item => item.id === SurveyOtherAnswerId.other);
            if (!otherAnswer?.isSelected || otherAnswer.isSelected && otherAnswer.freeTextAnswer.length > 3) {
                tracker[slideIndex] = question.answers.filter(item => item.isSelected === true).length > 0 || !question.isAnswerRequired;
            } else {
                tracker[slideIndex] = false;
            }
        } else {
            if (answerId === SurveyOtherAnswerId.other) {
                tracker[slideIndex] = question.answers.find(item => item.id === answerId)?.freeTextAnswer.length > 3;
            } else {
                tracker[slideIndex] = question.isAnswerRequired ? isSelected : true;
            }
        }
        this.answerTracker$.next(tracker);

        // Note: the find method manipulates the object directly, so we can emit it directly here.
        this.surveyById$.next({...this.surveyById$.value});
    }

    /**
     * Set the text which the user entered for e specific answer.
     */
    setAnswerFreeText(
        questionId: string,
        answerId: string,
        text: string,
        type: SurveyQuestionTypeEnum,
        slideIndex: number) {
        switch (type) {
            case SurveyQuestionTypeEnum.freeText:
                const questionFree = this.surveyById$.value.questions
                    .filter(item => item.__typename === SurveyQuestionTypeEnum.freeText)
                    .find(item => item.id === questionId) as FreeTextSurveyInterface;
                questionFree.freeTextAnswer = text.trim();
                break;
            case SurveyQuestionTypeEnum.multipleChoice:
                const questionMC = this.surveyById$.value.questions
                    .filter(item => item.__typename === SurveyQuestionTypeEnum.multipleChoice)
                    .find(item => item.id === questionId) as MultipleChoiceSurveyInterface;
                questionMC.answers.find(item => item.id === answerId).freeTextAnswer = text.trim();
                break;
        }

        const tracker = this.answerTracker$.value;
        const isRequired = this.surveyById$.value.questions
            .find(item => item.id === questionId && item.__typename === type).isAnswerRequired;
        tracker[slideIndex] = isRequired ? text.length > 3 : true;
        this.answerTracker$.next(tracker);

        // Note: the find method manipulates the object directly, so we can emit it directly here.
        this.surveyById$.next({...this.surveyById$.value});
    }

    submitSurvey() {
        const sendSurvey: SendSurveyInterface = {
            sendSurveyId: this.surveyById$.value.id,
            selectedAnswers: [],
        };
        this.surveyById$.value.questions.forEach((question: FreeTextSurveyInterface | MultipleChoiceSurveyInterface) => {
            switch (question.__typename) {
                case SurveyQuestionTypeEnum.freeText:
                    if (question.freeTextAnswer.length > 0) {
                        sendSurvey.selectedAnswers.push({
                            questionId: question.id,
                            selectedAnswerId: null,
                            answerFreeText: question.freeTextAnswer,
                            type: SurveyQuestionTypeEnum.freeText,
                        });
                    }
                    break;
                case SurveyQuestionTypeEnum.multipleChoice:
                    if ('answers' in question) {
                        const selectedAnswers = question.answers.filter(answer => answer.isSelected === true);
                        selectedAnswers.forEach(answer => {
                            sendSurvey.selectedAnswers.push({
                                questionId: question.id,
                                selectedAnswerId: answer.id === SurveyOtherAnswerId.other ? null : answer.id,
                                answerFreeText: answer.freeTextAnswer,
                                type: SurveyQuestionTypeEnum.multipleChoice,
                            });
                        });
                    }
                    break;
            }
        });
        this.surveyMutations.submitSurvey(sendSurvey);
    }
}
