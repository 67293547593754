import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../attributes';

export default gql`
  mutation setShopUserMessageAsRead($id: ID!) {
    setShopUserMessageAsRead(id: $id) {
      ${shopUserMessageAttributes}
    }
  }
`;
