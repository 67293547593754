import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import equal from 'fast-deep-equal';
import isNil from 'lodash-es/isNil';
import { firstValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { ChatStatePersistenceKeys, PersistedChatState } from '../../../../essentials/types/src/persistedChatState';
import { Logger } from '../../../../essentials/util/src/logger';
import { selectChatData } from '../../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { selectReleaseVersion } from '../../../../store/src/common-store/release-store/selectors/release.selectors';
import { selectCognitoId } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import { StorageService } from '../storage.service';

const logger = new Logger('StatePersistenceService');

export const STATE_PERSISTENCE_KEY = 'persistedNgrxState';

@Injectable({
  providedIn: 'root',
})
export class StatePersistenceService {
  constructor(
    private storageService: StorageService,
    private store: Store<CommonState>
  ) {
    this.initialize();
  }

  async initialize() {
    const currentReleaseVersion = await firstValueFrom(this.store.select(selectReleaseVersion));
    await this.removeOldPersistedStates(currentReleaseVersion);
  }

  keepConversationsAndMessagesPersistent() {
    this.store
      .select(selectChatData)
      .pipe(
        debounceTime(1000),
        map(({ conversations, messages, conversationsLoadStatus }) => ({
          conversations,
          messages,
          conversationsLoadStatus,
        })),
        distinctUntilChanged(equal),
        withLatestFrom(this.store.select(selectReleaseVersion), this.store.select(selectCognitoId))
      )
      .subscribe(async ([{ conversations, messages, conversationsLoadStatus }, releaseVersion, cognitoId]) => {
        if (!isNil(cognitoId) && conversationsLoadStatus === LoadStatus.UpToDate) {
          await this.persistChatState({ releaseVersion, cognitoId }, { conversations, messages });
        }
      });
  }

  async retrieveChatState(keys: ChatStatePersistenceKeys) {
    return this.storageService.get<PersistedChatState>(this.getKeyForPersistedChatState(keys));
  }

  async removePersistedStatesForUser(cognitoId: string) {
    try {
      await this.storageService.forEach((_, key: string) => {
        if (key.startsWith(STATE_PERSISTENCE_KEY) && key.includes(cognitoId)) {
          this.storageService.remove(key);
        }
      });
    } catch (e) {
      logger.error('Error user persisted state for user', e);
    }
  }

  private async removeOldPersistedStates(currentReleaseVersion: string) {
    try {
      await this.storageService.forEach((_, key: string) => {
        if (
          key.startsWith(STATE_PERSISTENCE_KEY) &&
          !key.startsWith([STATE_PERSISTENCE_KEY, currentReleaseVersion].join('_'))
        ) {
          this.storageService.remove(key);
        }
      });
    } catch (e) {
      logger.error('Error removing old persisted states', e);
    }
  }

  private async persistChatState(keys: ChatStatePersistenceKeys, chatState: PersistedChatState) {
    try {
      await this.storageService.set(this.getKeyForPersistedChatState(keys), chatState);
    } catch (e) {
      logger.error('Error persisting chat state', e);
    }
  }

  private getKeyForPersistedChatState({ releaseVersion, cognitoId }: ChatStatePersistenceKeys) {
    return [STATE_PERSISTENCE_KEY, releaseVersion, cognitoId].join('_');
  }
}
