import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { gql } from '@apollo/client/core';
import { Observable, map,  } from 'rxjs';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import {
    FetchPolicyKeys as FPK
} from '../../../enums/fetch-policy-keys.enum';
import{
    DataChangedKeys as DCK
} from '../../../enums/data-changed-keys.enum';

const GetNewsletterSubscriptions = (queryName) => gql`
    query ${queryName} {
        newsletterSubscriptions(limit: 100) {
            type
        }
    }`;

export const AllNewsletterQueries = [
    GetNewsletterSubscriptions('test')
];


@Injectable()
export class NewsletterQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getNewsletterSubscriptions]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
                [DCK.newsletterSubscriptionsChanged]:[FPK.getNewsletterSubscriptions],
        });
    }

    getNewsletterSubscriptions(): Observable<{type: string}[]> {
        const fetchPolicyKey = FPK.getNewsletterSubscriptions;
        return this.apollo.watchQuery({
            query: GetNewsletterSubscriptions(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['newsletterSubscriptions']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<{type: string}[]>;
    }
}

