<ion-button
  (click)="onClick($event)"
  [disabled]="disabled() || loading()"
  [ngClass]="buttonStyle()"
  [expand]="expand()"
  [fill]="fill()"
  [type]="type()"
>
  @if (loading()) {
    <ion-spinner></ion-spinner>
  }
  @if (icon(); as icon) {
    <ion-icon [style.visibility]="loading() ? 'hidden' : 'visible'" [src]="icon | iconName"></ion-icon>
  }
  <span [style.visibility]="loading() ? 'hidden' : 'visible'">
    <ng-content></ng-content>
  </span>
</ion-button>
