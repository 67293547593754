import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { OrderStatusAllConfig, OrderStatusConfig } from '../../config/order-status.config';
import { DeliveryReceiptStatusConfig, DeliveryReceiptSubTypeAllConfig, DeliveryReceiptSubTypeConfig } from '../../config/delivery-receipt.config';
import { OrderStatusInterface } from '../../interfaces/order-status.interface';
import { DocumentSubTypeInterface } from '../../interfaces/document.interface';
import { BadgeTypeEnum } from '../../enums/badge.enum';
import { InvoicesTypeFilterConfig, InvoiceTypesConfig } from '../../config/invoices.config';
import { DocumentStatus } from '../../enums/documents.enum';
import { ReturnsStatusAllConfig, ReturnsStatusConfig, ReturnsTypeConfig } from '../../config/returns.config';
import { ReturnsStatusInterface } from '../../interfaces/returns.interface';
import { OrderBulkActionConfig } from '../../config/order-bulk-action.config';
import { BulkActionCodeEnum } from '../../enums/bulk-action.enum';
import { NotificationTypeConfig } from '../../config/notification-type.config';
import { getCommunicationZoneBadgeConfig } from '../../config/communication-zone-forms.config';
import { BadgeComponentInterface } from '../../interfaces/badge.interface';
import { CommunicationZoneType } from '../../core.enums';

/**
 *
 * Usage:
 * <app-badge [code]="DEL"> </app-delivery-status-badge>
 */
@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit, OnChanges {
    @Input() code = '';
    @Input() badgeType: BadgeTypeEnum = BadgeTypeEnum.ORDER;
    @Input() badgeTypeInfo = '';
    @Input() ttk: string = null;
    @Input() tt: string = null;
    @Input() ttp = 'left';
    @Input() backgroundColor: string = null;
    @Input() textColor: string = null;
    @Input() borderColor: string = null;
    @Input() borderWidth: string = null;
    @Input() icon: string = null;
    @Input() isIconAfterBadge = true;
    @Input() showBadge = true;
    @Input() isClickable = false;
    @Input() noMargin = false;

    defaultDeliveryStatus: OrderStatusInterface = OrderStatusAllConfig;
    defaultDeliveryReceiptSubtype: DocumentSubTypeInterface = DeliveryReceiptSubTypeAllConfig;
    defaultInvoiceType: DocumentSubTypeInterface = InvoicesTypeFilterConfig[0];
    defaultReturnsStatus: ReturnsStatusInterface = ReturnsStatusAllConfig;

    badge: BadgeComponentInterface;

    constructor() {

    }

    ngOnInit() {
        if ((this.code && this.badgeType) || [BadgeTypeEnum.DOCUMENT_CANCEL, BadgeTypeEnum.MEA_DOWNLOADS].includes(this.badgeType)) {
            let useDefault;
            switch (this.badgeType) {
                case BadgeTypeEnum.ORDER:
                    const deliveryStates = OrderStatusConfig.filter(
                        status => status.id.toUpperCase() === this.code.toUpperCase()
                    );

                    useDefault = !deliveryStates || deliveryStates.length  === 0;
                    this.badge = {
                        color: useDefault ? this.defaultDeliveryStatus.color : deliveryStates[0].color,
                        tooltip: 'order_status_' + this.code,
                        value: useDefault ? this.defaultDeliveryStatus.shortcode : deliveryStates[0].shortcode
                    };
                    break;
                case BadgeTypeEnum.ORDER_BULK_ACTION:
                    const defaultConfig = OrderBulkActionConfig.find(action => action.code === BulkActionCodeEnum.DEFAULT);
                    this.badge = {
                        value: OrderBulkActionConfig.find(action => action.code === this.code)?.label ?? defaultConfig.label,
                        color: OrderBulkActionConfig.find(action => action.code === this.code)?.color ?? defaultConfig.color,
                    };
                    break;
                case BadgeTypeEnum.DOCUMENT_CANCEL:
                    const deliveryStatus = DeliveryReceiptStatusConfig.filter(
                        status => status.id.toUpperCase() === DocumentStatus.CANCELLED
                    );

                    this.badge = {
                        color: deliveryStatus[0].color,
                        tooltip: 'document_cancelled',
                        value: deliveryStatus[0].shortcode
                    };
                    break;
                case BadgeTypeEnum.USER_ADMINISTRATION:
                    this.badge = {
                        color: 'user-administration',
                        tooltip: this.ttk,
                        value: this.code
                    };
                    break;
                case BadgeTypeEnum.FILTER_RESULT:
                    this.badge = {
                        color: 'filter-result',
                        value: this.code
                    };
                    break;
                case BadgeTypeEnum.DELIVERY_RECEIPT_SUBTYPE:
                    const deliveryReceiptSubType = DeliveryReceiptSubTypeConfig.filter(
                        status => status.id.toUpperCase() === this.code.toUpperCase()
                    );

                    useDefault = !deliveryReceiptSubType || deliveryReceiptSubType.length === 0;
                    this.badge = {
                        color: useDefault ? this.defaultDeliveryReceiptSubtype.color : deliveryReceiptSubType[0].color,
                        tooltip: 'delivery_receipt_subtype_' + this.code,
                        value: useDefault ? this.defaultDeliveryReceiptSubtype.shortcode  : deliveryReceiptSubType[0].shortcode
                    };
                    break;
                case BadgeTypeEnum.INVOICE:
                    const invoiceType = InvoiceTypesConfig.filter(
                        status => status.id.toUpperCase() === this.code.toUpperCase()
                    );

                    useDefault = !invoiceType || invoiceType.length === 0;
                    this.badge = {
                        color: useDefault ? this.defaultInvoiceType.color : invoiceType[0].color,
                        tooltip: 'invoice_type_' + this.code,
                        value: useDefault ? this.defaultInvoiceType.shortcode  : invoiceType[0].shortcode
                    };
                    break;
                case BadgeTypeEnum.RETURNS_STATUS:
                    const returnStatus = ReturnsStatusConfig.filter(status => status.id.toUpperCase() === this.code.toUpperCase());
                    useDefault = !returnStatus || returnStatus.length === 0;
                    this.badge = {
                        color: useDefault ? this.defaultReturnsStatus.color : returnStatus[0].color,
                        tooltip: 'return_status_' + returnStatus[0]?.tooltipCode,
                        value: useDefault ? this.defaultReturnsStatus.shortcode : returnStatus[0].shortcode
                    };
                    break;
                case BadgeTypeEnum.RETURNS_TYPE:
                    const returnType = ReturnsTypeConfig.filter(type => type.id.toUpperCase() === this.code.toUpperCase());
                    this.badge = {
                        color: returnType[0].color,
                        tooltip: 'return_type_' + returnType[0].tooltipCode,
                        value: returnType[0].shortcode
                    };
                    break;
                case BadgeTypeEnum.OFFERS_ACTION_BADGE:
                    this.badge = {
                        value: this.code
                    };
                    break;
                case BadgeTypeEnum.NOTIFICATION:
                    this.badge = NotificationTypeConfig(this.code);
                    break;
                case BadgeTypeEnum.COMMUNICATION_ZONE:
                    this.badge = getCommunicationZoneBadgeConfig(this.code as CommunicationZoneType);
                    break;
                case BadgeTypeEnum.MEA_SHOP:
                    this.badge = {
                        color: 'notification-badge-meashop',
                        value: this.code
                    };
                    break;
                case BadgeTypeEnum.MEA_DOWNLOADS:
                    this.badge = {
                        color: `mea-downloads-${this.badgeTypeInfo || 'default'}`,
                        value: this.code
                    };
                    break;
                case BadgeTypeEnum.MEA_DOWNLOADS_MULTIPLE:
                    this.badge = {
                        color: `mea-download-multiple`,
                        value: this.code
                    };
                    break;
                case BadgeTypeEnum.EDUCATION_LOCATION:
                    this.badge = {
                        color: `badge-color-${this.badgeTypeInfo || 'grey'}`,
                        value: this.code
                    };
                    break;
                case BadgeTypeEnum.DELIVERED_ITEMS:
                    this.badge = {
                        color: 'delivered-items',
                        value: this.code + ' St zugestellt'
                    };
                    break;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.code || changes.badgeType) {
            this.ngOnInit();
        }
    }
}
