import { Injectable } from '@angular/core';
import { MessageCacheService } from '../../../../../../common/services/src/message-cache/message-cache.service';
import Message, { DecryptedMessage } from '../../../../../../essentials/types/src/message';

@Injectable({ providedIn: 'root' })
export class ChatStoreMessageDecryptionService {
  constructor(private messageCacheService: MessageCacheService) {}

  async decryptMessages(messages: Message[], conversationPassword: string | undefined): Promise<DecryptedMessage[]> {
    const decryptedMessages: DecryptedMessage[] = [];
    for (const message of messages) {
      const decryptedMessage = await this.messageCacheService.getDecryptedMessage(conversationPassword, message);
      if (decryptedMessage.displayAsPharmacyMessage) {
        decryptedMessage.decryptedTextContent = decryptedMessage.decryptedTextContent
          ?.replace(/%OPEN_B%/g, '<b>')
          .replace(/%CLOSE_B%/g, '</b>');
      }
      decryptedMessages.push(decryptedMessage);
    }
    return decryptedMessages;
  }
}
