import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { AppointmentInterface, AppointmentBySanacorpInterface } from '../../../core.interfaces';
import {
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK,
    QueryFetchPolicy
} from '../../../core.enums';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';

const GetAppointmentBySanacorpFromToData = (queryName) => gql`
    query ${queryName}($fromDate: String, $toDate: String, $limit: Int) {
        AppointmentsBySanacorp(fromDate: $fromDate, toDate: $toDate, limit: $limit) {
            id
            title
            description
            dateTimeFrom
            dateTimeTo
            formButton
            formId
        }
    }`;

const GetAppointmentFromToData = (queryName) => gql`
    query ${queryName}($fromDate: timestamptz!, $toDate: timestamptz!, $limit: Int) {
        appointment(where: {_and: [{dateTimeFrom: {_gte: $fromDate}}, {dateTimeTo: {_lte: $toDate}}]}, limit: $limit, order_by: [{dateTimeFrom: asc}]) {
            id
            title
            description
            dateTimeFrom
            dateTimeTo
            isPharmacy
            payload
            userId
        }
    }`;

export const AllAppointmentQueries = [
    GetAppointmentBySanacorpFromToData('test'),
    GetAppointmentFromToData('test')
];

const idDelimiter = '.';
export const setLockedBySanacorp = (
  appointmentArray: Array<AppointmentInterface>,
  isLocked: boolean,
  hasSeminar: boolean = true
): Array<AppointmentInterface> => {
    if (isLocked && hasSeminar) {
        return formatAppointmentData(appointmentArray);
    }
    if (appointmentArray) {
        return appointmentArray.map(appointment => {
            return {
                ...appointment,
                dateTimeFrom: appointment.dateTimeFrom,
                lockedBySanacorp: isLocked
            };
        });
    }
};

export const getSanacorpAppointmentIdByFullId = (id) => {
    return id.toString().split(idDelimiter)[0];
};
export const getSanacorpAppointmentTimespanIdByFullId = (id) => {
    const idSplit = id.toString().split(idDelimiter);
    // for all appointments with no timespan, 0 will be put (e.g. for not scheduled seminars)
    return idSplit && idSplit.length > 1 ? idSplit[1].toString() : '0';
};

export const formatAppointmentData = (appointmentArray: Array<AppointmentInterface>): Array<AppointmentInterface> => {
    if (appointmentArray) {
        return appointmentArray.reduce((array, appointment) => {
            // appointments that are not Scheduled
            if (appointment.timespan && appointment.timespan.length > 0) {
                // appointments with timespan
                appointment.timespan.map((appointmentTime) => {
                    array.push({
                        ...appointment,
                        id: appointment.id + idDelimiter + appointmentTime.id,
                        dateTimeFrom: appointmentTime.dateTimeFrom,
                        dateTimeTo: appointmentTime.dateTimeTo,
                        lockedBySanacorp: true
                    });
                });
            }
            return array;
        }, []);
    }
};


@Injectable()
export class AppointmentQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getAppointmentsByDate]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getAppointmentsBySanacorpByDate]: QueryFetchPolicy.NETWORK_ONLY,
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.appointmentChanged]: [
                FPK.getAppointmentsByDate,
            ],
            [DCK.privateAppointmentChanged]: [
                FPK.getAppointmentsBySanacorpByDate,
            ]
        });
    }

    public getAppointmentsByDate(
        fromDate: string,
        toDate: string,
        limit: number = 100
    ): Observable<AppointmentInterface[]> {

        if(limit > GraphQLLimits.sanacorpAppointments) {
            limit = GraphQLLimits.sanacorpAppointments;
            console.error('Limit above maximum!');
        }

        const fetchPolicyKey = FPK.getAppointmentsByDate;
        return this.apollo.watchQuery({
            query: GetAppointmentFromToData(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables: {fromDate: fromDate || '2000-01-01', toDate: toDate || '2100-01-01', limit}
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['appointment']))
            .pipe(map((appointmentArray: Array<AppointmentInterface>) => {
                return setLockedBySanacorp(appointmentArray, false, false);
            }))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;}));
    }

    public getAppointmentsBySanacorpByDate(
        fromDate: string,
        toDate: string,
        limit: number = 100
    ): Observable<AppointmentBySanacorpInterface[]> {
        const fetchPolicyKey = FPK.getAppointmentsBySanacorpByDate;
        const variables = { limit };
        if (fromDate) {
            variables['fromDate'] = fromDate;
        }
        if (toDate) {
            variables['toDate'] = toDate;
        }

        return this.apollo.watchQuery({
            query: GetAppointmentBySanacorpFromToData(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['AppointmentsBySanacorp']))
            .pipe(map((appointmentArray: Array<AppointmentBySanacorpInterface>) => {
                return setLockedBySanacorp(appointmentArray, true, false);
            }))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<AppointmentBySanacorpInterface[]>;
    }
}
