import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import { of } from 'rxjs';
import { mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppsyncConversationService } from '../../../../../common/services/src/appsync/appsync-conversation.service';
import { InitUserService } from '../../../../../common/services/src/init-user.service';
import { StatePersistenceService } from '../../../../../common/services/src/persistence/state-persistence.service';
import { ChatSubscriptionService } from '../../../../../common/services/src/subscriptions/chat-subscription.service';
import { SubscriptionManagementService } from '../../../../../common/services/src/subscriptions/subscription-management.service';
import { ChatUser } from '../../../../../essentials/types/src/chatUser';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import { LogoutType } from '../../../../../essentials/types/src/logoutType';
import { CONFIG } from '../../../../../essentials/types/src/mea-config';
import { ChatStatePersistenceKeys } from '../../../../../essentials/types/src/persistedChatState';
import { Logger } from '../../../../../essentials/util/src/logger';
import {
  initConversations,
  populateChatStateFromStorage,
  setRecentConversations,
} from '../../chat-store/actions/chat-conversation.actions';
import { CommonState } from '../../common.state';
import { selectReleaseVersion } from '../../release-store/selectors/release.selectors';
import {
  checkSsoSessionStatus,
  clearUserOnLogout,
  logoutUser,
  rehydrateUser,
  setUserLoadStatus,
  setUserOnLogin,
} from '../../user-store/actions/user.actions';
import { selectUser } from '../../user-store/selectors/user.selectors';
import {
  checkUserAndRestartSubscriptions,
  markDataWithSubscriptionsAsStale,
  startAppsyncSubscriptions,
  stopAppsyncSubscriptions,
} from '../actions/subscription.actions';

export const logger = new Logger('SubscriptionEffects');

@Injectable()
export class SubscriptionEffects {
  private actions$ = inject(Actions);
  private appsyncConversationService = inject(AppsyncConversationService);
  private chatSubscriptionService = inject(ChatSubscriptionService);
  private initUserService = inject(InitUserService);
  private statePersistenceService = inject(StatePersistenceService);
  private store: Store<CommonState> = inject(Store);
  private subscriptionManagementService = inject(SubscriptionManagementService);
  private config = inject(CONFIG);

  isPharmacy = !this.config.clientApp;

  initChatOnLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUserOnLogin),
        withLatestFrom(this.store.select(selectReleaseVersion)),
        switchMap(async ([{ user }, releaseVersion]) => {
          await this.loadMessagesAndConversationsFromStorage({ cognitoId: user.cognitoId, releaseVersion });
          if (this.isPharmacy) {
            const recentConversations = await this.appsyncConversationService.getRecentConversations();
            if (recentConversations.length > 0) {
              this.store.dispatch(setRecentConversations({ conversationsAndLastMessages: recentConversations }));
            }
          }
          this.initConversationsAndStartSubscriptions(user);
          if (this.isPharmacy) {
            this.store.dispatch(checkSsoSessionStatus({ user }));
          }
        })
      ),
    { dispatch: false }
  );

  stopSubscriptionsOnLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearUserOnLogout),
      switchMap(() => of(stopAppsyncSubscriptions()))
    )
  );

  startChatSubscriptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startAppsyncSubscriptions),
        tap(({ user }) => {
          this.chatSubscriptionService.startChatSubscriptions(user.cognitoId);
        })
      ),
    { dispatch: false }
  );

  unsubscribeAllSubscriptions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(stopAppsyncSubscriptions),
        tap(() => {
          this.subscriptionManagementService.unsubscribeAll();
        })
      ),
    { dispatch: false }
  );

  checkUserAndRestartSubscriptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkUserAndRestartSubscriptions),
      withLatestFrom(this.store.select(selectUser)),
      mergeMap(async ([_, userFromStore]) => {
        try {
          this.store.dispatch(markDataWithSubscriptionsAsStale());
          this.store.dispatch(setUserLoadStatus({ userLoadStatus: LoadStatus.Revalidating }));
          const currentSession = await this.initUserService.getUserOfCurrentSession();
          if (
            currentSession.userType !== 'known' ||
            !userFromStore ||
            currentSession.user.cognitoId !== userFromStore.cognitoId
          ) {
            return logoutUser({ logoutType: LogoutType.InconsistentWithBackend });
          } else {
            this.initConversationsAndStartSubscriptions(currentSession.user);
            return rehydrateUser({ user: currentSession.user });
          }
        } catch (e) {
          logger.error('Error getting user of current session', e);
          return logoutUser({ logoutType: LogoutType.InconsistentWithBackend });
        }
      })
    )
  );

  private initConversationsAndStartSubscriptions(user: ChatUser) {
    this.store.dispatch(initConversations());
    this.store.dispatch(startAppsyncSubscriptions({ user }));
  }

  private async loadMessagesAndConversationsFromStorage(keys: ChatStatePersistenceKeys) {
    try {
      const persistedChatState = await this.statePersistenceService.retrieveChatState(keys);
      if (!isNil(persistedChatState)) {
        this.store.dispatch(populateChatStateFromStorage({ persistedChatState }));
      }
    } catch (e) {
      logger.error('Error loading messages and conversations from storage', e);
    }
  }
}
