import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../attributes';

export default gql`
  mutation addEncryptedNoteToShopUserMessage($shopUserMessageId: ID!, $encryptedText: String!) {
      addEncryptedNoteToShopUserMessage(shopUserMessageId: $shopUserMessageId, encryptedText: $encryptedText) {
        ${shopUserMessageAttributes}
    }
  }
`;
