import { gql } from '@apollo/client/core';
import { Injectable, inject } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, switchMap } from 'rxjs';

import { NewsPostInterface } from '../../../interfaces/news-post.interface';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { convertFromLocalTimeToUTC, lastQuarterHour } from '../../../formatting/date.formatting';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { formatStrapiNewsData, getFiltersStrapi } from '../utils';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryWrapper } from '../query.wrapper';
import {
    FetchPolicyKeys as FPK
} from '../../../enums/fetch-policy-keys.enum';
import{
    DataChangedKeys as DCK
} from '../../../enums/data-changed-keys.enum';
import { PharmacyStoreInterface } from '../../../core.interfaces';
import { StaticAppLocations } from '../../../enums/app-location.enum';
import { getLocationFromNewsUrl } from '../../../config/navigation.config';


export const newsPostFields = `
    id : documentId
    title
    subtitle
    appLocations {
      url
      type
      isMea
    }
    content
    contentHtml
    date
    location {
        id: documentId
    }
    image {
        url
        height
        width
    }
    imageType
    form {
        id : documentId
        publishedAt
    }
    formButton`;

// We can't provide the date as a parameter (strapi returns error), so we have to inline it.
export const GetNewsPosts = (queryName) => gql`
   query ${queryName}($limit: Int, $filters: NewsPostFiltersInput, $status: PublicationStatus) {
	newsPosts(
        sort: ["date:desc"],
        pagination: {limit: $limit}
        filters: $filters,
        status: $status) {
        ${newsPostFields}
      }
    }
`;

export const GetNewsPost = (queryName) => gql`
   query ${queryName}($filters: NewsPostFiltersInput, $status: PublicationStatus) {
	newsPosts(filters: $filters, pagination: {limit: 1}, status: $status) {
        id : documentId
        title
        subtitle
        appLocations {
          url
          type
          isMea
        }
        content
        contentHtml
        date
        link
        videoLink
        publishedAt
        attachments {
            url
            mime
            size
            name
        }
        image {
            url
            height
            width
        }
        imageType
        form {
            id : documentId
            publishedAt
        }
        formButton
    }
}
`;

export const AllNewsPostQueries = [
    GetNewsPosts('test'),
    GetNewsPost('test')
];


@Injectable()
export class NewsPostQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    fetchPolicies = {
        [FPK.getNewsPosts]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getNewsPost]: QueryFetchPolicy.NETWORK_ONLY
    };

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.newsPostChanged]:[FPK.getNewsPosts, FPK.getNewsPost]
        });
    }

    public getNewsPostVariables = (
        pharmacy: PharmacyStoreInterface,
        appLocationUrl?: string,
        newsPostId = null,
        limit?: number
    ): {filters: {}, limit?: number, status?: string | null} => {
        const additionalFilters = getFiltersStrapi(pharmacy);
        if (newsPostId) {
            return {
                status: 'PUBLISHED',
                filters: {
                    documentId: {eq: newsPostId},
                    date: {lte: convertFromLocalTimeToUTC(lastQuarterHour(), false, true)},
                    ...additionalFilters
                }
            };
        }
        const appLocationType = getLocationFromNewsUrl(appLocationUrl);
        const strapiUrl = (appLocationType === StaticAppLocations.DynamicPage && appLocationUrl) ? [{ url: { eq: appLocationUrl } }] : [];

        return {
            limit: limit ? limit : GraphQLLimits.newsPosts,
            status: 'PUBLISHED',
            filters: {
                and: [
                    {date: {lte: convertFromLocalTimeToUTC(lastQuarterHour(), false, true)}},
                    {publishedAt: {notNull: true}},
                    (appLocationType ? {
                        appLocations: {
                            and: [
                                { type: { eq: appLocationType } },
                                ...strapiUrl
                            ]
                        }
                    } : {}),
                    additionalFilters
                ]
            }
        };
    };

    public getNewsPosts(appLocationUrl: string, limit?: number): Observable<NewsPostInterface[]> {
        const fetchPolicyKey = FPK.getNewsPosts;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetNewsPosts(fetchPolicyKey),
                        variables: this.getNewsPostVariables(pharmacy, appLocationUrl, null, limit),
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(map((d) => d?.data && d?.data['newsPosts']))
                    .pipe(map((newsData) => formatStrapiNewsData(newsData, appLocationUrl)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getNewsPost(newsPostId: string): Observable<NewsPostInterface> {
        const fetchPolicyKey = FPK.getNewsPost;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) => {
                    const variables = this.getNewsPostVariables(pharmacy, null, newsPostId);
                    return this.apollo
                        .watchQuery({
                            query: GetNewsPost(fetchPolicyKey),
                            variables,
                            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                        })
                        .valueChanges.pipe(
                        map(
                            (d) =>
                                d?.data &&
                                d?.data['newsPosts'] &&
                                d?.data['newsPosts']?.length > 0 &&
                                d?.data['newsPosts'][0]
                        )
                    )
                        .pipe(map((newsData) => formatStrapiNewsData(newsData, null)))
                        // If no status is set, then allow the returned value (preview mode)
                        .pipe(map((newsData) => (newsData.publishedAt || !variables.status ? newsData : null)))
                        .pipe(
                            map((d) => {
                                if (d) this.updateFetchPolicy(fetchPolicyKey);
                                return d;
                            })
                        ) as Observable<NewsPostInterface>;
                })
        );
    }
}
