import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { ConversationProgressEvent, DeletionEntry } from '../../../../../essentials/types/src/backendConversation';
import { ChatUser } from '../../../../../essentials/types/src/chatUser';
import { Conversation } from '../../../../../essentials/types/src/conversation';

export const openDeleteChatModal = createAction(
  `${ActionType.Chat}: Open delete chat modal`,
  props<{ conversationId: string }>()
);

export const updateProgressHistory = createAction(
  `${ActionType.Chat}: Update progress history`,
  props<{ conversation: Conversation; newProgressEvent: ConversationProgressEvent }>()
);

export const revertProgressUpdate = createAction(
  `${ActionType.Chat}: Revert update of progress status history`,
  props<{ conversationId: string; progressEventToRemove: ConversationProgressEvent }>()
);

export const updateDeletionRecordForConversation = createAction(
  `${ActionType.Chat}: Update deletion record for conversation`,
  props<{ conversationId: string; deletionRecord: DeletionEntry[] }>()
);

export const updateProgressHistoryForConversation = createAction(
  `${ActionType.Chat}: Update progress history for conversation`,
  props<{ conversationId: string; progressHistory: ConversationProgressEvent[] }>()
);

export const updateConversationLinkForConversation = createAction(
  `${ActionType.Chat}: Update user notification for conversation`,
  props<{ conversationId: string; conversationLink: string }>()
);

export const updateConversationChatPartner = createAction(
  `${ActionType.Chat}: Update chat partner in conversation`,
  props<{ conversationId: string; chatPartner: ChatUser }>()
);

export const updateArchivedByEnduserConversation = createAction(
  `${ActionType.Chat}: Update archived by enduser for conversation`,
  props<{ conversationId: string; archivedByEnduser: boolean }>()
);

export const updateArchivedByPharmacyConversation = createAction(
  `${ActionType.Chat}: Update archived by pharmacy for conversation`,
  props<{ conversationId: string; archivedByPharmacy: boolean }>()
);

export const updateAppointmentId = createAction(
  `${ActionType.Chat}: Update appointment id for conversation`,
  props<{ conversationId: string; appointmentId: string }>()
);

export const updateHasPrescriptions = createAction(
  `${ActionType.Chat}: Update hasPrescriptions for conversation`,
  props<{ conversationId: string; hasPrescriptions: boolean }>()
);
