import { Component, inject, Input } from '@angular/core';
import { take } from 'rxjs';
import { NewsPostInterface, NotificationInterface } from '../../../../../core/core.interfaces';
import { NotificationGoToEntryService } from '../../../../../core/core.services';
import { NewsPostQueries } from '../../../../../core/core.store';

@Component({
    selector: 'app-notification-news-content',
    templateUrl: './notification-news-content.component.html',
    styleUrls: ['./notification-news-content.component.scss'],
})
export class NotificationNewsContentComponent {
    private newsQueries = inject(NewsPostQueries);
    private notificationGoToEntryService = inject(NotificationGoToEntryService);

    news: NewsPostInterface;

    @Input()
    set notificationPayloadId(value: string) {
        this.news = null;

        this.newsQueries
            .getNewsPost(value)
            .pipe(take(1))
            .subscribe((newsData) => {
                this.news = newsData;
            });
    }

    @Input() notification: NotificationInterface;

    async goToNewsPost() {
        await this.notificationGoToEntryService.goToEntry(this.notification);
    }
}
