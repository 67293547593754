import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import isNil from 'lodash-es/isNil';
import omitBy from 'lodash-es/omitBy';
import { tap } from 'rxjs/operators';
import { AppsyncUserService } from '../../../../common/services/src/appsync/appsync-user.service';
import { SubscriptionManagementService } from '../../../../common/services/src/subscriptions/subscription-management.service';
import { ChatUser } from '../../../../essentials/types/src/chatUser';
import { LogoutType } from '../../../../essentials/types/src/logoutType';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { startAppsyncSubscriptions } from '../../../../store/src/common-store/other/actions/subscription.actions';
import {
  logoutUser,
  updateMePharmacy,
  updateUser,
} from '../../../../store/src/common-store/user-store/actions/user.actions';

@Injectable()
export class PharmacySubscriptionEffects {
  watchUpdatedPharmacy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startAppsyncSubscriptions),
        tap(({ user }) => {
          this.subscribeToPharmacyChatUserChanges(user);
          this.subscribeToPharmacyChanges(user);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private appsyncUserService: AppsyncUserService,
    private subscriptionManagementService: SubscriptionManagementService,
    private store: Store<CommonState>
  ) {}

  private subscribeToPharmacyChatUserChanges(user: ChatUser) {
    this.subscriptionManagementService.subscribe(
      'updatedPharmacyChatUser',
      this.appsyncUserService.updatedPharmacyChatUser(user.cognitoId),
      (pharmacyChatUser) => {
        if (pharmacyChatUser.userStatus === 'DISABLED') {
          this.store.dispatch(logoutUser({ logoutType: LogoutType.BackendDisabledPharmacy }));
        } else {
          this.store.dispatch(updateUser({ partialUser: omitBy(pharmacyChatUser, isNil) }));
        }
      }
    );
    if (!user.pharmacyId) {
      return;
    }
  }

  private subscribeToPharmacyChanges(user: ChatUser) {
    if (!user.pharmacy?.sanacorpCustomerId) {
      return;
    }
    this.subscriptionManagementService.subscribe(
      'updatedOSPharmacy',
      this.appsyncUserService.updatedOSPharmacy(user.pharmacy.sanacorpCustomerId),
      (updatedOSPharmacy) => this.store.dispatch(updateMePharmacy({ pharmacy: updatedOSPharmacy }))
    );
  }
}
