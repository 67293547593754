@if (!news) {
    <ion-spinner name="crescent" color="primary"></ion-spinner>
} @else {
    @if (news?.image?.url) {
        <ion-img class="news-post-image" [src]="news.image.url"></ion-img>
    }
    <div class="news-preview">
        <p [innerHtml]="news.contentHtml | convertLineBreak | formatModalMessage"></p>
    </div>
    <a (click)="goToNewsPost()" class="continue-reading-link">Jetzt weiterlesen</a>
}

