import { inject, Pipe, PipeTransform } from '@angular/core';
import { DownloadsSectionInterface } from '../interfaces/mea.interface';
import { DownloadsService } from '../services/downloads.service';

@Pipe({
    name: 'downloadSection',
})
export class DownloadSectionPipe implements PipeTransform {
    private _downloadsService = inject(DownloadsService);

    transform(index: number): DownloadsSectionInterface | null {
        return this._downloadsService.getDownloadSectionByIndex(index);
    }
}
