import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DEFAULT_TIME_ZONE } from '../../../common/resources/src/global-variables';

dayjs.extend(utc);
dayjs.extend(timezone); // dependent on utc plugin

export interface MeaDateOptions {
  enforceDate?: boolean;
}

export class MeaDateUtil {
  static transformTimestamp(value: number | null, format: 'enforceDate' | 'long' | 'default' = 'default'): string {
    if (!value) {
      return '';
    }
    const timestamp = dayjs(value * 1000);

    switch (format) {
      case 'enforceDate':
        return this.getDateStringForDate(timestamp);
      case 'long':
        return this.getLongFormat(timestamp);
      case 'default':
        return this.getDefaultFormat(timestamp);
    }
  }

  private static getLongFormat(timestamp: Dayjs) {
    const dayDescription = this.isToday(timestamp)
      ? this.getDescriptionForToday()
      : this.isYesterday(timestamp)
        ? this.getDescriptionForYesterday()
        : this.getDateStringForDate(timestamp);
    return `${dayDescription}, ${this.getTimeOfDay(timestamp)} Uhr`;
  }

  private static getDefaultFormat(timestamp: Dayjs) {
    return this.isToday(timestamp)
      ? this.getTimeOfDay(timestamp)
      : this.isYesterday(timestamp)
        ? this.getDescriptionForYesterday()
        : this.getDateStringForDate(timestamp);
  }

  private static isToday(timestamp: Dayjs): boolean {
    const now = dayjs().tz(DEFAULT_TIME_ZONE);
    return timestamp.isSame(now, 'day');
  }

  private static isYesterday(timestamp: Dayjs): boolean {
    const now = dayjs().tz(DEFAULT_TIME_ZONE);
    return timestamp.isSame(now.subtract(1, 'day'), 'day');
  }

  private static getDateStringForDate(timestamp: Dayjs) {
    return timestamp.tz(DEFAULT_TIME_ZONE).format('DD.MM.YYYY');
  }

  private static getTimeOfDay(timestamp: Dayjs) {
    return timestamp.tz(DEFAULT_TIME_ZONE).format('HH:mm');
  }

  private static getDescriptionForToday() {
    return 'heute';
  }

  private static getDescriptionForYesterday() {
    return 'gestern';
  }
}
