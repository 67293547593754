import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { today } from '../../../formatting/date.formatting';
import { AcceptedTermsInterface, StaticPageInterface } from '../../../interfaces/static-page.interface';
import { AuthStorageKeyEnum } from '../../../enums/authStorageKey.enum';
import { StaticPageTypeEnum } from '../../../enums/static-page-type.enum';


export const GetStaticPageByType = gql`
    query getStaticPageByType($where: StaticPageFiltersInput) {
        staticPages(
            sort: "createdAt:desc",
            pagination: {limit: 1},
            filters: $where) {
                id : documentId
                title
                content
                publicationDate
                createdAt
                updatedAt
                pageType
                versionNumber
                file {
                    url
                }
                synopsis {
                    url
            }
        }
    }
`;

export const GetAcceptedTermsVersion = gql`
    query getAcceptedTermsVersion($where: pharmacyStore_bool_exp) {
        pharmacyStore(where: $where, limit: 1) {
            acceptedTermsAndConditionVersion
            acceptedTermsAndConditionTime
        }
    }
`;

export const AllStaticPageQueries = [
    GetStaticPageByType,
    GetAcceptedTermsVersion
];

@Injectable()
export class StaticPagesQueries {
    getAcceptedTermsVersionQueryRef: QueryRef<any>;
    constructor(private apollo: Apollo) {
    }

    /**
     * Return a single static page by its type. The page has to be published.
     *
     * @param pageType The type of the page
     * @param fetchPolicy fetchPolicy type
     */
    public getStaticPageByType(
        pageType: StaticPageTypeEnum,
        fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.NETWORK_ONLY): Observable<StaticPageInterface> {
        return this.apollo.watchQuery({
                query: GetStaticPageByType,
                variables: {
                    requiresNoToken: true,
                    where: {
                        pageType: {eq: pageType},
                        publicationDate: {lte: today()}
                    }
                },
                fetchPolicy
            })
            .valueChanges
            .pipe(
                map(d => d?.data && d?.data['staticPages']),
                map((staticPages) => {
                    const page = staticPages && staticPages[0];
                    return page ? {
                        ...page,
                        file: page?.file?.url,
                        synopsis: page?.synopsis?.durl
                    } : null;
                }),
            );
    }

    public getAcceptedTermsVersion(): Observable<AcceptedTermsInterface> {
        const apiUser = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        this.getAcceptedTermsVersionQueryRef = this.apollo.watchQuery({
            query: GetAcceptedTermsVersion,
            variables: {
                where: {apiUser: {_eq: apiUser}}
            },
            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY
        });
        return this.getAcceptedTermsVersionQueryRef
        .valueChanges
        .pipe(
            map(d => {
                if (d?.data && d.data['pharmacyStore'] && d.data['pharmacyStore'] &&
                    d.data['pharmacyStore'].length > 0 && d.data['pharmacyStore'][0]) {
                    return {
                        versionNumber: d.data['pharmacyStore'][0]['acceptedTermsAndConditionVersion'] ?
                            d.data['pharmacyStore'][0]['acceptedTermsAndConditionVersion'] :
                            null,
                        acceptedAt: d.data['pharmacyStore'][0]['acceptedTermsAndConditionTime'] ?
                            d.data['pharmacyStore'][0]['acceptedTermsAndConditionTime'] :
                            null
                    };
                }
            })
        ) as Observable<AcceptedTermsInterface>;
    }
}
