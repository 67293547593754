import { Component, Input } from '@angular/core';
import { NotificationInterface } from '../../../../../core/interfaces/notification.interface';
import { NotificationsEnum } from '../../../../../core/enums/notifications.enum';

@Component({
    selector: 'app-notification-center-content',
    templateUrl: './notification-center-content.component.html',
    styleUrls: ['./notification-center-content.component.scss'],
})
export class NotificationCenterContentComponent {
    @Input() notificationPayloadId: number | string;
    @Input() notificationType: NotificationsEnum;
    @Input() notification: NotificationInterface;

    protected readonly NotificationsEnum = NotificationsEnum;
}
