import { computed, effect, inject, Injectable, Signal, signal, untracked } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, filter, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { Storage } from '@ionic/storage-angular';
import isEqual from 'lodash/isEqual';

import { defaultDownloadsFilters } from '../config/mea.config';
import { DownloadsStorageKeys, DownloadTypes } from '../enums/mea.enum';
import { DownloadQueries } from '../store/graphql/queries/downloads.graphql';
import { TableFieldsEnum, TableFilterTypeEnum } from '../enums/table.enum';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { ButtonTypes } from '../enums/button-actions.enum';
import { BadgeTypeEnum } from '../enums/badge.enum';
import {
    TableColumnInterface,
    TableFilterConfigInterface,
    TableSearchModalConfigInterface,
    TableSidebarItemsInterface,
} from '../interfaces/table.interface';
import {
    DownloadsDataInterface,
    DownloadsFiltersInterface,
    DownloadsSectionConfigInterface,
    DownloadsSectionInterface,
    DownloadTypesInterface,
} from '../interfaces/mea.interface';
import { DateSelectionEventInterface, DateSelectionFilterInterface } from '../interfaces/date-range.interface';
import { DateRangeConfig } from '../config/date-range.config';
import { formatDateTimeToDate } from '../formatting/date.formatting';
import { ModalService } from './modal.service';
import { PdfProviderService } from './pdfProvider.service';
import { FlattenedRows, TableService } from './table.service';
import { UtilService } from './util.service';
import { StaticAppLocations } from '../enums/app-location.enum';
import { DownloadsInfoModalComponent } from '../../pages/logistics/pages/downloads/components/downloads-info-modal/downloads-info-modal.component';
import { unsubscribe } from '../util/subscriptions.util';
import { filterMeaDownloadResults } from '../store/graphql/utils';

@Injectable({
    providedIn: 'root'
})
export class DownloadsService {
    private router = inject(Router);
    private storage = inject(Storage);
    private _downloadQueries = inject(DownloadQueries);
    private modalService = inject(ModalService);
    private pdfService = inject(PdfProviderService);
    private tableService = inject(TableService);
    private utilService = inject(UtilService);

    routerSubscription: Subscription;

    constructor() {
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        const appLocationString = this._appLocationString();
        const storageString = `${DownloadsStorageKeys.FILTERS}_${appLocationString}_${activePharmacy}`;

        this.storage
            .get(storageString)
            .then((filters: DownloadsFiltersInterface) => {
                if (filters) {
                    this._filters.set(filters);
                }
            });

        /**
         * Save the filters to the storage as soon as they change
         */
        effect(() => {
            if (this.filters()) {
                // required, so the effect gets triggered
                this.storage.get(storageString).then((filters) => {
                    const newFilters = untracked(() => this._filters());
                    if (!isEqual(filters, newFilters)) {
                        void this.storage.set(storageString, newFilters);
                    }
                });
            }
        });

        // Update app location depending on the route
        unsubscribe(this.routerSubscription);
        this.routerSubscription = this.router.events
            .pipe(filter( event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                switch(event.url){
                    case '/app/logistics/downloads':
                        this._appLocation.set(StaticAppLocations.Default);
                        break;
                    case '/app/mea/downloads':
                        this._appLocation.set(StaticAppLocations.Mea);
                        break;
                }
            });

    }

    public readonly columns: TableColumnInterface[] = [
        { id: 1, title: 'Name', width: '10rem', class: 'col-auto reduced-flex-basis', sortable: false, dataKey: TableFieldsEnum.label },
        { id: 2, title: 'Datum', width: '12rem', sortable: false, dataKey: TableFieldsEnum.createdAt },
        { id: 3, title: 'Format', width: '12rem', sortable: false, dataKey: TableFieldsEnum.file },
        { id: 4, title: 'Typ', width: '15rem', sortable: false, dataKey: TableFieldsEnum.downloadTypes, class: 'columnType' },
        { id: 5, title: 'Aktionen', width: '10rem', sortable: false, class: 'columnActions' },
    ];

    private readonly defaultDownloadSectionsConfig: DownloadsSectionConfigInterface = {
        personal: {
            subHeadline: {
                index: -1,
                label: 'Persönliche Downloads',
            },
            customBadgeConfig: {
                code: 'Persönliche Downloads',
                textColor: 'var(--mea-dark)',
                borderColor: 'var(--mea-dark)',
                backgroundColor: 'var(--ion-color-white)',
                borderWidth: '1px',
            },
        },
        public: {
            subHeadline: {
                index: 0,
                label: 'Sanacorp Downloads',
            },
        },
    };

    public readonly downloadSectionsConfig = computed<DownloadsSectionConfigInterface>(() => {
        const downloads = this.downloads();
        const config = structuredClone(this.defaultDownloadSectionsConfig);

        if (downloads?.length > 0 && downloads[0].data.id === 'personal') {
            config.personal.subHeadline.index = 0;
            config.public.subHeadline.index = 1;
        } else {
            config.personal.subHeadline.index = -1;
            config.public.subHeadline.index = 0;
        }

        switch (this.appLocation()) {
            case StaticAppLocations.Mea:
                config.public.subHeadline.label = 'mea Downloads';
                break;
            case StaticAppLocations.Default:
                config.public.subHeadline.label = 'Sanacorp Downloads';
                break;
            default:
                config.public.subHeadline.label = 'Downloads';
        }

        return config;
    });

    private filterConfig = defaultDownloadsFilters;
    private _selectedId = signal<number>(null);
    public selectedId = this._selectedId.asReadonly();

    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    private _expandedRowKeys = signal<{ [key: number]: boolean }>({ '-1': false });
    public expandedRowKeys = this._expandedRowKeys.asReadonly();

    private _appLocation = signal<StaticAppLocations>(null);
    public appLocation = this._appLocation.asReadonly();
    private _appLocationString = computed(() => {
        return this.appLocation() === StaticAppLocations.Mea ? 'mea' : 'default';
    });

    private _filters = signal<DownloadsFiltersInterface>(this.filterConfig);
    public filters = this._filters.asReadonly();
    public dateFilter = computed<DateSelectionFilterInterface>(() => {
        const filters = this.filters();
        return {
            label: 'Datum',
            selectedValue: filters?.dateOption,
            selectedValues: {
                dateOption: filters?.dateOption,
                dateRangeOptions: DateRangeConfig,
                dateFrom: filters?.dateFrom,
                dateTo: filters?.dateTo,
            },
        };
    });

    private _downloads = signal<FlattenedRows<DownloadTypesInterface, DownloadsDataInterface>>(
        [] as FlattenedRows<DownloadTypesInterface, DownloadsDataInterface>
    );
    public downloads = this._downloads.asReadonly();

    private downloads$ = combineLatest([toObservable(this.filters), toObservable(this.appLocation)]).pipe(
        switchMap(([filters, appLocation]) => {
            if (!appLocation) {
                return of([] as DownloadTypesInterface[]);
            }
            return this._downloadQueries.getDownloadsByAppLocation(appLocation);
        }),
        map((downloads) => {

            // Generate types filter items
            const items = downloads.map((type) => ({ id: type.type, title: type.type, type: type.type, color: type.color }));
            items.unshift({ id: '', type: DownloadTypes.ALL, title: DownloadTypes.ALL, color: '' });
            this._types.set(items.filter(i => i.type !== 'personal'));

            const downloadType = this.filters()?.type || DownloadTypes.ALL;
            if (downloadType && downloadType !== DownloadTypes.ALL) {
                return downloads.filter((dl) => dl.type === downloadType);
            }

            return downloads;
        }),
        map((d) => filterMeaDownloadResults(d, untracked(() => this.filters())) as DownloadTypesInterface[]),
        map((groupedDownloads) => {
            // remove empty groups from data
            return groupedDownloads.filter((group) => group?.downloads && group?.downloads?.length);
        }),
        map((groupedDownloads) => {
            // sort groups by name
            return this.utilService.prioritySortArray(groupedDownloads, 'type', 'personal');
        }),
        map((data) => {
            let mapped: FlattenedRows<DownloadTypesInterface, DownloadsDataInterface> = null;
            try {
                mapped = this.tableService.mapTableRows<DownloadTypesInterface, DownloadsDataInterface>(data);
            } catch (Error) {
                console.error(Error);
            }
            return mapped;
        }),
        tap((data) => {
            this._downloads.set(data || new FlattenedRows<DownloadTypesInterface, DownloadsDataInterface>());
            if (data?.length) {
                // we need to set null first, to change the download types if the filter changes
                // (if the first download has multiple download types)
                this._selectedId.set(null);
                this._selectedId.set(data.getFirstChildOfFirstRow()?.id);
                // expand the first group by default
                this._expandedRowKeys.set({ [data.getFirstRow()?.id]: true });
            } else {
                this._selectedId.set(null);
            }
            this._isLoading.set(false);
        })
    );
    // toSignal automatically subscribes and unsubscribes to the observable
    downloadsReadOnly = toSignal(this.downloads$, {
        initialValue: new FlattenedRows<DownloadTypesInterface, DownloadsDataInterface>(),
    });

    private _types = signal<{id: string, title: string, type: string, color: string;}[]>([]);
    public types = this._types.asReadonly();

    public sidebar = computed(() => {
        const download = this.selectedDownload();
        if (download) {
            return {
                title: download.label,
                actionPopoverItems: [
                    {
                        label: 'Drucken',
                        code: ButtonTypes.PRINT,
                        onAction: () => this.onPrint(),
                        isDisabled: download.file.split('.').pop() !== 'pdf',
                    },
                    { label: 'Download', code: ButtonTypes.DOWNLOAD, onAction: () => this.onDownload() },
                ],
                sidebarItems: this.buildSidebarItems(download),
                previousRow: () => this.updateActiveId(-1, false),
                nextRow: () => this.updateActiveId(1, false),
                hasEditButton: false,
            };
        }
        return null;
    });

    public selectedDownload: Signal<DownloadsDataInterface> = computed(() => {
        const selectedId = this._selectedId();

        return untracked(() => {
            const downloads = this._downloads();
            if (!downloads?.length) {
                return null;
            }
            if (!selectedId) {
                this._selectedId.set(this._downloads().getFirstChildOfFirstRow()?.id);
                return;
            }
            const selectedDownload = downloads.findChildById(selectedId)?.data;
            if (selectedDownload) {
                return selectedDownload;
            }
            this._selectedId.set(this._downloads().getFirstChildOfFirstRow()?.id);
        });
    });

    /**
     * Configure the filters for the returns table
     */
    public filterLeftConfig: TableFilterConfigInterface[] = [
        {
            label: 'Datum',
            type: TableFilterTypeEnum.date,
            dataKey: 'date',
            selectableValues: DateRangeConfig,
            onAction: (newFilter: DateSelectionEventInterface) => {
                this.updateDateFilter(newFilter);
            },
        },
        {
            label: 'Typ',
            type: TableFilterTypeEnum.select,
            dataKey: 'type',
            selectableSignal: this.types,
            onAction: (newFilter: string, filterKey: string) => {
                this.updateSelectFilter(newFilter, filterKey);
            },
        },
        {
            label: 'Suche',
            type: TableFilterTypeEnum.search,
            dataKey: 'search',
        },
    ];

    /* ################ filters on the right side (above the sidebar) ################ */
    public filterRightConfig: TableFilterConfigInterface[] = [
        {
            icon: 'information-outline',
            type: TableFilterTypeEnum.info,
            ttk: 'mea_downloads_hint',
            onAction: async () => {
                const modal = await this.modalService.create(DownloadsInfoModalComponent, {});

                await modal.present();
            },
        },
    ];

    /* ################ search modal filters configuration ################ */
    public SearchModalFilterConfig: TableSearchModalConfigInterface = {
        title: 'Retouren filtern',
        onAction: (newFilters: DownloadsFiltersInterface) => this.onSearchModalAction(newFilters),
        onReset: () => this.onSearchModalCancel(),
        items: [
            {
                label: 'Suche',
                type: TableFilterTypeEnum.search,
                dataKey: 'search',
            },
            {
                label: 'Datum',
                type: TableFilterTypeEnum.date,
                dataKey: 'date',
                selectableValues: DateRangeConfig,
                dateSignal: this.dateFilter,
                onAction: (newFilter: DateSelectionEventInterface) => {
                    this.updateDateFilter(newFilter);
                },
            },
            {
                label: 'Typ',
                type: TableFilterTypeEnum.select,
                dataKey: 'type',
                selectableSignal: this.types,
            },
        ],
    };

    /**
     * Expands or collapses a specific row in a table or list by its row ID.
     * Ensures that only one row is expanded at a time.
     *
     * @param rowId - The unique identifier of the row to be expanded or collapsed.
     * @param expanded - A boolean indicating whether the row should be expanded (true) or collapsed (false).
     */
    public expandRow(rowId: number, expanded: boolean) {
        const group = this.downloads().getRowById(rowId)?.data;
        if (group?.downloads?.length) {
            // only allow one group to be expanded at a time
            this._expandedRowKeys.set({ [rowId]: expanded });
        }
    }

    private buildSidebarItems(download: DownloadsDataInterface): TableSidebarItemsInterface[] {
        const filteredDownloadTypes = this.removeDownloadTypes(download.downloadTypes, 'personal');
        return [
            {
                key: 'updatedAt',
                value: formatDateTimeToDate(download.updatedAt, true),
                colWidth: '100%',
                label: 'Aktualisierungsdatum',
            },
            {
                key: 'downloadTypes',
                value: filteredDownloadTypes,
                badgeType: BadgeTypeEnum.MEA_DOWNLOADS_MULTIPLE,
                label: 'Typ',
                colWidth: '100%',
            },
            {
                key: 'format',
                value: download.file.split('.').pop().toUpperCase(),
                label: 'Dokumentformat',
                colWidth: '100%',
            },
            {
                key: 'description',
                value: download.description,
                label: 'Kurzbeschreibung',
                colWidth: '100%',
            },
            {
                key: 'file',
                value: download.file,
                isPreview: true,
                previewColor: download.downloadTypes?.[0]?.color,
                previewAction: () => this.onDownload(),
                label: 'Vorschau',
                colWidth: '100%',
            },
        ];
    }

    /**
     * Open new browser tab and print table as PDF.
     */
    public async onPrint() {
        const download = this.downloads()
            .flatMap((item) => item.children)
            .find((d) => d.id === this.selectedId()).data as DownloadsDataInterface;
        this.pdfService.openPdfInNewTab(download.file);
    }

    /**
     * Convert table values to excel or csv and download the file.
     */
    public async onDownload() {
        const download = this.downloads()
            .flatMap((item) => item.children)
            .find((d) => d.id === this.selectedId()).data as DownloadsDataInterface;
        await this.pdfService.downloadPdfByUrl(download.file, download.label);
    }

    /**
     * Updates the active ID by navigating through a list of downloads and setting the selection.
     * Expands the group to which the selected download belongs.
     *
     * @param upDown - The direction for navigation. A positive number moves down, and a negative number moves up.
     * @param wrapAround - whether the list uses a wrap-around navigation
     */
    public updateActiveId(upDown: number, wrapAround = true) {
        const newSelection = untracked(() => this.downloads().navigate(upDown, true, wrapAround)?.id);
        const currentGroup = untracked(() => this.downloads().getCurrentRow()?.id);
        // expand the group the selected download belongs to
        this._expandedRowKeys.set({ [currentGroup]: true });
        this._selectedId.set(newSelection);
    }

    private onSearchModalAction(newFilters: DownloadsFiltersInterface) {
        this._filters.update((prev) => {
            return {
                ...prev,
                ...newFilters,
            };
        });
    }

    private onSearchModalCancel() {
        this._filters.set(defaultDownloadsFilters);
    }

    /**
     * Set the id for a specific selected download item. For this item the sidebar is displayed.
     * @param id of the download item
     */
    public setSelectedId(id: number) {
        const isIdenticalRow = untracked(() => this._selectedId() === id);
        if (isIdenticalRow) {
            this._selectedId.set(null);
        }
        this._selectedId.set(id);
        untracked(() => {
            const expandedRowId = Number(Object.keys(this._expandedRowKeys())[0]);
            const expandedRowIndex = this.downloads().findIndex((row) => row.id === expandedRowId);
            const childIndex = this.downloads()[expandedRowIndex]?.children.findIndex((child) => child.id === id);
            this._downloads().updateCurrentRowAndChild(expandedRowIndex, childIndex);
        });
    }

    public setFilters(newFilters: DownloadsFiltersInterface) {
        this._filters.update((prev) => {
            return {
                ...prev,
                ...newFilters,
            };
        });
    }

    /**
     * Update the date filter
     * @param newFilter the new date filter
     * @param keyPrefix prefix of the attribute keys, e.g. 'date' or 'deliveryNoteDate'
     */
    public updateDateFilter(newFilter: DateSelectionEventInterface, keyPrefix = 'date') {
        this._filters.update((prev) => {
            return {
                ...prev,
                [keyPrefix + 'Option']: newFilter?.dateOption,
                [keyPrefix + 'From']: newFilter?.dateFrom,
                [keyPrefix + 'To']: newFilter?.dateTo,
            };
        });
    }

    /**
     * Save filters that are selected using a popover.
     * The filters are saved to the storage by using an effect to automatically save the filters as soon as they change.
     * @param newFilter string value of the new filter
     * @param filterKey key of the filter that is being updated
     */
    public updateSelectFilter(newFilter: string, filterKey: string) {
        this._filters.update((prev) => {
            return {
                ...prev,
                [filterKey]: newFilter,
            };
        });
    }

    public getDownloadSectionByIndex(index: number): DownloadsSectionInterface | null {
        const key = Object.keys(this.downloadSectionsConfig()).find((k) => this.downloadSectionsConfig()[k].subHeadline.index === index);
        return this.downloadSectionsConfig()[key] || null;
    }

    public removeDownloadTypes(downloadTypes: DownloadTypesInterface[], ...types: string[]) {
        return downloadTypes.filter((v) => !types.includes(v.type));
    }
}
