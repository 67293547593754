import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../essentials/types/src/actionType';
import { PharmacyLoginDetailsWithCustomerId } from '../../../../essentials/types/src/service-interfaces/single-sign-on.service.interface';

export const setSsoPharmacies = createAction(
  `${ActionType.SsoPharmacies}: Set ssoPharmacies`,
  props<{ ssoPharmacies: PharmacyLoginDetailsWithCustomerId[] | undefined }>()
);

export const setUnreadMessagesForInactiveSsoPharmacy = createAction(
  `${ActionType.SsoPharmacies}: Set unread messages for inactive ssoPharmacy`,
  props<{ cognitoId: string; unreadMessagesIds: Set<string> }>()
);

export const updateUnreadMessagesForInactiveSsoPharmacy = createAction(
  `${ActionType.SsoPharmacies}: Update unread messages for inactive ssoPharmacy`,
  props<{ cognitoId: string; messageId: string; isNew: boolean }>()
);
