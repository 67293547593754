import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { QueryWrapper } from '../query.wrapper';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { ProducerInterface } from '../../../interfaces/producers.interface';

export const GetProducer = (queryName) => gql`
    query ${queryName}($limit: Int) {
        producer(limit: $limit) {
            id
            producerId
            producerName
        }
    }
`;

export const AllProducerQueries = [
    GetProducer('test')
];

@Injectable()
export class ProducerQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getProducer]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.producerChanged]: [
                FPK.getProducer
            ]
        });
    }

    public getProducer(): Observable<ProducerInterface[]> {
        const fetchPolicyKey = FPK.getProducer;
        return this.apollo.watchQuery({
            query: GetProducer(fetchPolicyKey),
            variables: {
                limit: GraphQLLimits.defaultLimit,
            },
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(
                map(data => data?.data && data.data['producer'] && data.data['producer'] || []),
                map(d => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<ProducerInterface[]>;
    }
}
