import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import {
    NotificationEnabledTypesInterface,
    NotificationInterface,
} from '../../../core.interfaces';
import {
    convertFromLocalTimeToUTC,
    convertFromUTCToLocalTime, endOf,
    formatDateTimeToDate
} from '../../../formatting/date.formatting';
import {
    AuthStorageKeyEnum,
    FetchPolicyKeys as FPK,
    DataChangedKeys as DCK,
    HelpSectionTypes,
    NotificationsEnum,
    QueryFetchPolicy,
    NotificationFilterEnum,
    SortEnum,
    SortDirectionEnum
} from '../../../core.enums';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { DataChangedForceStateVar } from '../../locals/dataChangeForceState.var';
import { getDateRangesWithoutDates } from '../../../config/date-range.config';
import { formatNotificationData } from '../utils';

const GetNotificationFiltered = (queryName) => gql`
    query ${queryName}($limit: Int) {
        notificationsFormatted(order_by:[{created_at:desc}, {id: desc}], limit: $limit, args: {is_archive: false}) {
            id
            created_at
            message
            isRead
            isArchive
            type
            payload
        }
    }`;

export const GetNotificationsById = (queryName) => gql`
    query ${queryName}($id: Int!, $isArchive: Boolean!) {
        notificationsFormatted(where:  {id: {_eq: $id}}, limit: 1, args: {is_archive: $isArchive}) {
        id
        created_at
        updated_at
        message
        isRead
        isArchive
        type
        payload
      }
    }
`;

export const GetNotifications = (queryName) => gql`
    query ${queryName}($where: notificationFormattedTemplate_bool_exp, $orderBy: [notificationFormattedTemplate_order_by!], $args: notificationsFormatted_args!, $offset: Int, $limit: Int) {
        notificationsFormatted(where: $where, limit: $limit, offset: $offset, order_by: $orderBy, args: $args) {
            id
            message
            type
            isRead
            isArchive
            created_at
            payload
        }
    }
`;

export const GetNotificationsCount = (queryName) => gql`
    query ${queryName}($where: notificationFormattedTemplate_bool_exp, $orderBy: [notificationFormattedTemplate_order_by!], $args: notificationsFormatted_args!, $offset: Int, $limit: Int) {
        notificationsFormatted_aggregate(where: $where, limit: $limit, offset: $offset, order_by: $orderBy, args: $args) {
            aggregate {
                count
            }
        }
    }
`;

export const GetMeaShopNotifications = (queryName) => gql`
    query ${queryName}($where: notificationFormattedTemplate_bool_exp, $limit: Int) {
        meaShopNotificationsFormatted(where: $where, limit: $limit) {
            id
            message
            type
            isRead
            isArchive
            created_at
            payload
        }
    }
`;

export const GetNotificationEnabledTypes = gql`
    query getNotificationEnabledTypes($limit: Int) {
        notificationEnabledTypes(limit: $limit) {
            type
        }
    }
`;


export const AllNotificationQueries = [
    GetNotifications('test'),
    GetNotificationsCount('test'),
    GetNotificationsById('test'),
    GetNotificationEnabledTypes
];

@Injectable()
export class NotificationQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getNotifications]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getMeaShopNotifications]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getNotificationsCount]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getNotificationById]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getNotificationFiltered2]: QueryFetchPolicy.NETWORK_ONLY
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar,
        private dataChangedForceState: DataChangedForceStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.notificationChanged]: [
                FPK.getNotifications,
                FPK.getMeaShopNotifications,
                FPK.getNotificationsCount,
                FPK.getNotificationById,
                FPK.getNotificationFiltered2
            ],
            [DCK.readNotificationChanged]: [
                FPK.getNotifications,
                FPK.getNotificationsCount,
                FPK.getNotificationById,
                FPK.getNotificationFiltered2
            ]
        });
    }

    static getHelpTypes(type: string = null) : Array<string> {
        const types: Array<string> = [HelpSectionTypes.account];
        if (type) {
            types.push(type);
            let slashPosition = type.indexOf('/');
            while (slashPosition !== -1) {
                types.push(type.substring(0, slashPosition));
                slashPosition = type.indexOf('/', slashPosition + 1);
            }
        }
        return types;
    }

    private replacePlaceholder = (notificationArray: Array<NotificationInterface>): Array<NotificationInterface> => {
        if (notificationArray) {
            notificationArray = notificationArray.map(notification => {
                let message = notification.message;
                if (notification.payload?.orderId && message.includes('Neue %s ist verfügbar.')) {
                    message = message.replace(' %s', 'r Auftrag ' + notification.payload?.orderId.toString());
                } else if (notification.payload?.orderId && message.includes('Änderung in %s ist verfügbar.')) {
                    message = message.replace('in %s', 'im Auftrag ' + notification.payload?.orderId.toString());
                } else if (notification.payload?.orderId && message.includes('%s')) {
                    message = message.replace('%s', 'Auftrag ' + notification.payload?.orderId.toString());
                }

                if (notification.type === NotificationsEnum.NEW_MAINTENANCE) {
                    if (message.includes('TT.MM.JJJJ')) {
                        message = message.replace(
                            /TT.MM.JJJJ/g,
                            formatDateTimeToDate(notification.created_at, true)
                        );
                    }
                    if (message.includes('HH:MM')) {
                        const momentDate = convertFromUTCToLocalTime(notification.created_at, true);
                        if (momentDate && typeof momentDate !== 'string') {
                            message = message.replace(
                                /HH:MM/g,
                                momentDate.format('HH:mm')
                            );
                        }
                    }
                }
                return {...notification, message};
            });
        }
        return notificationArray;
    };


    /**
     * Returns all notifications
     */
    public getNotifications(filters, offset = 0, limit = 100, ): Observable<NotificationInterface[]> {
        const fetchPolicyKey = FPK.getNotifications;
        const {where, args} =  this.createFiltersWhereAndArgs(filters);
        const orderBy =  {created_at: filters.sort === SortEnum.LATEST ? SortDirectionEnum.desc : SortDirectionEnum.asc};


        return this.apollo.watchQuery({
            query: GetNotifications(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables: {
                offset,
                where,
                args,
                orderBy: [orderBy, {id: SortDirectionEnum.desc}],
                limit
            },
        })
        .valueChanges
        .pipe(map(d => d?.data && d?.data['notificationsFormatted']))
        .pipe(map((notificationData) => formatNotificationData(notificationData)))
        .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<NotificationInterface[]>;
    }

    /**
     * Return all notifications by types
     */
    public getNotificationsByTypes(): Observable<NotificationInterface[]> {
        const fetchPolicyKey = FPK.getNotificationFiltered2;
        return this.apollo.watchQuery({
            query: GetNotificationFiltered(fetchPolicyKey),
            fetchPolicy:this.getFetchPolicy(fetchPolicyKey),
            variables: {
                apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy),
                limit: GraphQLLimits.notification
            }
        })
            .valueChanges
            .pipe(map(d =>
                d?.data && d?.data['notificationsFormatted']
            ))
            .pipe(map((notificationArray: Array<NotificationInterface>) => {
                return this.replacePlaceholder(notificationArray);
            }))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;}));
    }

    /**
     * Return the number of available notifications
     */
    public getNotificationsCount(filters): Observable<number> {
        const fetchPolicyKey = FPK.getNotificationsCount;
        const {where, args} =  this.createFiltersWhereAndArgs(filters);
        return this.apollo.watchQuery({
            query: GetNotificationsCount(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables: {
                where,
                args
            },
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['notificationsFormatted_aggregate']
                && d?.data['notificationsFormatted_aggregate']['aggregate']
                && d?.data['notificationsFormatted_aggregate']['aggregate']['count']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<number>;

    }

    /**
     * Return a single notification
     *
     * @param id - notification id
     * @param isArchive - is archive
     */
    public getNotificationById(id, isArchive = false): Observable<NotificationInterface> {
        const fetchPolicyKey = FPK.getNotificationById;
        return this.apollo.watchQuery({
                query: GetNotificationsById(fetchPolicyKey),
                fetchPolicy:this.getFetchPolicy(fetchPolicyKey),
                variables: {id, isArchive}
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['notificationsFormatted'] && d?.data['notificationsFormatted'][0]))
            .pipe(map((notificationData) => formatNotificationData(notificationData)))
            .pipe(map((notification: NotificationInterface) => {
                if (notification) {
                    return this.replacePlaceholder([notification])[0];
                }
                return notification;
            }))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<NotificationInterface>;
    }

    public getMeaShopNotifications(filters): Observable<NotificationInterface[]> {
        const fetchPolicyKey = FPK.getMeaShopNotifications;
        const {where} =  this.createFiltersWhereAndArgs(filters);

        return this.apollo.watchQuery({
            query: GetMeaShopNotifications(fetchPolicyKey),
            variables: {
                where,
                limit: GraphQLLimits.meaShopNotifications
            },
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(
                map(d => d?.data && d?.data['meaShopNotificationsFormatted']),
                map(d => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})
            );
    }


    public notificationFiltersChanged() {
        void this.dataChangedForceState.setForceState({[DCK.notificationChanged]: null});
    }

    public getNotificationEnabledTypes(): Observable<NotificationEnabledTypesInterface[]> {
        return this.apollo.watchQuery({
            query: GetNotificationEnabledTypes,
            variables: {limit: GraphQLLimits.notificationEnabledTypes},
            fetchPolicy: QueryFetchPolicy.NETWORK_ONLY
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['notificationEnabledTypes'])) as Observable<NotificationEnabledTypesInterface[]>;
    }

    /**
     * Returns where coditions by filters
     */
    private createFiltersWhereAndArgs(filters) {
        const where = {_and: []};

        // Filter by READ
        if([NotificationFilterEnum.READ, NotificationFilterEnum.UNREAD].includes(filters.filter)) {
            where['_and'].push({isRead:{_eq: filters.filter === NotificationFilterEnum.READ}});
        }

        // Filter by ARCHIVED
        // Notice: If filter isn't set explicitly to archived, we don't want to show archived notifications
        const args = {is_archive: filters.filter === NotificationFilterEnum.ARCHIVED};

        // Filter by TYPES
        if (filters.types?.length > 0) {
            where['_and'].push({type: {_in: filters.types}});
        }

        // Filter by DATE
        if (filters.dateOption) {
            if (!getDateRangesWithoutDates(filters.dateOption)
                && filters.dateFrom && filters.dateTo) {
                where['_and'].push({created_at: {
                        _gte: convertFromLocalTimeToUTC(filters.dateFrom),
                        _lte: convertFromLocalTimeToUTC(endOf('day', true, filters.dateTo))
                    }});
            }
        }

        // Filter by SEARCH
        if (filters.search) {
            where['_and'].push({message: {_ilike: `%${filters.search}%`}});
        }

        return {where, args};
    }
}
