import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';
import keyBy from 'lodash-es/keyBy';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { markDataWithSubscriptionsAsStale } from '../../../../store/src/common-store/other/actions/subscription.actions';
import { clearUserOnLogout } from '../../../../store/src/common-store/user-store/actions/user.actions';
import {
  addCreatedShopUserMessage,
  deleteShopUserMessage,
  loadAllShopUserMessages,
  loadAllShopUserMessagesFailure,
  loadAllShopUserMessagesSuccess,
  loadRecentShopUserMessages,
  loadRecentShopUserMessagesFailure,
  loadRecentShopUserMessagesSuccess,
  revertProgressUpdateForShopUserMessage,
  updateProgressHistoryForShopUserMessage,
  updateUpdatedShopUserMessage,
} from './shop-user-messages.actions';
import { ShopUserMessagesState } from './shop-user-messages.state';

export const initialShopUserMessagesState: ShopUserMessagesState = {
  shopUserMessagesDictionary: {},
  shopUserMessagesLoadStatus: LoadStatus.Init,
};

const _shopUserMessagesReducer = createReducer(
  initialShopUserMessagesState,
  on(loadRecentShopUserMessages, (state) =>
    update(state, { shopUserMessagesLoadStatus: { $set: LoadStatus.LoadingInitial } })
  ),
  on(loadRecentShopUserMessagesSuccess, (state, { shopUserMessages }) =>
    update(state, {
      shopUserMessagesDictionary: { $set: keyBy(shopUserMessages, 'id') },
      shopUserMessagesLoadStatus: { $set: LoadStatus.Stale },
    })
  ),
  on(loadRecentShopUserMessagesFailure, (state) =>
    update(state, { shopUserMessagesLoadStatus: { $set: LoadStatus.Error } })
  ),
  on(loadAllShopUserMessages, (state) =>
    update(state, { shopUserMessagesLoadStatus: { $set: LoadStatus.Revalidating } })
  ),
  on(loadAllShopUserMessagesSuccess, (state, { shopUserMessages }) => {
    const existingShopUserMessageIds = new Set(Object.keys(state.shopUserMessagesDictionary));
    const newShopUserMessages = shopUserMessages.filter(({ id }) => !existingShopUserMessageIds.has(id));
    return update(state, {
      shopUserMessagesDictionary: { $merge: keyBy(newShopUserMessages, 'id') },
      shopUserMessagesLoadStatus: { $set: LoadStatus.UpToDate },
    });
  }),
  on(loadAllShopUserMessagesFailure, (state) =>
    update(state, { shopUserMessagesLoadStatus: { $set: LoadStatus.Error } })
  ),
  on(addCreatedShopUserMessage, updateUpdatedShopUserMessage, (state, { shopUserMessage }) =>
    update(state, { shopUserMessagesDictionary: { [shopUserMessage.id]: { $set: shopUserMessage } } })
  ),
  on(deleteShopUserMessage, (state, { id }) => update(state, { shopUserMessagesDictionary: { $unset: [id] } })),
  on(updateProgressHistoryForShopUserMessage, (state, { shopUserMessage, newProgressEvent }) => {
    if (shopUserMessage.progressHistory) {
      return update(state, {
        shopUserMessagesDictionary: { [shopUserMessage.id]: { progressHistory: { $push: [newProgressEvent] } } },
      });
    } else {
      return update(state, {
        shopUserMessagesDictionary: { [shopUserMessage.id]: { progressHistory: { $set: [newProgressEvent] } } },
      });
    }
  }),
  on(revertProgressUpdateForShopUserMessage, (state, { shopUserMessageId, progressEventToRemove }) => {
    const currentProgressHistory = state.shopUserMessagesDictionary[shopUserMessageId]?.progressHistory;
    if (!currentProgressHistory) {
      return state;
    }
    return update(state, {
      shopUserMessagesDictionary: {
        [shopUserMessageId]: {
          progressHistory: { $set: currentProgressHistory.filter((entry) => !isEqual(entry, progressEventToRemove)) },
        },
      },
    });
  }),
  on(markDataWithSubscriptionsAsStale, (state) => {
    if (state.shopUserMessagesLoadStatus === LoadStatus.UpToDate) {
      return update(state, { shopUserMessagesLoadStatus: { $set: LoadStatus.Stale } });
    } else {
      return state;
    }
  }),
  on(clearUserOnLogout, (_) => cloneDeep(initialShopUserMessagesState))
);

export function shopUserMessagesReducer(
  state: ShopUserMessagesState | undefined,
  action: Action
): ShopUserMessagesState {
  return _shopUserMessagesReducer(state, action);
}
