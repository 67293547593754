import gql from 'graphql-tag';

export default gql`
  mutation updateShoppingCart($conversationId: ID!, $encryptedShoppingCart: String!, $hasPrescriptions: Boolean) {
    updateShoppingCart(
      id: $conversationId
      encryptedShoppingCart: $encryptedShoppingCart
      hasPrescriptions: $hasPrescriptions
    ) {
      id
    }
  }
`;
