import { Product, ProductDetails, ProductPackaging } from '../../types/src/product';
import {
  ShopProduct,
  ShopProductDetails,
  ShopProductPackaging,
  ShopProductSafetyRegulation,
} from '../../types/src/shopProduct';
import { Logger } from './logger';

const logger = new Logger('ShopProductUtil');

export class ShopProductUtil {
  public static getShopApiBaseUrl(shopUrl: string | undefined): string | undefined {
    // https://www.meineapotheke.de/shop/... for real shops with api, ...localhost:1234... for local testing
    return shopUrl?.startsWith('https://www.meineapotheke.de/shop/') || shopUrl?.includes('localhost:1234')
      ? this.addMissingSlashToUrl(shopUrl)
      : undefined;
  }

  public static getShopAskQuestionUrl(shopUrl: string | undefined): string | undefined {
    const shopApiBaseUrl = this.getShopApiBaseUrl(shopUrl);
    return shopApiBaseUrl ? `${shopApiBaseUrl}frage-stellen` : undefined;
  }

  public static getFullPzn(pzn: string): string {
    if (pzn.length === 8) {
      return pzn;
    } else if (pzn.length === 7) {
      return `0${pzn}`;
    } else {
      logger.error(`invalid pzn: ${pzn}`);
      return pzn;
    }
  }

  public static mapShopProductToProduct(shopProduct: ShopProduct): Product {
    return {
      pzn: shopProduct.pzn,
      basePrice: shopProduct.base_price,
      discount: this.calculateDiscountInPercent(shopProduct.price, shopProduct.avp),
      imageUrl: shopProduct.image_url || undefined,
      name: shopProduct.name,
      price: shopProduct.price,
      retailPrice: shopProduct.avp,
      retailPriceType: 'AVP',
      thumbnailUrl: shopProduct.thumbnail_url || undefined,
      url: shopProduct.url,
      quantityAndUnit: shopProduct.quantity_and_unit,
      crossSellPackagings: shopProduct.cross_sell_packagings
        ?.map(this.mapShopProductPackagingToProductPackaging)
        .sort((a, b) => {
          if (!a.quantityAndUnit || !b.quantityAndUnit) {
            return 1;
          } else if (a.quantityAndUnit.length === b.quantityAndUnit.length) {
            return a.quantityAndUnit > b.quantityAndUnit ? 1 : -1;
          } else {
            return a.quantityAndUnit.length > b.quantityAndUnit.length ? 1 : -1;
          }
        }),
      tax: shopProduct.tax,
      taxRate: shopProduct.taxRate,
    };
  }

  public static mapShopProductDetailsToProductDetails(shopProductDetails: ShopProductDetails): ProductDetails {
    return {
      pzn: shopProductDetails.pzn,
      basePrice: shopProductDetails.base_price,
      crossSellPackagings: shopProductDetails.cross_sell_packagings.map(this.mapShopProductPackagingToProductPackaging),
      description: shopProductDetails.description,
      discount: this.calculateDiscountInPercent(shopProductDetails.price, shopProductDetails.avp),
      footnotes: shopProductDetails.alerts.map((alert) => ({
        text: alert.text,
        link: alert.link ? { url: alert.link, description: alert.link_title || alert.link } : undefined,
      })),
      imageUrl: shopProductDetails.image_url,
      name: shopProductDetails.name,
      obligatoryTexts: shopProductDetails.obligatory_texts,
      pharmaceuticalForm: shopProductDetails.darreichungsform,
      price: shopProductDetails.price,
      quantityAndUnit: shopProductDetails.quantity_and_unit,
      retailPrice: shopProductDetails.avp,
      retailPriceType: 'AVP',
      thumbnailUrl: shopProductDetails.thumbnail_url || undefined,
      url: shopProductDetails.url,
      tax: shopProductDetails.tax,
      taxRate: shopProductDetails.taxRate,
      safetyRegulation: this.getSafetyRegulation(shopProductDetails.gpsr_info),
    };
  }

  public static calculateDiscountInPercent(
    price: number | undefined,
    retailPrice: number | undefined
  ): number | undefined {
    if (price && retailPrice) {
      const discount = Math.trunc((1 - price / retailPrice) * 100);
      return discount >= 5 ? discount : undefined;
    }
    return undefined;
  }

  public static isShopProduct(product: any): product is ShopProduct {
    return product.retailPriceType === undefined;
  }

  private static addMissingSlashToUrl(url: string): string {
    return url.endsWith('/') ? url : `${url}/`;
  }

  private static mapShopProductPackagingToProductPackaging(
    shopProductPackaging: ShopProductPackaging
  ): ProductPackaging {
    return {
      pzn: shopProductPackaging.pzn,
      quantityAndUnit: shopProductPackaging.quantity_and_unit,
      price: shopProductPackaging.price,
    };
  }

  private static getSafetyRegulation(regulation?: ShopProductSafetyRegulation): string | undefined {
    if (!regulation) {
      return undefined;
    }
    const addressEntries = [
      regulation.company,
      regulation.street,
      `${regulation.zipCode} ${regulation.city}`,
      regulation.country,
    ];
    const combinedAddress = addressEntries
      .map((entry) => entry?.trim())
      .filter((entry): entry is string => !!entry && entry.length > 0)
      .join('<br />');

    let htmlText = '';
    if (regulation.label) {
      htmlText += `<h2><div class="mea-text-m">${regulation.label}</div></h2>`;
    }
    if (regulation.logo) {
      htmlText += `<img alt="Logo" src="${regulation.logo}" />`;
    }
    if (regulation.website) {
      const website = `<a href="${regulation.website}" target="_blank" rel="noopener noreferrer">${regulation.website}</a>`;
      htmlText += `<div class="mea-text-m">${website}<br /><br /></div>`;
    }
    if (combinedAddress.length) {
      htmlText += `<div class="mea-text-m">${combinedAddress}<br /><br /></div>`;
    }
    if (regulation.phoneNumber) {
      htmlText += `<div class="mea-text-m"><a href="tel:${regulation.phoneNumber}">${regulation.phoneNumber}</a></div>`;
    }
    if (regulation.email) {
      htmlText += `<div class="mea-text-m"><a href="mailto:${regulation.email}">${regulation.email}</a><br /><br /></div>`;
    }
    if (regulation.qualifiedPerson) {
      htmlText += `<div class="mea-text-m">Verantwortliche Person: ${regulation.qualifiedPerson}<br /><br /></div>`;
    }
    if (regulation.urlImpress) {
      const urlImpress = `<a href="${regulation.urlImpress}" target="_blank" rel="noopener noreferrer">${regulation.urlImpress}</a>`;
      htmlText += `<div class="mea-text-m">Impressum: ${urlImpress}</div>`;
    }
    return htmlText;
  }
}
