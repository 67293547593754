import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { NavigationRoutesEnum } from '../navigation/navigation.routes';

@Injectable(
    { providedIn: 'root' }
)
export class FeatureFlagGuard {
    private _router = inject(Router)

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let hasAccess: boolean;
        switch (route.url[0].path) {
            default:
                hasAccess = true;
        }

        if (!hasAccess) {
            void this._router.navigateByUrl(NavigationRoutesEnum.dashboard);
        }
        return hasAccess;
    }
}
