import { inject, Injectable } from '@angular/core';
import dayjs from 'dayjs';
import {
  BackendAppointmentV2,
  BackendAppointmentV2Input,
  BlockingAppointmentV2,
  DecryptedAppointmentV2,
} from '../../../../essentials/types/src/appointmentV2';
import { AppointmentV2Type, BackendAppointmentV2Type } from '../../../../essentials/types/src/appointmentV2Type';
import { CONFIG } from '../../../../essentials/types/src/mea-config';
import { AppointmentUtil } from '../../../../essentials/util/src/appointment.util';
import bookAppointmentV2 from '../../../resources/src/graphql/mutations/bookAppointmentV2';
import getAppointmentsV2 from '../../../resources/src/graphql/queries/getAppointmentsV2';
import getAppointmentV2 from '../../../resources/src/graphql/queries/getAppointmentV2';
import getAppointmentV2Types from '../../../resources/src/graphql/queries/getAppointmentV2Types';
import getBlockingAppointmentsV2 from '../../../resources/src/graphql/queries/getBlockingAppointmentsV2';
import { AppointmentV2MappingService } from '../appointmentsV2/appointmentV2-mapping.service';
import { AppsyncService, AppsyncServiceClient } from './appsync.service';

@Injectable({ providedIn: 'root' })
export class AppsyncCommonAppointmentService {
  private config = inject(CONFIG);
  private appSync = inject(AppsyncService);
  private appointmentV2MappingService = inject(AppointmentV2MappingService);

  isPharmacy = !this.config.clientApp;

  // ************* Queries *************

  async getAppointmentV2(id: string) {
    const client = await this.appSync.getClient();
    const { data } = await client.query({ query: getAppointmentV2, variables: { id } });
    return this.appointmentV2MappingService.mapBackendAppointmentV2ToDecryptedAppointmentV2(data.getAppointmentV2);
  }

  async getAppointmentsV2(pharmacyCognitoId: string, month: string): Promise<DecryptedAppointmentV2[]> {
    const client = await this.appSync.getClient();
    let next: string | undefined;
    const backendAppointments: BackendAppointmentV2[] = [];
    const startOfMonth = dayjs(month).startOf('month').toISOString();
    const endOfMonth = dayjs(month).endOf('month').toISOString();
    do {
      const { appointments, nextToken } = await this.getPaginatedAppointmentsV2(client, {
        pharmacyCognitoId,
        startDate: startOfMonth,
        endDate: endOfMonth,
        nextToken: next,
      });
      backendAppointments.push(...appointments);
      next = nextToken;
    } while (next);
    const mappedAppointments = await Promise.all(
      backendAppointments.map(async (backendAppointment) =>
        this.appointmentV2MappingService.mapBackendAppointmentV2ToDecryptedAppointmentV2(backendAppointment)
      )
    );
    return mappedAppointments.filter((appointment): appointment is DecryptedAppointmentV2 => !!appointment);
  }

  async getBlockingAppointmentsV2(pharmacyCognitoId: string): Promise<BlockingAppointmentV2[]> {
    const client = await this.appSync.getClient();
    let next: string | undefined;
    const backendAppointments: BackendAppointmentV2[] = [];
    const startDate = dayjs().toISOString();
    const endDate = dayjs().endOf('day').add(3, 'months').toISOString();
    do {
      const { appointments, nextToken } = await this.getPaginatedAppointmentsV2(client, {
        pharmacyCognitoId,
        startDate,
        endDate,
        nextToken: next,
      });
      backendAppointments.push(...appointments);
      next = nextToken;
    } while (next);
    return backendAppointments
      .filter(
        (backendAppointment): backendAppointment is BackendAppointmentV2 & { isCancelled?: false } =>
          !backendAppointment.isCancelled
      )
      .map((backendAppointment) =>
        this.appointmentV2MappingService.mapBlockingBackendAppointmentV2ToBlockingAppointmentV2(backendAppointment)
      );
  }

  async getAppointmentV2Types(pharmacyCognitoId: string): Promise<AppointmentV2Type[]> {
    const client = await this.appSync.getClient();
    let next: string | undefined;
    const backendAppointmentTypes: BackendAppointmentV2Type[] = [];
    do {
      const { appointmentTypes, nextToken } = await this.getPaginatedAppointmentV2Types(
        client,
        pharmacyCognitoId,
        next
      );
      backendAppointmentTypes.push(...appointmentTypes);
      next = nextToken;
    } while (next);
    return backendAppointmentTypes.map(AppointmentUtil.mapBackendAppointmentV2TypeToAppointmentV2Type);
  }

  // ************* Mutations *************

  async bookAppointmentV2(variables: BackendAppointmentV2Input) {
    const backendAppointment = await this.bookAppointmentV2WithoutMapping(variables);
    return this.appointmentV2MappingService.mapBackendAppointmentV2ToDecryptedAppointmentV2(backendAppointment);
  }

  async bookAppointmentV2WithoutMapping(variables: BackendAppointmentV2Input): Promise<BackendAppointmentV2> {
    const client = await this.appSync.getClient();
    const appointmentData = await client.mutate({ mutation: bookAppointmentV2, variables });
    return appointmentData.data.bookAppointmentV2;
  }

  // ************* Helpers *************

  private getPaginatedAppointmentV2Types = async (
    client: AppsyncServiceClient,
    pharmacyCognitoId: string,
    nextToken: string | undefined
  ): Promise<{ appointmentTypes: BackendAppointmentV2Type[]; nextToken: string | undefined }> => {
    const { data } = await client.query({
      query: getAppointmentV2Types,
      variables: { pharmacyCognitoId, nextToken },
    });
    return data.getAppointmentV2Types;
  };

  private getPaginatedAppointmentsV2 = async (
    client: AppsyncServiceClient,
    variables: { pharmacyCognitoId: string; startDate: string; endDate: string; nextToken: string | undefined }
  ): Promise<{ appointments: BackendAppointmentV2[]; nextToken: string | undefined }> => {
    const query = this.isPharmacy ? getAppointmentsV2 : getBlockingAppointmentsV2;
    const { data } = await client.query({ query, variables });
    return data.getAppointmentsV2;
  };
}
