import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NumberFormat } from '../config/formats.config';
@Pipe({
    name: 'formInputIsInvalid',
})
export class FormInputValidationPipe implements PipeTransform {
    transform(
        isDirty: boolean,
        isValid: boolean
    ): boolean {
        return isDirty && !isValid;
    }
}

@Pipe({
    name: 'formInputErrorMessage',
})
export class FormInputErrorMessagePipe implements PipeTransform {
    transform(
        control: AbstractControl,
        value,
        patternErrorMessage = ''
    ): {title: string, list: string[]} {
        let errorMessage = null;

        // (value || !value) = Only to keep value as possible parameter - Qodana
        if ((value || !value) && control && control.errors && Object.keys(control.errors).length > 0) {
            const errorMessages = Object.keys(control.errors).map((errorKey) => {
                switch (errorKey) {
                    case 'email':
                        return 'Die eingegebene Email Adresse entspricht nicht dem Schema "beispiel@apotheke.de".';
                    case 'minlength':
                        /* availa control.errors.minlength.requiredLength */
                        return 'Die Mindestzeichenlänge beträgt ' + control.errors.minlength.requiredLength +
                            '. Sie haben nur ' + this.formatNumbers(control.errors.minlength.actualLength) + ' Zeichen eingegeben.';
                    case 'maxlength':
                        return 'Die maximale Zeichenlänge beträgt ' + control.errors.maxlength.requiredLength +
                            '. Sie haben ' + this.formatNumbers(control.errors.maxlength.actualLength) + ' Zeichen eingegeben.';
                    case 'min':
                        return 'Bitte geben Sie eine Zahl ein, die größer ist als ' + this.formatNumbers(control.errors.min.min) + '.';
                    case 'max':
                        return 'Bitte geben Sie eine Zahl ein, die kleiner ist als ' + this.formatNumbers(control.errors.max.max) + '.';
                    case 'pattern':
                        return patternErrorMessage;
                    case 'required':
                        return 'Das Feld ist ein Pflichtfeld.';
                    case 'whitespace':
                        return 'Bitte löschen Sie die unnötigen Leerzeichen/Leerzeilen.';
                    case 'containsNumber':
                        return 'Ihre Eingabe darf keine Zahl enthalten.';
                    case 'dateInFuture':
                        return 'Das Datum darf nicht in der Zukunft liegen.';
                    case 'dateBeforeThreshold':
                        if(Object.keys(control.errors).includes('dateInFuture')) {
                            return null;
                        }
                        return `Rückgabefrist überschritten. Eine Rückgabe ist nur innerhalb von ${control.errors.dateBeforeThreshold.thresholdWeeks} Wochen nach Lieferung möglich.`;
                }
            });
            errorMessage = {title: 'Bitte korrigieren Sie Ihre Eingaben:', list: errorMessages.filter(message => message !== null)};
        }
        return errorMessage;
    }

    /**
     * convert number to local format
     * @param value - Number to format
     * @return number
     */
    formatNumbers = (value) => {
        if (typeof value === 'number') {
            return Intl.NumberFormat(NumberFormat).format(value);
        }
        return value;
    };
}
