import { computed, inject, Injectable, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { NotificationQueries } from '../store/graphql/queries/notification.graphql';
import { NotificationInterface, NotificationWidgetRowInterface } from '../interfaces/notification.interface';
import {
    convertYearMonthToGermanFormatted,
    formatDateTimeInComparisonToToday,
    formatDateTimeToMoment,
    formatDateToCustomFormat,
} from '../formatting/date.formatting';
import { NotificationTypeBadgeConfig, NotificationTypeLabelConfig } from '../config/notification-type.config';
import { NotificationsEnum } from '../enums/notifications.enum';
import { InvoicesTypeFilterConfig } from '../config/invoices.config';
import { splitTextFunction } from '../pipes/slice-text.pipe';
import { MeaChatService } from './mea-chat.service';
import { NotificationGoToEntryService } from './notification-go-to-entry.service';
import { ModalService } from './modal.service';
import { unsubscribe } from '../util/subscriptions.util';
import { InformationMutations } from '../store/graphql/mutations/information.graphql';
import { NotificationCenterComponent } from '../../navigation/components/notification-center/notification-center.component';
import { StatisticsAssortmentService } from '../../pages/statistics/pages/statistics-assortment/statistics-assortment.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationWidgetService {
    private notificationQueries = inject(NotificationQueries);
    private notificationGoToEntryService = inject(NotificationGoToEntryService);
    private meaChatService = inject(MeaChatService);
    private modalService = inject(ModalService);
    private informationMutations = inject(InformationMutations);
    private statisticsAssortmentService = inject(StatisticsAssortmentService);

    private _notifications = signal<NotificationInterface[]>(null);
    public notifications = this._notifications.asReadonly();
    private _notificationsStream = toSignal<NotificationInterface[]>(
        this.notificationQueries.getNotificationsByTypes().pipe(
            tap((notifications) => {
                if (notifications?.length) {
                    this._notifications.set(notifications);
                }
            })
        )
    );

    private _meaChatConversations = this.meaChatService.conversations;
    public meaChatConversations = computed<NotificationWidgetRowInterface[]>(() => {
        const conversations = this._meaChatConversations();
        return conversations?.map((conversation) => {
            return {
                id: conversation.id,
                rowCss: 'ion-justify-content-between',
                canGoToEntry: true,
                goToEntry: () => this.meaChatService.redirectToMeaChatConversation(conversation),
                onRowClick: () => {},
                columns: [
                    {
                        data: conversation.time,
                        css: 'first-column no-shrink ion-padding-right-big',
                    },
                    { data: conversation.chatPartnerName, css: 'size-auto no-overflow ion-padding-right-big', overflowFade: true },
                    {
                        data: conversation?.intent || 'neue Nachricht',
                        css: 'last-column no-shrink ion-text-left no-overflow',
                        isLastCol: true,
                        colWidth: '195px',
                        overflowFade: true,
                    },
                ],
                isRead: conversation.unreadMessagesCount === 0,
                type: conversation.intent,
            };
        });
    });

    /**
     * Returns a signal that contains notifications
     */
    public allNotifications = computed<NotificationWidgetRowInterface[]>(() => {
        const allNotifications = [];
        if (this._notifications()) {
            allNotifications.push(...this._notifications());
        }
        if (allNotifications.length) {
            allNotifications.sort((a, b) => (a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0));
        }
        return allNotifications?.map((notification) => {
            return {
                id: notification.id,
                rowCss: 'ion-justify-content-between',
                onRowClick: (event) => this.onNotificationClick(event, notification.id, notification.type, notification),
                canGoToEntry: this.notificationGoToEntryService.checkHasEntry(notification.type),
                goToEntry: () => this.notificationGoToEntry(notification),
                columns: [
                    { data: this.notificationLabel(notification, true), css: 'first-column no-overflow size-auto', overflowFade: true },
                    {
                        data: formatDateTimeInComparisonToToday(notification.created_at),
                        css: 'ion-text-right ion-text-wrap last-column',
                        isLastCol: true,
                    },
                ],
                isRead: notification.isRead,
                type: notification.type,
            };
        });
    });

    public notificationLabel(notification: NotificationInterface, shortLabel = false, fullMessage = false, badgeLabel = false): string {
        if (shortLabel) {
            let label = NotificationTypeLabelConfig[notification.type] || '';

            if (notification.payload && notification.payload['productName']) {
                label = label.replace('{{productName}}', notification.payload['productName'] + ' - ');
            }
            if (notification.type === NotificationsEnum.NEW_ASSORTMENT_STATISTIC) {
                const loadingStatus = notification.payload.loadingStatusType;
                const type = this.statisticsAssortmentService.getTranslatedType(loadingStatus) ?? '';
                label = label.replace('{{type}}', type);
            } else if (notification.type === NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR) {
                let date = notification.payload?.yearMonth ?? '';
                if (date !== '') {
                    date = date.slice(0, -2);
                }
                label = label.replace('{{currentYear}}', date);
            } else if (notification.type === NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED) {
                let yearMonth = notification.payload?.yearMonth ?? '';
                if (yearMonth !== '') {
                    yearMonth = convertYearMonthToGermanFormatted(yearMonth);
                }
                label = label.replace('{{yearMonth}}', yearMonth);
            } else if (notification.type === NotificationsEnum.NEW_NEWS || notification.type === NotificationsEnum.NEW_IMPORTANT_NEWS) {
                const title = notification.payload?.title ?? 'Neue News';
                label = label.replace('{{newsTitle}}', title);
            } else if (notification.type === NotificationsEnum.NEW_SURVEY) {
                const title = notification.payload?.title ?? 'Neue Umfrage';
                label = label.replace('{{surveyTitle}}', title);
            } else if (notification.type === NotificationsEnum.NEW_MAINTENANCE) {
                let date = notification.payload?.['startDate'] ?? '';
                if (date !== '') {
                    date = formatDateToCustomFormat(date);
                }
                label = label.replace('{{datetime}}', date);
            } else if (notification.type === NotificationsEnum.NEW_OFFER) {
                const title = notification.payload['name'] ?? 'Neues Angebot';
                label = label.replace('{{offerTitle}}', title);
            } else if (notification.type === NotificationsEnum.NEW_DOCUMENT) {
                const yearMonth = notification.payload['recDateFormatted'] ?? '';
                label = label.replace('{{yearMonth}}', yearMonth);
            } else if (notification.type === NotificationsEnum.NEW_RELEASE_NOTE) {
                const version = notification.payload['version'] ?? '';
                label = label.replace('{{version}}', version);
            }

            return label;
        }

        if (badgeLabel) {
            let badgeLabelConfig = NotificationTypeBadgeConfig[notification.type] || '';

            if (notification.type === NotificationsEnum.NEW_OFFER) {
                const offerType = notification.payload?.appLocation?.isMea ? 'mea' : 'Sanacorp';
                badgeLabelConfig = badgeLabelConfig.replace('{{offerType}}', offerType);
            }

            return badgeLabelConfig;
        }

        let notificationMessage = notification.message;

        if (notificationMessage) {
            notificationMessage = notificationMessage.replace(new RegExp('__(.*?)__', 'g'), '$1'); // Format underline
            notificationMessage = notificationMessage.replace(new RegExp('##(.*?)##', 'g'), '$1'); // Format strong
            notificationMessage = notificationMessage.replace(new RegExp('\\*\\*(.*?)\\*\\*', 'g'), '$1'); // Format italic
        }

        switch (notification.type) {
            case NotificationsEnum.NEW_USER_WELCOME:
                return 'Willkommen in Sanacorp Connect';
            case NotificationsEnum.NEW_USER_DATA_POLICY:
                return 'Nutzungsbedingungen';
            case NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR:
                return 'mea(R) Kooperation: Jahresziel erreicht';
            case NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED:
                return 'Monatsziel ' + formatDateTimeToMoment(notification.created_at, true).format('MMMM YYYY') + ' erreicht.';
            case NotificationsEnum.NEW_OFFER:
                return notification.payload && notification.payload['name']
                    ? notification.payload['name'] + ': ' + notificationMessage
                    : notificationMessage;
            case NotificationsEnum.NEW_DOCUMENT:
                if (notification.payload['documentSubType']) {
                    const subtype = InvoicesTypeFilterConfig.find((type) => type.id === notification.payload['documentSubType']);
                    if (subtype) {
                        return 'Neue ' + subtype.title + ' ' + notification.payload['recDateFormatted'];
                    }
                }
                return notificationMessage;
            case NotificationsEnum.NEW_SURVEY:
                if (notification.payload['title']) {
                    return `Neue Umfrage: ${notification.payload['title']}`;
                }
                return notificationMessage;
            default:
                if (notification.payload && notification.payload['productName']) {
                    if (fullMessage) {
                        return (
                            notification.payload['productName'] + ' ' + notification.payload['packageSize'] + ' - ' + notificationMessage
                        );
                    }
                    return (
                        splitTextFunction(notification.payload['productName'] + ' ' + notification.payload['packageSize'], 18) +
                        ' ' +
                        notificationMessage
                    );
                }
                return notificationMessage;
        }
    }

    /**
     * User clicked a single notification
     *
     * @param event - The click event
     * @param id - The notification id
     * @param type - The notification type
     * @param notification - The notification object
     */
    private async onNotificationClick(event, id, type, notification = null) {
        if (event.target.className.includes('clickable')) {
            return;
        }
        await this.presentNotificationModal(id, type);
    }

    /**
     * Opens the notification modal
     *
     * @param notificationId - The notification id
     * @param type - The notification type
     * @param notification - The notification object
     */
    private async presentNotificationModal(notificationId, type, notification = null) {
        const modal = await this.modalService.create(NotificationCenterComponent, {
            activeNotificationId: notificationId,
            notificationItem: notification,
        });

        return await this.modalService.present(modal);
    }

    /**
     * Redirects the user to the corresponding entry of the notification
     * @param notification
     * @private
     */
    private notificationGoToEntry(notification: NotificationInterface) {
        this.changeNotificationReadState(notification);
        const notificationObservable = this.notificationQueries.getNotificationById(notification.id).subscribe((_notification) => {
            if (_notification) {
                void this.notificationGoToEntryService.goToEntry(_notification, false);
                unsubscribe(notificationObservable);
            }
        });
    }

    /**
     * Update read status of notification
     *
     * @param notification - The notification object
     */
    private changeNotificationReadState(notification: NotificationInterface) {
        if (notification) {
            this.informationMutations.upsertNotificationReadStatus(notification.id, true);
        }
    }
}
