import {
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    HostListener, inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ViewTypeEnum } from '../../enums/view-type.enum';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    animations: [
        trigger('bulkVisible', [
            state('visible', style({
                flex: '0 0 auto',
                width: '*',
                padding: '*',
                margin: '0 var(--ion-margin-medium) 0 0',
                opacity: 1,
            })),
            state('hidden', style({
                flex: '0 0 0',
                padding: 0,
                margin: 0,
                width: 0,
                opacity: 0,
                display: 'none'
            })),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ])
    ]
})
export class ListComponent implements OnChanges, OnInit {
    private el = inject(ElementRef);
    // Templates
    @Input() noResultsTmpl: TemplateRef<any>;
    @Input() titleBeforeTmpl: TemplateRef<any>;
    @Input() titleTmpl: TemplateRef<any>;
    @Input() titleAfterTmpl: TemplateRef<any>;
    @Input() titleRightTmpl: TemplateRef<any>;
    @Input() contentCommonTmpl: TemplateRef<any>;
    @Input() contentAdditionalTmpl: TemplateRef<any>;

    @Input() isLoading = false;
    @Input() isBulkActive = false;
    @Input() bulkSelectedItems = [];
    @Input() items = null;
    @Input() activeId = null;
    @Input() color = 'magenta';
    @Input() viewType = ViewTypeEnum.LIST;


    @Output() loadMore = new EventEmitter<ListComponent>();
    @Output() changeActiveId = new EventEmitter<number>();
    @Output() changeBulkSelectedItems = new EventEmitter<any[]>();

    @ViewChild('infiniteScroll') infiniteScroll;

    viewTypeTable = ViewTypeEnum.TABLE;


    @HostListener('document:keyup.ArrowUp', ['$event']) onArrowUpHandler(event: KeyboardEvent) {
        if (document.activeElement.contains(this.el.nativeElement)) {
            event.preventDefault();
            this.moveActiveId(true);
        }
    }

    @HostListener('document:keyup.ArrowDown', ['$event']) onArrowDownHandler(event: KeyboardEvent) {
        if (document.activeElement.contains(this.el.nativeElement)) {
            event.preventDefault();
            this.moveActiveId(false);
        }
    }

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.scrollToActiveItem();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Reactivate infinite scroll if items changed (e.g. after search or filter change)
        if(changes && changes.items && this.infiniteScroll) {
            this.infiniteScroll.disabled = false;
        }
    }

    /**
     * Scroll to the list item with the activeId.
     */
    private scrollToActiveItem(): void {
        if (!this.activeId) {
            return;
        }
        setTimeout(() => {
            const activeItem = document.querySelector(`.list-item-${this.activeId}`);
            if (activeItem) {
                activeItem.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }, 100);
    }

    /**
     * User clicked on item
     *
     * @param id - id of the clicked item
     */
    onItemClick(id){
        this.changeActiveId.emit(id);
    }

    moveActiveId(up: boolean) {
        const indexChange = up ? -1 : 1;
        if(this.items && this.activeId) {
            const index = this.items.findIndex(item => item.id === this.activeId);
            // If up is true, move 1 index back, stop at the beginning of the items
            // If up is false, move 1 index forward, stop at the end of the items
            if((index === 0 && up)
            || (index === this.items.length - 1 && !up)) {
                return;
            }
            this.changeActiveId.emit(this.items[(index + indexChange + this.items.length) % this.items.length].id);
            this.scrollToActiveItem();
        }
    }

    onBulkItemChange(event, id) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        if(this.bulkSelectedItems.findIndex(item => item === id) !== -1) {
            this.bulkSelectedItems = [...this.bulkSelectedItems.filter(item => item !== id)];
        } else {
            this.bulkSelectedItems = [...this.bulkSelectedItems, id];
        }
        this.cdr.detectChanges();

        this.changeBulkSelectedItems.emit(this.bulkSelectedItems);
    }

    /**
     * User scrolled down to load more items
     */
    onLoadMore() {
        this.loadMore.emit(this);
    }

    complete() {
        this.infiniteScroll.complete();
    }
    disable() {
        this.infiniteScroll.disabled = true;
    }
}
