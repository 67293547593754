import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NgxHintsModule } from '@codeculture/ngx-hints';

import { MarkdownModule } from 'ngx-markdown';

import { CoreModule } from '../../../core/core.module';
import { AppointmentsWidgetComponent } from './appointments-widget/appointments-widget.component';
import { NotificationViewComponent } from './notification-view/notification-view.component';
import { NotificationsActionsComponent } from './notifications-actions/notifications-actions.component';
import { NotificationsWidgetComponent } from './notifications-widget/notifications-widget.component';
import { SeminarsWidgetsModule } from '../../seminars/widgets/seminars.widgets.module';
import { NewsComponentsModule } from '../../news/components/news.components.module';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        CoreModule,
        MarkdownModule,
        SeminarsWidgetsModule,
        NgxHintsModule,
        NewsComponentsModule
    ],
    declarations: [
        AppointmentsWidgetComponent,
        NotificationViewComponent,
        NotificationsActionsComponent,
        NotificationsWidgetComponent,
    ],
    exports: [
        AppointmentsWidgetComponent,
        NotificationsWidgetComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CalendarWidgetsModule {
}
