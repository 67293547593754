<app-widget i18n-title [title]="title" [displayHeader]="displayWidgetHeader" [useSmallerPadding]="designV2" [noPaddingHeader]="designV2" [isStandalone]="true">
    <ng-container headerActions>
        <ion-icon name="information-circle-outline" appTooltip [ttk]="titleTtk" ttp="right" class="widget-info" [style.--widgetInfoIconMarginRight]="widgetInfoIconMarginRight"></ion-icon>
        <ng-content select="[segmentButton]"></ng-content>
    </ng-container>

    <ng-container content>
        <div class="table-slider-container" #contentContainer>
            <swiper-container
                    [modules]="swiperModules"
                    (swiperslidechange)="updatePageCount()"
                    [spaceBetween]="50"
                    [class.no-data]="hasData && !isLoading && data()?.length == 0"
                    #tableSlider>
                <!-- SKELETON LOADING -->
                <swiper-slide *ngIf="!hasData || isLoading">
                    <ng-container *ngTemplateOutlet="skeleton"></ng-container>
                </swiper-slide>

                <!-- DATA TABLE -->
                <ng-container *ngTemplateOutlet="hasData"></ng-container>

                <!-- NO DATA AVAILABLE -->
                <swiper-slide *ngIf="hasData && !isLoading && data()?.length == 0">
                    <ng-container *ngTemplateOutlet="noData"></ng-container>
                </swiper-slide>
            </swiper-container>
        </div>
    </ng-container>

    <!-- Footer for pagination etc. -->
    <ng-container footer>
        <div #footerContainer class="footerContainer" [style.--theme-color]="themeColor?.mainColor">
            <!-- More Button -->
            <ion-grid class="ion-no-padding" *ngIf="hasData && data()?.length > 0">
                <ion-row [class.ion-margin-top]="!showMoreButton">
                    <ion-col size="6"></ion-col>
                    <ion-col size="12" class="ion-align-items-center  ion-justify-content-center display-flex">
                        <ion-icon *ngIf="pageCount > 1" class="clickable ion-margin-end" name="arrow-back" (click)="previousSlide()"></ion-icon>
                        <app-pagination-bar [current]="currentPage" [total]="pageCount" [remainderColor]="themeColor?.secondaryColor" [markerColor]="themeColor?.mainColor" *ngIf="pageCount > 1"></app-pagination-bar>
                        <ion-icon *ngIf="pageCount > 1" class="clickable ion-margin-start" name="arrow-forward" (click)="nextSlide()"></ion-icon>
                    </ion-col>
                    <ion-col class="display-flex ion-align-items-center justify-content-end">
                        <ion-text *ngIf="pageCount > 1" class="ion-no-padding ion-text-uppercase widget-text-element"
                                  color="medium">{{currentPage}} / {{pageCount}}</ion-text>
                    </ion-col>
                    <ion-col *ngIf="showMoreButton" size="auto" class="display-flex ion-justify-content-end">
                        <ion-button fill="clear" i18n class="more-button ion-no-padding ion-text-uppercase widget-text-element ion-text-right"
                                    color="medium" size="small" (click)="onMoreButtonClick()" [ngStyle]="{'--padding-end': pageCount > 9 ? '0' : null}">Mehr
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>

        </div>
    </ng-container>
</app-widget>

<ng-template #skeleton>
    <ion-grid class="ion-no-padding table">
        <ion-row *ngFor="let skeletonRow of skeletonArray" class="ion-align-items-center skeleton-row">
            <ion-col *ngFor="let col of columns" [size]="col.size" class="ion-padding-end">
                <ion-skeleton-text animated></ion-skeleton-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-template>
<ng-template #hasData>
    <!-- DATA TABLE -->
    <ng-container *ngIf="hasData && !isLoading && data()?.length > 0 && isCalculationDone">
        <swiper-slide *ngFor="let splitData of data() | arraySplitOrders:maximumRows">
            <ion-grid class="ion-no-padding table">
                <ion-row *ngIf="hasTableHeader">
                    <ion-col *ngFor="let col of columns" [size]="col.size" [class]="col?.classes" class="ion-padding-end table-header">
                        {{ col?.label }}
                    </ion-col>
                </ion-row>
                <ion-row *ngFor="let item of splitData;trackBy:trackBy" class="ion-align-items-center"
                         (click)="onRowPressed($event, item.id)">
                    <ion-col *ngFor="let col of columns" [size]="col.size" [sizeMd]="col?.sizeMd" [class]="col?.classes" class="column">
                        <ion-badge color="primary" *ngIf="col.type === columnTypes.ionBadge && (item.created_at | compareDates) <= 3" i18n>Neu</ion-badge>
                        <app-badge [code]="item?.status" [badgeType]="col?.badgeType ? col?.badgeType : badgeTypes.ORDER" *ngIf="col.type === columnTypes.appBadge"></app-badge>
                        <ng-container *ngIf="col.type === columnTypes.externalLink">
                            <ion-icon name="open-outline"
                                      mode="md"
                                      class="clickable"
                                      size="small"/>
                        </ng-container>
                        <ng-container *ngIf="col.type === columnTypes.actions">
                            <ng-container *ngIf="popoverActionItems">
                                <ion-icon [id]="'table-widget-action-popover-'+ widgetKey + '-' + item.id"
                                          name="ellipsis-vertical"
                                          mode="md"
                                          class="clickable"
                                          size="small"/>

                                <ion-popover [trigger]="'table-widget-action-popover-'+ widgetKey + '-' + item.id" dismissOnSelect="true" class="orders-popover">
                                    <ng-template>
                                        <ion-list lines="full" class="ion-no-padding">
                                            <ion-item *ngFor="let actionItem of popoverActionItems"
                                                      button="true"
                                                      [class.disabled]="actionItem.isDisabled"
                                                      (click)="!actionItem.isDisabled && actionItem?.onAction()"
                                                      appTooltip
                                                      ttk="{{actionItem?.tooltipKey}}"
                                                      ttp="left">
                                                <ion-icon *ngIf="actionItem?.icon" slot="start" [name]="actionItem.icon"></ion-icon>
                                                {{actionItem.label}}
                                            </ion-item>
                                        </ion-list>
                                    </ng-template>
                                </ion-popover>
                            </ng-container>
                            <ng-container *ngIf="!popoverActionItems">
                                <ion-icon
                                          [name]="iconName" mode="md" class="clickable"
                                          (click)="presentPopover($event, item.id)"
                                          size="small"></ion-icon>
                            </ng-container>
                        </ng-container>
                        {{ col?.showComponentOnly !== true ? item[col.key] : '' }} {{ col?.key2 ? 'x ' + item[col.key2] : '' }}
                    </ion-col>
                </ion-row>
            </ion-grid>
        </swiper-slide>
    </ng-container>
</ng-template>
<ng-template #noData>
    <ion-grid class="ion-padding ion-margin">
        <ion-row class="ion-align-items-center">
            <ion-col size="24" class="ion-text-center">
                <ion-text i18n class="h2">Aktuell keine Daten verfügbar</ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
</ng-template>

