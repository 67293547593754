import { Dictionary } from 'ts-essentials';
import { ChatPartnerWithMetadata } from '../../../essentials/types/src/chatPartner';
import { ChatPartnerMetadata } from '../../../essentials/types/src/chatPartnerMetadata';
import { ConversationWithLastMessage } from '../../../essentials/types/src/conversation';
import { ChatPartnerMetadataUtil } from '../../../essentials/util/src/chat-partner-metadata.util';
import { ConversationUtil } from '../../../essentials/util/src/conversation.util';

export class ChatConversationsUtil {
  public static getDisplayName(name: string, nickname: string | undefined) {
    return nickname ? `${name} (${nickname})` : name;
  }

  public static addMetadataToEnduserChatPartners(
    enduserChatPartnersDictionary: Dictionary<ConversationWithLastMessage[]>,
    decryptedChatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>
  ): Dictionary<ChatPartnerWithMetadata> {
    const enduserChatPartnersWithMetadata: Dictionary<ChatPartnerWithMetadata> = {};

    for (const [chatPartnerId, conversationsWithLastMessage] of Object.entries(enduserChatPartnersDictionary)) {
      const firstConversation = conversationsWithLastMessage[0] as ConversationWithLastMessage;
      const containsOpenTicket = ConversationUtil.isConversationOpen(firstConversation.conversation);
      const lastMessageTimestamp = firstConversation.lastMessage.createdAt;
      const chatPartner = firstConversation.conversation.chatPartner;

      const chatPartnerMetadata = decryptedChatPartnerMetadataDictionary[chatPartnerId];
      const chatPartnerName = chatPartnerMetadata?.decryptedChatPartnerChatname || chatPartner.chatname || '';
      const chatPartnerFirstName = chatPartnerMetadata?.decryptedChatPartnerFirstName;
      const chatPartnerLastName = chatPartnerMetadata?.decryptedChatPartnerLastName;
      const isDecryptingChatPartnerName = chatPartnerMetadata?.chatPartnerChatnameDecryptionStatus === 'encrypted';
      const chatPartnerNickname = chatPartnerMetadata?.decryptedChatPartnerNickname;
      const isDecryptingChatPartnerNickname = chatPartnerMetadata?.chatPartnerNicknameDecryptionStatus === 'encrypted';

      enduserChatPartnersWithMetadata[chatPartnerId] = {
        conversations: conversationsWithLastMessage,
        containsOpenTicket,
        lastMessageTimestamp,
        chatPartner,
        chatPartnerId,
        chatPartnerName,
        chatPartnerFirstName,
        chatPartnerLastName,
        isDecryptingChatPartnerName,
        chatPartnerNickname,
        isDecryptingChatPartnerNickname,
        chatPartnerDisplayName: this.getDisplayName(chatPartnerName, chatPartnerNickname),
        isSuspended: chatPartnerMetadata?.isSuspended,
      };
    }
    return enduserChatPartnersWithMetadata;
  }

  public static getActiveChatPartnerId(
    enduserChatPartnersDictionary: Dictionary<ChatPartnerWithMetadata>,
    activeConversationId: string
  ) {
    if (!activeConversationId) {
      return undefined;
    }
    for (const [chatPartnerId, conversations] of Object.entries(enduserChatPartnersDictionary)) {
      const currentConversationWithLastMessage = conversations.conversations.find(
        (conversationWithLastMessage) => conversationWithLastMessage.conversation.id === activeConversationId
      );
      if (currentConversationWithLastMessage) {
        return chatPartnerId;
      }
    }
    return undefined;
  }

  public static splitConversationsIntoChatPartners(
    conversationsWithLastMessage: ConversationWithLastMessage[]
  ): Dictionary<ConversationWithLastMessage[]> {
    const conversationsByChatPartners: Dictionary<ConversationWithLastMessage[]> = {};
    conversationsWithLastMessage.forEach((conversationWithLastMessage) => {
      const chatPartnerId = ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(
        conversationWithLastMessage.conversation
      );
      const conversationsWithChatPartner = conversationsByChatPartners[chatPartnerId];
      if (conversationsWithChatPartner) {
        conversationsWithChatPartner.push(conversationWithLastMessage);
      } else {
        conversationsByChatPartners[chatPartnerId] = [conversationWithLastMessage];
      }
    });
    Object.values(conversationsByChatPartners).forEach((conversations: ConversationWithLastMessage[]) =>
      this.sortConversations(conversations)
    );
    return conversationsByChatPartners;
  }

  public static splitChatPartnerConversationsIntoCategoriesAndSortByTimestamp(
    enduserChatPartnersDictionary: Dictionary<ChatPartnerWithMetadata>
  ) {
    const openChatPartnerConversations: ChatPartnerWithMetadata[] = [];
    const closedChatPartnerConversations: ChatPartnerWithMetadata[] = [];
    Object.entries(enduserChatPartnersDictionary).forEach(([_, enduserChatPartner]) => {
      if (enduserChatPartner.containsOpenTicket) {
        openChatPartnerConversations.push(enduserChatPartner);
      } else {
        closedChatPartnerConversations.push(enduserChatPartner);
      }
    });
    this.sortChatPartnerConversationsByTimestamp(openChatPartnerConversations);
    this.sortChatPartnerConversationsByTimestamp(closedChatPartnerConversations);

    return { openChatPartnerConversations, closedChatPartnerConversations };
  }

  protected static sortConversations(conversationsWithLastMessage: ConversationWithLastMessage[]) {
    return conversationsWithLastMessage.sort((conversationA, conversationB) => {
      if (
        ConversationUtil.isConversationOpen(conversationA.conversation) &&
        !ConversationUtil.isConversationOpen(conversationB.conversation)
      ) {
        return -1;
      } else if (
        !ConversationUtil.isConversationOpen(conversationA.conversation) &&
        ConversationUtil.isConversationOpen(conversationB.conversation)
      ) {
        return 1;
      } else {
        return conversationB.lastMessage.createdAt - conversationA.lastMessage.createdAt;
      }
    });
  }

  private static sortChatPartnerConversationsByTimestamp(chatPartnerConversations: ChatPartnerWithMetadata[]): void {
    chatPartnerConversations.sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);
  }
}
