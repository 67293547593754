export enum LogisticsRoutesEnum {
    orders = 'orders',
    deliveryReceipts = 'delivery',
    invoices = 'invoices',
    returns = 'returns',
    downloads = 'downloads',
    terms = 'terms',
    datasheets = 'datasheets',
    archive = 'archive'
}

export enum FullLogisticsRoutesEnum {
    orders = '/app/logistics/orders',
    deliveryReceipts = '/app/logistics/delivery',
    invoices = '/app/logistics/invoices',
    returns = '/app/logistics/returns',
    downloads = '/app/logistics/downloads',
    terms = '/app/logistics/terms',
    datasheets = '/app/logistics/datasheets',
    archive = '/app/logistics/archive'
}
