import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment/moment';

import { environment } from '../../../../environments/environment';
import { ModalService } from '../../services/modal.service';
import { SconnectAuthService } from '../../services/authentication/sconnect.auth.service';
import { StaticPageModalComponent } from '../static-page-modal/static-page-modal.component';
import { mailContentConfig } from '../../config/mail.config';
import { AppRoutesEnum, StaticPageTypeEnum, WaitingRoomEnum } from '../../core.enums';
import { MaintenanceStateVar } from '../../store/locals/maintenanceState.var';
import { unsubscribe, unsubscribeAll } from '../../util/subscriptions.util';

/** @title Monitoring autofill state with cdkAutofill */
@Component({
    selector: 'app-waiting-room',
    templateUrl: 'waiting-room.component.html',
    styleUrls: ['waiting-room.component.scss'],
})
export class WaitingRoomComponent implements OnInit {
    @ViewChild('logoAnimation') logoAnimation;
    @Input() defaultWaitingRoom: WaitingRoomEnum;

    isEaster = false;
    waitingRoom: WaitingRoomEnum;
    waitingRoomEnum = WaitingRoomEnum;
    animation;
    appVersionCheckInterval;

    currentYear = new Date().getFullYear().toString();
    logoSrc = 'assets/sanacorp-logo.svg';
    logoLetterSrc = 'assets/sanacorp-logo-s.png';
    isLogoAnimationInitialised = false;
    mailContent = mailContentConfig.requestAccessData.content;
    receiverMailAddress = environment.supportMail;

    staticPagesTypeEnum = StaticPageTypeEnum;
    maintenanceSubscription;
    maintenanceText = '';

    newUserSubscription: Subscription;

    constructor(
        private router: Router,
        private sconnectAuthService: SconnectAuthService,
        private maintenanceStateVar: MaintenanceStateVar,
        private modalService: ModalService
    ) {}

    ngOnInit() {
        this.waitingRoom = this.defaultWaitingRoom;

        const currentDate = moment(new Date());
        this.isEaster = currentDate.isBetween(
            moment(environment.fun.easterGame.fromDate, 'DD.MM.YYYY', true),
            moment(environment.fun.easterGame.toDate, 'DD.MM.YYYY', true)
        );
    }

    /**
     * on page load
     */
    initialize(defaultWaitingRoom = null) {
        this.defaultWaitingRoom = defaultWaitingRoom || this.defaultWaitingRoom;
        this.waitingRoom = this.defaultWaitingRoom;
        // fix error IndexSizeError: Failed to execute 'getImageData' on 'CanvasRenderingContext2D': The source width is 0
        if (this.logoAnimation.nativeElement.offsetWidth > 0
        && this.logoAnimation.nativeElement.offsetHeight > 0) {
            this.isLogoAnimationInitialised = true;
            this.initLogoCanvasAnimation(
                this.logoAnimation.nativeElement.offsetWidth,
                this.logoAnimation.nativeElement.offsetHeight
            );
        }

        this.sconnectAuthService.maintenancePolling();
        // Check if maintenance page
        unsubscribe(this.maintenanceSubscription);
        this.maintenanceSubscription = this.maintenanceStateVar.get().subscribe(maintenance => {
            if(maintenance && maintenance.isMaintenance) {
                this.waitingRoom = WaitingRoomEnum.isMaintenance;
                const duration = maintenance.duration ? maintenance.duration : '60 Minuten';
                this.maintenanceText = maintenance.maintenanceText.replace('{{duration}}', duration);
            } else {
                this.waitingRoom = this.defaultWaitingRoom;
                if (this.defaultWaitingRoom === WaitingRoomEnum.isMaintenance) {
                    void this.router.navigateByUrl(AppRoutesEnum.waiting);
                }
            }
        });
    }

    /**
     * on page leave
     */
    clear() {
        cancelAnimationFrame(this.animation);
        this.logoAnimation.nativeElement.innerHTML = '';

        unsubscribeAll([
            this.maintenanceSubscription
        ]);
    }

    /**
     * inits the logo canvas animation
     *
     * @param width - Logo width
     * @param height - Logo height
     */
    initLogoCanvasAnimation(width, height) {
        /**
         * 3D Software ocean effect with Canvas2D
         * You can change properties under comment "Effect properties"
         */
        const context = this.logoAnimation.nativeElement.appendChild(document.createElement('canvas')).getContext('2d');
        const canvas = context.canvas,
            pixels = [];
        canvas.width = width;
        canvas.height = height;

        for (let x = -400; x < 400; x += 15) {
            for (let z = -450; z < 250; z += 15) {
                pixels.push({x, y: 100, z});
            }
        }


        const render = (ts) => {
            try {
                const imageData = context.getImageData(0, 0, width, height),
                    len = pixels.length,
                    fov = 550;
                let pixel, scale,
                    x2d, y2d, c;

                for (let i = 0; i < len; i++) {
                    pixel = pixels[i];
                    scale = fov / (fov + pixel.z);
                    x2d = pixel.x * scale + width / 2;
                    y2d = pixel.y * scale + height / 2;
                    if (x2d >= 0 && x2d <= width && y2d >= 0 && y2d <= height) {
                        c = (Math.round(y2d) * imageData.width + Math.round(x2d)) * 4;
                        imageData.data[c] = 255;
                        imageData.data[c + 1] = 255;
                        imageData.data[c + 2] = 255;
                        imageData.data[c + 3] = 100;
                    }
                    pixel.z -= 0.0;
                    pixel.y = height / 14 + Math.sin(i / len * 15 + (ts / 450)) * 10;
                    if (pixel.z < -fov) {
                        pixel.z += 2 * fov;
                    }
                }
                context.putImageData(imageData, 0, 0);
            } catch (e) {
                // context is dead - Prevent "can't access dead object" error
            }
        };

        const draw = (ts) => {
            // @ts-ignore
            context.clearRect(0,0,width, height);
            this.animation = requestAnimationFrame(draw);
            render(ts);
        };
        draw(0);
    }


    /**
     * User has pressed the login button
     */
    async onLoginButtonPressed() {
        await this.sconnectAuthService.login();
    }

    /**
     * User clicked a legal link
     */
    async onLegalLinkClick(pageType: StaticPageTypeEnum) {
        const modal = await this.modalService.create(
            StaticPageModalComponent,
            {pageType}
        );
        return await this.modalService.present(modal);
    }
}
