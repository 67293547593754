import { inject, signal } from '@angular/core';
import { combineLatest, debounceTime, switchMap } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { NewsPostQueries } from '../../../core/store/graphql/queries/news-post.graphql';
import { NewsPostInterface } from '../../../core/interfaces/news-post.interface';
import { StaticAppLocations } from '../../../core/enums/app-location.enum';
import { NewsLocationRouteMap } from '../../../core/config/navigation.config';

export class NewsService {
    private newsPostQueries = inject(NewsPostQueries);

    private _limit = signal<number>(100);
    public limit = this._limit.asReadonly();

    private _appLocationUrl = signal<string>(NewsLocationRouteMap[StaticAppLocations.Default]);
    public appLocationUrl = this._appLocationUrl.asReadonly();

    private _news$ = combineLatest([toObservable(this._limit), toObservable(this._appLocationUrl)])
        .pipe(debounceTime(200))
        .pipe(switchMap(([limit, appLocationUrl]) => this.newsPostQueries.getNewsPosts(appLocationUrl, limit)));
    public news = toSignal<NewsPostInterface[]>(this._news$, {initialValue: null});


    setLimit(limit: number) {
        this._limit.set(limit);
    }

    setAppLocationUrl(url: string) {
        this._appLocationUrl.set(url);
    }
}
