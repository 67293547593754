import { inject, Injectable } from '@angular/core';
import { CustomAmplifyService } from '../../../common/services/src/amplify.service';
import { AppsyncUserService } from '../../../common/services/src/appsync/appsync-user.service';
import { PrivateKeyBackupService } from '../../../common/services/src/encryption/private-key-backup.service';
import { PrivateKeyStoreService } from '../../../common/services/src/encryption/private-key-store.service';
import { PrivateKeyValidationService } from '../../../common/services/src/encryption/private-key-validation.service';
import { ChatUser } from '../../../essentials/types/src/chatUser';
import { Logger } from '../../../essentials/util/src/logger';
import { LoginStatus } from './types/loginStatus';

const logger = new Logger('MeaChatSignInService');

@Injectable({ providedIn: 'root' })
export class MeaChatSignInService {
  private amplifyService = inject(CustomAmplifyService);
  private appsyncUserService = inject(AppsyncUserService);
  private privateKeyBackupService = inject(PrivateKeyBackupService);
  private privateKeyStoreService = inject(PrivateKeyStoreService);
  private privateKeyValidationService = inject(PrivateKeyValidationService);

  private signInCache: { dbUserData: ChatUser | undefined; cognitoUser?: any } | null = null;

  async amplifySignInWithSsoToken(username: string, token: string): Promise<LoginStatus> {
    try {
      let user = await this.amplifyService.auth().signIn(username);
      if (user?.challengeName === 'CUSTOM_CHALLENGE') {
        user = await this.amplifyService.auth().sendCustomChallengeAnswer(user, token);
        if (user) {
          logger.info(user);
        }
        return await this.retrieveUserFromBackendAndCheckForPrivateKey();
      }
      return LoginStatus.LoginError;
    } catch (e) {
      logger.error('Error signing in to mea chat with sso', e);
      return LoginStatus.LoginError;
    }
  }

  async retrieveUserFromBackendAndCheckForPrivateKey(): Promise<LoginStatus> {
    const dbUserData = await this.appsyncUserService.getUserFromBackend('PHARMACY');
    if (!dbUserData) {
      return LoginStatus.BackupPrivateKey;
    } else {
      return this.signingInExistingDynamoDbUser(dbUserData);
    }
  }

  async restorePrivateKeyAndContinueSignIn(password: string): Promise<LoginStatus> {
    try {
      logger.info('restore private key and continue signIn');
      const signInCache = this.signInCache;
      if (!signInCache) {
        logger.error('No user data found, redirect to login');
        return LoginStatus.LoginError;
      }

      const { dbUserData } = signInCache;
      if (!dbUserData || this.shouldResetKeyPair(dbUserData) || this.shouldBackupPrivateKey(dbUserData)) {
        return LoginStatus.BackupPrivateKey;
      }
      if (!dbUserData.privateKey && dbUserData.encryptedPrivateKey && dbUserData.encryptionSalt) {
        logger.info('No private key, restoring backup');
        const privateKey = this.privateKeyBackupService.decryptPrivateKey(password, {
          encryptedPrivateKey: dbUserData.encryptedPrivateKey,
          salt: dbUserData.encryptionSalt,
        });
        if (!privateKey) {
          return LoginStatus.RestorePrivateKeyPasswordError;
        }
        dbUserData.privateKey = privateKey;

        if (!this.privateKeyValidationService.isPrivateKeyValid(dbUserData.privateKey, dbUserData.publicKey)) {
          logger.error('Restored private key is not valid');
          return LoginStatus.RestorePrivateKeyError;
        }
      }
      await this.privateKeyStoreService.updatePrivateKeyInStorageAndSetUser(dbUserData);
      this.signInCache = null;
      return LoginStatus.Success;
    } catch (e) {
      logger.error('Error restoring private key for mea chat', e);
      return LoginStatus.RestorePrivateKeyError;
    }
  }

  private async signingInExistingDynamoDbUser(dbUserData: ChatUser): Promise<LoginStatus> {
    const privateKeyFromStorage = await this.privateKeyStoreService.getPrivateKey(dbUserData.cognitoId);
    const privateKeyIsValid = this.privateKeyValidationService.isPrivateKeyValid(
      privateKeyFromStorage,
      dbUserData.publicKey
    );
    if (privateKeyFromStorage && privateKeyIsValid) {
      logger.info('Private key is valid');
      dbUserData.privateKey = privateKeyFromStorage;
    }
    this.signInCache = { dbUserData };

    if (this.shouldResetKeyPair(dbUserData)) {
      logger.info('No private key, resetting key pair');
      return LoginStatus.BackupPrivateKey;
    } else if (!dbUserData.privateKey && dbUserData.encryptedPrivateKey) {
      logger.info('No private key, redirect to restore page');
      return LoginStatus.RestorePrivateKey;
    } else if (this.shouldBackupPrivateKey(dbUserData)) {
      logger.info('No backup yet, redirect to backup page');
      return LoginStatus.BackupPrivateKey;
    } else if (!dbUserData.privateKey) {
      logger.errorWithFingerprint('No private key and no backup', 'signin');
      return LoginStatus.LoginError;
    } else {
      await this.privateKeyStoreService.updatePrivateKeyInStorageAndSetUser(dbUserData);
      this.signInCache = null;
      return LoginStatus.Success;
    }
  }

  private shouldResetKeyPair = (dbUserData: ChatUser) => dbUserData.resetKeyPairOnNextLogin;

  private shouldBackupPrivateKey = (dbUserData: ChatUser) => dbUserData.privateKey && !dbUserData.encryptedPrivateKey;
}
