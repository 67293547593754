export type AuthErrorTranslateKey = `AUTH_ERROR.${CognitoErrorCode | MeaAuthErrorCode}`;

export enum CognitoErrorCode {
  CodeMismatchException = 'CodeMismatchException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidPasswordException = 'InvalidPasswordException',
  LimitExceededException = 'LimitExceededException',
  NotAuthorizedException = 'NotAuthorizedException',
  UsernameExistsException = 'UsernameExistsException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException',
}

export enum MeaAuthErrorCode {
  SecurityKeyNotEmptyException = 'SecurityKeyNotEmptyException',
  SecurityKeyRequirementsNotFulfilled = 'SecurityKeyRequirementsNotFulfilled',
  SecurityKeyEmptySpaceException = 'SecurityKeyEmptySpaceException',
  SecurityKeyMismatch = 'SecurityKeyMismatch',
  CodeNotEmptyException = 'CodeNotEmptyException',
  CommonError = 'CommonError',
  ConfirmationNeeded = 'ConfirmationNeeded',
  DataConsentException = 'DataConsentException',
  InvalidPasswordExceptionEmptySpace = 'InvalidPasswordExceptionEmptySpace',
  InvalidPasswordExceptionLowercase = 'InvalidPasswordExceptionLowercase',
  InvalidPasswordExceptionLength = 'InvalidPasswordExceptionLength',
  InvalidPasswordExceptionNumeric = 'InvalidPasswordExceptionNumeric',
  InvalidPasswordExceptionUppercase = 'InvalidPasswordExceptionUppercase',
  InvalidUsernameException = 'InvalidUsernameException',
  LicenseAndTosAgreementException = 'LicenseAndTosAgreementException',
  MailValidationException = 'MailValidationException',
  NicknameEmptyException = 'NicknameEmptyException',
  PasswordChangeAttemptsExceededException = 'PasswordChangeAttemptsExceededException',
  PasswordExpiredExceptionResendFailure = 'PasswordExpiredExceptionResendFailure',
  PasswordExpiredExceptionResendSuccess = 'PasswordExpiredExceptionResendSuccess',
  PasswordNeededException = 'PasswordNeededException',
  PasswordNotEmptyException = 'PasswordNotEmptyException',
  PhoneValidationException = 'PhoneValidationException',
  SignInAttemptsExceededException = 'SignInAttemptsExceededException',
  UserAlreadyConfirmedException = 'UserAlreadyConfirmedException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotEmptyException = 'UserNotEmptyException',
  UserDisabledException = 'UserDisabledException',
  WrongOldPasswordException = 'WrongOldPasswordException',
}
