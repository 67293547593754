import { computed, inject, Injectable, Signal, signal, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, map, Observable, of, switchMap, tap } from 'rxjs';
import { ContingentMap, GraduatedPriceInterface, OfferProductsInterface, ProductContingent } from '../interfaces/offers.interface';
import { OffersMutations, OffersQueries } from '../core.store';
import { TableColumnInterface, TableSidebarInterface, TableSidebarItemsInterface, TableSortInterface } from '../interfaces/table.interface';
import { TableFieldsEnum } from '../enums/table.enum';
import { SortDirectionEnum } from '../enums/sort-direction.enum';
import { ColorInterface } from '../interfaces/theme-color.interface';
import { ThemeColorConfig } from '../config/theme-color.config';
import { OffersOrderTypeEnum } from '../enums/offers.enum';

@Injectable({
    providedIn: 'root',
})
export class OffersService {
    private _offersQueries = inject(OffersQueries);
    private _offerMutations = inject(OffersMutations);

    private _columns = signal<TableColumnInterface[]>([]);
    public columns = this._columns.asReadonly();

    private productsToUpdate = signal<ProductContingent[]>([]);
    private _isLoading = signal<boolean>(false);
    public isLoading = this._isLoading.asReadonly();

    private _currentOffer = signal<string>('');
    public currentOffer = this._currentOffer.asReadonly();

    private _selectedOfferItem = signal<OfferProductsInterface>(null);
    public selectedOfferItem = this._selectedOfferItem.asReadonly();

    private _selectedRowId = signal(0);
    public selectedRowId = this._selectedRowId.asReadonly();

    private _offerItems = signal<OfferProductsInterface[]>([]);
    public offerItems = this._offerItems.asReadonly();

    private _themeColor = signal<ColorInterface>(ThemeColorConfig.primary);
    public themeColor = this._themeColor.asReadonly();

    private _isBrokenGoods = signal<boolean>(false);
    public isBrokenGoods = this._isBrokenGoods.asReadonly();

    private _sidebar$: Observable<TableSidebarInterface> = toObservable(this._selectedOfferItem).pipe(
        debounceTime(200),
        map((offerItem) => {
            return {
                title: offerItem?.offerProduct?.productName,
                subTitle: 'Name',
                sidebarItems: this.buildSidebarItems(offerItem),
                hasEditButton: false,
                previousRow: () => this.updateCurrentOfferItem(-1),
                nextRow: () => this.updateCurrentOfferItem(1),
            } as TableSidebarInterface;
        })
    );

    public sidebar: Signal<TableSidebarInterface> = toSignal(this._sidebar$, { initialValue: null });

    private _sort = signal<TableSortInterface>({
        field: TableFieldsEnum.productName,
        order: SortDirectionEnum.desc,
        onUpdate: (field: TableFieldsEnum) => {
            this.updateSort(field);
        },
    });
    public sort = this._sort.asReadonly();

    private _offer$ = combineLatest([toObservable(this._currentOffer), toObservable(this._sort)]).pipe(
        debounceTime(200),
        switchMap(([currentOffer, sort]) => {
            if (!currentOffer) {
                return of(null);
            }
            return this._offersQueries.getOffersWithDetails(currentOffer).pipe(
                tap((offer) => {
                    if (offer.offerProducts && offer.offerProducts.length) {
                        const sortedOfferProducts = this.sortOfferProducts(offer.offerProducts, sort);
                        this._offerItems.set(sortedOfferProducts);
                        this._selectedOfferItem.set(sortedOfferProducts[0] || null);
                        this._selectedRowId.set(0);
                        this._isLoading.set(false);
                    }
                    this._isBrokenGoods.set(offer.orderType === OffersOrderTypeEnum.BROKEN_GOODS);
                    this._columns.set(this.buildTableColumns());
                })
            );
        })
    );
    public offer = toSignal(this._offer$, { initialValue: null });

    private _offerQuote$ = toObservable(this._currentOffer).pipe(
        debounceTime(200),
        switchMap((currentOffer) => {
            if (!currentOffer) {
                return of(null);
            }
            return this._offersQueries.getOfferQuote(currentOffer).pipe(
                tap((offerQuote) => {
                    if (offerQuote) {
                        this._isLoading.set(false);
                    }
                })
            );
        })
    );
    public offerQuote = toSignal(this._offerQuote$, { initialValue: [] });

    private _offerFilter$ = toObservable(this._currentOffer).pipe(
        debounceTime(200),
        switchMap((currentOffer) => {
            if (!currentOffer) {
                return of(null);
            }
            return this._offersQueries.getOffersFilter(currentOffer).pipe(
                tap((filter) => {
                    if (filter) {
                        this._themeColor.set(filter.appLocation?.isMea ? ThemeColorConfig.mea : ThemeColorConfig.primary);
                        if (filter.orderType === OffersOrderTypeEnum.BROKEN_GOODS && filter.offerProducts) {
                            this.updateRemainingContingents(filter.offerProducts);
                        }
                        this._isLoading.set(false);
                    }
                })
            );
        })
    );
    public offerFilter = toSignal(this._offerFilter$, { initialValue: null });

    private _offerBanner$ = toObservable(this._currentOffer).pipe(
        debounceTime(200),
        switchMap((currentOffer) => {
            if (!currentOffer) {
                return of(null);
            }
            return this._offersQueries.getOffersBannerByOfferId(currentOffer);
        })
    );
    public offerBanner = toSignal(this._offerBanner$, { initialValue: null });

    private readonly contingentUpdates$ = toObservable(this.productsToUpdate).pipe(
        debounceTime(200),
        switchMap((products) => {
            if (!products.length) {
                return of({});
            }
            return this.getRemainingContingents(products);
        })
    );

    public readonly remainingContingents = toSignal(this.contingentUpdates$, { initialValue: {} as ContingentMap });

    public readonly hasImageProducts = computed(() => {
        const items = this.offerItems();
        return items.some((item) => item?.offerProduct?.image?.url);
    });

    public readonly hasInactiveProducts = computed(() => {
        const items = this.offerItems();
        return items.some((item) => !item.isActive);
    });

    //
    //  offers cart signals
    //

    private _isCartLoading = signal<boolean>(false);
    public isCartLoading = this._isCartLoading.asReadonly();

    public readonly cartItems = computed(() => {
        const quote = this.offerQuote();
        const offer = this.offer();

        if (!quote || !offer) {
            return [];
        }

        const cart = quote.length > 0 ? quote[0] : null;

        if (!cart || !cart.offerQuoteItems) {
            return [];
        }

        return cart.offerQuoteItems
            .map((item) => {
                const fullProduct = offer.offerProducts.find((product) => product.id === item.productId);
                if (
                    fullProduct &&
                    fullProduct.isActive &&
                    (offer.orderType === OffersOrderTypeEnum.EXTRAS || fullProduct.offerProduct.pzn)
                ) {
                    return { ...fullProduct, quantity: item.quantity };
                }
                return null;
            })
            .filter((item) => item && item.id);
    });

    public readonly offerCart = computed(() => {
        const quote = this.offerQuote();
        return quote && quote.length > 0 ? quote[0] : null;
    });

    public readonly cartMarkGradPrices = computed(() => {
        const items = this.cartItems();
        return OffersService.markGraduatedPrice(items);
    });

    public readonly showVaccineDosesColumn = computed(() => {
        const items = this.cartItems();
        let vaccineDosesInItems = 0;

        items.forEach((item) => {
            if (item && item.offerProduct) {
                vaccineDosesInItems += Number(item.offerProduct.vaccineDoses || 0);
            }
        });

        return vaccineDosesInItems > 0;
    });

    //
    // functions
    //

    static markGraduatedPrice(offerProducts: OfferProductsInterface[], quantitiesInCart: Array<number> = null) {
        const markGradPrices = [];
        if (offerProducts && offerProducts.length > 0) {
            offerProducts.forEach((offerProduct) => {
                let markIndex;
                const value = (quantitiesInCart ? quantitiesInCart[offerProduct.id] : offerProduct.quantity) || 0;
                if (offerProduct.graduatedPrice && offerProduct.graduatedPrice.length > 0) {
                    offerProduct.graduatedPrice.forEach((gradPrice, index) => {
                        if (value >= gradPrice.quantity) {
                            markIndex = index + 1;
                        }
                    });
                }
                markGradPrices[offerProduct.id] = markIndex ? markIndex - 1 : null;
            });
        }
        return markGradPrices;
    }

    setCurrentOffer(offerId: string): void {
        this._isLoading.set(true);
        this._currentOffer.set(offerId);
    }

    setOfferItem(offerItem: OfferProductsInterface): void {
        this._selectedOfferItem.set(offerItem);
    }

    clearRemainingContingents(): void {
        this.productsToUpdate.set([]);
    }

    updateRemainingContingents(products: ProductContingent[]): void {
        this.productsToUpdate.set(products);
    }

    /**
     * Calculates the remaining contingent for products
     * @param products Array of ProductContingent
     * @returns Observable with array of remaining contingents indexed by product ID
     */
    private getRemainingContingents(products: ProductContingent[]): Observable<ContingentMap> {
        const productsWithContingent = products.filter((product): product is ProductContingent => product?.contingent != null);

        const productIds = productsWithContingent.map((product) => product.id);

        if (!productIds.length) {
            return of({});
        }

        return this._offersQueries.getOrderedQuantities(productIds).pipe(
            map((orderedQuantities) => {
                const remainingContingents: ContingentMap = {};

                products.forEach((product) => {
                    const ordered = orderedQuantities.find((q) => q.productId === product.id)?.totalOrderedQuantity ?? 0;

                    remainingContingents[product.id] = Math.max(0, product.contingent - ordered);
                });

                return remainingContingents;
            })
        );
    }

    private buildSidebarItems(offerItem: OfferProductsInterface): TableSidebarItemsInterface[] {
        const quantityLabel = `Preis (AEP* ${offerItem?.offerProduct.aep} €)`;

        let quantityString = '';
        if (offerItem?.graduatedPrice && offerItem.graduatedPrice.length > 0) {
            const quantities = offerItem.graduatedPrice.map((gradPrice) => `Ab ${gradPrice.quantity?.toString() || ''}`);
            quantityString = quantities.join('\n');
        }

        const priceString = this.formatGraduatedPrices(offerItem?.graduatedPrice, 'price');
        const discountString = this.formatGraduatedPrices(offerItem?.graduatedPrice, 'discount');
        const fields: TableSidebarItemsInterface[] = [];

        if (offerItem?.offerProduct?.image?.url) {
            fields.push({
                key: 'image',
                value: '',
                colWidth: '100%',
                image: {
                    src: offerItem.offerProduct.image.url,
                    alt: offerItem.offerProduct.productName || 'Produktbild',
                },
            });
        }

        // Important: We need both checks. Otherwise the barcode will be visible for a short moment.
        if (this.offerFilter() && !this.offerFilter()?.offerCanBuy) {
            fields.push(
                {
                    key: 'pznBarcode',
                    value: 'PZN - ' + offerItem?.offerProduct.pzn,
                    colWidth: '100%',
                    barcode: {
                        format: 'CODE39',
                        value: '-' + offerItem?.offerProduct.pzn,
                        displayValue: false,
                        formatting: {
                            width: 1,
                            height: 50,
                            margin: 0,
                            marginBottom: 0,
                            marginLeft: 0,
                            marginRight: 0,
                            marginTop: 0,
                        },
                    },
                },
                {
                    key: 'pzn',
                    value: offerItem?.offerProduct.pzn,
                    label: 'PZN',
                    colWidth: '100%',
                    copyIcon: {
                        name: 'copy-outline',
                        copyValue: () => offerItem?.offerProduct.pzn.toString(),
                        tooltipKey: 'offers_copy_pzn_to_clipboard',
                    },
                }
            );
        } else {
            fields.push({
                key: 'pzn',
                value: offerItem?.offerProduct.pzn,
                label: 'PZN',
                colWidth: '100%',
            });
        }
        fields.push(
            {
                key: 'unit',
                value: offerItem?.offerProduct.unit,
                label: 'Packungsgröße',
                colWidth: '50%',
            },
            {
                key: 'dosageForm',
                value: offerItem?.offerProduct.dosageForm,
                label: 'Darreichung',
                colWidth: '50%',
            },
            {
                key: 'quantity',
                value: quantityString,
                label: quantityLabel,
                colWidth: '40%',
            },
            {
                key: 'price',
                label: '',
                keepEmptyLabel: true,
                value: priceString,
                colWidth: '30%',
            },
            {
                key: 'discount',
                label: 'Ihr Vorteil',
                keepEmptyLabel: true,
                value: discountString,
                colWidth: '30%',
            },
            {
                key: 'producer',
                value: offerItem?.offerProduct.producer,
                label: 'Hersteller',
                colWidth: '100%',
            }
        );
        if (offerItem?.offerProduct?.productDescription && offerItem?.offerProduct?.productDescription.length) {
            fields.push({
                key: 'description',
                value: offerItem?.offerProduct?.productDescription,
                label: 'Beschreibung',
                colWidth: '100%',
            });
        }
        return fields;
    }

    public updateCurrentOfferItem(upDown: number) {
        this._selectedRowId.update((prev) => {
            const newId = prev + upDown;
            if (newId < 0 || newId >= this.offerItems().length) {
                return prev;
            }
            return newId;
        });
        const newOfferItem = this.offerItems()[this.selectedRowId()] || this._selectedOfferItem();
        this._selectedOfferItem.set(newOfferItem);
    }

    public updateSort(field: TableFieldsEnum) {
        this._sort.update((prev) => ({
            ...prev,
            field,
            order: this._sort().order === SortDirectionEnum.asc ? SortDirectionEnum.desc : SortDirectionEnum.asc,
        }));
    }

    public setSelectedRowId(id: number) {
        this._selectedRowId.set(id);
    }

    /**
     * Transforms an array of graduated price objects based on the specified property and formatting options.
     *
     * @param graduatedPrices - The array of graduated price objects to be transformed.
     * @param on - The property of the graduated price objects to extract.
     * @param [joinBy='\n'] - The delimiter used to join the values.
     * @return Returns a single joined string of the specified property values.
     */
    public formatGraduatedPrices(
        graduatedPrices: GraduatedPriceInterface[] | null,
        on: 'price' | 'quantity' | 'discount',
        joinBy: string = '\n'
    ): string {
        if (!graduatedPrices || graduatedPrices.length === 0) {
            return '';
        }
        const prices = graduatedPrices.map((gradPrices) => gradPrices[on]?.toString());
        return prices.join(joinBy);
    }

    /**
     * Places an order for the specified offer using the provided item details.
     *
     * @param itemFormatted - An array of objects, where each object contains the item ID and the corresponding amount to be included in the order.
     * @return A promise that resolves to the result of the order operation.
     */
    public async orderOffer(itemFormatted: [{ itemId: number; amount: number }]) {
        return this._offerMutations.orderOffer(this.offer().id, itemFormatted);
    }

    /**
     * Deletes an offer quote with the given identifier.
     *
     * @param id - The unique identifier of the offer quote to be deleted.
     */
    public deleteOfferQuote(id: number) {
        this._offerMutations.deleteOfferQuote(id);
    }

    /**
     * Inserts an offer quote with the given items.
     *
     * @param itemFormatted - An array of objects where each object contains
     *        the product ID and the quantity to be included in the offer quote.
     * @return A promise that resolves with the result of the insert operation.
     */
    public async insertOfferQuote(itemFormatted: [{ productId: string; quantity: number }]) {
        this._isCartLoading.set(true);
        try {
            const result = await this._offerMutations.insertOfferQuote(this.offer().id, itemFormatted);
            return result;
        } finally {
            this._isCartLoading.set(false);
        }
    }

    /**
     * Deletes an offer quote item based on the provided ID.
     *
     * @param id - The unique identifier of the offer quote item to be deleted.
     * @return A promise that resolves when the offer quote item is successfully deleted.
     */
    public async deleteOfferQuoteItem(id: number) {
        this._isCartLoading.set(true);
        try {
            const result = await this._offerMutations.deleteOfferQuoteItem(id);
            return result;
        } finally {
            this._isCartLoading.set(false);
        }
    }

    /**
     * Generates and builds a list of table column configurations based on specific conditions.
     *
     * The method creates an array of column objects, determining their properties such as title, width,
     * class names, sorting capabilities, and data keys. Additional columns are conditionally added
     * based on the `orderType` of the provided `offer`.
     *
     * @return An array of table column objects with unique `id` properties.
     */
    private buildTableColumns() {
        const columns: TableColumnInterface[] = [];
        let id = 1;

        if (this.hasInactiveProducts()) {
            columns.push({
                id: id++,
                title: '',
                class: 'col-inactive col-center',
                width: '3rem',
                sortable: false,
            });
        }

        if (this.hasImageProducts()) {
            columns.push({
                id: id++,
                title: '',
                class: 'col-center product-image-cell',
                width: '5rem',
                sortable: false,
            });
        }

        columns.push({
            id: id++,
            title: 'Name',
            width: '10rem',
            class: 'col-auto',
            sortable: true,
            dataKey: TableFieldsEnum.productName,
        });

        if (this.isBrokenGoods && this.isBrokenGoods()) {
            columns.push({
                id: id++,
                title: 'Kontingent',
                width: '8rem',
                sortable: true,
                dataKey: TableFieldsEnum.contingent,
            });
        }

        columns.push(
            { id: id++, title: 'DAR', width: '6rem', class: 'col-center', sortable: true, dataKey: TableFieldsEnum.dar },
            { id: id++, title: 'PZN', width: '8rem', class: 'col-pzn', sortable: true, dataKey: TableFieldsEnum.pzn },
            { id: id++, title: 'AEP*', width: '5rem', sortable: false, dataKey: TableFieldsEnum.aep },
            { id: id++, title: 'Ab Menge', width: '5rem', class: 'col-right', sortable: false, dataKey: TableFieldsEnum.quantity },
            { id: id++, title: 'Preis', width: '6rem', class: 'col-center', sortable: false, dataKey: TableFieldsEnum.price },
            { id: id++, title: 'Ihr Vorteil', width: '8rem', class: 'col-center', sortable: false, dataKey: TableFieldsEnum.discount },
            { id: id++, title: 'Menge', width: '5rem', class: '', sortable: false, dataKey: TableFieldsEnum.orderedQuantity }
        );

        return columns;
    }

    private sortOfferProducts(offerProducts: OfferProductsInterface[], sort: TableSortInterface): OfferProductsInterface[] {
        return offerProducts.sort((a, b) => {
            const aValue = a.offerProduct[sort.field];
            const bValue = b.offerProduct[sort.field];
            if (aValue === bValue) {
                return 0;
            }
            if (sort.order === SortDirectionEnum.asc) {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue > bValue ? -1 : 1;
            }
        });
    }
}
