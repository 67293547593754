import { Component, Input, OnChanges, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UserAccessModulesEnum, UserAccessRightsEnum } from '../../../../../../core/enums/user-administration.enum';
import { UserAccessRightsConfig } from '../../../../../../core/config/user-administration.config';

interface BadgeAccessRights {
    right: UserAccessRightsEnum;
    name: string;
    isChecked: boolean;
}

@Component({
    selector: 'access-rights-badge',
    templateUrl: './access-rights-badge.component.html',
    styleUrls: ['./access-rights-badge.component.scss'],
})
export class AccessRightsBadgeComponent implements OnInit, OnChanges {
    @Input() accessRights: UserAccessRightsEnum[];
    @Input() badgeType: UserAccessModulesEnum;
    @Input() clickable = true;
    @Input() deactivated = false;
    @Input() disabled: boolean;
    @Output() accessRightsClick: EventEmitter<{
        accessRight: UserAccessRightsEnum,
        isChecked: boolean
    }> = new EventEmitter();

    badge: {
        color: string;
        tooltip: string;
        label: string;
        accessRights: Array<BadgeAccessRights>;
    };
    userAdministrationAccessRight = UserAccessRightsEnum.USER_ADMINISTRATION;

    constructor() {
    }

    ngOnInit() {
        this.init();
    }

    init() {
        if (this.accessRights && this.badgeType) {
            let rights: Array<BadgeAccessRights>;
            if (UserAccessRightsConfig[this.badgeType] && UserAccessRightsConfig[this.badgeType].accessRights) {
                rights = Object.entries(UserAccessRightsConfig[this.badgeType].accessRights).map(
                    ([accessRight, accessRightValues]) => ({
                        right: accessRight,
                        name: accessRightValues.label,
                        isChecked: this.accessRights.includes(accessRight as UserAccessRightsEnum)
                    }) as BadgeAccessRights
                );
            }
            let tooltip: string;
            if (this.badgeType === UserAccessModulesEnum.SACADEMY && !this.disabled && this.deactivated) {
                tooltip = 'ua_sacademy_deactivated_hint';
            } else if (this.accessRights.includes(UserAccessRightsEnum.PHARMACY_OWNER)
                || this.accessRights.includes(UserAccessRightsEnum.PHARMACY_MEMBER)) {
                tooltip = 'ua_access_rights_badge';
            } else {
                tooltip = 'ua_access_rights_badge_no_access';
            }
            this.badge = {
                color: this.disabled ? 'user-administration-disabled' :UserAccessRightsConfig[this.badgeType].color,
                tooltip,
                label: UserAccessRightsConfig[this.badgeType].label,
                accessRights: rights ? rights : [],
            };
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.accessRights || changes.badgeType || changes.deactivated || changes.disabled) {
            this.init();
        }
    }

    onAccessRightClick(accessRight: UserAccessRightsEnum, value: boolean) {
        if (!this.disabled && this.clickable && accessRight !== this.userAdministrationAccessRight) {
            const index = this.badge.accessRights.findIndex(item => item.right === accessRight);
            this.badge.accessRights[index].isChecked = !value;

            // emit event with new accessRights
            this.accessRightsClick.emit({
                accessRight,
                isChecked: !value
            });
        }
    }
}
