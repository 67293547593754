export enum AppRoutes {
  Loading = 'loading',
  Login = 'login',
  Main = 'mea',
}

export enum MainRoutes {
  Orders = 'bestellungen',
  Patients = 'kunden',
  Appointments = 'termine',
  Settings = 'einstellungen',
}

export enum OrdersRoutes {
  ChatOverview = 'mea-app',
  ShopOverview = 'mea-shop',
  PharmaciesOverview = 'apotheken',
  News = 'news',
}

export enum PatientsRoutes {
  Feedback = 'kundenfeedback',
  Profile = 'profil',
}

export enum SettingsRoutes {
  PharmacyData = 'apothekendaten',
  DemoPharmacy = 'demo-apotheke',
  ImageData = 'bilddaten',
  OpeningHours = 'oeffnungszeiten',
  Notifications = 'benachrichtigungen',
  InitialMessages = 'initialnachrichten',
  Textblocks = 'textbausteine',

  AppMarketing = 'app-werbematerial',
  CardlinkMarketing = 'cardlink-werbematerial',

  CardlinkExtension = 'cardlink',
  AppointmentExtension = 'terminbuchung',
  WidgetsExtension = 'widgets',
}

export const HomePage = {
  enduserChat: [AppRoutes.Main, MainRoutes.Orders, OrdersRoutes.ChatOverview],
  shop: [AppRoutes.Main, MainRoutes.Orders, OrdersRoutes.ShopOverview],
  pharmaciesChat: [AppRoutes.Main, MainRoutes.Orders, OrdersRoutes.PharmaciesOverview],
};

export const SettingsPage = {
  settings: [AppRoutes.Main, MainRoutes.Settings],
  textblocks: [AppRoutes.Main, MainRoutes.Settings, SettingsRoutes.Textblocks],
  appointmentExtension: [AppRoutes.Main, MainRoutes.Settings, SettingsRoutes.AppointmentExtension],
};

export const PatientsPage = {
  feedback: [AppRoutes.Main, MainRoutes.Patients, PatientsRoutes.Feedback],
};

export const getOrdersPage = (route: OrdersRoutes) => [AppRoutes.Main, MainRoutes.Orders, route];

export const getEnduserConversationPage = (orderId: string) => [
  AppRoutes.Main,
  MainRoutes.Orders,
  OrdersRoutes.ChatOverview,
  orderId,
];

export const getPharmacyConversationPage = (conversationOrSegmentId: string) => [
  AppRoutes.Main,
  MainRoutes.Orders,
  OrdersRoutes.PharmaciesOverview,
  conversationOrSegmentId,
];

export const getShopMessagePage = (orderId: string) => [
  AppRoutes.Main,
  MainRoutes.Orders,
  OrdersRoutes.ShopOverview,
  orderId,
];

export const getSettingsPage = (route: SettingsRoutes) => [AppRoutes.Main, MainRoutes.Settings, route];

export const getPatientProfilePage = (cognitoId: string) => [
  AppRoutes.Main,
  MainRoutes.Patients,
  PatientsRoutes.Profile,
  cognitoId,
];

export const getAppointmentsPage = (appointmentId: string) => [AppRoutes.Main, MainRoutes.Appointments, appointmentId];
