import { gql } from '@apollo/client/core';
import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, switchMap } from 'rxjs';

import { QueryFetchPolicy } from '../../../enums/api.enum';
import { formatStrapiNewsData, formatStrapiOffersConnectionData, getFiltersStrapi } from '../utils';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { QueryWrapper } from '../query.wrapper';
import {
    FetchPolicyKeys as FPK
} from '../../../enums/fetch-policy-keys.enum';
import{
    DataChangedKeys as DCK
} from '../../../enums/data-changed-keys.enum';
import { newsPostFields, NewsPostQueries } from './news-post.graphql';
import { offerFields, OffersQueries } from './offers.graphql';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { DynamicPageInterface } from '../../../interfaces/dynamicPage.interface';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { DynamicPageContentTypeEnum } from '../../../enums/dynamicPage.enum';

export const GetDynamicPage = (queryName) => gql`
    query ${queryName}(
    $filters: DynamicPageFiltersInput,
    $newsFilters: NewsPostFiltersInput,
    $offerFilters: OfferFiltersInput,
    $offerFiltersLimit: Int
    ) {
        dynamicPages(
            filters: $filters,
            pagination: {limit: 1},
            sort: [
                "filter.customerNumbers:asc"
                "filter.chatUserOnly:desc"
                "filter.shopUserOnly:desc"
                "filter.sanacorpLocations.id:asc"
            ]
        ) {
            id : documentId
            colorTheme
            dynamicContent {
                ... on ComponentDynamicContentStaticContent {
                    id
                    type
                }
                ... on ComponentDynamicContentContentModule {
                    id
                    headline
                    subheadline
                    content
                    textAlignment
                    imageModule {
                        image {
                            id : documentId
                            url
                        }
                        imagePosition
                        imageType
                    }
                    simpleModal {
                        id
                        modalLink
                        modalContent
                        linkAsButton
                    }
                    __typename
                }
                ... on ComponentDynamicContentNewsSlider {
                    id
                    newsSliderHeadline: headline
                    newsSliderElement(filters: {news: $newsFilters}, pagination: {limit: 100}) {
                        id
                        news {
                            ${newsPostFields}
                        }
                    }
                    __typename
                }
                ... on ComponentDynamicContentOfferSlider {
                    id
                    offerSliderElement(filters: {offer: $offerFilters}, pagination: {limit: 100}) {
                        id
                        offer {
                            ${offerFields}
                        }
                    }
                    __typename
                }
                ... on ComponentDynamicContentHeadlineModule {
                    id
                    text
                    backgroundColor
                    image {
                        id : documentId
                        url
                    }
                }
                ... on ComponentDynamicContentDownloadContent {
                    id
                    headline
                    content
                    downloadItem(pagination: {limit: 100}) {
                        id
                        label
                        file {
                            url
                        }
                    }
                    __typename
                }
            }
        }
    }
`;


export const AllDynamicPageQueries = [
    GetDynamicPage('test')
];

@Injectable()
export class DynamicPageQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);
    private newsPostQueries = inject(NewsPostQueries);
    private offersQueries = inject(OffersQueries);

    fetchPolicies = {
        [FPK.getDynamicPage]: QueryFetchPolicy.NETWORK_ONLY,
    };
    constructor(private apollo: Apollo, private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.dynamicPageChanged]: [FPK.getDynamicPage],
            [DCK.cmsMenuItemChanged]: [FPK.getDynamicPage],
            [DCK.newsPostChanged]: [FPK.getDynamicPage],
            [DCK.offerChanged]: [FPK.getDynamicPage],
        });
    }

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    public getDynamicPage(dynamicPageUrl: string): Observable<DynamicPageInterface> {
        const fetchPolicyKey = FPK.getDynamicPage;
        return this.activePharmacyStore$.pipe(
            switchMap(
                (pharmacy) =>
                    this.apollo
                        .watchQuery({
                            query: GetDynamicPage(fetchPolicyKey),
                            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                            variables: {
                                filters: {
                                    ...getFiltersStrapi(pharmacy),
                                    appLocations: {
                                        url: {
                                            eq: dynamicPageUrl
                                        }
                                    },
                                },
                                newsFilters: this.newsPostQueries.getNewsPostVariables(pharmacy, dynamicPageUrl).filters,
                                offerFilters: this.offersQueries.getOffersVariables(pharmacy, null, GraphQLLimits.offers, dynamicPageUrl).filters,
                                offerFiltersLimit: GraphQLLimits.offerFilters,
                            },
                        })
                        .valueChanges.pipe(
                        map((d) => d?.data && d.data['dynamicPages']),
                        map((page) => (page?.length > 0 ? page[0] : null)),
                            map((page) =>
                                page
                                    ? {
                                          ...page,
                                          dynamicContent: page?.dynamicContent.map((content: any) => {
                                              if (content?.imageModule) {
                                                  return {
                                                      ...content,
                                                      imageModule: {
                                                          image: content?.imageModule?.image?.url,
                                                          imagePosition: content?.imageModule?.imagePosition,
                                                          imageType: content?.imageModule?.imageType,
                                                      },
                                                  };
                                              }
                                              if (content?.newsSliderElement) {
                                                  return {
                                                      ...content,
                                                      newsSliderElement: content?.newsSliderElement
                                                          ?.map((news) => formatStrapiNewsData(news?.news, dynamicPageUrl))
                                                          .filter((news: any) => !!news),
                                                  };
                                              }
                                              if (content?.offerSliderElement) {
                                                  return {
                                                      ...content,
                                                      offerSliderElement: content?.offerSliderElement
                                                          ?.map((offer) => formatStrapiOffersConnectionData(offer?.offer, dynamicPageUrl))
                                                          .filter((offer: any) => !!offer),
                                                  };
                                              }
                                              if(content?.__typename === DynamicPageContentTypeEnum.headlineModule) {
                                                  return {
                                                      ...content,
                                                      image: content?.image?.url,
                                                  };
                                              }
                                              if(content?.downloadItem) {
                                                  return {
                                                      ...content,
                                                      downloadItem: content?.downloadItem
                                                          ?.map((download) => ({
                                                              documentId: download?.id,
                                                              title: download?.label,
                                                              filename: download?.label,
                                                              url: download?.file?.url,
                                                          }))
                                                          .filter((download: any) => !!download),
                                                  };
                                              }
                                              return content;
                                          }),
                                      }
                                    : null
                            ),
                            map((d) => {
                                if (d) this.updateFetchPolicy(fetchPolicyKey);
                                return d;
                            })
                        ) as Observable<DynamicPageInterface>
            )
        );
    }
}
