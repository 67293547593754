import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  query getUnreadShopUserMessagesForPharmacy($cognitoId: ID!, $nextToken: String) {
      getUnreadShopUserMessagesForPharmacy(cognitoId: $cognitoId, nextToken: $nextToken) {
          __typename
          shopUserMessages {
              ${shopUserMessageAttributes}
          }
          nextToken
    }
  }
`;
