import { computed, inject, Injectable, Signal, signal, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { combineLatest, debounceTime, firstValueFrom, map, switchMap} from 'rxjs';
import { format, addMinutes, startOfMonth, endOfMonth, addDays, subDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { de } from 'date-fns/locale/de';
import { CalendarEvent } from 'angular-calendar';

import { PopoverService } from '../../core/services/popover.service';
import { AppointmentQueries } from '../../core/store/graphql/queries/appointment.graphql';
import { AppointmentMutations } from '../../core/store/graphql/mutations/appointments.graphql';
import { CalendarPopoverComponent } from './components/calendar-popover/calendar-popover.component';
import { CalendarColors } from './calendar-colors';
import { UserQueries } from '../../core/store/graphql/queries/user.graphql';
import { FormQueries } from '../../core/store/graphql/queries/form.graphql';
import { ProfileSettingsVar } from '../../core/store/locals/profileSettings.var';
import { UserInterface } from '../../core/interfaces/user.interface';
import { today } from '../../core/formatting/date.formatting';
import { AppointmentInterface } from '../../core/interfaces/appointments.interface';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private _userQueries = inject(UserQueries);
    private _profileSettingsVar = inject(ProfileSettingsVar);
    private _formQueries = inject(FormQueries);
    private _popoverService = inject(PopoverService);
    private _appointmentQueries = inject(AppointmentQueries);
    private _appointmentMutations = inject(AppointmentMutations);

    private _initData = signal<boolean>(false);

    private _filters = signal<{fromDate: string, toDate: string, disabledUsers: string[]}>({
        // Start and end of week
        fromDate: format(subDays(startOfMonth(new Date()), 6), 'yyyy-MM-dd'),
        toDate: format(addDays(endOfMonth(new Date()), 6), 'yyyy-MM-dd'),
        disabledUsers: [],
    });
    public filters = this._filters.asReadonly();


    private _users$ = combineLatest([toObservable(this._filters),toObservable(this._initData)])
        .pipe(debounceTime(200))
        .pipe(switchMap(([filters, initData]) => {
            if(!initData) {
                return [];
            }

            return this._userQueries.getUsers()
                .pipe(map(users => {
                    return [...(users || [])]
                        .sort((a, b) => {
                            const aName = `${a.firstName} ${a.lastName}`;
                            const bName = `${b.firstName} ${b.lastName}`;
                            return aName.localeCompare(bName);
                        });
            }));
        }));
    public users: Signal<UserInterface[]> = toSignal(this._users$, {initialValue: null});

    public userNames = computed(() => {
        const users = this.users();
        if(!users) {
            return {};
        }

        return users.reduce((acc, user) => {
            acc[user.id] = `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
            return acc;
        }, {});

    });

    public currentUser = computed(() => this._profileSettingsVar.profileSettings().user);


    private _sanacorpAppointments$ = combineLatest([toObservable(this._initData)])
        .pipe(debounceTime(200))
        .pipe(switchMap(([initData]) => {
            if (!initData) {
                return [];
            }

            return this._appointmentQueries.getAppointmentsBySanacorpByDate(today(), null, 2)
                .pipe(map(appointments => (appointments || []) as AppointmentInterface[]));
        }));
    public sanacorpAppointments: Signal<AppointmentInterface[]> = toSignal(this._sanacorpAppointments$, {initialValue: []});


    private _apiEvents$ = combineLatest([toObservable(this._filters),toObservable(this._initData)])
        .pipe(debounceTime(200))
        .pipe(switchMap(([filters, initData]) => {
            if(!initData) {
                return [];
            }

            return this._appointmentQueries
                .getAppointmentsByDate(filters.fromDate, filters.toDate)
                .pipe(map(appointments => {

                    // Remove previously added "new" event
                    this.removeNewEvent();

                    return (appointments?.map(appointment => ({
                            id: appointment.id,
                            start: new Date(appointment.dateTimeFrom),
                            end: new Date(appointment.dateTimeTo),
                            title: appointment.title,
                            color: appointment.isPharmacy ? CalendarColors.pharmacy : CalendarColors.user,
                            isPharmacy: appointment.isPharmacy,
                            meta: {
                                icon: appointment.payload?.icon,
                                description: appointment.description,
                                userId: appointment.userId
                            },
                            allDay: this.checkIfIsAllDay(appointment.dateTimeFrom, appointment.dateTimeTo),
                    }) || []) as CalendarEvent[]);
                }))
                .pipe(switchMap((events:CalendarEvent[] = []) => {
                    return this._appointmentQueries.getAppointmentsBySanacorpByDate(filters.fromDate, filters.toDate)
                        .pipe(map(sanacorpAppointments => {
                            return sanacorpAppointments.map(appointment => ({
                                id: appointment.id,
                                start: new Date(appointment.dateTimeFrom),
                                end: new Date(appointment.dateTimeTo),
                                title: appointment.title,
                                color: CalendarColors.sanacorp,
                                meta: {
                                    lockedBySanacorp: true,
                                    description: appointment.description,
                                    formId: appointment.formId,
                                    formButton: appointment.formButton,
                                    userId: 'S'
                                },
                                allDay: this.checkIfIsAllDay(appointment.dateTimeFrom, appointment.dateTimeTo),
                            }));
                        }))
                        .pipe(map(sanacorpEvents => [...events, ...sanacorpEvents]));
                }));
        }));
    private _apiEvents = toSignal(this._apiEvents$, {initialValue: null});
    private _addEvent = signal<CalendarEvent>(null);

    private _shouldRemoveAddEvent = false;

    public events  = computed(() => {
        let events = this._apiEvents();
        if(this._addEvent()) {
            events = [this._addEvent(), ...this._apiEvents()];
        }

        if(this.filters().disabledUsers.length > 0) {
            events = events.filter(event => {
                const userId = event.meta?.userId;
                return userId && !this.filters().disabledUsers.includes(userId);
            });
        }

        return events;
    });

    public daysWithAppointment = computed(() => {
        if(!this.events()) {
            return [];
        }

        return this.events()
            .map(event => ({ date: event?.start }))
            .filter((v,i,a)=>
                a.findIndex(t=>(t.date.getTime() === v.date.getTime()))===i
            );
    });

    /**
     * Must be executed to load data
     */
    initializeData() {
        this._initData.set(true);
    }

    /**
     * Change filter to given month with offset in the previous and next month to show all events
     * @param date - Date of the month to show
     */
    changeFilter(date: Date) {
        const existingFilters = untracked(() => this._filters());
        const fromDate = format(subDays(startOfMonth(date), 6), 'yyyy-MM-dd');
        const toDate = format(addDays(endOfMonth(date), 6), 'yyyy-MM-dd');

        if (existingFilters.fromDate !== fromDate || existingFilters.toDate !== toDate) {
            this._filters.update(filters => ({
                ...filters,
                fromDate,
                toDate
            }));
        }
    }

    addRemoveUserFilter(userId: string) {
        this._filters.update(filters => ({
            ...filters,
            disabledUsers: filters.disabledUsers.includes(userId) ?
                filters.disabledUsers.filter(id => id !== userId) :
                [...filters.disabledUsers, userId]
        }));
    }

    /**
     * Add placeholder for new event
     *
     * @param start - Start date of the new event
     */
    addNewEvent(start: Date) {
        this._addEvent.set({
            start,
            end: addMinutes(start, 30),
            title: 'Neuer Termin',
            color: CalendarColors.common,
            meta: {
                id: '',
            }
        });
    }

    /**
     * Remove the placeholder for the new event
     */
    removeNewEvent() {
        if(this._shouldRemoveAddEvent) {
            this._shouldRemoveAddEvent = false;
            this._addEvent.set(null);
        }
    }

    /**
     * Save the event/appointment
     *
     * @param event - Event to save
     */
    saveEvent(event) {
        const appointmentData = {
            title: event.title,
            description: event.description,
            dateTimeFrom: event.dateFrom,
            dateTimeTo: event.dateTo,
            isPharmacy: event?.isPharmacy,
            payload: {
                icon: event?.icon,
            }
        };

        if(event.id) {
            this._appointmentMutations.updateAppointment(parseInt(event.id, 10), appointmentData);
        } else {
            // Update added event with new data to show the entry
            this._addEvent.update(e => ({
                ...e,
                ...appointmentData
            }));
            this._shouldRemoveAddEvent = true;
            this._appointmentMutations.createAppointment(appointmentData);
        }
    }

    /**
     * Delete the appointment with the given id
     * @param id - Id of the appointment
     */
    removeEvent(id: number) {
        void this._appointmentMutations.deleteAppointment(id);
    }

    /**
     * If no "allDay" flag is set, check the times of the event
     *
     * @param dateTimeFrom - Start date and time of the event
     * @param dateTimeTo - End date and time of the event
     */
    checkIfIsAllDay(dateTimeFrom: string, dateTimeTo: string): boolean {
        const dateFrom = new Date(dateTimeFrom);
        const dateTo = new Date(dateTimeTo);

        const timeFrom = formatInTimeZone(dateFrom,
            'Europe/Berlin',
            'HH:mm:ss',
            { locale: de }
        );
        const timeTo = formatInTimeZone(dateTo,
            'Europe/Berlin',
            'HH:mm:ss',
            { locale: de }
        );
        return timeFrom === '00:00:00' && timeTo === '00:00:00';
    }

    /**
     * Open popover for info/edit/create of events
     * @param options - Event Date options
     * @param isAddPopover - Is the popover triggered by the add button in the toolbar?
     * @param isDashboard - Is the popover triggered by the dashboard?
     * @param forceSide - Force the side of the popover
     */
    async openCalendarPopover(options: {
        dateFrom: Date;
        dateTo: Date;
        id?: string | number;
        title?: string;
        description?: string;
        icon?: string;
        formId?: string;
        formButton?: string;
        isPharmacy?: boolean
        lockedBySanacorp?: boolean
        isAllDay?: boolean
        event: any;
    }, isAddPopover = false, isDashboard = false, forceSide?: 'left' | 'right'): Promise<void> {
        let isSideRight = true;
        // if dateFrom is friday, saturday or sunday, set side to left
        if([5, 6, 0].includes(options.dateFrom.getDay())) {
            isSideRight = false;
        }
        const isFormAlreadySubmitted = await this.checkIsFormAlreadySubmitted(options.formId);

        const calPopover = await this._popoverService.create({
            component: CalendarPopoverComponent,
            componentProps: {
                dateFrom: options.dateFrom,
                dateTo: options.dateTo,
                id: options.id,
                ...(options.icon ? {icon: options.icon} : {}),
                ...(options.title ? {title: options.title} : {}),
                ...(options.description ? {description: options.description} : {}),
                ...(options.formId ? {formId: options.formId} : {}),
                ...(options.formButton ? {formButton: options.formButton} : {}),
                ...(options.isPharmacy ? {isPharmacy: options.isPharmacy} : {}),
                ...(options.lockedBySanacorp ? {lockedBySanacorp: options.lockedBySanacorp} : {}),
                ...(options.isAllDay ? {isAllDay: options.isAllDay} : {}),
                isBiggerPopover: isAddPopover || options.lockedBySanacorp,
                formAlreadySubmitted: isFormAlreadySubmitted
            },
            event: options.event,
            translucent: true,
            alignment: isDashboard ? 'start' : (isAddPopover ? 'start' : 'center'),
            side: forceSide ? forceSide : (isDashboard ? 'left' : (isAddPopover ? 'left' : (isSideRight ? 'right' : 'left'))),
            cssClass: `popover-calendar
                ${isDashboard ? ' popover-calendar-dashboard' : ' popover-no-backdrop'}
                ${isAddPopover ? ' popover-on-add-btn' : ''}
                ${options.lockedBySanacorp ? ' popover-bigger' : ''}`
        });

        calPopover.onDidDismiss().then(result => {
            if(result.data && !result.data.remove) {
                this.saveEvent(result.data);
            }else if(result.data && result.data.remove) {
                this.removeEvent(result.data.id);
            } else {
                this._shouldRemoveAddEvent = true;
                this.removeNewEvent();
            }
        });

        return this._popoverService.present(calPopover);
    }


    async checkIsFormAlreadySubmitted(formId:string): Promise<boolean>{
        if(!formId) {
            return false;
        }
        const form = await firstValueFrom(this._formQueries.getFormResult(formId));
        return !!(form && form[0]?.id);
    }
}
