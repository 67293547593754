import { Component, inject, Input } from '@angular/core';
import { take } from 'rxjs';
import { formatDateToCustomFormat } from '../../../../../core/core.formatting';
import { NotificationInterface, OffersInterface } from '../../../../../core/core.interfaces';
import { OffersQueries } from '../../../../../core/core.store';
import { NotificationMessageConfig } from '../../../../../core/config/notification-center.config';

@Component({
    selector: 'app-notification-offer-content',
    templateUrl: './notification-offer-content.component.html',
    styleUrls: ['./notification-offer-content.component.scss'],
})
export class NotificationOfferContentComponent {
    private _offersQueries = inject(OffersQueries);

    formattedMessage: string;
    offer: OffersInterface;

    @Input()
    set notificationPayloadId(value: string) {
        // reset the component
        this.offer = null;
        this.formattedMessage = '';

        this._offersQueries
            .getOffersWithDetails(value)
            .pipe(take(1))
            .subscribe((offerData) => {
                this.offer = offerData;
                this.updateFormattedMessage();
            });
    }

    @Input() notification: NotificationInterface;

    private updateFormattedMessage(): void {
        let message = NotificationMessageConfig[this.notification?.type];
        if (message) {
            // Replace the {{offerFromDate}} and {{offerToDate}} placeholders
            if (this.offer && this.offer?.fromDate && this.offer?.toDate) {
                const formattedFromDate = formatDateToCustomFormat(this.offer.fromDate, 'DD.MM.YYYY');
                const formattedToDate = formatDateToCustomFormat(this.offer.toDate, 'DD.MM.YYYY');
                message = message.replace('{{offerFromDate}}', formattedFromDate);
                message = message.replace('{{offerToDate}}', formattedToDate);
            } else {
                message = message.replace('{{offerFromDate}}', '');
                message = message.replace('{{offerToDate}}', '');
            }

            this.formattedMessage = message;
        }
    }
}
