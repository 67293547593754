import gql from 'graphql-tag';
import { messageAttributes } from '../attributes';

export default gql`
  mutation updateMessageMediaUserOptions(
    $messageId: ID
    $exifOrientation: Int
    $encryptedLink: String
    $mediaId: String
  ) {
    updateMessageMediaUserOptions(
      messageId: $messageId
      exifOrientation: $exifOrientation
      encryptedLink: $encryptedLink
      mediaId: $mediaId
    ) {
      ${messageAttributes}
    }
  }
`;
