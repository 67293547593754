import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService, SurveyService } from '../../core.services';
import { SurveyOverviewInterface } from '../../core.interfaces';
import { SurveyModalComponent } from '../survey-modal/survey-modal.component';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyComponent implements OnInit {
    surveys$: Observable<SurveyOverviewInterface[]>;

    constructor(
        private surveyService: SurveyService,
        private modalService: ModalService
    ) {}

    ngOnInit() {
        this.surveys$ = this.surveyService.getSurveys();
    }

    async openModal(surveyId: string, isFilledOut: boolean) {
        if (isFilledOut) return;
        const modal = await this.modalService.create(SurveyModalComponent, {
            surveyId
        });

        await this.modalService.present(modal);
    }

}
