import { inject, Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import last from 'lodash-es/last';
import uniq from 'lodash-es/uniq';
import { from, Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppContext } from '../../../../essentials/types/src/appContext';
import { BackendConversation, ConversationStatusOption } from '../../../../essentials/types/src/backendConversation';
import { BackendMessage } from '../../../../essentials/types/src/backendMessage';
import BackendUserConversation from '../../../../essentials/types/src/backendUserConversation';
import { Conversation, ConversationAndLastMessage } from '../../../../essentials/types/src/conversation';
import { ConversationIntent } from '../../../../essentials/types/src/conversationIntent';
import { AppsyncErrorUtil } from '../../../../essentials/util/src/appsync-error.util';
import { getValidConversationsWithNonNullParticipants } from '../../../../essentials/util/src/chat/chat-helper';
import { ConversationMappingUtil } from '../../../../essentials/util/src/conversation-mapping.util';
import { Logger } from '../../../../essentials/util/src/logger';
import addConversationProgressEvent from '../../../resources/src/graphql/mutations/addConversationProgressEvent';
import addEncryptedNoteToUserConversation from '../../../resources/src/graphql/mutations/addEncryptedNoteToUserConversation';
import addHasPrescriptionsToConversations from '../../../resources/src/graphql/mutations/addHasPrescriptionsToConversations';
import createConversation from '../../../resources/src/graphql/mutations/createConversation';
import createUserConversations from '../../../resources/src/graphql/mutations/createUserConversations';
import deleteConversation from '../../../resources/src/graphql/mutations/deleteConversation';
import updateAppointmentId from '../../../resources/src/graphql/mutations/updateAppointmentId';
import updateArchivedByEnduser from '../../../resources/src/graphql/mutations/updateArchivedByEnduser';
import updateArchivedByPharmacy from '../../../resources/src/graphql/mutations/updateArchivedByPharmacy';
import updateShoppingCart from '../../../resources/src/graphql/mutations/updateShoppingCart';
import getRecentReceivedMessages from '../../../resources/src/graphql/queries/getRecentReceivedMessages';
import getRecentSentMessages from '../../../resources/src/graphql/queries/getRecentSentMessages';
import getUserConversation from '../../../resources/src/graphql/queries/getUserConversation';
import getUserConversationByConversationId from '../../../resources/src/graphql/queries/getUserConversationByConversationId';
import getUserConversations from '../../../resources/src/graphql/queries/getUserConversations';
import createdOwnUserConversation from '../../../resources/src/graphql/subscriptions/createdOwnUserConversation';
import createdUserConversation from '../../../resources/src/graphql/subscriptions/createdUserConversation';
import updatedUserConversation from '../../../resources/src/graphql/subscriptions/updatedUserConversation';
import { AppsyncService, AppsyncServiceClient } from './appsync.service';

const logger = new Logger('AppsyncConversationService');

type RecentMessage = Pick<BackendMessage, 'id' | 'senderId' | 'recipientId' | 'conversationId' | 'createdAt'>;

interface PaginatedBackendUserConversations {
  userConversations: BackendUserConversation[];
  nextToken: string | undefined;
}

@Injectable({ providedIn: 'root' })
export class AppsyncConversationService {
  private appSync = inject(AppsyncService);

  // ************* Query *************

  async getAllConversations(): Promise<ConversationAndLastMessage[]> {
    const client = await this.appSync.getClient();

    let next: string | undefined;
    const backendUserConversations: BackendUserConversation[] = [];
    do {
      const { userConversations, nextToken } = await this.getValidPaginatedUserConversations(client, next);
      backendUserConversations.push(...userConversations);
      next = nextToken;
    } while (next);
    return ConversationMappingUtil.mapUserConversations(backendUserConversations);
  }

  async getRecentConversations(): Promise<ConversationAndLastMessage[]> {
    try {
      const recentReceivedMessages = await this.getRecentReceivedMessages();
      const recentSentMessages = await this.getRecentSentMessages();
      const segmentIdsWithRecentMessages = uniq(
        [...recentReceivedMessages, ...recentSentMessages].map((message) => message.conversationId)
      );
      const initialUserConversations = await this.getUserConversationsBySegmentIds(segmentIdsWithRecentMessages);
      const firstSegmentIds = initialUserConversations
        .map((userConv) => userConv?.conversation.firstSegmentId)
        .filter((id): id is string => !isNil(id));
      const missingFirstSegmentIds = firstSegmentIds.filter((id) => !segmentIdsWithRecentMessages.includes(id));
      const missingUserConversations = await this.getUserConversationsBySegmentIds(missingFirstSegmentIds);
      const existingUserConversations = [...missingUserConversations, ...initialUserConversations].filter(
        (userConv): userConv is BackendUserConversation => !isNil(userConv)
      );
      const validUserConversations = getValidConversationsWithNonNullParticipants(existingUserConversations);
      return ConversationMappingUtil.mapUserConversations(validUserConversations);
    } catch (e) {
      logger.error('error getting recent conversations', e);
      return [];
    }
  }

  async getUpdatesForConversation(conversation: Conversation): Promise<BackendUserConversation> {
    const userConversationIdOfNewestSegment = last(conversation.segments)?.backendUserConversationId;
    const client = await this.appSync.getClient();
    const options = {
      query: getUserConversation,
      variables: { id: userConversationIdOfNewestSegment },
    };
    const { data } = await client.query(options);
    return data.getUserConversation;
  }

  private async getRecentReceivedMessages(): Promise<RecentMessage[]> {
    const client = await this.appSync.getClient();

    let next: string | undefined;
    const backendMessages: BackendMessage[] = [];
    do {
      const { messages, nextToken } = (
        await client.query({
          query: getRecentReceivedMessages,
          variables: { nextToken: next },
        })
      ).data.getRecentReceivedMessages;
      backendMessages.push(...messages);
      next = nextToken;
    } while (next);
    return backendMessages;
  }

  private async getRecentSentMessages(): Promise<RecentMessage[]> {
    const client = await this.appSync.getClient();

    let next: string | undefined;
    const backendMessages: BackendMessage[] = [];
    do {
      const { messages, nextToken } = (
        await client.query({
          query: getRecentSentMessages,
          variables: { nextToken: next },
        })
      ).data.getRecentSentMessages;
      backendMessages.push(...messages);
      next = nextToken;
    } while (next);
    return backendMessages;
  }

  private async getUserConversationsBySegmentIds(segmentIds: string[]): Promise<(BackendUserConversation | null)[]> {
    const client = await this.appSync.getClient();
    return Promise.all(segmentIds.map((segmentId) => this.getUserConversationBySegmentId(segmentId, client)));
  }

  private async getUserConversationBySegmentId(
    segmentId: string,
    client: AppsyncServiceClient
  ): Promise<BackendUserConversation | null> {
    try {
      const { data } = await client.query({
        query: getUserConversationByConversationId,
        variables: { conversationId: segmentId },
      });
      return data.getUserConversationByConversationId;
    } catch (e) {
      logger.error(`Error getting user conversation by segment id ${segmentId}`, e);
      return null;
    }
  }

  private async getValidPaginatedUserConversations(
    client: AppsyncServiceClient,
    nextToken: string | undefined
  ): Promise<PaginatedBackendUserConversations> {
    const userConversationsWithoutAppsyncError = await this.getUserConversationsWithoutAppsyncError(client, nextToken);
    const userConversations = getValidConversationsWithNonNullParticipants(
      userConversationsWithoutAppsyncError.userConversations
    );
    return {
      userConversations,
      nextToken: userConversationsWithoutAppsyncError.nextToken,
    };
  }

  private async getUserConversationsWithoutAppsyncError(
    client: AppsyncServiceClient,
    nextToken: string | undefined
  ): Promise<PaginatedBackendUserConversations> {
    try {
      return (
        await client.query({
          query: getUserConversations,
          variables: { nextToken },
        })
      ).data.getUserConversations as PaginatedBackendUserConversations;
    } catch (err) {
      const userConversationsWithoutAppsyncError = this.extractUserConversationsWithoutAppsyncError(err);
      if (userConversationsWithoutAppsyncError) {
        return userConversationsWithoutAppsyncError;
      } else {
        throw err;
      }
    }
  }

  // ************* Mutations *************

  async createConversation(variables: {
    intent?: ConversationIntent;
    appContext?: AppContext;
    earliestExpirationTimestamp?: number;
  }): Promise<BackendConversation> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: createConversation,
      variables,
    });
    return data.createConversation;
  }

  async createUserConversations(variables: any) {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: createUserConversations,
      variables,
    } as any);
    return data.createUserConversations;
  }

  async updateShoppingCart(variables: {
    conversationId: string;
    encryptedShoppingCart: string;
    hasPrescriptions?: boolean;
  }) {
    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: updateShoppingCart,
      variables,
    });
  }

  async addEncryptedNoteToUserConversation(variables: { userConversationId: string; encryptedText: string }) {
    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: addEncryptedNoteToUserConversation,
      variables,
    });
  }

  async updateAppointmentId(variables: { conversationId: string; appointmentId: string }) {
    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: updateAppointmentId,
      variables,
    });
  }

  async updateProgressStatus(conversationId: string, status: ConversationStatusOption) {
    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: addConversationProgressEvent,
      variables: {
        id: conversationId,
        status: status.status,
        subStatus: status.subStatus,
        data: status.data,
      },
    });
  }

  async deleteConversation(conversationId: string) {
    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: deleteConversation,
      variables: { conversationId },
    });
  }

  async updateArchivedByEnduser(conversationId: string, archivedByEnduser: boolean) {
    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: updateArchivedByEnduser,
      variables: { conversationId, archivedByEnduser },
    });
  }

  async updateArchivedByPharmacy(conversationId: string, archivedByPharmacy: boolean) {
    const client = await this.appSync.getClient();
    await client.mutate({
      mutation: updateArchivedByPharmacy,
      variables: { conversationId, archivedByPharmacy },
    });
  }

  async addHasPrescriptionsToConversations(conversationIds: string[]): Promise<boolean> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: addHasPrescriptionsToConversations,
      variables: { conversationIds },
    });
    if (data.addHasPrescriptionsToConversations) {
      return true;
    } else {
      logger.error('Failed to add hasPrescriptions to conversations, lambda returned false');
      return false;
    }
  }

  // ************* Subscriptions *************

  createdConversations(currentUserCognitoId: string): Observable<BackendUserConversation[]> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdUserConversation,
            variables: { otherParticipantId: currentUserCognitoId },
          })
          .pipe(
            map(
              ({
                data: {
                  createdUserConversation: { userConversations: conversations },
                },
              }) => conversations
            )
          )
      )
    );
  }

  newOwnBackendConversations(currentUserCognitoId: string): Observable<BackendUserConversation[]> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdOwnUserConversation,
            variables: { ownerId: currentUserCognitoId },
          })
          .pipe(
            map(
              ({
                data: {
                  createdOwnUserConversation: { userConversations: conversations },
                },
              }) => conversations
            )
          )
      )
    );
  }

  updatedUserConversation(cognitoId: string): Observable<BackendUserConversation> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedUserConversation,
            variables: { ownerId: cognitoId },
          })
          .pipe(
            map(({ data: { updatedUserConversation: conversation } }) => conversation),
            filter((conversation) => !!conversation)
          )
      )
    );
  }

  private extractUserConversationsWithoutAppsyncError(
    appsyncError: any
  ): PaginatedBackendUserConversations | undefined {
    try {
      const indicesWithErrors = new Set<number>();
      for (const individualError of appsyncError.errors) {
        if (AppsyncErrorUtil.isOpenSearchNotFoundError(individualError)) {
          continue;
        }
        const path = individualError.path;
        if (path && path.length > 2 && path[0] === 'getUserConversations' && path[1] === 'userConversations') {
          const index = path[2];
          logger.error(
            'Error in user conversation with id ' +
              `${appsyncError.data.getUserConversations.userConversations[index]?.id}: ${individualError.message}`
          );
          indicesWithErrors.add(index);
        } else {
          logger.error('Unable to attribute error to individual user conversation', individualError);
          return undefined;
        }
      }
      const allUserConversations = appsyncError.data.getUserConversations.userConversations;
      const validUserConversations: BackendUserConversation[] = [];
      for (let i = 0; i < allUserConversations.length; i++) {
        const userConversation = allUserConversations[i] as BackendUserConversation;
        if (!indicesWithErrors.has(i)) {
          validUserConversations.push(userConversation);
        }
      }
      return {
        userConversations: validUserConversations,
        nextToken: appsyncError.data.getUserConversations.nextToken,
      };
    } catch (extractError) {
      logger.error('Unexpected error extracting valid user conversations', extractError);
      return undefined;
    }
  }
}
