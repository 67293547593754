import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { CountryCodesEnum } from '../enums/country-codes.enum';
import { CompareDateEnum, DateEnum } from '../enums/date.enum';
import { SortDirectionEnum } from '../enums/sort-direction.enum';
import { UpdateTimeframeEnum } from '../enums/update-timeframe.enum';

const timezone = 'Europe/Berlin';
export const defaultDateFormat = 'YYYY-MM-DD';
export const defaultDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';

const todayLoc = 'heute';

/* from moment.js: fromNow() displays relative time in years instead of months, when difference > 334 days */
moment.relativeTimeThreshold('MM', 334);

/**
 * Initialize moment.js localization for relative time formatting
 */
const relativeTimeLocalization = {
    future: 'in %s',
    past: 'vor %s',
    s  : 'gerade eben',
    ss : '%d Sekunden',
    m:  '1 Minute',
    mm: '%d Minuten',
    h:  '1 Stunde',
    hh: '%d Stunden',
    d: '1 Tag',
    dd: '%d Tagen',
    M: '1 Monat',
    MM: '%d Monaten',
    y: '1 Jahr',
    yy: '%d Jahren'
};

export const TIME_SEPARATOR = ':';

export const getCurrentLanguageCode = () => {
    moment.locale(CountryCodesEnum.DE);
};


export const getDate = (outputFormat = DateEnum.yearMonthDay) => {
    return moment(todayAsDate()).format(outputFormat);
};

/**
 * converts the datetime to date
 *
 * @param dateTime string
 * @param isUTC boolean
 */
export const formatDateTimeToMoment = (dateTime, isUTC = false) => {
    getCurrentLanguageCode();
    if (isUTC) {
        const momentDate = convertFromUTCToLocalTime(dateTime, true);
        return typeof momentDate === 'object' ? momentDate : null;
    }
    return moment(dateTime).tz(timezone);
};

/**
 * converts datetime to local time
 *
 * @param dateTime string
 * @param returnMomentDate boolean
 */
export const convertFromUTCToLocalTime = (dateTime, returnMomentDate = true) => {
    const momentDate = momentTimezone.utc(dateTime).tz(timezone);
    return returnMomentDate ? momentDate : momentDate.format(defaultDateTimeFormat);
};

/**
 * converts milliseconds to local time
 *
 * @param dateTime number
 * @param returnMomentDate boolean
 */
export const convertFromMillisecondsToLocalTime = (dateTime: number, returnMomentDate = true) => {
    const momentDate = moment.unix(dateTime / 1000);
    return returnMomentDate ? momentDate : momentDate.format(defaultDateTimeFormat);
};

/**
 * converts a unix date to local date
 *
 * @param dateTime number
 * @param returnMomentDate boolean
 */
export const convertFromUnixDateToLocalDate = (dateTime: number, returnMomentDate = true) => {
    const momentDate = moment.unix(dateTime);
    return returnMomentDate ? momentDate : momentDate.format('L');
};

/**
 * converts datetime to utc
 *
 * @param dateTime string
 * @param returnMomentDate boolean
 * @param toISO boolean
 */
export const convertFromLocalTimeToUTC = (dateTime, returnMomentDate = true, toISO = false) => {
    const momentDate = momentTimezone.tz(dateTime, timezone).utc();
    return returnMomentDate ? momentDate : (toISO ? momentDate.toISOString() : momentDate.format(defaultDateTimeFormat));
};

/**
 * converts the datetime to date
 *
 * @param dateTime string
 * @param isUTC boolean
 */
export const formatDateTimeToDate = (dateTime, isUTC= false) => {
    getCurrentLanguageCode();
    return dateTime ? formatDateTimeToMoment(dateTime, isUTC).format('L') : 'Kein Datum';
};

/**
 * converts the datetime to time
 *
 * @param dateTime string
 * @param isUTC boolean
 * @param useDefaultFullTimeSign boolean
 */
export const formatDateTimeToTime = (dateTime, isUTC= false, useDefaultFullTimeSign = true) => {
    getCurrentLanguageCode();
    const date = formatDateToDefaultDateFormat(dateTime, false);
    return date !== dateTime ? formatDateTimeToMoment(dateTime, isUTC).format('LT') : (useDefaultFullTimeSign ? '...' : null);
};
/**
 * converts the datetime to date and time
 *
 * @param dateTime string
 * @param isUTC boolean
 * @param hasDivider
 */
export const formatDateTimeToDateTime = (dateTime: string, isUTC= false, hasDivider= false) => {
    return formatDateTimeToDate(dateTime, isUTC) +
        (hasDivider ?  ' - ' : ' ') +
        formatDateTimeToTime(dateTime, isUTC);
};

/**
 * format input date to custom date format
 * @param date date to change format
 * @param format custom format, defaults to DD.MM.YYYY
 */
export const formatDateToCustomFormat = (date: string, format: string = 'DD.MM.YYYY') => {
    getCurrentLanguageCode();
    return formatDateTimeToMoment(date, false).format(format).toString();
};

/**
 * Returns YYYY-MM-DD
 *
 * @param monthYear string
 * @param day string
 */
export const concatMonthYearAndDay = (monthYear, day) => {
    return monthYear+'-'+day;
};

/**
 * Return true, if yearMonth is current year
 *
 * @param yearMonth string
 * @param inputFormat string - format of input date
 */
export const isCurrentYear = (yearMonth, inputFormat = DateEnum.yearMonth) => {
    const currentYear = moment().format(DateEnum.year);
    const inputYear = moment(yearMonth, inputFormat).format(DateEnum.year);
    return inputYear === currentYear;
};

/**
 * Return true, if yearMonth is previous year
 *
 * @param yearMonth string
 * @param inputFormat format of input date
 */
export const isPreviousYear = (yearMonth, inputFormat = DateEnum.yearMonth) => {
    const previousYear = moment().subtract(1, 'years').format(DateEnum.year);
    const inputYear = moment(yearMonth, inputFormat).format(DateEnum.year);
    return inputYear === previousYear;
};


/**
 * Returns DD
 *
 * @param date string
 */
export const getDay = (date) => {
    return getDatePartOfDate(date, DateEnum.dayNum);
};

/**
 * Returns the month of input date
 *
 * @param date string
 * @param inputFormat string - format of input date
 * @param returnFormat string - format of output month, default: MM
 */
export const getMonthFromDate = (date, inputFormat = DateEnum.yearMonth, returnFormat = DateEnum.month) => {
    return moment(date, inputFormat).format(returnFormat);
};

/**
 * Returns the year of input date
 *
 * @param date string
 * @param inputFormat string - format of input date
 * @param returnFormat string - format of output month, default: YYYY
 */
export const getYearFromDate = (date, inputFormat = DateEnum.yearMonth, returnFormat = DateEnum.year) => {
    return moment(date, inputFormat).format(returnFormat);
} ;

/**
 * Returns YYYY-MM
 *
 * @param date string
 */
export const getMonthYear = (date) => {
    return moment(date).tz(timezone).format(DateEnum.year + '-' + DateEnum.month);
};

/**
 * formats time
 *
 * @param time :string
 */
export const getHours = (time) => {
    return moment(time, 'HH' + TIME_SEPARATOR + 'mm' + TIME_SEPARATOR + 'ss').format('HH');
};

/**
 * formats time
 *
 * @param time :string
 */
export const getMinutes = (time) => {
    return moment(time, 'HH' + TIME_SEPARATOR + 'mm' + TIME_SEPARATOR + 'ss').format('mm');
};

/**
 * formats any moment date to defaultDateFormat
 */
export const formatMomentDateToDefaultDateFormat = (momentDate: moment.Moment, isUTC = false) => {
    if (momentDate) {
        getCurrentLanguageCode();
        momentDate.format(defaultDateFormat);

        if(momentDate.isValid()) {
            return isUTC ? momentDate.utc().format(defaultDateFormat) : momentDate.format(defaultDateFormat);
        }
    }
    return '';
};

/**
 * formats any moment date to defaultDateFormat
 */
export const formatMomentDateToDefaultDateTimeFormat = (momentDate: moment.Moment, time: string = '') => {
    if (momentDate) {
        const date = formatMomentDateToDefaultDateFormat(momentDate, false);
        if(date) {
            return time ?
                convertFromLocalTimeToUTC(date + 'T' + time, false).toString()
                : momentDate.format(defaultDateTimeFormat);
        }

    }
    return '';
};

/**
 * formats today to defaultDateFormat
 */
export const today = () => {
    getCurrentLanguageCode();
    return momentTimezone().tz(timezone).format(defaultDateFormat);
};
/**
 * formats today to date
 */
export const todayAsDate = () => {
    getCurrentLanguageCode();
    return momentTimezone().tz(timezone).toDate();
};
/**
 * formats yesterday to defaultDateFormat
 */
export const yesterday = () => {
    getCurrentLanguageCode();
    return momentTimezone().tz(timezone).subtract(1, 'day').format(defaultDateFormat);
};

/**
 * formats today minus the given number of days to defaultDateFormat
 */
export const todayMinus = (days: number) => {
    getCurrentLanguageCode();
    return momentTimezone().tz(timezone).subtract(days, 'days').format(defaultDateFormat);
};

export const nowAsMoment = () => {
    return moment().tz(timezone);
};

export const now = () => {
    return nowAsMoment().format(defaultDateTimeFormat);
};

export const lastQuarterHour = () => {
    const remainder = nowAsMoment().minute() % 15;
    const minutes = nowAsMoment().subtract(remainder, 'minutes');
    return minutes.seconds(0).format(defaultDateTimeFormat);
};

/**
 * formats today to defaultDateFormat
 */
export const getDatePartOfDate = (dateTime, datePart: DateEnum, isUTC = false) => {
    getCurrentLanguageCode();
    return formatDateTimeToMoment(dateTime, isUTC).format(datePart);
};

/**
 * formats date time to defaultDateFormat
 * @param dateTime :string
 * @param isUTC :boolean
 */
export const formatDateToDefaultDateFormat = (dateTime, isUTC = false) => {
    getCurrentLanguageCode();
    return formatDateTimeToMoment(dateTime, isUTC).format(defaultDateFormat);
};
/**
 * formats date time to defaultDateFormat
 * @param dateTime :string
 * @param isUTC :boolean
 */
export const formatDateToDefaultDateTimeFormat = (dateTime, isUTC = false) => {
    getCurrentLanguageCode();
    return formatDateTimeToMoment(dateTime, isUTC).format(defaultDateTimeFormat);
};

/**
 * formats the start of week to defaultDateFormat
 *
 * @param unitOfTime moment.unitOfTime.StartOf
 * @param dateTime string
 */
export const startOfCurrent = (unitOfTime: moment.unitOfTime.StartOf, dateTime = null) => {
    const dt = dateTime || now();
    return moment(dt, defaultDateTimeFormat).startOf(unitOfTime).format(defaultDateFormat);
};

/**
 * formats the end of week to defaultDateFormat
 *
 * @param unitOfTime moment.unitOfTime.StartOf
 * @param dateTime string
 */
export const endOfCurrent = (unitOfTime: moment.unitOfTime.StartOf, dateTime = null) => {
    const dt = dateTime || now();
    return moment(dt, defaultDateTimeFormat).endOf(unitOfTime).format(defaultDateFormat);
};

/**
 * formats the start of last unitOfTime to defaultDateFormat
 */
export const startOfWithUTC = (type: moment.unitOfTime.DurationConstructor, isNext, dateTime = null, amount = 1, time = false) => {
    const dt = dateTime || moment.utc();
    if (isNext) {
        return moment.utc(dt, defaultDateTimeFormat).add(amount, type).startOf(type).format(time ? null : defaultDateFormat);
    }
    return moment.utc(dt, defaultDateTimeFormat).subtract(amount, type).startOf(type).format(time ? null : defaultDateFormat);
};

/**
 * formats the start of last unitOfTime to defaultDateFormat
 */
export const startOf = (type: moment.unitOfTime.DurationConstructor, isNext, dateTime = null, amount = 1, time = false) => {
    const dt = dateTime || now();
    if (amount === 0) {
        return moment(dt, defaultDateTimeFormat).startOf(type).format(time ? null : defaultDateFormat);
    }
    if (isNext) {
        return moment(dt, defaultDateTimeFormat).add(amount, type).startOf(type).format(time ? null : defaultDateFormat);
    }
    return moment(dt, defaultDateTimeFormat).subtract(amount, type).startOf(type).format(time ? null : defaultDateFormat);
};

/**
 * formats the end of last unitOfTime to defaultDateFormat
 */
export const endOf = (type: moment.unitOfTime.DurationConstructor, isNext, dateTime = null, amount = 1, time = false) => {
    const dt = dateTime || now();
    if (isNext) {
        return moment(dt, defaultDateTimeFormat).add(amount, type).endOf(type).format(time ? null : defaultDateFormat);
    }
    return moment(dt, defaultDateTimeFormat).subtract(amount, type).endOf(type).format(time ? null : defaultDateFormat);
};

/**
 * Add days to a given date. Date is a string in defaultDateFormat.
 * @param date date as string to add days to
 * @param days days to add as number
 */
export const addDaysToDate = (date: string, days: number) => {
    const dateAsMoment = formatDateTimeToMoment(date, false);
    return dateAsMoment.add(days, 'days').format(defaultDateFormat);
};

export const convertDateTimeToInteger = (dateTime) => {
    return parseInt(moment(dateTime).format('YYYYMMDDHHmm'),10);
};

export const compareDates = (dateTimeA, dateTimeB, comparisonType: CompareDateEnum) => {
    switch (comparisonType) {
        case CompareDateEnum.isBefore:
            return moment(dateTimeA).isBefore(dateTimeB) ? 1 : 0;
        case CompareDateEnum.isSameOrBefore:
            return moment(dateTimeA).isSameOrBefore(dateTimeB) ? 1 : 0;
        case CompareDateEnum.isSame:
            return moment(dateTimeA).isSame(dateTimeB) ? 1 : 0;
        case CompareDateEnum.isAfter:
            return moment(dateTimeA).isAfter(dateTimeB) ? 1 : 0;
        case CompareDateEnum.isSameOrAfter:
            return moment(dateTimeA).isSameOrAfter(dateTimeB) ? 1 : 0;
        case CompareDateEnum.diffInMinutes:
            return moment(dateTimeA).diff(dateTimeB, 'minutes');
        case CompareDateEnum.diffInHours:
            return moment(dateTimeA).diff(dateTimeB, 'hours');
        case CompareDateEnum.diffInDays:
            return moment(dateTimeA).diff(dateTimeB, 'days');
        case CompareDateEnum.diffInMonths:
            return moment(dateTimeA).diff(dateTimeB, 'months');
    }
    return 0;
};

export const sortDate = (dateTimeA, dateTimeB, sortOrder = SortDirectionEnum.asc) => {
    getCurrentLanguageCode();
    if (compareDates(dateTimeA, dateTimeB, CompareDateEnum.isAfter)) {
        return sortOrder === SortDirectionEnum.asc ? 1 : -1;
    } else if(compareDates(dateTimeA, dateTimeB, CompareDateEnum.isBefore)) {
        return sortOrder === SortDirectionEnum.asc ? -1 : 1;
    } else {
        return 0;
    }
};

/**
 * Get difference of dates and formats the returned string accordingly.
 * The already existing function formatDateTimeInComparisonToToday returned the day difference + 1 day for delivery receipts.
 *
 * @param date string
 */
export const formatDateInComparisonToToday = (date) => {
    const todayDate = moment().startOf('day');
    const dateFormatted = formatDateToDefaultDateFormat(date, false);
    let timeDiff = compareDates(dateFormatted, today(), CompareDateEnum.diffInDays);
    timeDiff = typeof timeDiff === 'boolean' ? 0 : timeDiff;

    if (timeDiff === 0) {
        return todayLoc;
    }
    return moment(dateFormatted).from(todayDate);
};

/**
 * Get difference of dates in days.
 *
 * @param date : string
 * @returns number
 */
export const dateDifferenceToToday = (date): number => {
    const dateFormatted = formatDateToDefaultDateFormat(date, false);
    let timeDiff = compareDates(dateFormatted, today(), CompareDateEnum.diffInDays);
    timeDiff = typeof timeDiff === 'boolean' ? 0 : timeDiff;
    return Math.abs(timeDiff);
};

/**
 * Get a date before X days in default date format.
 * @param beforeXDays : number
 */
export const getDateBeforeXDays = (beforeXDays: number) => {
    return formatDateToDefaultDateFormat(moment().subtract(beforeXDays, 'days'));
};

/**
 * formats the date in comparison to today
 *
 * @param dateTime : string
 * @param isUTC : boolean
 */
export const formatDateTimeInComparisonToToday = (dateTime, isUTC = false) => {
    const dateTimeFormatted = formatDateToDefaultDateTimeFormat(dateTime, isUTC);
    const dateFormatted = formatDateToDefaultDateFormat(dateTime, isUTC);
    let timeDiff = compareDates(dateFormatted, today(), CompareDateEnum.diffInDays);
    timeDiff = typeof timeDiff === 'boolean' ? 0 : timeDiff;
    updateRelativeTimeLocalization(true);

    if (timeDiff === 0) {
        return todayLoc;
    }
    return moment(dateTimeFormatted).fromNow();

};

/**
 * Sets format for formatDateInComparisonToToday.
 * Must be called before relative time formatting to ensure correct the format is used.
 *
 * @param isToday boolean
 */
const updateRelativeTimeLocalization = (isToday = false) => {
    const relativeTime = {...relativeTimeLocalization};
    if (isToday) {
        relativeTime.s = todayLoc;
        relativeTime.ss = todayLoc;
        relativeTime.m = todayLoc;
        relativeTime.mm = todayLoc;
        relativeTime.h = todayLoc;
    }
    moment.updateLocale(CountryCodesEnum.DE, {relativeTime});
};

/**
 * formats the date in comparison to current time
 *
 * @param dateTime string
 * @param isUTC boolean
 */
export const formatDateInComparisonToTime = (dateTime, isUTC = false) => {
    const defaultDate = formatDateToDefaultDateTimeFormat(dateTime, isUTC);
    updateRelativeTimeLocalization(false);
    const returnVal = moment(defaultDate).fromNow();

    // prefix / suffix needs to be suppressed for "gerade eben"
    return returnVal.endsWith(relativeTimeLocalization.s) ? relativeTimeLocalization.s : returnVal;
};

/**
 * returns the options used for the ion
 */
export const getDefaultDateOptionForIonCalendar = (onlyFutureDates: boolean, onlyPastDates: boolean, inludingToday: boolean) => {
    getCurrentLanguageCode();

    const calendarOptions: any = {
        weekdays: moment.weekdaysShort(),
        monthPickerFormat: moment.monthsShort(),
        weekStart: 1,
        from: new Date(new Date().getFullYear() - 3,12)
    };
    if (onlyFutureDates) {
        calendarOptions.from = inludingToday ? new Date() : new Date(new Date().getDate() + 1);
    }
    if (onlyPastDates) {
        calendarOptions.to = inludingToday ? new Date() : new Date(new Date().getDate() - 1);
    }

    return calendarOptions;
};

/**
 * returns the options used for the ion
 */
export const getDefaultCalendarOptions = (onlyFutureDates: boolean, onlyPastDates: boolean, inludingToday: boolean) => {
    getCurrentLanguageCode();

    const calendarOptions: any = {
        format: defaultDateFormat,
        monthNames: moment.monthsShort(),
        firstDay: 1,
        minDate: new Date(new Date().getFullYear() - 3,12)
    };
    if (onlyFutureDates) {
        calendarOptions.minDate = inludingToday ? new Date() : new Date(new Date().getDate() + 1);
    }
    if (onlyPastDates) {
        calendarOptions.maxDate = inludingToday ? new Date() : new Date(new Date().getDate() - 1);
    }

    return calendarOptions;
};

/**
 * Returns a boolean that is true, when today is the first day of a month.
 */
export const isTodayFirstOfMonth = () => {
    const todayDate = today();
    const firstDayOfMonth = startOfCurrent('month', todayDate);
    return todayDate === firstDayOfMonth;
};

export const getStartOfCurrentMonth = () => {
    return moment().startOf('month').format(defaultDateFormat);
};

export const getLastOfCurrentMonth = () => {
    return moment().endOf('month').format(defaultDateFormat);
};

/**
 * Returns the last day of the month for the given date.
 *
 * @param date - The date for which to get the last day of the month.
 * @returns The last day of the month as a Date object.
 */
export const getLastOfTheMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0);

/**
 * ######################## default JS Date() functions #########################################
 */

/**
 * Returns an array of two dates, the first and the last day of the month.
 * @returns Array<Date> containing the start and end date of the specified month
 * @param startMonth number of the month to start with
 * @param endMonth number of the month to end with
 */
export const startEndOfMonths = (startMonth: number, endMonth: number): Array<Date> => {
    if (endMonth < startMonth) {
        throw new Error('endMonth must be later than startMonth');
    }

    const currentDate = new Date();
    const year = currentDate.getFullYear();

    const startDate = new Date(year, startMonth, 1);
    const endDate = new Date(year, endMonth, 1);

    return [startDate, endDate];
};


export const getDateFromTimeframe = (timeframe: UpdateTimeframeEnum): string => {
    switch (timeframe) {
        case UpdateTimeframeEnum.today:
            return today();
        case UpdateTimeframeEnum.one_day:
            return todayMinus(1);
        case UpdateTimeframeEnum.three_days:
            return todayMinus(3);
        case UpdateTimeframeEnum.seven_days:
            return todayMinus(7);
    }
};

export const convertYearMonthToGermanFormatted = (yearMonth: string): string => {
    return moment(yearMonth, "YYYYMM").locale('de').format("MMMM YYYY");
};
