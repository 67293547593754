import * as Sentry from '@sentry/angular';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { jwtDecode } from 'jwt-decode';

export type SentryPriority = 'low' | 'medium' | 'high' | 'critical';
export type SentryLevel = 'info' | 'warning' | 'error';

/**
 * Logs authentication-related messages to Sentry with detailed context
 * @param message Message to log
 * @param level Sentry log level
 * @param action Type of authentication action
 * @param priority Priority level for the message
 * @param extraData Additional context data
 */
export const logAuthSentryMessage = (
    message: string,
    level: SentryLevel = 'info',
    action: string = 'unknown',
    priority: SentryPriority = 'medium',
    extraData: Record<string, any> = {}
) => {
    const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
    const accessToken = localStorage.getItem(AuthStorageKeyEnum.accessToken);
    const refreshToken = localStorage.getItem(AuthStorageKeyEnum.refreshToken);

    let userId = null;
    let expiresAtAccessToken = -1;
    let expiresAtRefreshToken = -1;
    let error = null;

    try {
        if (accessToken) {
            const decodedAccessToken = jwtDecode(accessToken);
            userId = decodedAccessToken?.sub;
            expiresAtAccessToken = decodedAccessToken?.exp;
        }

        if (refreshToken) {
            expiresAtRefreshToken = jwtDecode(refreshToken)?.exp;
        }
    } catch (e) {
        console.error('Error decoding JWT token:', e);
        error = e;
    }

    const currentUrl = window.location.href;
    const callerStack = new Error().stack;

    Sentry.captureMessage(`<Tracking> ${message}`, {
        level,
        tags: {
            priority,
            action,
            hasAccessToken: !!accessToken,
            hasRefreshToken: !!refreshToken,
        },
        extra: {
            ...extraData,
            callerStack,
            userId,
            activePharmacy,
            currentUrl,
            expiresAtAccessToken,
            expiresAtRefreshToken,
            error,
        },
    });
};
