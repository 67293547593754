import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { DownloadTypesInterface } from '../../../interfaces/mea.interface';
import { formatStrapiDownloads } from '../utils';
import { StaticAppLocations } from '../../../enums/app-location.enum';

export const GetDownloads = (queryName) => gql`
    query ${queryName}($where: FilteredDownloadsInput!) {
        FilteredDownloads(where: $where) {
            id : documentId
            description
            label
            isPersonal
            downloadTypes {
                id : documentId
                type
                color
            }
            createdAt
            updatedAt
            file {
                url
            }
            personalDirectory {
                path
                filename
            }
        }
    }
`;

export const AllDownloadsQueries = [
    GetDownloads('test')
];

@Injectable()
export class DownloadQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getDownloads]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDownloadTypes]: QueryFetchPolicy.NETWORK_ONLY,
    };

    constructor(private apollo: Apollo, private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {
            [DCK.downloadChanged]: [FPK.getDownloads],
            [DCK.downloadTypeChanged]: [FPK.getDownloadTypes],
        });
    }

    public getDownloadsByAppLocation(appLocation: StaticAppLocations): Observable<DownloadTypesInterface[]> {
        const fetchPolicyKey = FPK.getDownloads;
        return this.apollo
            .watchQuery({
                query: GetDownloads(fetchPolicyKey),
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                variables: this.getMeaDownloadsVariables(appLocation),
            })
            .valueChanges.pipe(
                map((d) => formatStrapiDownloads(d?.data['FilteredDownloads'])),
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            );
    }

    public getMeaDownloadsVariables(appLocation: StaticAppLocations) {
        return {
            where: {
                appLocations: { type: appLocation },
            },
        };
    }
}
