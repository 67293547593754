import { AppContext } from './appContext';
import { BackendMessage } from './backendMessage';
import { ConversationIntent } from './conversationIntent';
import TicketStatus from './ticketStatus';
import TicketUpdater from './ticketUpdater';

export enum ActiveConversationProgressStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InDelivery = 'IN_DELIVERY',
  InProgress = 'IN_PROGRESS',
  ReadyForPickup = 'READY_FOR_PICKUP',
  AppointmentConfirmed = 'APPOINTMENT_CONFIRMED',
  AppointmentCompleted = 'APPOINTMENT_COMPLETED',
  AppointmentCancelled = 'APPOINTMENT_CANCELLED',
}

export enum PassiveConversationProgressStatus {
  New = 'NEW',
}

export type ConversationProgressStatus = ActiveConversationProgressStatus | PassiveConversationProgressStatus;

export interface ConversationProgressEventData {
  pickupDate?: string; // AWSDate
  pickupTime?: string; // AWSTime
  pickupNow?: boolean;
}

export interface ConversationProgressEvent {
  timestamp: number;
  status: ConversationProgressStatus;
  subStatus?: string;
  data?: ConversationProgressEventData;
}

export type ConversationStatusOption = Omit<ConversationProgressEvent, 'timestamp'>;

export interface TicketEvent {
  timestamp: number;
  updatedStatus: TicketStatus;
  updatedBy: TicketUpdater;
  accepted?: boolean;
}

export interface DeletionEntry {
  cognitoId: string;
  deletedAt: number;
}

export interface BackendConversation {
  id: string;
  createdAt: number;
  appContext?: AppContext;
  archivedByEnduser?: boolean;
  archivedByPharmacy?: boolean;
  deletionRecord?: DeletionEntry[];
  earliestExpirationTimestamp?: number;
  encryptedShoppingCart?: string;
  encryptedAppointment?: string;
  firstSegmentId?: string;
  intent?: ConversationIntent;
  lastMessage?: BackendMessage;
  progressHistory?: ConversationProgressEvent[];
  ticketHistory?: TicketEvent[];
  appointmentId?: string;
  hasPrescriptions?: boolean;
}

export type BackendConversationAttachments = Pick<
  BackendConversation,
  'encryptedShoppingCart' | 'encryptedAppointment' | 'appointmentId'
>;
