import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import { ReleaseNoteInterface } from '../../../interfaces/release-note.interface';
import { QueryFetchPolicy } from '../../../enums/api.enum';
import { today } from '../../../formatting/date.formatting';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { environment } from '../../../../../environments/environment';
import { formatStrapiData } from '../utils';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';

export const GetReleaseNotes = gql`
   query ReleaseNotes($limit: Int) {
	releaseNotes(
        pagination: {limit: $limit},
        sort: ["date:desc"],
        filters: {
            date: {lte: "${today()}"}
        }) {
            id : documentId
            version
            description
            date
            details {
                id : documentId
                title
                description
                type
            }
        }
    }
`;

export const GetReleaseNoteById = (queryName) => gql`
    query ${queryName}($documentId: ID) {
        releaseNotes(filters: { documentId: { eq: $documentId } }, pagination: { limit: 1 }) {
            id: documentId
            version
            description
            date
            details {
                title
                description
                type
            }
        }
    }
`;

export const AllReleaseNoteQueries = [
    GetReleaseNotes,
    GetReleaseNoteById('test')
];

@Injectable()
export class ReleaseNoteQueries extends QueryWrapper {
    fetchPolicies = {
        [FPK.getReleaseNoteById]: QueryFetchPolicy.CACHE_AND_NETWORK
    };

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar) {
        super(apollo, dataChangedVar, {});
    }

    public pollReleaseNotes(fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK): Observable<ReleaseNoteInterface[]> {
        return this.apollo.watchQuery({
                query: GetReleaseNotes,
                variables: {limit: GraphQLLimits.releaseNotes},
                fetchPolicy,
                pollInterval: environment.pollingInterval.releaseNotes
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['releaseNotes'] && d?.data['releaseNotes']))
            .pipe(map((data: Array<ReleaseNoteInterface>) => {
                return data && data[0] ? data.map(entry => {
                    const entryFormatted = formatStrapiData(entry);
                    return {
                        ...entryFormatted,
                        details: entryFormatted?.details ? entryFormatted.details.map(detail => formatStrapiData(detail)) : []
                    };
                }) : null;
            })) as Observable<ReleaseNoteInterface[]>;
    }

    public getReleaseNoteById(documentId: string): Observable<ReleaseNoteInterface> {
        const fetchPolicyKey = FPK.getReleaseNoteById;
        return this.apollo
            .watchQuery({
                query: GetReleaseNoteById(fetchPolicyKey),
                variables: { documentId },
                fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            })
            .valueChanges.pipe(map((d) => d?.data && d?.data['releaseNotes'] && d?.data['releaseNotes'][0]))
            .pipe(
                map((data: ReleaseNoteInterface) => {
                    return data
                        ? {
                              ...formatStrapiData(data),
                              details: data.details ? data.details.map((detail) => formatStrapiData(detail)) : [],
                          }
                        : null;
                })
            )
            .pipe(
                map((d) => {
                    if (d) this.updateFetchPolicy(fetchPolicyKey);
                    return d;
                })
            ) as Observable<ReleaseNoteInterface>;
    }
}
