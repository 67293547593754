import { Routes } from '@angular/router';
import { AccessGuard } from '../../guard/access.guard';
import { AccessRightEnum } from '../../core/enums/access-right.enum';
import { StaticPageTypeEnum } from '../../core/enums/static-page-type.enum';
import { FeatureFlagGuard } from '../../guard/feature-flag.guard';
import { AppRoutesEnum } from '../../core/enums/routes.enum';

export enum SettingsRoutesEnum {
    common = '/app/settings/common',
    profile = '/app/settings/profile',
    notifications = '/app/settings/notifications',
    newsletter = '/app/settings/newsletter',
    statistics = '/app/settings/statistics',
    security = '/app/settings/security',
    termsOfUse = '/app/settings/terms-of-use',
    privacy = '/app/settings/privacy',
    imprint = '/app/settings/imprint',
    tracking = '/app/settings/tracking',
    help = '/app/information/general',
    releaseNotes = '/app/settings/release-notes',
    administration = '/app/settings/user-administration'
}

export enum SettingsRoutesShortEnum {
    common = 'common',
    profile = 'profile',
    notifications = 'notifications',
    newsletter = 'newsletter',
    statistics = 'statistics',
    security = 'security',
    generalTerms = 'general-terms',
    termsOfUse = 'terms-of-use',
    privacy = 'privacy',
    imprint = 'imprint',
    tracking = 'tracking',
    help = 'help',
    releaseNotes = 'release-notes',
    administration = 'user-administration'
}

export const childrenRoutes: Routes = [
    {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
    },
    {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
    },
    {
        path: 'newsletter',
        loadChildren: () => import('./pages/newsletter/newsletter.module').then(m => m.NewsletterPageModule),
        canActivate: [FeatureFlagGuard]
    },
    {
        path: 'statistics',
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [AccessGuard],
        data: {accessRight: AccessRightEnum.statistics}
    },
    {
        path: AppRoutesEnum.noAccess,
        loadChildren: () => import('../common/no-access/no-access.module').then(m => m.NoAccessPageModule)
    },
    {
        path: 'security',
        loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityModule)
    },
    {
        path: 'tracking',
        loadChildren: () => import('./pages/tracking/tracking.module').then(m => m.TrackingModule)
    },
    {
        path: 'user-administration',
        loadChildren: () => import('./pages/user-administration/user-administration.module').then(m => m.UserAdministrationModule),
        canActivate: [AccessGuard],
        data: {accessRight: AccessRightEnum.userAdministration}
    },
    {
        path: 'release-notes',
        loadChildren: () => import('./pages/release-notes/release-notes.module').then(m => m.ReleaseNotesPageModule)
    },
    {
        path: 'general-terms',
        loadChildren: () => import('./../static-page/static-page.module').then(m => m.StaticPageModule),
        canActivate: [FeatureFlagGuard],
        data: {pageType: StaticPageTypeEnum.GENERAL_TERMS}
    },
    {
        path: 'terms-of-use',
        loadChildren: () => import('./../static-page/static-page.module').then(m => m.StaticPageModule),
        data: {pageType: StaticPageTypeEnum.TERMS_OF_USE}
    },
    {
        path: 'privacy',
        loadChildren: () => import('./../static-page/static-page.module').then(m => m.StaticPageModule),
        data: {pageType: StaticPageTypeEnum.DATA_POLICY}
    },
    {
        path: 'imprint',
        loadChildren: () => import('./../static-page/static-page.module').then(m => m.StaticPageModule),
        data: {pageType: StaticPageTypeEnum.IMPRINT}

    },
    {
        path: '',
        redirectTo: 'notifications',
        pathMatch: 'full'
    }
];
