import { Conversation, ConversationWithLastMessage } from './conversation';
import { ShopUserMessage } from './shopUserMessage';

export interface ShopOrder {
  type: 'ShopUserMessage';
  orderId: string;
  shopUserMessage: ShopUserMessage;
}

export interface ChatOrder {
  type: 'Conversation';
  orderId: string;
  conversation: Conversation;
}

export type Order = ChatOrder | ShopOrder;

export interface ChatOrderWithLastMessage extends ConversationWithLastMessage {
  type: 'ConversationWithLastMessage';
  orderId: string;
}

export type OrderWithLastMessage = ChatOrderWithLastMessage | ShopOrder;

export function orderIsShopOrder(order: Order | OrderWithLastMessage): order is ShopOrder {
  return order.type === 'ShopUserMessage';
}

export function orderIsArchived(order: Order | OrderWithLastMessage) {
  return orderIsShopOrder(order) ? order.shopUserMessage.archivedByPharmacy : order.conversation.archivedByPharmacy;
}
