import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  query getAllShopUserMessages($nextToken: String) {
      getAllShopUserMessages(nextToken: $nextToken) {
      __typename
      shopUserMessages {
        ${shopUserMessageAttributes}
      }
      nextToken
    }
  }
`;
