import { ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, Event } from '@angular/router';
import { filter, Subscription } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';

import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

import { EnvironmentService, SconnectAuthService } from './core/core.services';
import { convertFromMillisecondsToLocalTime, compareDates, now } from './core/core.formatting';
import { AppRoutesEnum, AuthStorageKeyEnum, CompareDateEnum } from './core/core.enums';
import { NavigationRoutesEnum } from './navigation/navigation.routes';
import { PharmacyStoreStateVar } from './core/store/locals/pharmacyStoreState.var';
import { unsubscribe } from './core/core.utils';
import { logAuthSentryMessage } from './core/util/sentryMessages.util';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);
    private storage = inject(Storage);
    private router = inject(Router);
    private platform = inject(Platform);
    private environmentService = inject(EnvironmentService);
    private sconnectAuthService = inject(SconnectAuthService);
    private cdr = inject(ChangeDetectorRef);
    private primeNgConfig = inject(PrimeNGConfig);

    routerSubscription: Subscription;
    isDashboard = false;
    isMessageVisible = false;

    pharmacyStores = this.pharmacyStoreStateVar.pharmacyStoresState();

    @HostListener('window:focus', ['$event'])
    onFocus(): void {
        this.setDisplayToast(true);
        this.checkToken();
    }

    @HostListener('window:blur', ['$event'])
    onBlur(): void {
        this.setDisplayToast(false);
    }

    constructor() {
        this.setDisplayToast(true);
        this.initializeApp();
    }

    /**
     * Do startup checks and initializing of the app.
     */
    initializeApp() {
        this.isDashboard = window.location.pathname !== NavigationRoutesEnum.dashboard;
        unsubscribe(this.routerSubscription);
        this.routerSubscription = this.router.events
            .pipe(filter( event => event instanceof NavigationEnd))
            .subscribe((event: Event|RouterEvent) => {
            if (event instanceof RouterEvent && event.url) {
                this.isDashboard = event.url === NavigationRoutesEnum.dashboard || event.url === AppRoutesEnum.app;
            }
        });
        // Check every 20s if a new app version is available
        void this.environmentService.initializeChecks();
        if (this.checkToken() && localStorage.getItem(AuthStorageKeyEnum.refreshToken)) {
            if (this.pharmacyStores && this.pharmacyStores.length === 0) {
                void this.router.navigateByUrl(AppRoutesEnum.waiting);
            }
        }
        this.environmentService.checkCacheVersion().then(() => {
            this.platform.ready().then(() => {
                EnvironmentService.appInfo();
            });
        });

        /**
         * Set german translations for primeng components
         */
        this.primeNgConfig.setTranslation({
            dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag','Samstag'],
            dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr','Sa'],
            dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr','Sa'],
            monthNames: ['Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember'],
            monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun','Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        });
    }

    setDisplayToast(value: boolean) {
        void this.storage.set('displayToasts', value);
    }

    /**
     * Check if the user has a token (is logged in) otherwise reset the state and redirect to login
     */
    checkToken() {
        const accessToken = localStorage.getItem(AuthStorageKeyEnum.accessToken);
        const refreshToken = localStorage.getItem(AuthStorageKeyEnum.refreshToken);
        const getExpiredAt = () => {
            const expiresAtFromLocalStorage = localStorage.getItem(AuthStorageKeyEnum.expiresAt);
            return expiresAtFromLocalStorage ? convertFromMillisecondsToLocalTime(
                parseInt(expiresAtFromLocalStorage, 10),
                false
            ) : null;
        };
        let expiresAt = getExpiredAt();

        if(
            !accessToken || (accessToken && accessToken.trim().length === 0) ||
            !refreshToken || (refreshToken && refreshToken.trim().length === 0)  ||
            !expiresAt ||  compareDates(expiresAt, now(), CompareDateEnum.diffInMinutes) <= 0
        ) {
            if (window.location.pathname !== AppRoutesEnum.login
            && window.location.pathname !== AppRoutesEnum.loginCallback) {
                window.localStorage.setItem(AppRoutesEnum.redirectUrl, window.location.pathname);
                if (!accessToken && !refreshToken) {
                    void this.router.navigateByUrl(AppRoutesEnum.login);
                } else {
                    this.sconnectAuthService.checkAccessToken().then((isSuccessful) => {
                        // you don't have to logout users globally whose token has already been expired
                        expiresAt = getExpiredAt();
                        const isExpired = expiresAt && compareDates(expiresAt, now(), CompareDateEnum.diffInMinutes) <= 0;
                        if (!isSuccessful || isExpired) {
                            logAuthSentryMessage(
                                'Token check failed',
                                'warning',
                                'check_token_logout',
                                'medium',
                            );

                            void this.sconnectAuthService.logout(false, !isExpired);
                        }
                    });
                }
                return false;
            }
        }
        return true;
    }

    /**
     * Triggered if a fullpage message is visible
     */
    onMessageVisible(isMessageVisible){
        this.isMessageVisible = isMessageVisible;
        this.cdr.detectChanges();
    }
}
