import { Injectable } from '@angular/core';
import { DocumentQueries } from '../store/graphql/queries/documents.graphql';
import { ToastService } from './toast.service';
import { unsubscribe } from '../core.utils';
import { DocumentInterface } from '../interfaces/document.interface';
import { AuthStorageKeyEnum } from '../enums/authStorageKey.enum';
import { DocumentPdfFilenamePrefix, DocumentSubType, DocumentType } from '../enums/documents.enum';

@Injectable({
    providedIn: 'root',
})
export class DocumentService {

    constructor(
        private documentQueries: DocumentQueries,
        private toastService: ToastService
    ) { }

    /**
     * Gets the pfd as integer array
     *
     * @param id - The id of the document
     */
    async getDeliveryDocumentPdf(id: number) : Promise<{result: number[], error: string}> {
        return new Promise<{result: number[], error: string}>((resolve) => {
            const documentSubscription = this.documentQueries.getDocumentsPdf(id).subscribe(pdf => {
                unsubscribe(documentSubscription);
                if (pdf && pdf.data && pdf.data.length > 0) {
                    resolve({result: pdf.data.split(',').map(n => parseInt(n, 10)), error: null});
                } else {
                    this.toastService.presentError(
                        pdf && pdf['message'] ?
                            pdf['message'] :
                            'Lieferschein konnte nicht abgerufen werden. In Sanacorp Connect sind nur die Lieferscheine der letzten 2 Jahre verfügbar.'
                    ).then(() => resolve({result: [], error: pdf['message']}));
                }
            });
        });
    }

    getDocumentDownloadFilename(document: DocumentInterface, childRecDate: string = null): string {
        const activePharmacy = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
        const documentType = document.documentType;
        const documentSubType = document.documentSubType;
        let filenamePrefix = '';

        if (documentType === DocumentType.LS) {
            filenamePrefix = DocumentPdfFilenamePrefix.LS;
        } else {
            switch (documentSubType) {
                case DocumentSubType.CHARTIER:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_TIER;
                    break;
                case DocumentSubType.CHARBLUT:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_BLUT;
                    break;
                case DocumentSubType.CHARSECU:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_SECU;
                    break;
                case DocumentSubType.CHARDOKU:
                    filenamePrefix = DocumentPdfFilenamePrefix.CH_DOKU;
                    break;
                case DocumentSubType.E_SR:
                    filenamePrefix = DocumentPdfFilenamePrefix.E_SR;
                    break;
                case DocumentSubType.E_TR:
                    filenamePrefix = DocumentPdfFilenamePrefix.E_TR;
                    break;
            }
        }

        let filename = childRecDate ? childRecDate : document.recDate;
        filename += activePharmacy ? '_' + activePharmacy : '';
        filename += filenamePrefix ? filenamePrefix : '';
        filename += document.documentNumber ? document.documentNumber : '';
        filename += '_SC.pdf';
        return filename;
    }
}
