import { Dictionary } from 'ts-essentials';
import { AddonStatus } from './addonStatus';
import { AddonType } from './addonType';
import { CustomUsername } from './customUsername';
import { InitialMessages } from './initialMessages';
import { EmergencyOpeningStatus, PharmacyHolidaySettings, PharmacyVacation } from './openingHours';
import Pharmacy, { PharmacyWithChatUser } from './pharmacy';
import UserStatus from './userStatus';
import { UserType } from './userType';

export type PushRepetition = '300' | '600' | '900' | '1200' | null;

export const isPharmacyChatUser = (user: ChatUser): boolean => user.userType === UserType.PharmacyChatUser;

export interface AppNotificationSettingsEntry {
  token: string;
  isEnabled: boolean;
  onlyDuringOpeningTimes?: boolean;
}

export type AppNotificationSettings = AppNotificationSettingsEntry[];

export interface ChatUser {
  cognitoId: string;
  userType: UserType.EndUser | UserType.PharmacyChatUser;
  isGuest: boolean;
  registered: boolean;
  userStatus: UserStatus;
  publicKey: string;
  privateKey?: string;
  encryptionSalt?: string;

  pushNotificationSubscription?: string;
  emailNotification?: boolean;

  // registered end user and pharmacy user
  cognitoUsername?: string;
  encryptedPrivateKey?: string;

  // end user
  chatname?: string;
  deleteAccountAt?: number;
  consentToDataTransfer?: boolean;
  consentToDataTransferVersion?: string;
  ads?: boolean;
  favoritePharmaciesIds?: string[];
  favoritePharmacies?: Pharmacy[];
  guestNotificationEmail?: string;

  // pharmacy user
  pharmacyId?: string;
  pharmacy?: PharmacyWithChatUser;
  pushNotificationRepetitionInterval?: PushRepetition;

  initialMessages?: InitialMessages;

  favoritedByAppUserIds?: string[];
  favoritedByAppUserCountAtStartOfMonth?: number;
  addons?: Dictionary<AddonStatus, AddonType>;
  productCardsSentLast30Days?: number;
  resetKeyPairOnNextLogin?: boolean;
  customAppointmentTypes?: string[];
  hiddenDefaultAppointmentTypes?: string[];
  migratedToAppointmentsV2?: boolean;
  addedHasPrescriptionsToConversations?: boolean;
  holidays?: PharmacyHolidaySettings;
  vacation?: [PharmacyVacation];
  emergencyOpeningStatus?: EmergencyOpeningStatus;
  customPickupTimes?: string[];

  //app user
  isAppUser?: boolean;
  appNotificationSettings?: AppNotificationSettings;
  favoritePharmaciesCustomerIds?: string[];
  contentGroup?: string;
  customUsername?: CustomUsername;
}
