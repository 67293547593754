import { Injectable } from '@angular/core';
import { gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';

import {
    AuthStorageKeyEnum,
    DataChangedKeys as DCK,
    DeliveryReceiptOrderTypeFormatted,
    DocumentType,
    FetchPolicyKeys as FPK,
    QueryFetchPolicy,
    SortDirectionEnum
} from '../../../core.enums';
import {
    DocumentFiltersInterface,
    DocumentInterface,
    DocumentNumberSelectionInterface
} from '../../../interfaces/document.interface';
import { DocumentSortInterface } from '../../../interfaces/sorting.interface';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { checkForMinDate } from '../../../config/logistics.config';
import { getDateFromConfig, getDateRangesWithoutDates } from '../../../config/date-range.config';

import {
    DeliveryReceiptSubTypeAllConfig,
    formatOrderTypes,
    orderTypeFilter
} from '../../../config/delivery-receipt.config';
import { QueryWrapper } from '../query.wrapper';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { defaultInvoiceSorting } from '../../../config/sorting.config';

export const GetDocumentPdfById = gql`
    query GetDocumentPdfById($documentId: Int!) {
        DocumentPdfById(documentId: $documentId) {
            status
            message
            data
        }
    }
`;

export const GetDocuments = (queryName) => gql`
    query ${queryName}($apiUser: String!, $offset: Int, $limit: Int, $order_by: [document_order_by!], $where: document_bool_exp) {
        pharmacyStore: pharmacyStoreDocuments(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            documents(offset: $offset, limit: $limit, order_by: $order_by, where: $where)  {
                id
                documentId
                documentNumber
                documentSubType
                documentType
                orderType
                isCancelled
                recDate
            }
        }
    }
`;

export const GetDocumentsWithChild = (queryName) => gql`
    query ${queryName}($apiUser: String!, $offset: Int, $limit: Int, $order_by: [document_order_by!], $where: document_bool_exp) {
        pharmacyStore: pharmacyStoreDocuments(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            documents(offset: $offset, limit: $limit, order_by: $order_by, where: $where)  {
                id
                documentId
                documentNumber
                documentSubType
                documentType
                orderType
                isCancelled
                recDate
                documentChildren {
                    id
                    childId: id
                    childDocumentId : documentId
                    documentId
                    documentSubType
                }
            }
        }
    }
`;

export const GetDocumentByDocumentId = (queryName) => gql`
    query ${queryName}($apiUser: String!, $offset: Int, $documentId: Int!) {
        pharmacyStore: pharmacyStoreDocuments(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            documents(offset: $offset, limit: 1, where: {documentId: {_eq: $documentId}})  {
                id
                documentId
                documentNumber
                documentSubType
                documentType
                orderType
                isCancelled
                recDate
            }
        }
    }
`;

export const GetDocumentByPk = (queryName) => gql`
    query ${queryName}($id: Int!) {
        document_by_pk(id: $id)  {
            id
            documentId
            documentNumber
            documentSubType
            documentType
            orderType
            isCancelled
            recDate
        }
    }
`;

export const GetDocumentNumberCollection = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $search: [document_bool_exp!], $documentType: String) {
        pharmacyStore: pharmacyStoreDocuments(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            documents(limit: $limit, distinct_on:[documentNumber], order_by: [{documentNumber: asc}],
                where: {_and:[{documentNumber: {_is_null: false}}, {documentNumber: {_neq: "null"}}, {_or: $search}, {documentType: {_eq: $documentType}}]}) {
                documentNumber
            }
        }
    }
`;

export const GetDocumentNumberAndDateCollection = (queryName) => gql`
    query ${queryName}($apiUser: String!, $limit: Int, $where: document_bool_exp) {
        pharmacyStore: pharmacyStoreDocuments(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            documents(limit: $limit, distinct_on:[documentNumber], order_by: [{documentNumber: asc}], where: $where) {
                documentNumber
                recDate
            }
        }
    }
`;

export const DocumentsCount = (queryName) => gql`
    query ${queryName}($where: document_bool_exp) {
        document_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

export const DocumentFilterResultsCount = (queryName) => gql`
    query ${queryName}($where: documentFilterResult_bool_exp) {
        documentFilterResult_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;

const GetDocumentFilterResult = (queryName) => gql`
    query ${queryName}($apiUser: String!, $offset: Int, $limit: Int, $order_by: [documentFilterResult_order_by!], $where: documentFilterResult_bool_exp) {
        pharmacyStore: pharmacyStoreDocuments(where: {apiUser: {_eq: $apiUser}}, limit: 1) {
            documentFilterResults(offset: $offset, limit: $limit, order_by: $order_by, where: $where) {
                document {
                    id
                    documentId
                    documentNumber
                    documentSubType
                    documentType
                    isCancelled
                    orderType
                    recDate
                    documentChildren {
                        id
                        childId: id
                        childDocumentId : documentId
                        documentId
                        documentSubType
                    }
                }
            }
        }
    }
`;

const GetDocumentFiltersStatus = (queryName) => gql`
    query ${queryName}($limit: Int) {
        documentFilterStatus(limit: $limit) {
            isFinished
            errorMessage
        }
    }
`;

export const AllDocumentQueries = [
    GetDocumentPdfById,
    GetDocuments('test'),
    GetDocumentsWithChild('test'),
    GetDocumentByDocumentId('test'),
    GetDocumentByPk('test'),
    GetDocumentNumberCollection('test'),
    GetDocumentNumberAndDateCollection('test'),
    DocumentsCount('test'),
    DocumentFilterResultsCount('test'),
    GetDocumentFilterResult('test'),
    GetDocumentFiltersStatus('test')
];

@Injectable()
export class DocumentQueries extends QueryWrapper {

    fetchPolicies = {
        [FPK.getDocumentById]: QueryFetchPolicy.NETWORK_ONLY,

        [FPK.getDocumentsLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentsWithChildLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentsCountLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getAllDocumentsCountLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentFilterResultLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentFilterResultCountLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentFilterStatusLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentByDocumentIdLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentNumberCollectionLS]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentNumberAndDateCollectionLS]: QueryFetchPolicy.NETWORK_ONLY,

        [FPK.getDocumentsRE]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentsWithChildRE]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentsCountRE]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getAllDocumentsCountRE]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentByDocumentIdRE]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentNumberCollectionRE]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getDocumentNumberAndDateCollectionRE]: QueryFetchPolicy.NETWORK_ONLY
    };
    ignoredPolicies = [
        FPK.getDocumentFilterResultCountLS,
        FPK.getDocumentFilterStatusLS,
        FPK.getDocumentFilterResultLS
    ];

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.documentDeliveriesChanged]: [
                FPK.getDocumentById,
                FPK.getDocumentsLS,
                FPK.getDocumentsWithChildLS,
                FPK.getDocumentsCountLS,
                FPK.getAllDocumentsCountLS,
                FPK.getDocumentByDocumentIdLS,
                FPK.getDocumentNumberCollectionLS,
                FPK.getDocumentNumberAndDateCollectionLS
            ],
            [DCK.documentInvoicesChanged]: [
                FPK.getDocumentById,
                FPK.getDocumentsRE,
                FPK.getDocumentsWithChildRE,
                FPK.getDocumentsCountRE,
                FPK.getAllDocumentsCountRE,
                FPK.getDocumentByDocumentIdRE,
                FPK.getDocumentNumberCollectionRE,
                FPK.getDocumentNumberAndDateCollectionRE
            ],
            [DCK.documentSearchChanged]: [
                FPK.getDocumentFilterResultCountLS,
                FPK.getDocumentFilterStatusLS,
                FPK.getDocumentFilterResultLS
            ],
        });
    }
    static isLS(filters: DocumentFiltersInterface) {
        return filters.documentType === 'LS';
    }

    /**
     * Create a where from the given document filters. This includes documentChild as well.
     *
     * @param _filters - the document filters
     * @param hasChildren - has the document children?
     * @param isCount - is this a count query?
     */
    static createFiltersWhere(
        _filters: DocumentFiltersInterface,
        hasChildren = false,
        isCount = false
    ) {
        const filters = {..._filters}; // necessary to break reference to original filters object, else the "search" filter will be deleted
        const queryFilters = [];
        delete filters.search;

        // filtering of documents without children
        if (filters.orderType && filters.orderType !== DeliveryReceiptOrderTypeFormatted.ALL) {
            queryFilters.push({orderType: orderTypeFilter(filters.orderType)});
        }

        if (filters.documentType && filters.documentType !== DocumentType.ALL) {
            queryFilters.push({documentType: {_eq: filters.documentType}});
        }

        const subTypeFilter = DocumentQueries.getDocumentChildrenFilters(filters, hasChildren, isCount);
        if (subTypeFilter) {
            queryFilters.push(subTypeFilter);
        }

        if (filters.documentNumber) {
            queryFilters.push({documentNumber: {_eq: filters.documentNumber}});
        }

        if (filters.recDateOption
            && !getDateRangesWithoutDates(filters.recDateOption)
            && filters.recDateFrom && filters.recDateTo
        ) {
            queryFilters.push({recDate: {_gte: checkForMinDate(filters.recDateFrom)}});
            queryFilters.push({recDate: {_lte: filters.recDateTo}});
        } else {
            const fromDate = getDateFromConfig(filters.recDateOption, true, true);
            const toDate = getDateFromConfig(filters.recDateOption, false, true);
            if(fromDate && toDate) {
                queryFilters.push({recDate: {_gte: fromDate}});
                queryFilters.push({recDate: {_lte: toDate}});
            }
        }

        let where = {};
        if (queryFilters.length > 0) {
            where = {_and: queryFilters};
        }

        return where;
    }

    /**
     * get subtype filters
     *
     * @param filters - the document filters
     * @param hasChildren - has the document children?
     * @param isCount - is this a count query?
     *
     * return object
     */
    static getDocumentChildrenFilters(filters, hasChildren, isCount) {
        const getDocumentChildrenFilter = (filter = null) => ({
            documentChildren_aggregate: {count: {
                predicate: {_gt: 0},
                ...filter
            }}
        });

        // documentChildren is better for calling of count-queries and documentChildren_aggregate has fewer costs for data calling
        if (filters.documentSubType && filters.documentSubType !== DeliveryReceiptSubTypeAllConfig.id) {
            const subTypeFilter = {documentSubType: {_in: filters.documentSubType.split(',')}};
            return hasChildren ? isCount ? {documentChildren: subTypeFilter} : getDocumentChildrenFilter({filter: subTypeFilter}) : subTypeFilter;
        } else if (filters.hasBatchDocument) {
            // if a documentSubType is filtered, only documents with batches will be displayed
            return isCount ? {documentChildren: {id: {_is_null: false}}} :getDocumentChildrenFilter();
        }
        return null;
    }

    /**
     * Create document_bool_expression for searchString.
     * @param searchString - the search string
     * @private
     */
    static documentCollectionSearchVars(searchString: string): object {
        // Fill the string with underscores and replace the 0s with empty string
        // for correct pzn search
        const searchQuery = [];
        if (searchString && searchString.length > 0) {
            const searchStringFormatted = searchString.trim().replace('-', '').replace(/^0+/g, '');
            searchQuery[0] = {documentNumber: {_ilike: searchStringFormatted + '%'}};
            if (searchString.length <= 10) {
                searchQuery[1] = {documentNumber: {_ilike: '0' + searchStringFormatted + '%'}};
                searchQuery[2] = {documentNumber: {_ilike: '00' + searchStringFormatted + '%'}};
            }
        } else {
            searchQuery.push({documentNumber: {_ilike:'%'}});
        }
        return searchQuery;
    }

    public getDocuments(
        filters: DocumentFiltersInterface,
        offset = 0,
        limit = 0,
        orderBy: DocumentSortInterface = {
            field: defaultInvoiceSorting.fieldName,
            direction: defaultInvoiceSorting.sortDirection
        },
        hasChildren = false
    ): Observable<DocumentInterface[]> {
        const variables = this.createFilterVariables(
            filters,
            orderBy,
            hasChildren,
            limit,
            offset
        );

        const fetchPolicyKey = hasChildren ?
            FPK.getDocumentsWithChildLS :
            (DocumentQueries.isLS(filters) ? FPK.getDocumentsLS : FPK.getDocumentsRE);
        return this.apollo.watchQuery({
            query: hasChildren ? GetDocumentsWithChild(fetchPolicyKey) : GetDocuments(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables,
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] ?
                d?.data['pharmacyStore'][0]['documents'] : []))
            .pipe(map((documentArray: DocumentInterface[]) => this.mapDocuments(documentArray)))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<DocumentInterface[]>;
    }

    /**
     * Get amount of available documents
     * @param filters - the document filters
     * @param hasChildren - has the document children?
     */
    public getDocumentsCount(
        filters: DocumentFiltersInterface,
        hasChildren = false
    ): Observable<number> {
        const variables = {};

        const newFilters = DocumentQueries.createFiltersWhere(filters, hasChildren, true);
        if (newFilters && newFilters['_and']) {
            variables['where'] = {...variables['where'], ...newFilters};
        }

        let fetchPolicyKey = DocumentQueries.isLS(filters) ? FPK.getDocumentsCountLS : FPK.getDocumentsCountRE;
        let query = DocumentsCount(fetchPolicyKey);
        let aggregate = 'document_aggregate';
        if (filters?.search) {
            fetchPolicyKey = FPK.getDocumentFilterResultCountLS;
            query = DocumentFilterResultsCount(fetchPolicyKey);
            aggregate = 'documentFilterResult_aggregate';
            variables['where']['_and'] = variables['where']['_and'].map(filterObj => ({document: filterObj}));
        }


        return this.apollo.watchQuery({
            query,
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data[aggregate]
              && d?.data[aggregate]['aggregate'] && d?.data[aggregate]['aggregate']['count']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<number>;
    }

    /**
     * Get amount of available documents
     * @param documentType - Type of document
     */
    public getAllDocumentsCount(
        documentType = DocumentType.LS
    ): Observable<number> {
        const variables = {};
        variables['where'] = {documentType: {_eq: documentType}};


        const fetchPolicyKey = DocumentQueries.isLS({documentType}) ? FPK.getAllDocumentsCountLS : FPK.getAllDocumentsCountRE;
        return this.apollo.watchQuery({
            query: DocumentsCount(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data[ 'document_aggregate']
              && d?.data[ 'document_aggregate']['aggregate'] && d?.data[ 'document_aggregate']['aggregate']['count']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<number>;
    }

    public getDocumentFilterResult(
        offset = 0,
        limit = 0,
        filters: DocumentFiltersInterface,
        orderBy: DocumentSortInterface = {
            field: defaultInvoiceSorting.fieldName,
            direction: defaultInvoiceSorting.sortDirection
        },
        hasChildren = false
    ): Observable<DocumentInterface[]> {
        const variables = this.createFilterVariables(
            filters,
            orderBy,
            hasChildren,
            limit,
            offset
        );
        variables['order_by'] = variables['order_by'].map(orderObj => ({document: orderObj}));
        variables['where']['_and'] = variables['where']['_and'].map(filterObj => ({document: filterObj}));

        const fetchPolicyKey = FPK.getDocumentFilterResultLS;
        return this.apollo.watchQuery({
            query: GetDocumentFilterResult(fetchPolicyKey),
            variables,
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d.data['pharmacyStore'][0]['documentFilterResults'] ?
                d.data['pharmacyStore'][0]['documentFilterResults'] :
                []))
            .pipe(map(filterResults => (filterResults.map(filterResult => (filterResult.document)))))
            .pipe(map((documents: DocumentInterface[]) => this.mapDocuments(documents)))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<DocumentInterface[]>;
    }

    public getDocumentsPdf(
        documentId: number,
        fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.NETWORK_ONLY
    ): Observable<{ data: string, id: number }> {
        return this.apollo.watchQuery({
            query: GetDocumentPdfById,
            variables: {documentId},
            fetchPolicy
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['DocumentPdfById'])) as Observable<{ data: string, id: number }>;
    }

    public getDocumentById(id: number): Observable<DocumentInterface> {

        const fetchPolicyKey = FPK.getDocumentById;
        return this.apollo.watchQuery({
            query: GetDocumentByPk(fetchPolicyKey),
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
            variables: {id}
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['document_by_pk']))
            .pipe(
                map((document: DocumentInterface) => this.mapDocuments([document])[0])
            )
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<DocumentInterface>;
    }

    public getDocumentByDocumentId(origDocumentId: number): Observable<DocumentInterface[]> {
        // TODO DON'T USE THIS FUNCTION LIKE THIS  - invoices and deliveries may have the same number
        const fetchPolicyKey = FPK.getDocumentByDocumentIdLS;
        return this.apollo.watchQuery({
            query: GetDocumentByDocumentId(fetchPolicyKey),
            variables: {documentId: origDocumentId, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] ?
                d?.data['pharmacyStore'][0]['documents'] : []))
            .pipe(
                map((document: DocumentInterface[]) => this.mapDocuments(document))
            )
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<DocumentInterface[]>;
    }

    /**
     * Listen for changes of the document filtering
     */
    public getDocumentFiltersStatus(): Observable<{ errorMessage: string, isFinished: boolean }> {
        const fetchPolicyKey = FPK.getDocumentFilterStatusLS;
        return this.apollo.watchQuery({
            query: GetDocumentFiltersStatus(fetchPolicyKey),
            variables: {limit: GraphQLLimits.defaultLimit},
            fetchPolicy:this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['documentFilterStatus']))
            .pipe(map(documentFilterStatus => documentFilterStatus ? documentFilterStatus[0] : documentFilterStatus))
            .pipe(map(
                (d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})
            ) as Observable<{ errorMessage: string, isFinished: boolean }>;
    }

    /**
     * Return an array of document numbers
     */
    public getDocumentNumberCollection(
        searchString: string = '',
        documentType = DocumentType.LS
    ): Observable<string[]> {
        const searchVars = DocumentQueries.documentCollectionSearchVars(searchString);

        const fetchPolicyKey = DocumentQueries.isLS({documentType}) ? FPK.getDocumentNumberCollectionLS : FPK.getDocumentNumberCollectionRE;
        const documentQuery : Observable<Array<DocumentInterface>> = this.apollo.watchQuery({
            query: GetDocumentNumberCollection(fetchPolicyKey),
            variables: {
                limit: GraphQLLimits.documents,
                search: searchVars, documentType,
                apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)
            },
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] ?
                d?.data['pharmacyStore'][0]['documents'] :
                []))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<Array<DocumentInterface>>;
        return documentQuery.pipe(map((documents: Array<DocumentInterface>) =>
                (!documents ? [] : documents.map(document => document.documentNumber))
            )) as Observable<string[]>;
    }

    public getDocumentNumberAndDateCollection(
        searchString: string = '',
        documentType = DocumentType.LS,
        fromDate: string = null
    ): Observable<Array<DocumentNumberSelectionInterface>> {
        const searchVars = DocumentQueries.documentCollectionSearchVars(searchString);

        const whereObj = {};
        const filterVars = [
            {documentNumber: {_is_null: false}},
            {documentNumber: {_neq: 'null'}},
            {_or: searchVars},
            {documentType: {_eq: documentType}}
        ];
        if (fromDate) {
            whereObj['_and'] = [...filterVars, {recDate: {_gt: fromDate}}];
        } else {
            whereObj['_and'] = [...filterVars];
        }

        const fetchPolicyKey = DocumentQueries.isLS({documentType}) ?
            FPK.getDocumentNumberAndDateCollectionLS :
            FPK.getDocumentNumberAndDateCollectionRE;
        return this.apollo.watchQuery({
            query: GetDocumentNumberAndDateCollection(fetchPolicyKey),
            variables: {limit: GraphQLLimits.documents, where: whereObj, apiUser: localStorage.getItem(AuthStorageKeyEnum.activePharmacy)},
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey)
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['pharmacyStore'] && d?.data['pharmacyStore'][0] ?
                d?.data['pharmacyStore'][0]['documents'] :
                []))
            .pipe(map((documents: Array<DocumentInterface>) => (
                (!documents ? [] : documents)
            )))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<Array<DocumentNumberSelectionInterface>>;
    }

    private mapDocuments(documents: DocumentInterface[]): DocumentInterface[] {
        return documents ?
            documents.map(doc => ({...doc, ...{
                orderTypeFormatted: doc && doc.orderType ? formatOrderTypes(doc.orderType) : null
            }})) : [];
    }

    /**
     * Helper function to construct filter variables for documents query.
     *
     * @param _filters - The filters to apply
     * @param hasChildren - Whether the document has children
     * @param orderBy - Order by field and direction
     * @param limit - Maximum number of items to return
     * @param offset - Offset for pagination
     * @private
     */
    private createFilterVariables(
        _filters: DocumentFiltersInterface,
        orderBy: DocumentSortInterface = {
            field: defaultInvoiceSorting.fieldName,
            direction: defaultInvoiceSorting.sortDirection
        },
        hasChildren: boolean,
        limit = 0,
        offset = 0) {

        const filters = {..._filters}; // necessary to break reference to original filters object, else the "search" filter will be deleted
        const variables = {};
        const orderByObj = {};
        delete filters.search;
        orderByObj[orderBy['field']] = orderBy.direction;
        variables['order_by'] = [orderByObj, {documentNumber: SortDirectionEnum.desc}];
        variables['apiUser'] = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);

        if(limit > GraphQLLimits.documents) {
            limit = GraphQLLimits.documents;
            console.error('Limit above maximum!');
        }

        if (limit > 0) {
            variables['limit'] = limit;
        }

        if (offset > 0) {
            variables['offset'] = offset;
        }

        const newFilters = filters ? DocumentQueries.createFiltersWhere(filters, hasChildren, false) : null;
        if (newFilters && newFilters['_and']) {
            variables['where'] = newFilters;
        }

        return variables;
    }
}
