import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'flatMap'
})
export class FlatMapPipe implements PipeTransform {
    transform(elements: Array<any>, key: string): Array<any> {
        return elements.flatMap(item => item?.[key]) || [];
    }
}
