import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { v4 as uuid } from 'uuid';
import { Logger } from '../../../../essentials/util/src/logger';

const DEFAULT_KEY_SIZE = '1024';
const logger = new Logger('EncryptionService');

@Injectable({ providedIn: 'root' })
export class EncryptionService {
  private encrypt = new JSEncrypt({ default_key_size: DEFAULT_KEY_SIZE });

  createKeyPair(): { privateKey: string; publicKey: string } {
    const keyPair = new JSEncrypt({ default_key_size: DEFAULT_KEY_SIZE });
    const privateKey = keyPair.getPrivateKey();
    const publicKey = keyPair.getPublicKey();
    return { privateKey, publicKey };
  }

  generateOneTimehashPassword(): string {
    return uuid();
  }

  encryptUsingPassword(text: string, password: string): string {
    try {
      return CryptoJS.AES.encrypt(text, password).toString();
    } catch (err) {
      return '';
    }
  }

  decryptUsingPassword(encryptedText: string, password: string | undefined): string {
    return CryptoJS.AES.decrypt(encryptedText, password as string).toString(CryptoJS.enc.Utf8);
  }

  encryptUsingPublicKey(text: string, publicKey: string): string | false {
    /*
     Key size is 1024 bits, so 128 bytes. 11 bytes are needed for padding.
     text.length accounts for multibyte characters like emoji.
    */
    if (text.length > 117) {
      logger.error('tried to encrypt text longer than 117 characters with public key');
    }
    this.encrypt.setPublicKey(publicKey);
    return this.encrypt.encrypt(text);
  }

  decryptUsingPrivateKey(encryptedText: string, privateKey: string): string | false {
    this.encrypt.setPrivateKey(privateKey);
    return this.encrypt.decrypt(encryptedText);
  }
}
