import { inject, Injectable } from '@angular/core';
import { MatomoTracker } from '@codeculture/ngx-matomo';
import { ButtonTypes } from '../enums/button-actions.enum';

@Injectable({
    providedIn: 'root',
})
export class MatomoService {
    private _matomoTracker = inject(MatomoTracker);

    /**
     * Tracks an action for the download pages (both logistics and mea) depending
     * on it being a print or download action.
     *
     * @param buttonType - The type of button action, either DOWNLOAD or PRINT.
     * @param [id] - The optional identifier associated with the download.
     * @param [filename] - The optional name of the file being downloaded or printed.
     */
    public trackDownloadAction(buttonType: ButtonTypes, id?: string, filename?: string) {
        if (buttonType === ButtonTypes.DOWNLOAD || buttonType === ButtonTypes.PRINT) {
            const isPrint = buttonType === ButtonTypes.PRINT;
            this._matomoTracker.trackEvent('Downloadbereich', isPrint ? 'Drucken' : 'Download', filename, parseInt(id, 10));
        }
    }

    /**
     * Tracks an event related to the information modal.
     *
     * @param action - The specific action taken within the modal (e.g., opened, closed, interacted).
     * @param name - The name or identifier of the modal or associated element.
     * @param value - A numerical value associated with the event, such as a count or score.
     */
    public trackInfoModalEvent(action: string, name: string, value: number) {
        this._matomoTracker.trackEvent('Info Modal', action, name, value);
    }

    /**
     * Tracks an event related to delivery receipts.
     *
     * @param action - The specific action being tracked (e.g., "view", "click").
     * @param name - The name or label associated with the event.
     * @param value - The numeric value associated with the event (e.g., a count or a metric).
     */
    public trackDeliveryReceiptsEvent(action: string, name: string, value: number) {
        this._matomoTracker.trackEvent('delivery', action, name, value);
    }
}
