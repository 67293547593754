import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../essentials/types/src/actionType';
import { ConversationProgressEvent } from '../../../../essentials/types/src/backendConversation';
import { ShopUserMessage } from '../../../../essentials/types/src/shopUserMessage';

export const loadRecentShopUserMessages = createAction(
  `${ActionType.ShopUserMessages}: Load recent shop user messages`
);

export const loadRecentShopUserMessagesSuccess = createAction(
  `${ActionType.ShopUserMessages}: Load recent shop user messages success`,
  props<{ shopUserMessages: ShopUserMessage[] }>()
);

export const loadRecentShopUserMessagesFailure = createAction(
  `${ActionType.ShopUserMessages}: Load recent shop user messages failure`
);

export const loadAllShopUserMessages = createAction(`${ActionType.ShopUserMessages}: Load all shop user messages`);

export const loadAllShopUserMessagesSuccess = createAction(
  `${ActionType.ShopUserMessages}: Load all shop user messages success`,
  props<{ shopUserMessages: ShopUserMessage[] }>()
);

export const loadAllShopUserMessagesFailure = createAction(
  `${ActionType.ShopUserMessages}: Load all shop user messages failure`
);

export const addCreatedShopUserMessage = createAction(
  `${ActionType.ShopUserMessages}: Add created shop user message`,
  props<{ shopUserMessage: ShopUserMessage }>()
);

export const updateUpdatedShopUserMessage = createAction(
  `${ActionType.ShopUserMessages}: Update updated shop user message`,
  props<{ shopUserMessage: ShopUserMessage }>()
);

export const deleteShopUserMessage = createAction(
  `${ActionType.ShopUserMessages}: Delete shop user message`,
  props<{ id: string }>()
);

export const updateProgressHistoryForShopUserMessage = createAction(
  `${ActionType.ShopUserMessages}: Update progress history for shop user message`,
  props<{ shopUserMessage: ShopUserMessage; newProgressEvent: ConversationProgressEvent }>()
);

export const revertProgressUpdateForShopUserMessage = createAction(
  `${ActionType.Chat}: Revert update of progress status history for shop user message`,
  props<{
    shopUserMessageId: string;
    progressEventToRemove: ConversationProgressEvent;
  }>()
);
