import sentryConf from './sentry-conf';
export const environment = {
    name: 'production',
    production: true,
    baseHref: './',
    cacheVersion: 4,
    customerEmail: '',
    customerPassword: '',
    sprintImageUrl: null,
    apiUri: 'https://apollo.connect.sanacorp.de/graphql',
    apiWSUri: 'wss://apollo.connect.sanacorp.de/graphql',
    mediaUriStrapi: 'https://strapi.connect.sanacorp.de',
    mediaS3Uri: 'https://cdn.connect.sanacorp.de/',
    meadiaS3CustomerUploadUri: 'https://cdn.connect.sanacorp.de/customer-documents/',
    meaChatUri: 'https://apotheke.meineapotheke.de/',
    meaChatRegisterMail: 'digital@sanacorp.de',
    supportMail: 'digital@sanacorp.de',
    sentryUri: 'https://7b0c2ffe7b55465684713ff867c27219@sentry.sanacorp.cloud/4',
    sanacorpAcademyUrl: 'https://www.sanacorp-akademie.de/start/op/openid',
    meamind: {
        userIdentityProviderUrl: 'https://auth.starmind.com/auth/realms/1038',
        autoPublishing: true, // Do not change, this will affect the publish process of questions and solutions
        notificationPollInterval: 10000,
    },
    showReportDialog: false,
    pollingInterval: {
        news: 600000,
        offers: 60000,
        educations: 3600000,
        seminars: 3600000,
        user: 60000,
        strapiDataChanged: 60000,
        faq: 3600000,
        releaseNotes: 3600000,
        customerService: 3600000,
        pharmacyStoresOften: 60000,
        pharmacyStores: 3600000,
    },
    sso: {
        baseUrl: 'https://login.sanacorp.de/auth/realms/Apotheken',
        changePasswordUri: '{{BASE_URL}}/account/password/?referrer=sanacorp-connect',
    },
    tracking: {
        matomoUrl: 'https://matomo.sanacorp.cloud/js/container_8bSPOCOX.js',
    },
    sentry: {
        release: sentryConf.release,
        tracesSampleRate: 0.2, // Use lower tracesSampleRate to prevent sentry overload
        tracingOrigins: ['connect.sanacorp.de', 'apollo.connect.sanacorp.de', /^\//],
    },
    featureFlags: {},
    fun: {
        easterGame: {
            fromDate: '25.03.2024',
            toDate: '05.04.2024',
        },
        halloweenGame: {
            fromDate: '28.10.2024',
            toDate: '01.11.2024',
        },
        santaGame: {
            fromDate: '06.12.2023',
            toDate: '27.12.2023',
        },
        helpSanta: {
            fromDate: '06.12.2024',
            toDate: '25.12.2024',
        },
    },
};
