import { computed, EventEmitter, inject, Injectable, signal, untracked } from '@angular/core';
import {
    PharmaciesFromKeyCloakInterface,
    PharmaciesProduct,
    TableColumnInterface,
    TableFilterConfigInterface,
    TableSearchModalConfigInterface,
    TableSortInterface,
    UserAdministrationFiltersInterface,
    UserAdministrationGraphqlInterface,
    UserAdministrationInterface,
} from '../core.interfaces';

import { toSignal } from '@angular/core/rxjs-interop';
import { ProfileSettingsVar, UserAdministrationQueries } from '../core.store';
import { BehaviorSubject, map, switchMap, tap } from 'rxjs';
import { PharmacyStoreStateVar } from '../store/locals/pharmacyStoreState.var';
import { ModalService } from './modal.service';
import { TableFieldsEnum, TableFilterTypeEnum } from '../enums/table.enum';
import { UserAccessModulesEnum, UserAccessRightsEnum } from '../enums/user-administration.enum';
import { UserEditModalComponent } from '../../pages/settings/pages/user-administration/widgets/user-edit-modal/user-edit-modal.component';
import { DefaultUserAdministrationFilters, UserAccessRightsConfig } from '../config/user-administration.config';
import { SortDirectionEnum } from '../core.enums';

@Injectable({
    providedIn: 'root',
})
export class UserAdministrationService {
    private _userAdminQueries = inject(UserAdministrationQueries);
    private _pharmacyStoreStateVar = inject(PharmacyStoreStateVar);
    private _profileSettingsVar = inject(ProfileSettingsVar);
    private _modalService = inject(ModalService);

    public userDataChanged: EventEmitter<boolean> = new EventEmitter();
    public userDataSetLoading: EventEmitter<boolean> = new EventEmitter();
    private _refreshTrigger = new BehaviorSubject<number>(0);

    public readonly columns: TableColumnInterface[] = [
        {
            id: 1,
            title: '',
            width: '3.0rem',
            sortable: false,
            class: 'first-col-padding col-drop-down',
        },
        {
            id: 2,
            title: 'Vorname',
            width: '15.0rem',
            sortable: true,
            class: 'col-auto',
            dataKey: TableFieldsEnum.firstName,
        },
        { id: 3, title: 'Nachname', width: '10.0rem', sortable: true, dataKey: TableFieldsEnum.lastName },
        { id: 4, title: 'Apotheken', width: '10.0rem', sortable: true, dataKey: TableFieldsEnum.pharmacy },
        { id: 5, title: 'Produkte', width: '10.0rem', sortable: false, dataKey: TableFieldsEnum.product },
        {
            id: 6,
            title: 'Typ',
            width: '8.0rem',
            sortable: false,
            dataKey: TableFieldsEnum.type,
        },
        { id: 7, title: '', width: '3.0rem', sortable: false },
    ];

    private readonly allowedUserAccessRights = Object.values(UserAccessRightsEnum).map((value) => value as string);
    private readonly validPharmacyStores = this._pharmacyStoreStateVar.pharmacyStoresState;

    private _isLoading = signal<boolean>(true);
    public isLoading = this._isLoading.asReadonly();

    private _selectedRowId = signal<string | null>(null);
    public selectedRowId = this._selectedRowId.asReadonly();

    private _expandedRowKeys = signal<{ [key: number]: boolean }>({ '-1': false });
    public expandedRowKeys = this._expandedRowKeys.asReadonly();

    private _owner = signal<UserAdministrationInterface | null>(null);
    public owner = this._owner.asReadonly();

    private _isPharmacyOwner = computed(() => this._profileSettingsVar.profileSettings()?.user?.isPharmacyOwner);

    private _sort = signal<TableSortInterface>({
        // default sort configuration
        field: TableFieldsEnum.pharmacy,
        order: SortDirectionEnum.asc,
        onUpdate: (field: TableFieldsEnum) => {
            this.updateSort(field);
        },
    });
    public sort = this._sort.asReadonly();

    private _filters = signal<UserAdministrationFiltersInterface>(DefaultUserAdministrationFilters);
    public filters = this._filters.asReadonly();

    private _pharmaciesFilterOptions = computed(() => {
        const pharmacies = [
            {
                id: 'all',
                title: 'Alle',
            },
        ];

        if (this.validPharmacyStores() && this.validPharmacyStores().length > 0) {
            this.validPharmacyStores().forEach((pharmacy) => {
                pharmacies.push({
                    id: pharmacy.apiUser,
                    title: pharmacy.name,
                });
            });
        }

        return pharmacies;
    });

    private _userAdministrationUsers = toSignal(
        this._refreshTrigger.pipe(
            switchMap(() =>
                this._userAdminQueries.getAllUsers().pipe(
                    tap(() => this._isLoading.set(true)),
                    map((response: UserAdministrationGraphqlInterface) => response.data),
                    map((users) =>
                        users.map((user) => ({
                            ...user,
                            products: this.computeProducts(user.pharmacies),
                        }))
                    ),
                    map((users) => this.processUserData(users)),
                    tap((processedUsers) => {
                        if (processedUsers?.length > 0) {
                            this._owner.set(
                                processedUsers.find((user) =>
                                    user.pharmacies.some((pharmacy) => pharmacy.sconnect.includes(UserAccessRightsEnum.PHARMACY_OWNER))
                                )
                            );
                        }
                        this._isLoading.set(false);
                    })
                )
            )
        )
    );

    private sortedUsers = computed(() => {
        const users = this._userAdministrationUsers();
        if (!users) return [];

        const sortedArray = [...users];

        const ownerIndex = sortedArray.findIndex((user) =>
            user.pharmacies.some((pharmacy) => pharmacy.sconnect.includes(UserAccessRightsEnum.PHARMACY_OWNER))
        );

        let owner = null;
        if (ownerIndex !== -1) {
            owner = sortedArray.splice(ownerIndex, 1)[0];
        }

        sortedArray.sort((a, b) => {
            const field = this._sort().field;
            const order = this._sort().order;

            if (!a[field]) return order === SortDirectionEnum.asc ? -1 : 1;
            if (!b[field]) return order === SortDirectionEnum.asc ? 1 : -1;

            let comparison = 0;

            switch (field) {
                case TableFieldsEnum.firstName:
                case TableFieldsEnum.lastName:
                    comparison = a[field].localeCompare(b[field]);
                    break;
                default:
                    comparison = a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
            }

            return order === SortDirectionEnum.asc ? comparison : -comparison;
        });

        if (owner) {
            sortedArray.unshift(owner);
        }

        return sortedArray;
    });

    public filteredUsers = computed(() => {
        const users = this.sortedUsers();
        const currentFilters = this._filters();

        const filteredUsers = users
            .filter((user) => {
                if (currentFilters.apiUser && currentFilters.apiUser !== 'all') {
                    if (!user.pharmacies.some((pharmacy) => pharmacy.apiUser === currentFilters.apiUser)) {
                        return false;
                    }
                }

                if (currentFilters.search && currentFilters.search.trim() !== '') {
                    const searchText = currentFilters.search.toLowerCase().trim();

                    const matchesFirstName = user.firstName?.toLowerCase().includes(searchText);
                    const matchesLastName = user.lastName?.toLowerCase().includes(searchText);
                    const matchesEmail = user.email?.toLowerCase().includes(searchText);

                    if (!matchesFirstName && !matchesLastName && !matchesEmail) {
                        return false;
                    }
                }

                return true;
            })
            .map((user) => {
                if (!currentFilters.apiUser || currentFilters.apiUser === 'all') {
                    return user;
                }

                const filteredPharmacies = user.pharmacies.filter((pharmacy) => pharmacy.apiUser === currentFilters.apiUser);

                return {
                    ...user,
                    pharmacies: filteredPharmacies,
                    products: this.computeProducts(filteredPharmacies),
                };
            });

        // Auto-expand the first row if there are any results
        untracked(() => {
            if (filteredUsers.length > 0 && !this._isLoading()) {
                const firstUserId = filteredUsers[0].id;
                this._expandedRowKeys.set({ [firstUserId]: true });
                this._selectedRowId.set(firstUserId);
            }
        });

        return filteredUsers;
    });

    public filterLeftConfig: TableFilterConfigInterface[] = [
        {
            label: 'Nutzer von Apotheken anzeigen',
            type: TableFilterTypeEnum.select,
            dataKey: 'apiUser',
            selectableSignal: this._pharmaciesFilterOptions,
            onAction: (newFilterId: string, dataKey: string) => this.updateSelectFilter(newFilterId, dataKey),
        },
        {
            label: 'Suche',
            type: TableFilterTypeEnum.search,
            dataKey: 'search',
            placeholder: 'Suche (Vorname, Nachname, E-Mail)',
        },
    ];

    public filterRightConfig: TableFilterConfigInterface[] = [
        {
            label: 'Nutzer hinzufügen',
            type: TableFilterTypeEnum.button,
            icon: 'add-outline',
            ttk: !this._isPharmacyOwner() ? '' : 'user_administration_create_new_user_button',
            onAction: async () => {
                const isOwner = this._profileSettingsVar.profileSettings()?.user.isPharmacyOwner;
                if (isOwner) {
                    const userEditModal = await this._modalService.create(UserEditModalComponent);
                    await this._modalService.present(userEditModal);
                }
            },
            payload: {
                isDisabled: !this._isPharmacyOwner(),
            },
        },
    ];

    public searchModalFilterConfig: TableSearchModalConfigInterface = {
        title: 'Nutzer filtern',
        onAction: (newFilters: UserAdministrationFiltersInterface) => this.onSearchModalAction(newFilters),
        onReset: () => this.onSearchModalCancel(),
        items: [
            {
                label: 'Suche',
                type: TableFilterTypeEnum.search,
                dataKey: 'search',
                placeholder: 'Suche (Vorname, Nachname, E-Mail)',
            },
            {
                label: 'Nutzer von Apotheken anzeigen',
                type: TableFilterTypeEnum.select,
                dataKey: 'apiUser',
                selectableSignal: this._pharmaciesFilterOptions,
                onAction: (newFilterId: string, dataKey: string) => this.updateSelectFilter(newFilterId, dataKey),
            },
        ],
    };

    /**
     * Processes an array of pharmacy objects to compute product module accessibility.
     *
     * @param {PharmaciesFromKeyCloakInterface[]} pharmacies - Array of pharmacy objects containing module access information
     * @returns {PharmaciesProduct[]} Array of pharmacy products with module activation status, sorted by configured order
     *
     * @description
     * This method performs the following operations:
     * 1. Flattens pharmacy module data into individual module entries
     * 2. Merges duplicate module entries, combining their activation status
     * 3. Filters for valid modules defined in UserAccessModulesEnum
     * 4. Sorts the resulting array based on UserAccessRightsConfig sort order
     *
     * @throws Will return empty array if input is not an array
     */
    private computeProducts(pharmacies: Array<PharmaciesFromKeyCloakInterface>): PharmaciesProduct[] {
        if (!Array.isArray(pharmacies)) return [];

        const modules = pharmacies
            .map((pharmacy) =>
                Object.keys(pharmacy).map(
                    (module): PharmaciesProduct => ({
                        moduleName: module as UserAccessModulesEnum,
                        isActive: pharmacy[module] && pharmacy[module].length > 0,
                    })
                )
            )
            .flat(1);

        const merged = modules.reduce((products, module) => {
            const found = products.find((p) => p.moduleName === module.moduleName);
            if (found) {
                found.isActive = module.isActive || found.isActive;
            } else if (Object.values(UserAccessModulesEnum).includes(module.moduleName)) {
                products.push(module);
            }
            return products;
        }, [] as PharmaciesProduct[]);

        return merged.sort((a, b) => UserAccessRightsConfig[a.moduleName].sortOrder - UserAccessRightsConfig[b.moduleName].sortOrder);
    }

    /**
     * Processes and filters user administration data according to allowed access rights and valid pharmacy stores.
     *
     * @param users - Array of user administration objects to be processed
     * @returns Processed array of user administration objects with filtered pharmacy access rights and stores
     *
     * The function performs the following:
     * 1. Maps through each user object
     * 2. For each user's pharmacies:
     *    - Filters access rights for different services (sconnect, chat, shop, sacademy, sanavendi)
     *    - Only includes rights that exist in allowedUserAccessRights
     *    - Filters pharmacies to only include those matching valid pharmacy stores
     * 3. Returns empty arrays for any invalid or non-array inputs
     */
    private processUserData(users: UserAdministrationInterface[]): UserAdministrationInterface[] {
        return users.map((user) => ({
            ...user,
            pharmacies: Array.isArray(user.pharmacies)
                ? user.pharmacies
                      .map((pharmacy) => ({
                          ...pharmacy,
                          sconnect: Array.isArray(pharmacy.sconnect)
                              ? pharmacy.sconnect.filter((r) => r && this.allowedUserAccessRights.includes(r))
                              : [],
                          chat: Array.isArray(pharmacy.chat)
                              ? pharmacy.chat.filter((r) => r && this.allowedUserAccessRights.includes(r))
                              : [],
                          shop: Array.isArray(pharmacy.shop)
                              ? pharmacy.shop.filter((r) => r && this.allowedUserAccessRights.includes(r))
                              : [],
                          sacademy: Array.isArray(pharmacy.sacademy)
                              ? pharmacy.sacademy.filter((r) => r && this.allowedUserAccessRights.includes(r))
                              : [],
                          sanavendi: Array.isArray(pharmacy.sanavendi)
                              ? pharmacy.sanavendi.filter((r) => r && this.allowedUserAccessRights.includes(r))
                              : [],
                      }))
                      .filter(
                          (pharmacy) =>
                              this.validPharmacyStores() && this.validPharmacyStores().find((store) => pharmacy.apiUser === store.apiUser)
                      )
                : [],
        }));
    }

    /**
     * Sets the ID of the currently selected row in the user administration interface.
     * @param rowId - The unique identifier of the selected row.
     */
    setSelectedRowId(rowId: string) {
        this._selectedRowId.set(rowId);
    }

    /**
     * Expands or collapses a specific row in a table or list by its row ID.
     * Ensures that only one row is expanded at a time.
     *
     * @param rowId - The unique identifier of the row to be expanded or collapsed.
     * @param expanded - A boolean indicating whether the row should be expanded (true) or collapsed (false).
     */
    public expandRow(rowId: string, expanded: boolean) {
        const group = this._userAdministrationUsers().find((user) => user.id === rowId);
        if (group?.pharmacies?.length) {
            // only allow one group to be expanded at a time
            this._expandedRowKeys.set({ [rowId]: expanded });
        }
    }

    /**
     * Updates the sort configuration for a table field.
     * Toggles the sort direction between ascending and descending
     * when the same field is selected.
     *
     * @param field - The table field to sort by
     * @returns void
     *
     */
    public updateSort(field: TableFieldsEnum) {
        this._sort.update((prev) => ({
            ...prev,
            field,
            order: this._sort().order === SortDirectionEnum.asc ? SortDirectionEnum.desc : SortDirectionEnum.asc,
        }));
    }

    /**
     * Updates a specific filter value in the filters object.
     * @param newFilterId - The new ID value to set for the filter
     * @param dataKey - The key in the filters object to update
     */
    updateSelectFilter(newFilterId: string, dataKey: string) {
        this._filters.update((filters) => {
            return {
                ...filters,
                [dataKey]: newFilterId,
            };
        });
    }

    /**
     * Updates the user administration filters based on new filter values from the search modal.
     * @param newFilters - New filter values to be merged with existing filters
     * @private
     */
    private onSearchModalAction(newFilters: UserAdministrationFiltersInterface) {
        this._filters.update((prev) => {
            return {
                ...prev,
                ...newFilters,
            };
        });
    }

    /**
     * Handles the cancellation event of the search modal by resetting the filters
     * to their default values using DefaultUserAdministrationFilters.
     * @private
     */
    private onSearchModalCancel() {
        this._filters.set(DefaultUserAdministrationFilters);
    }

    /**
     * Refreshes the user administration data by triggering a new request.
     */
    public refreshUserData(): void {
        this._refreshTrigger.next(this._refreshTrigger.value + 1);
    }
}
