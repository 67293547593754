<div class="sidebar" [class.standalone-content]="!hasHeader && !hasFooter">
    <div class="sidebar-header" *ngIf="hasHeader && data()?.hasHeader !== false">
        @let title = data()?.title;
        @let subTitle = data()?.subTitle;
        <div class="label" *ngIf="subTitle">{{ subTitle }}</div>
        <div class="headline" *ngIf="title">{{ title | breakAtSlash }}</div>
        <div class="action-popover-button">
            <ion-icon id="{{id}}-action" name="ellipsis-vertical" [class.clickable]="!bulkModeActive()"
                      [class.disabled]="bulkModeActive()" size="large"/>
        </div>
        <ion-popover trigger="{{id}}-action" dismissOnSelect="true" *ngIf="!bulkModeActive()">
            <ng-template>
                <ng-container *ngTemplateOutlet="actionsPopoverRef"/>
                <ion-list lines="full" class="ion-no-padding">
                    <ion-item *ngFor="let actionItem of data().actionPopoverItems"
                              button="true"
                              [class.disabled]="actionItem.isDisabled"
                              (click)="!actionItem.isDisabled && actionItem?.onAction()"
                              appTooltip
                              ttk="{{actionItem?.tooltipKey}}"
                              ttp="left">
                        <ion-icon *ngIf="actionItem?.icon" slot="start" [name]="actionItem.icon"></ion-icon>
                        {{ actionItem.label }}
                    </ion-item>
                    <ion-item button="true" (click)="onCloseSidebar()" i18n>Schließen</ion-item>
                </ion-list>
            </ng-template>
        </ion-popover>
    </div>
    <div class="sidebar-content">
        <div [class]="'content-item' + (item?.barcode ? ' barcode-wrapper' : '')"
             [class.clickable]="item?.onAction"
             [style.--col-width]="item?.colWidth"
             *ngFor="let item of data()?.sidebarItems | filterVisibleTableItems"
             (click)="item?.onAction && item?.onAction()">
            <div class="label" *ngIf="(item?.label || item?.keepEmptyLabel) && item?.value">{{ item.label }}
                <ion-icon name="information-circle-outline" *ngIf="item.ttKey" appTooltip [ttk]="item.ttKey" ttp="top"
                          class="widget-info"></ion-icon>
            </div>
            @if (item?.image) {
                <div class="product-image-container">
                    <img class="product-image"
                         [src]="item.image.src"
                         [alt]="item.image.alt"
                         [title]="item.image.alt ?? null"/>
                </div>
            } @else if (item?.barcode) {
                <ngx-barcode6
                        [bc-format]="item?.barcode?.format"
                        [bc-value]="item?.barcode?.value"
                        [bc-display-value]="item?.barcode?.displayValue"
                        [bc-width]="item?.barcode?.formatting?.width"
                        [bc-height]="item?.barcode?.formatting?.height"
                        [bc-margin]="item?.barcode?.formatting?.margin"
                        [bc-margin-top]="item?.barcode?.formatting?.marginTop"
                        [bc-margin-bottom]="item?.barcode?.formatting?.marginBottom"
                        [bc-margin-left]="item?.barcode?.formatting?.marginLeft"
                        [bc-margin-right]="item?.barcode?.formatting?.marginRight"
                ></ngx-barcode6>
                <ion-text>{{ item.value }}</ion-text>
            } @else {
                @if (!item.badgeType && !item.isPreview) {
                    <div
                            [class]="'value' + (item?.copyIcon ? ' copy-wrapper' : '')"
                            *ngIf="item?.value">
                        <span [innerHtml]="item.value | convertLineBreak"></span>
                        @if (item?.copyIcon) {
                            <ion-icon
                                    [name]="item?.copyIcon?.name"
                                    [cdkCopyToClipboard]="getCopyValue(item?.copyIcon)"
                                    appTooltip
                                    [ttk]="item?.copyIcon?.tooltipKey"
                            ></ion-icon>
                        }
                    </div>


                } @else if (item?.badgeType === BadgeTypeEnum.MEA_DOWNLOADS_MULTIPLE) {
                    <ion-text class="multiple-badges">
                        @for (downloadType of item.value; track downloadType.id) {
                            @if (downloadType.id === 'personal') {
                                @let customBadgeConfig = meaDownloadsSectionConfig().personal.customBadgeConfig;
                                @if (customBadgeConfig) {
                                    <app-badge
                                            [code]="customBadgeConfig.code"
                                            [badgeType]="BadgeTypeEnum.MEA_DOWNLOADS"
                                            [textColor]="customBadgeConfig.textColor"
                                            [backgroundColor]="customBadgeConfig.backgroundColor"
                                            [borderColor]="customBadgeConfig.borderColor"
                                            [borderWidth]="customBadgeConfig.borderWidth">
                                    </app-badge>
                                }
                            } @else {
                                <app-badge [code]="downloadType.type" [badgeType]="BadgeTypeEnum.MEA_DOWNLOADS"
                                           [badgeTypeInfo]="downloadType.color"></app-badge>
                            }
                        }
                    </ion-text>

                } @else if (item?.badgeType) {
                    <app-badge [badgeType]="item.badgeType" [code]="item.value"></app-badge>
                } @else if (item.isPreview) {
                    <app-download-preview
                            [title]="item.label"
                            [filePreviewUrl]="(item.value | filePreviewImage: 'jpg')"
                            [fileExtension]="(item.value | lastPartOfString : '.')"
                            [color]="item.previewColor"
                            (click)="item.previewAction()"
                    ></app-download-preview>
                }
            }
        </div>
    </div>
    <div class="sidebar-footer" *ngIf="hasFooter && data()?.hasFooter !== false">
        <ion-icon class="clickable" name="arrow-back" (click)="data().previousRow()"></ion-icon>
        <ion-button *ngIf="hasEditButton  && data()?.hasEditButton !== false"
                    id="{{id}}-sidebar-details-modal"
                    class="with-shadow"
                    [disabled]="bulkModeActive()"
                    i18n>Bearbeiten
        </ion-button>
        <ion-icon class="clickable" name="arrow-forward" (click)="data().nextRow()"></ion-icon>
    </div>
</div>

<ion-modal *ngIf="hasFooter && data()?.hasFooter !== false" trigger="{{id}}-sidebar-details-modal" class="modal-large">
    <ng-template>
        <ng-container *ngTemplateOutlet="detailsModalRef"></ng-container>
    </ng-template>
</ion-modal>
