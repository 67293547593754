import { Injectable } from '@angular/core';
import getAllTextblocks from '../../../../../common/resources/src/graphql/queries/getAllTextblocks';
import { AppsyncService } from '../../../../../common/services/src/appsync/appsync.service';
import { Textblock } from '../../../../../essentials/types/src/textblock';

@Injectable({
  providedIn: 'root',
})
export class TextblockLoadService {
  constructor(private appsyncService: AppsyncService) {}

  async getAllTextblocks(): Promise<Textblock[]> {
    const client = await this.appsyncService.getClient();
    const { data } = await client.query({
      query: getAllTextblocks,
    });
    if (data && data.getAllTextblocks) {
      return data.getAllTextblocks;
    }
    return [];
  }
}
