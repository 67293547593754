import { DateRangeOptionCodes } from '../enums/date-range.enum';
import { DownloadsFiltersInterface } from '../interfaces/mea.interface';

export const defaultDownloadsFilters: DownloadsFiltersInterface = {
    search: '',
    dateOption: DateRangeOptionCodes.all,
    dateFrom: null,
    dateTo: null,
    type: ''
};

export const MeaDynamicPageMembershipTypes = ['mea premium', 'mea select'];
