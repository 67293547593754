import { AppContext } from '../../types/src/appContext';
import {
  ActiveConversationProgressStatus,
  ConversationProgressStatus,
  ConversationStatusOption,
} from '../../types/src/backendConversation';

export class ChatStatusUtil {
  private static appointmentStatusOptions = [
    { status: ActiveConversationProgressStatus.AppointmentConfirmed },
    { status: ActiveConversationProgressStatus.AppointmentCompleted },
    { status: ActiveConversationProgressStatus.AppointmentCancelled },
  ] as const;

  private static defaultStatusOptions = [
    { status: ActiveConversationProgressStatus.InProgress },
    { status: ActiveConversationProgressStatus.ReadyForPickup },
    { status: ActiveConversationProgressStatus.InDelivery },
    { status: ActiveConversationProgressStatus.Completed },
    { status: ActiveConversationProgressStatus.Cancelled },
  ] as const;

  static getSortedStatusOptions(
    currentStatus: ConversationProgressStatus | undefined,
    conversationIntent: AppContext | undefined,
    chatInputEnabled: boolean
  ) {
    switch (conversationIntent) {
      case 'BOOK_APPOINTMENT':
        return this.getAppointmentStatusOptions(currentStatus, chatInputEnabled);
      default:
        return this.getDefaultStatusOptions(currentStatus, chatInputEnabled);
    }
  }

  static isCompleted(status: ConversationProgressStatus) {
    return (
      status === ActiveConversationProgressStatus.Completed ||
      status === ActiveConversationProgressStatus.AppointmentCompleted
    );
  }

  private static getAppointmentStatusOptions(
    currentStatus: ConversationProgressStatus | undefined,
    chatInputEnabled: boolean
  ): ConversationStatusOption[] {
    const options = chatInputEnabled
      ? this.appointmentStatusOptions
      : [{ status: ActiveConversationProgressStatus.AppointmentCompleted }];
    return options.filter((option) => option.status !== currentStatus);
  }

  private static getDefaultStatusOptions(
    currentStatus: ConversationProgressStatus | undefined,
    chatInputEnabled: boolean
  ): ConversationStatusOption[] {
    const options = chatInputEnabled
      ? this.defaultStatusOptions
      : [{ status: ActiveConversationProgressStatus.Completed }];
    return options.filter((option) => option.status !== currentStatus);
  }
}
