import { EducationInterface } from '../../interfaces/education.interface';
import { DownloadTypes } from '../../enums/mea.enum';
import { DownloadTypesInterface, PharmacyStoreInterface } from '../../core.interfaces';
import { getLocationFromNewsUrl, getLocationFromOffersUrl, NewsLocationRouteMap, OffersLocationRouteMap } from '../../config/navigation.config';
import { StaticAppLocations } from '../../enums/app-location.enum';
import { NotificationsEnum } from '../../enums/notifications.enum';
import { environment } from '../../../../environments/environment';
import { AuthStorageKeyEnum } from '../../enums/authStorageKey.enum';

export function formatStrapiData(data) {
    // this is not needed right now, but I would keep it, just in case strapi has some new ideas
    return data;
}

export function formatStrapiBannerData(dataObject) {
    return dataObject?.map(data => ({
        ...data,
        media: data?.media?.map(it => ({
            size: it?.size,
            image: {
                url: it?.image?.url
            }
        }))
    }));
}

export function formatInfoModalData(dataObject) {
    return dataObject?.map(data => ({
        id: parseInt(data?.id, 10),
        version: data?.releaseNote?.version,
        entries: data?.infoModalEntry?.map(entry => ({
            id: parseInt(entry?.id, 10),
            title: entry?.title,
            content: entry?.content
        }))
    }));
}

export function formatStrapiOffersConnectionData(dataObject, appLocationUrl?: string) {
    return Array.isArray(dataObject) ? dataObject?.map(data => (formatOffer(data, appLocationUrl))) : formatOffer(dataObject, appLocationUrl);
}

function formatOffer(data, appLocationUrl?: string) {
    const membershipTypes = [];
    data?.offerFilters?.map(filter => filter?.membershipTypes?.map(type => {
        const name = type?.name;
        if (name && !membershipTypes.includes(name)) {
            membershipTypes.push(name);
        }
        return type;
    }));

    if (!data) return {};

    let appLocation = null;
    const locations = data?.appLocations;

    if (locations?.length) {
        const locationKey = getLocationFromOffersUrl(appLocationUrl);
        const fallBackUrl = OffersLocationRouteMap;
        appLocation = findAppLocation(locations, locationKey, appLocationUrl, fallBackUrl);
    }

    return data ? {
        ...data,
        appLocation,
        offerFilters: {
            membershipTypes: membershipTypes.map(type => ({name: type}))
        },
    } : data;
}

function findAppLocation(locations, appLocationKey?: string, appLocationUrl?: string, fallBackUrl?: any) {
    let appLocation = null;

    if (appLocationUrl) {
        appLocation = locations?.find(
            location => location?.type === appLocationKey &&
            (location?.url === appLocationUrl || location?.url === "" || location?.url === null)
        );
    } else {
        const locationPriorities = [
            StaticAppLocations.Default,
            StaticAppLocations.Mea,
            StaticAppLocations.DynamicPage
        ];

        appLocation = locationPriorities
            .reduce((found, locationType) =>
                found || locations.find(loc => loc?.type === locationType),
                null
            );
    }

    if (appLocation && !appLocation.url) {
        appLocation = {...appLocation, url: fallBackUrl[appLocation.type]}
    }

    return appLocation;
}

function formatNotification(data) {
    let appLocation = null;
    let fallBackUrl = null;
    const locations = data?.payload?.appLocations;

    if (locations?.length) {
        switch (data?.type) {
            case NotificationsEnum.NEW_NEWS:
            case NotificationsEnum.NEW_IMPORTANT_NEWS:
                fallBackUrl = NewsLocationRouteMap;
                break;
            case NotificationsEnum.NEW_OFFER:
                fallBackUrl = OffersLocationRouteMap;
                break;
            default:
                return data;
        }
        appLocation = findAppLocation(locations, null, null, fallBackUrl);
    }

    return {
        ...data,
        payload: {
            ...data?.payload,
            appLocation
        }
    };
}

export function formatNotificationData(dataObject) {
    return Array.isArray(dataObject) ? dataObject?.map(data => (formatNotification(data))) : formatNotification(dataObject);
}

function formatNews(data: any, appLocationUrl?: string) {
    if (!data) return {};

    let appLocation = null;
    const locations = data?.appLocations;

    if (locations?.length) {
        const locationKey = getLocationFromNewsUrl(appLocationUrl);
        const fallBackUrl = NewsLocationRouteMap;
        appLocation = findAppLocation(locations, locationKey, appLocationUrl, fallBackUrl);
    }

    return {
        ...data,
        attachments: data?.attachments ? [data?.attachments] : null,
        appLocation,
        formId: data?.form?.publishedAt ? data?.form?.id : null,
        formButton: data?.formButton,
        image: {
            url: data?.image?.url,
            height: data?.image?.height,
            width: data?.image?.width
        }
    };
}

export function formatStrapiNewsData(dataObject, appLocationUrl?: string) {
    return Array.isArray(dataObject) ? dataObject?.map(data => (formatNews(data, appLocationUrl))) : formatNews(dataObject, appLocationUrl);
}

export function formatStrapiForm(dataObject) {
   return dataObject;
}

export function formatFormResult(dataObject) {
    return dataObject?.map(data => ({
        id: data?.id,
    }));
}

export function formatStrapiServiceBranches(branches) {
    return branches?.map(data => ({
        ...data,
        teamMembers: data?.teamMembers?.map(it => ({
            ...it,
            image: it?.url
        })),
        image: data?.image?.url,
        file: data?.file?.url,
    }));
}

export function formatStrapiHelpSections(sections) {
    return sections;
}

export function formatStrapiDownloads(downloads) {
    if (downloads?.length === 0) return [];

    const downloadTypes = (Array.from(
        new Map(
            downloads.flatMap(item => item.downloadTypes).map(type => [type.id, type])
        ).values()
    ).map((item: object) => ({...item, downloads: []})) as Array<DownloadTypesInterface>)
    .map(dType => {
        // get all downloads with the same type
        const filteredDownloads = downloads.filter(download => !download.isPersonal && download.downloadTypes?.some(t => t.id === dType.id));
        return {
            ...dType,
            downloads: filteredDownloads.map(download => ({
                ...download,
                file: download?.file?.url,
                downloadTypes: download?.downloadTypes?.filter((type, index, self) =>
                    // Remove duplicate types by id
                    index === self.findIndex((t) => (
                        t.id === type.id
                    ))
                ),
                isSelected: false
            }))
        };
    }) as Array<DownloadTypesInterface>;

    const cdnUrl = environment.meadiaS3CustomerUploadUri.replace(/\/$/, '');
    const apiUser = localStorage.getItem(AuthStorageKeyEnum.activePharmacy);
    downloadTypes.push({
        id: 'personal',
        type: 'personal',
        color: '',
        downloads: downloads.filter(download => download.isPersonal).map(download => ({
            ...download,
            file: `${cdnUrl}${download?.personalDirectory?.path}/${apiUser}/${download?.personalDirectory?.filename}`,
            downloadTypes: [
                ...(download?.downloadTypes?.filter((type, index, self) =>
                        // Remove duplicate types by id
                        index === self.findIndex((t) => (
                            t.id === type.id
                        ))
                ) || []),
                {
                    id: 'personal',
                    type: 'personal',
                    color: ''
                }
            ],
            isSelected: false,
            isPersonal: true
        }))
    });
    return downloadTypes;
}


export function filterMeaDownloadResults(results, filters) {
    return results
        .map((dType) => {
            let meaDownloads = dType.downloads;

            if (filters.dateFrom && filters.dateTo) {
                const fromDate = new Date(filters.dateFrom);
                const toDate = new Date(filters.dateTo);
                meaDownloads = meaDownloads.filter((d) => {
                    const date = new Date(d.createdAt);
                    return date >= fromDate && date <= toDate;
                });
            }

            if (filters.type && filters.type !== DownloadTypes.ALL) {
                meaDownloads = meaDownloads.filter((d) => {
                    return d.downloadTypes.some((t) => t.type === filters.type);
                });
            }

            if (filters.search) {
                meaDownloads = meaDownloads.filter((d) => {
                    return (
                        d.label?.toLowerCase()?.includes(filters.search.toLowerCase()) ||
                        d.description?.toLowerCase()?.includes(filters.search.toLowerCase())
                    );
                });
            }
            return { ...dType, downloads: meaDownloads };
        })
        .filter((dType) => dType?.downloads?.length);
}


export function formatStrapiEducations(educations): Array<EducationInterface> {
    return educations?.map(data => formatStrapiEducation(data));
}

export function formatStrapiEducation(data): EducationInterface {
    if (!data) return;

    const education = {
        ...data,
        category: data?.educationCategory?.category,
        location: {
            ...data?.educationLocation,
            name: data?.educationLocation?.location,
            id: data?.educationLocation?.id
        },
        imageUrl: data?.image?.url
    };

    delete education.educationCategory;
    delete education.educationLocation;
    delete education.image;
    delete education._typename;

    return education;
}

export function getFiltersStrapi(pharmacy: PharmacyStoreInterface) {
    return {
        filter: {
            and: [
                {
                    customerNumbersList: {
                        or: [{customerNumber: {eq: pharmacy?.apiUser}}, {customerNumber: {null: true}}],
                    }
                },
                {
                    or: [
                        {
                            chatUserOnly: {
                                in: [
                                    'alle',
                                    pharmacy?.chatAvailable ? 'hatChat' : 'keinChat'
                                ]
                            }
                        },
                        {chatUserOnly: {null: true}}
                    ],
                },
                {
                    or: [
                        {
                            shopUserOnly: {
                                in: [
                                    'alle',
                                    pharmacy?.shopAvailable ? 'hatShop' : 'keinShop'
                                ]
                            }
                        },
                        {shopUserOnly: {null: true}}
                    ]
                },
                {
                    sanacorpLocations: {
                        or: [
                            {documentId: {eq: pharmacy?.sanacorpLocation}},
                            {documentId: {null: true}}
                        ]
                    }
                },
                {
                    membershipTypes: {
                        or: [
                            {name: {eq: pharmacy?.membershipType}},
                            {name: {null: true}}
                        ]
                    }
                }

            ]
        },
    };
}
