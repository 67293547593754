<ion-badge *ngIf="badge"
           [class]="showBadge ? badge.color : badge.color + ' transparentBadge'"
           [class.clickable]="isClickable"
           [class.no-margin]="noMargin"
           appTooltip
           ttk="{{badge.tooltip ? badge.tooltip.toLowerCase() : null}}"
           [tt]="tt"
           [ttp]="ttp"
           [style.background-color]="backgroundColor ? backgroundColor : null"
           [style.color]="textColor ? textColor : null"
           [style.border-color]="borderColor ? borderColor : null"
           [style.border-width]="borderWidth ? borderWidth : null"
>
    <ion-icon *ngIf="icon && !isIconAfterBadge" [name]="icon"></ion-icon>
    {{ badge.value }}
    <ion-icon *ngIf="icon && isIconAfterBadge" [name]="icon"></ion-icon>
</ion-badge>

