import { createSelector } from '@ngrx/store';
import { OrderUtil } from '../../utils/order-util';
import { MeaPharmacyState } from '../mea-pharmacy.state';

export const selectShopUserMessagesState = (state: MeaPharmacyState) => state.shopUserMessages;

export const selectShopUserMessages = createSelector(selectShopUserMessagesState, ({ shopUserMessagesDictionary }) =>
  Object.values(shopUserMessagesDictionary)
);
export const selectShopUserMessagesLoadStatus = createSelector(
  selectShopUserMessagesState,
  ({ shopUserMessagesLoadStatus }) => shopUserMessagesLoadStatus
);

export const selectShopUsers = createSelector(selectShopUserMessagesState, ({ shopUserMessagesDictionary }) =>
  OrderUtil.mapShopUserMessagesToShopUsers(Object.values(shopUserMessagesDictionary))
);
