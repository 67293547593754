<ion-button (click)="closeModal()" icon="close" modalHeaderButton />
<div class="modal">
  @if (hasShoppingCart()) {
    <div class="mea-headline-l">{{ 'DELETE_CHAT.ORDER_HEADER' | translate }}</div>
    <div class="mea-text-m">{{ 'DELETE_CHAT.ORDER_BODY' | translate }}</div>
  } @else {
    <div class="mea-headline-l">{{ 'DELETE_CHAT.QUESTION_HEADER' | translate }}</div>
    <div class="mea-text-m">{{ 'DELETE_CHAT.QUESTION_BODY' | translate }}</div>
  }
  <mea-button (buttonClick)="deleteConversation()" buttonStyle="danger">{{ 'BUTTONS.DELETE' | translate }}</mea-button>
</div>
