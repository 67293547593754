import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from 'ts-essentials';
import { ChatPartnerMetadata } from '../../../../essentials/types/src/chatPartnerMetadata';
import { isPharmacyChatUser } from '../../../../essentials/types/src/chatUser';
import { ConversationWithLastMessage } from '../../../../essentials/types/src/conversation';
import { ChatOrderWithLastMessage, ShopOrder } from '../../../../essentials/types/src/order';
import { ShopUserMessage } from '../../../../essentials/types/src/shopUserMessage';
import { MeaPharmacyWebChatPartner } from '../../../../essentials/types/src/web-deeplinks';
import { ChatPartnerMetadataUtil } from '../../../../essentials/util/src/chat-partner-metadata.util';
import { MeaDateUtil } from '../../../../essentials/util/src/mea-date.util';
import { ChatConversationsUtil } from '../../../../mea-pharmacy-only/src/utils/chat-conversations.util';
import { OrderUtil } from '../../../../mea-pharmacy-only/src/utils/order-util';
import { ShopUserMessageUtil } from '../../../../mea-pharmacy-only/src/utils/shop-user-message-util';
import { SconnectConversation } from '../types/sconnectConversation';

export class SconnectConversationsUtil extends ChatConversationsUtil {
  public static mapShopUserMessagesToSconnectConversations(
    shopUserMessages: ShopUserMessage[],
    translateService: TranslateService
  ): SconnectConversation[] {
    const shopOrders = shopUserMessages.map(OrderUtil.mapShopUserMessageToShopOrder);
    const sortedOrders = OrderUtil.sortOrders(shopOrders);
    return sortedOrders.map((order) => this.mapShopOrderToSconnectConversation(order, translateService));
  }

  public static mapConversationsToSconnectConversations(
    conversationsWithLastMessage: ConversationWithLastMessage[],
    decryptedChatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>,
    unreadMessagesCountPerConversation: { [conversationId: string]: number },
    translateService: TranslateService
  ): SconnectConversation[] {
    const orders = conversationsWithLastMessage.map(OrderUtil.mapConversationWithLastMessageToChatOrder);
    const sortedOrders = OrderUtil.sortOrders(orders);
    return sortedOrders.map((conversationWithMetadata) =>
      this.mapChatOrderToSconnectConversation(
        conversationWithMetadata,
        decryptedChatPartnerMetadataDictionary,
        unreadMessagesCountPerConversation,
        translateService
      )
    );
  }

  private static mapShopOrderToSconnectConversation(
    { shopUserMessage, orderId }: ShopOrder,
    translateService: TranslateService
  ): SconnectConversation {
    const lastMessageTimestamp = shopUserMessage.createdAt;
    return {
      id: orderId,
      isConversationOpen: !shopUserMessage.archivedByPharmacy,
      lastMessageTimestamp,
      formattedLastMessageTimestamp: MeaDateUtil.transformTimestamp(lastMessageTimestamp, 'long'),
      unreadMessagesCount: shopUserMessage.readByRecipient ? 0 : 1,
      isDecryptingChatPartnerName: false,
      chatPartnerName: ShopUserMessageUtil.getPatientName(shopUserMessage),
      chatPartner: MeaPharmacyWebChatPartner.ShopMail,
      intent: translateService.instant('CHAT.SHOP_CONTEXT.FORM'),
    };
  }

  private static mapChatOrderToSconnectConversation(
    { conversation, lastMessage, orderId }: ChatOrderWithLastMessage,
    decryptedChatPartnerMetadataDictionary: Dictionary<ChatPartnerMetadata>,
    unreadMessagesCountPerConversation: { [conversationId: string]: number },
    translateService: TranslateService
  ): SconnectConversation {
    const chatPartner = conversation.chatPartner;
    const chatPartnerId = ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(conversation);
    const chatPartnerMetadata = decryptedChatPartnerMetadataDictionary[chatPartnerId];
    const chatPartnerName = isPharmacyChatUser(chatPartner)
      ? chatPartner.pharmacy?.name || 'Unbekannte Apotheke'
      : chatPartnerMetadata?.decryptedChatPartnerChatname || chatPartner.chatname || '';

    const isDecryptingChatPartnerName = chatPartnerMetadata?.chatPartnerChatnameDecryptionStatus === 'encrypted';
    const rawIntent = conversation.appContext || conversation.intent;
    const intent = rawIntent ? translateService.instant(`CHAT.APP_CONTEXT.${rawIntent}`) : undefined;

    const lastMessageTimestamp = lastMessage.createdAt;
    return {
      id: orderId,
      isConversationOpen: !conversation.archivedByPharmacy,
      lastMessageTimestamp,
      formattedLastMessageTimestamp: MeaDateUtil.transformTimestamp(lastMessageTimestamp, 'long'),
      unreadMessagesCount: unreadMessagesCountPerConversation?.[conversation.id] || 0,
      isDecryptingChatPartnerName,
      chatPartnerName,
      chatPartner: isPharmacyChatUser(chatPartner)
        ? MeaPharmacyWebChatPartner.Pharmacy
        : MeaPharmacyWebChatPartner.Patient,
      intent,
    };
  }
}
