import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, map, Observable, shareReplay } from 'rxjs';
import { UnreadMessagesCountService } from '../../../common/services/src/unread-messages-count.service';
import { ChatPartnerMetadata } from '../../../essentials/types/src/chatPartnerMetadata';
import { ChatUser, isPharmacyChatUser } from '../../../essentials/types/src/chatUser';
import { Conversation, ConversationWithLastMessage } from '../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../essentials/types/src/loadStatus';
import { ChatPartnerMetadataUtil } from '../../../essentials/util/src/chat-partner-metadata.util';
import { ConversationUtil } from '../../../essentials/util/src/conversation.util';
import {
  pipeConversationsAndLastMessages,
  selectConversationsLoadStatus,
} from '../../../store/src/common-store/chat-store/selectors/chat.selectors';
import { CommonState } from '../../../store/src/common-store/common.state';
import { ChatPartnerDecryptionService } from './chat-partner-decryption.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {
  conversationsLoadStatusError$ = this.store.select(selectConversationsLoadStatus).pipe(
    map((conversationsLoadStatus) => conversationsLoadStatus === LoadStatus.Error),
    distinctUntilChanged()
  );

  conversationsWithLastMessage$: Observable<ConversationWithLastMessage[]> = this.store.pipe(
    pipeConversationsAndLastMessages,
    map((conversationsAndLastMessages) => conversationsAndLastMessages.filter(ConversationUtil.hasLastMessage))
  );

  pharmacyConversationsWithLastMessage$: Observable<ConversationWithLastMessage[]> =
    this.conversationsWithLastMessage$.pipe(
      map((allConversationsWithLastMessage) =>
        allConversationsWithLastMessage.filter(({ conversation }) => isPharmacyChatUser(conversation.chatPartner))
      ),
      shareReplay(1)
    );

  hasUnreadPharmacyMessage$: Observable<boolean> = combineLatest([
    this.pharmacyConversationsWithLastMessage$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(([pharmacyConversationsWithLastMessage, unreadMessageCountPerConversation]) => {
      for (const { conversation } of pharmacyConversationsWithLastMessage) {
        if (unreadMessageCountPerConversation[conversation.id]) {
          return true;
        }
      }
      return false;
    }),
    shareReplay(1)
  );

  enduserConversationsWithLastMessage$: Observable<ConversationWithLastMessage[]> =
    this.conversationsWithLastMessage$.pipe(
      map((conversationsWithLastMessage) =>
        conversationsWithLastMessage.filter(({ conversation }) => !isPharmacyChatUser(conversation.chatPartner))
      ),
      shareReplay(1)
    );

  hasUnreadEnduserMessage$: Observable<boolean> = combineLatest([
    this.enduserConversationsWithLastMessage$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(([enduserConversationsWithLastMessage, unreadMessageCountPerConversation]) => {
      for (const { conversation } of enduserConversationsWithLastMessage) {
        if (unreadMessageCountPerConversation[conversation.id]) {
          return true;
        }
      }
      return false;
    })
  );

  constructor(
    private chatPartnerDecryptionService: ChatPartnerDecryptionService,
    private store: Store<CommonState>,
    private unreadMessagesCountService: UnreadMessagesCountService
  ) {}

  getChatPartnerName$(conversation: Conversation) {
    return this.chatPartnerDecryptionService.decryptedChatPartnerMetadataDictionary$.pipe(
      map((dictionary) => {
        const chatPartnerId = ChatPartnerMetadataUtil.getChatPartnerIdAsPharmacy(conversation);
        const chatPartnerMetadata = dictionary[chatPartnerId];
        const chatPartnerName = this.getChatPartnerName(conversation.chatPartner, chatPartnerMetadata);
        const chatPartnerNickname = chatPartnerMetadata?.decryptedChatPartnerNickname;
        return {
          isDecryptingChatPartnerName: chatPartnerMetadata?.chatPartnerChatnameDecryptionStatus === 'encrypted',
          chatPartnerName,
          chatPartnerNickname,
          isDecryptingChatPartnerNickname: chatPartnerMetadata?.chatPartnerNicknameDecryptionStatus === 'encrypted',
          chatPartnerDisplayName: this.getDisplayName(chatPartnerName, chatPartnerNickname),
        };
      })
    );
  }

  private getChatPartnerName(chatPartner: ChatUser, chatPartnerMetadata: ChatPartnerMetadata | undefined) {
    if (isPharmacyChatUser(chatPartner)) {
      return chatPartner.pharmacy?.name || 'Unbekannte Apotheke';
    } else {
      return chatPartnerMetadata?.decryptedChatPartnerChatname || chatPartner.chatname || 'Unbekannter Nutzer';
    }
  }

  private getDisplayName(name: string, nickname: string | undefined) {
    return nickname ? `${name} (${nickname})` : name;
  }
}
