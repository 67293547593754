import { AfterViewInit, Directive, ElementRef, inject, input, Renderer2 } from '@angular/core';
import { IconNamePipe } from '../../../../essentials/util/src/pipes/icon-name.pipe';

@Directive({
  selector: '[modalHeaderButton]',
  standalone: true,
})
export class ModalHeaderButtonDirective implements AfterViewInit {
  icon = input.required<string>();

  private el = inject(ElementRef);
  private renderer = inject(Renderer2);
  private iconNamePipe = inject(IconNamePipe);

  ngAfterViewInit() {
    const ionButton = this.el.nativeElement;
    this.renderer.setAttribute(ionButton, 'shape', 'round');

    // Create an ion-icon element
    const ionIcon = this.renderer.createElement('ion-icon');
    this.renderer.setAttribute(ionIcon, 'slot', 'icon-only');
    this.renderer.setAttribute(ionIcon, 'aria-label', this.icon());
    this.renderer.setAttribute(ionIcon, 'src', this.iconNamePipe.transform(this.icon()));

    // append the created elements
    this.renderer.appendChild(ionButton, ionIcon);
  }
}
