import { NavigationRoutesEnum } from '../../navigation/navigation.routes';
import { StaticAppLocations } from '../enums/app-location.enum';
import { FullMeaRoutesEnum } from '../enums/mea-routes.enum';
import { NavigationEnum } from '../enums/navigation.enum';

/**
 * get the navigation label by NavigationEnum
 */
export const NavigationLabel = {
    [NavigationEnum.dashboard]: 'Startseite',
    [NavigationEnum.meaChat]: 'mea App',
    [NavigationEnum.mea]: 'mea',
    [NavigationEnum.logistics]: 'Warenlogistik',
    [NavigationEnum.offers]: 'Angebote',
    [NavigationEnum.seminars]: 'Seminare',
    [NavigationEnum.calendar]: 'Kalender',
    [NavigationEnum.statistics]: 'Statistiken',
    [NavigationEnum.representatives]: 'Vertreter',
    [NavigationEnum.knowledge]: 'Pharma Wissen',
    [NavigationEnum.settings]: 'Einstellungen',
    [NavigationEnum.releaseNotes]: 'Was gibt\'s Neues?',
    [NavigationEnum.releaseHistory]: 'Versionshistorie',
    [NavigationEnum.information]: 'Hilfe',
    [NavigationEnum.news]: 'Neuigkeiten',
    [NavigationEnum.sanavendi]: 'SanaVendi',
    [NavigationEnum.communications]: 'Kommunikation',
};

/**
* news app location to base route map
*/
export const NewsLocationRouteMap = {
 [StaticAppLocations.Default]: NavigationRoutesEnum.news,
 [StaticAppLocations.Mea]: FullMeaRoutesEnum.info,
}

/**
* offers app location to base route map
*/
export const OffersLocationRouteMap = {
 [StaticAppLocations.Default]: NavigationRoutesEnum.offers,
 [StaticAppLocations.Mea]: FullMeaRoutesEnum.offers,
}

export function getNewsNavigationUrl(key: StaticAppLocations, url: string): string {
   if (NewsLocationRouteMap.hasOwnProperty(key)) {
       return NewsLocationRouteMap[key];
   }
   return url;
}

export function getLocationFromNewsUrl(url: string): StaticAppLocations {
    for (const [location, route] of Object.entries(NewsLocationRouteMap)) {
        if (route === url) {
            return location as StaticAppLocations;
        }
    }
    return StaticAppLocations.DynamicPage;
}

export function getOffersNavigationUrl(key: StaticAppLocations, url: string): string {
   if (OffersLocationRouteMap.hasOwnProperty(key)) {
       return OffersLocationRouteMap[key];
   }
   return url;
}

export function getLocationFromOffersUrl(url: string): StaticAppLocations {
    for (const [location, route] of Object.entries(OffersLocationRouteMap)) {
        if (route === url) {
            return location as StaticAppLocations;
        }
    }
    return StaticAppLocations.DynamicPage;
}
