import { InjectionToken } from '@angular/core';
import FeatureFlags from './feature-flags';

export const CONFIG = new InjectionToken<MeaConfig>('CONFIG');

export interface MeaConfig {
  featureFlags: FeatureFlags;
  name: 'dev' | 'tstage' | 'e2e' | 'e2e2' | 'staging' | 'csstage' | 'csprod' | 'prod';
  production: boolean;
  clientApp: boolean;
  frontendType: 'pharmacy' | 'enduser' | 'chat-widget' | 'app' | 'pharmacy-app';
  keycloak?: {
    baseUrl: string;
    realm: string;
    shouldAddToken: string;
  };
  aws_config: {
    aws_cognito_identity_pool_id: string;
    aws_cognito_region: string;
    aws_user_pools_id: string;
    aws_mandatory_sign_in: string;
    aws_user_pools_web_client_id: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_graphqlEndpoint: string;
    aws_appsync_region: string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    aws_appsync_authenticationType: string;
  };
  bucket: {
    aws_user_files_s3_bucket: string;
    aws_user_files_s3_bucket_base_url: string;
    aws_image_base_url?: string;
    aws_gerti_images_base_url?: string;
  };
  msgCache: {
    enabled: boolean;
    lifetime: number;
  };
  pushNotificationPublicKey?: string;
  sentryDSN: string;
  animated: boolean;
  checkForHolidays: boolean;

  // chat-widget
  baseUrl?: string;
  endUserBaseUrl?: string;

  // enduser
  meineApothekeUrl?: string;

  // redirectUrl for sconnect
  meaChatUrl?: string;

  // app
  googleMaps?: {
    accessToken: string;
  };
  appVersionMetadataUrl?: string;
  releaseNotesUrl?: string;
}
