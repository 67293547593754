import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output, Signal, TemplateRef } from '@angular/core';
import { TableSidebarInterface, TableSidebarItemIconInterface } from '../../../interfaces/table.interface';
import { ReturnsViewComponent } from '../../../../pages/logistics/pages/returns/widgets/returns-view/returns-view.component';
import { BulkModeVar } from '../../../store/locals/bulk-mode.var';
import { BadgeTypeEnum } from '../../../enums/badge.enum';
import { DownloadsService } from '../../../services/downloads.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
    private bulkModeVar = inject(BulkModeVar);
    private _downloadsService = inject(DownloadsService);

    protected readonly BadgeTypeEnum = BadgeTypeEnum;

    @Input({ required: true }) data: Signal<TableSidebarInterface>;
    @Input({ required: true }) id: string;
    @Input() hasHeader = true;
    @Input() hasFooter = true;
    @Input() hasEditButton = true;
    @Input() actionsPopoverRef?: TemplateRef<any>;
    @Input() detailsModalRef?: TemplateRef<ReturnsViewComponent>;
    @Output() closeSidebar = new EventEmitter<void>();

    bulkModeActive = this.bulkModeVar.isActive;
    meaDownloadsSectionConfig = this._downloadsService.downloadSectionsConfig;

    constructor() {}

    onCloseSidebar() {
        this.closeSidebar.emit();
    }

    getCopyValue(copyIcon: TableSidebarItemIconInterface): string {
        if (copyIcon && copyIcon.copyValue) {
            // Check if it's a function by trying to call it
            if (typeof copyIcon.copyValue === 'function') {
                return copyIcon.copyValue();
            }
            // Otherwise, it's a string
            return copyIcon.copyValue as string;
        }
        return '';
    }

}
