import { Component, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { Moment } from 'moment';
import * as _ from 'lodash/array';

import { DateRangeInterface, NotificationInterface, OrderDetailInterface, ReturnsDetailInterface } from '../../../core/core.interfaces';
import { InformationMutations, NotificationQueries, OrderQueries, ReturnsQueries } from '../../../core/core.store';
import { NotificationService } from '../../../core/services/notification.service';
import { ModalService, OrderService, PopoverService, UtilService } from '../../../core/core.services';
import { unsubscribe, unsubscribeAll } from '../../../core/core.utils';
import { ModalClassEnum } from '../../../core/enums/modal-class.enum';
import {
    NotificationBulkConfig,
    NotificationFiltersConfig,
    NotificationOptionsConfig,
    NotificationBulkActionsConfig,
    NotificationSortConfig,
    NotificationViewConfig,
} from '../../../core/config/notifications.config';
import { GetDateRangeConfig } from '../../../core/config/date-range.config';
import { BadgeTypeEnum } from '../../../core/enums/badge.enum';
import { DateRangeOptionCodes } from '../../../core/enums/date-range.enum';
import { SelectPopoverComponent } from '../../../core/components/select-popover/select-popover.component';
import { NotificationTypes } from '../../../core/config/notification-type.config';
import { ViewTypeEnum } from '../../../core/enums/view-type.enum';
import { NotificationFilterEnum, NotificationOptionEnum, NotificationsEnum } from '../../../core/enums/notifications.enum';
import { SortEnum } from '../../../core/enums/sort.enum';
import { NotificationHasEntryPipe } from '../../../core/pipes/notification-has-entry.pipe';
import { CommunicationZoneFormComponent } from '../../../pages/communications/pages/communication-zone/widgets/communication-zone-form/communication-zone-form.component';
import { BulkActionCodeEnum, BulkOptionCodeEnum } from '../../../core/enums/bulk-action.enum';
import { NotificationGoToEntryService } from '../../../core/services/notification-go-to-entry.service';
import { getReturnsReplacementArray } from '../../../core/config/communication-zone-forms.config';
import { CommunicationZoneType } from '../../../core/core.enums';

@Component({
    selector: 'app-notification-center',
    templateUrl: './notification-center.component.html',
    styleUrls: ['./notification-center.component.scss'],
    providers: [NotificationHasEntryPipe],
    animations: [
        trigger('bulkVisible', [
            state(
                'visible',
                style({
                    opacity: 1,
                    display: 'flex',
                })
            ),
            state(
                'hidden',
                style({
                    opacity: 0,
                    display: 'none',
                })
            ),
            transition('visible=>hidden', animate('250ms')),
            transition('hidden=>visible', animate('250ms')),
        ]),
    ],
})
export class NotificationCenterComponent implements OnInit {
    static modalClass = ModalClassEnum.notificationCenter;
    badgeType = BadgeTypeEnum.NOTIFICATION;
    filters = NotificationFiltersConfig;
    sortOptions = NotificationSortConfig;
    viewOptions = NotificationViewConfig;
    bulkOptions = NotificationBulkConfig();

    dateRangeOption: Array<DateRangeInterface> = GetDateRangeConfig();
    selectedDateRangeOption = DateRangeOptionCodes.all;
    selectedFromDate: Moment;
    selectedToDate: Moment;
    selectedType = NotificationTypes.find((t) => t.id === 'all');
    selectedFilter = this.filters[0].id;
    selectedSort = this.sortOptions[0].id;
    searchText = '';
    selectedView = ViewTypeEnum.LIST;
    listViewType = ViewTypeEnum.LIST;

    isBulkActive = false;
    bulkSelectedItems = [];
    bulkActions = NotificationBulkActionsConfig();

    offset = 0;
    limit = 50;
    totalRows: number;
    notificationCount: number;

    @Input() isDirectEntry: boolean;

    filterText = '';
    activeNotificationId: number;
    order: OrderDetailInterface;
    returns: ReturnsDetailInterface;
    notifications: NotificationInterface[] = [];
    allNotifications: NotificationInterface[] = [];
    notification: NotificationInterface = null;

    markAsReadNotificationIds: number[] = [];

    orderDetailSubscription: Subscription;
    returnsDetailSubscription: Subscription;
    notificationSubscription: Subscription;
    notificationsSubscription: Subscription;
    notificationsCountSubscription: Subscription;

    isInitialized = false;
    isLoading = true;
    isLoadingDetails = true;
    markAllAsRead = true;
    protected readonly NotificationsEnum = NotificationsEnum;
    constructor(
        private popoverService: PopoverService,
        private utilService: UtilService,
        private modalService: ModalService,
        private orderService: OrderService,
        private orderQueries: OrderQueries,
        private returnsQueries: ReturnsQueries,
        private notificationService: NotificationService,
        private notificationGoToEntryService: NotificationGoToEntryService,
        private informationMutations: InformationMutations,
        private notificationQueries: NotificationQueries,
        private notificationHasEntryPipe: NotificationHasEntryPipe
    ) {
        // Debounce mark as read (while user clicks fast through notification)
        this.completeMarkAsRead = this.utilService.debounce(this.completeMarkAsRead, 1000);
    }

    ngOnInit() {
        this.loadNotificationsCount();
    }

    ionViewWillLeave() {
        unsubscribeAll([
            this.orderDetailSubscription,
            this.returnsDetailSubscription,
            this.notificationSubscription,
            this.notificationsSubscription,
            this.notificationsCountSubscription,
        ]);
    }

    /**
     * Load notifications count
     */
    loadNotificationsCount() {
        unsubscribe(this.notificationsCountSubscription);
        this.notificationsCountSubscription = this.notificationQueries
            .getNotificationsCount({
                types: this.selectedType?.group,
                dateOption: this.selectedDateRangeOption,
                dateFrom: this.selectedFromDate,
                dateTo: this.selectedToDate,
                filter: this.selectedFilter,
                sort: this.selectedSort,
                search: this.searchText,
            })
            .subscribe((notificationsCount) => {
                this.notificationCount = notificationsCount || 0;
                this.totalRows = notificationsCount || 0;
                this.getNotifications();
            });
    }

    /**
     * Load notifications
     *
     * @param loadMore - Indicates if the load is a load more or a new load
     * @param onFinished - Callback function to be called when the load is finished
     */
    getNotifications(loadMore = false, onFinished = null) {
        if (!loadMore) {
            this.offset = 0;
        } else if (this.notificationCount >= this.offset * this.limit + this.limit) {
            this.offset += 1;
        }

        unsubscribe(this.notificationsSubscription);
        this.notificationsSubscription = this.notificationQueries
            .getNotifications(
                {
                    types: this.selectedType?.group,
                    dateOption: this.selectedDateRangeOption,
                    dateFrom: this.selectedFromDate,
                    dateTo: this.selectedToDate,
                    filter: this.selectedFilter,
                    sort: this.selectedSort,
                    search: this.searchText,
                },
                this.offset * this.limit,
                this.limit
            )
            .subscribe((notifications) => {
                if (!loadMore) {
                    // If filter is set to unread, keep existing notifications and mark the missing one as read.
                    if (this.selectedFilter === NotificationFilterEnum.UNREAD) {
                        this.notifications = [
                            ...this.notifications?.map((notification) => {
                                const nIndex = notifications?.findIndex((n) => n.id === notification.id) || -1;
                                return nIndex !== -1 ? notification : { ...notification, isRead: true };
                            }),
                        ];
                        this.notifications = _.unionBy(notifications || [], this.notifications, 'id');
                    } else {
                        this.notifications = notifications || [];
                    }
                } else {
                    this.notifications = _.unionBy(notifications || [], this.notifications, 'id');
                }

                this.updateNotifications();

                if (onFinished) {
                    onFinished(notifications ? notifications.length : 0);
                }
                unsubscribe(this.notificationsSubscription);
            });
    }

    /**
     * Combine notifications
     */
    updateNotifications() {
        this.allNotifications = [];
        this.allNotifications.push(...(this.notifications || []));
        this.allNotifications.sort((a, b) => {
            if (this.selectedSort === SortEnum.LATEST) {
                return a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0;
            } else {
                return a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0;
            }
        });
        const activeNotificationFound =
            this.activeNotificationId && this.allNotifications.findIndex((n) => n.id === this.activeNotificationId) !== -1;
        this.onActiveNotificationIdChange(
            activeNotificationFound ? this.activeNotificationId : this.allNotifications[0]?.id,
            !this.isInitialized
        );
        this.isLoading = false;
        this.isLoadingDetails = false;
    }

    /**
     * Filter notifications by selected filter
     *
     * @param filterId - Id of the selected filter
     */
    onFilterChange(filterId) {
        this.isLoadingDetails = true;
        this.selectedFilter = this.filters.find((filter) => filter.id === filterId)?.id;
        this.bulkActions = NotificationBulkActionsConfig(
            this.selectedFilter !== NotificationFilterEnum.ARCHIVED,
            this.selectedFilter !== NotificationFilterEnum.UNREAD,
            this.selectedFilter !== NotificationFilterEnum.READ
        );
        this.notifications = [];
        this.bulkSelectedItems = [];
        this.onBulkOptionChange(null);

        this.notificationQueries.notificationFiltersChanged();
        this.loadNotificationsCount();
    }

    /**
     * Search notifications title by search text
     *
     * @param searchText - Search text
     */
    onSearchChange(searchText) {
        this.isLoadingDetails = true;
        this.searchText = searchText.trim();
        this.notifications = [];
        this.bulkSelectedItems = [];
        this.onBulkOptionChange(null);
        this.loadNotificationsCount();
    }

    /**
     * Sort notifications by selected sort
     *
     * @param sortId - Id of the selected sort
     */
    onSortChange(sortId) {
        this.isLoadingDetails = true;
        this.selectedSort = this.sortOptions.find((sort) => sort.id === sortId)?.id;
        this.loadNotificationsCount();
    }

    /**
     * Updates the date filter
     *
     * @param event - Event containing the date range option and the from and to date
     */
    updateDateFilter(event) {
        this.isLoadingDetails = true;
        this.notifications = [];
        this.bulkSelectedItems = [];
        this.onBulkOptionChange(null);
        let option = DateRangeOptionCodes.individual;

        if (event.dateRangeId && event.dateRangeId !== DateRangeOptionCodes.individual) {
            option = event.dateRangeId;
        }
        const from = event.fromDate;
        const to = event.toDate;
        this.selectedDateRangeOption = option;
        this.selectedFromDate = from;
        this.selectedToDate = to;
        this.loadNotificationsCount();
    }

    /**
     * Change the current list view
     *
     * @param viewId - Id of the selected view
     */
    onViewChange(viewId) {
        this.selectedView = viewId;
    }

    /**
     * User selected a bulk option to de-/activate the bulk selection or to un-/select all notifications
     *
     * @param bulkOptionId - Id of the selected bulk option
     */
    onBulkOptionChange(bulkOptionId) {
        if (bulkOptionId) {
            // Every action except deactivation is considered as activation
            this.isBulkActive = bulkOptionId !== BulkOptionCodeEnum.DEACTIVATE;

            if (bulkOptionId === BulkOptionCodeEnum.SELECT_NONE) {
                this.bulkSelectedItems = [];
            }

            if (bulkOptionId === BulkOptionCodeEnum.SELECT_ALL) {
                this.bulkSelectedItems = this.allNotifications.map((n) => n.id);
            }
        }

        // Update bulk options in popover
        this.bulkOptions = NotificationBulkConfig(
            this.isBulkActive,
            this.bulkSelectedItems.length === this.allNotifications.length && this.allNotifications.length !== 0
        );
    }

    /**
     * User clicked on a bulk action button
     * @param code - Code of the clicked bulk action
     */
    onBulkActionClick(code: BulkActionCodeEnum) {
        switch (code) {
            case BulkActionCodeEnum.ARCHIVE:
                this.informationMutations.archiveMultipleNotifications(this.bulkSelectedItems);
                if (this.bulkSelectedItems.includes(this.activeNotificationId)) {
                    this.activeNotificationId = null;
                    this.notification = null;
                }

                break;
            case BulkActionCodeEnum.DELETE:
                this.informationMutations.deleteMultipleNotifications(this.bulkSelectedItems);
                if (this.bulkSelectedItems.includes(this.activeNotificationId)) {
                    this.activeNotificationId = null;
                    this.notification = null;
                }

                break;
            case BulkActionCodeEnum.MARK_AS_READ:
            case BulkActionCodeEnum.MARK_AS_UNREAD:
                const isRead = code === BulkActionCodeEnum.MARK_AS_READ;
                this.informationMutations.upsertMultipleNotificationReadStatus(
                    this.bulkSelectedItems.map((id) => ({ notificationId: id })),
                    isRead
                );

                this.allNotifications = [
                    ...this.allNotifications.map((n) => {
                        if (this.bulkSelectedItems.includes(n.id)) {
                            return { ...n, isRead };
                        }
                        return n;
                    }),
                ];

                // Update current selected notification
                if (this.bulkSelectedItems.includes(this.activeNotificationId)) {
                    this.notification = { ...this.notification, isRead };
                }
                break;
        }

        this.bulkSelectedItems = [];
        this.isBulkActive = false;
        this.onBulkOptionChange(null);
    }

    /**
     * User has selected or unselected one or multiple items
     * @param items - Selected items
     */
    onChangeBulkSelectedItems(items) {
        this.bulkSelectedItems = [...items];
        this.onBulkOptionChange(null);
    }

    /**
     * User has selected a notification
     *
     * @param id - Id of the selected notification
     * @param isUserInput - True if the user has selected the notification, false if the notification was selected by the component
     */
    onActiveNotificationIdChange(id, isUserInput = false) {
        // Use isInitialized flag to mark the first notification as read.
        // if this.notification is null, it means that the component is just initialized and no notification is selected.
        if (this.notification) {
            this.isInitialized = true;
        }

        this.notification = null;
        this.activeNotificationId = id;

        if (!id) return;

        this.notification = this.allNotifications.find((n) => n.id === id);

        if (this.notification?.payload?.realOrderId) {
            unsubscribe(this.orderDetailSubscription);
            this.orderDetailSubscription = this.orderQueries
                .getOrderById(parseInt(this.notification.payload.realOrderId, 10))
                .subscribe(async (order) => {
                    this.order = order;
                });
        }
        if (this.notification?.payload?.returnsId) {
            unsubscribe(this.returnsDetailSubscription);
            this.returnsDetailSubscription = this.returnsQueries
                .getReturnById(parseInt(this.notification.payload.returnsId.toString(), 10))
                .subscribe(async (returns) => {
                    this.returns = returns;
                });
        }

        if (!this.notification.isRead && isUserInput) {
            this.markAsReadNotificationIds.push(this.notification.id);
            this.completeMarkAsRead();

            this.allNotifications = [
                ...this.allNotifications.map((n) => {
                    if (n.id === this.notification.id) {
                        return { ...n, isRead: true };
                    }
                    return n;
                }),
            ];
            // Update current selected notification
            this.notification = { ...this.notification, isRead: true };
        }
    }

    /**
     * Load additional notifications as the user scrolls down
     *
     * @param event - Event of the scroll
     */
    onLoadMoreNotifications(event) {
        this.getNotifications(true, (newItems) => {
            // Hide infinite loading spinner for now
            event.complete();
            // if there is no new result, disable endless loading;
            if (newItems === 0) {
                event.disable();
            }
        });
    }

    /**
     * User has clicked on a notification option
     */
    async onNotificationOptionClick() {
        const popover = await this.popoverService.create({
            component: SelectPopoverComponent,
            componentProps: {
                options: NotificationOptionsConfig.map((option) => {
                    const hasGoToEntry = this.notificationHasEntryPipe.transform(this.notification.type);
                    if (option.id === NotificationOptionEnum.GO_TO_ENTRY) {
                        return { ...option, disabled: !hasGoToEntry };
                    }
                    if (option.id === NotificationOptionEnum.MARK_AS_UNREAD) {
                        return { ...option, disabled: !this.notification.isRead };
                    }
                    if (option.id === NotificationOptionEnum.ARCHIVE) {
                        return { ...option, disabled: !this.notification || (this.notification && this.notification.isArchive) };
                    }
                    return option;
                }).filter((option) => {
                    switch (this.notification.type) {
                        case NotificationsEnum.NEW_USER_WELCOME:
                            return (
                                option.id !== NotificationOptionEnum.GO_TO_DATA_POLICY && option.id !== NotificationOptionEnum.GO_TO_ENTRY
                            );
                        case NotificationsEnum.NEW_USER_DATA_POLICY:
                            return option.id !== NotificationOptionEnum.GO_TO_HELP && option.id !== NotificationOptionEnum.GO_TO_ENTRY;
                        default:
                            return (
                                option.id !== NotificationOptionEnum.GO_TO_HELP && option.id !== NotificationOptionEnum.GO_TO_DATA_POLICY
                            );
                    }
                }),
            },
            translucent: true,
            cssClass: 'popover-auto-width',
        });

        popover.onDidDismiss().then((result) => {
            if (result && result.data && result.data.id) {
                switch (result.data.id) {
                    case NotificationOptionEnum.GO_TO_ENTRY:
                    case NotificationOptionEnum.GO_TO_HELP:
                    case NotificationOptionEnum.GO_TO_DATA_POLICY:
                        this.onToEntryClick();
                        break;

                    case NotificationOptionEnum.MARK_AS_READ:
                    case NotificationOptionEnum.MARK_AS_UNREAD:
                        this.onChangeReadStatusClick(result.data.id === NotificationOptionEnum.MARK_AS_READ);
                        break;

                    case NotificationOptionEnum.ARCHIVE:
                        this.onChangeArchiveStatusClick();
                        break;
                }
            }
        });

        return await this.popoverService.present(popover);
    }

    /**
     * User has clicked the archive button
     */
    onChangeArchiveStatusClick() {
        if (!this.notification.isRead) {
            this.onChangeReadStatusClick(true);
        }

        // Remove notification from current list
        this.allNotifications = [...this.allNotifications.filter((n) => n.id !== this.notification.id)];
        this.informationMutations.archiveNotification(this.notification.id);
        this.activeNotificationId = null;
        this.notification = null;
    }

    /**
     * User has clicked the delete button
     */
    onDeleteClick() {
        // Remove notification from current list
        this.allNotifications = [...this.allNotifications.filter((n) => n.id !== this.notification.id)];
        this.informationMutations.deleteNotification(this.notification.id);
        this.activeNotificationId = null;
        this.notification = null;
    }

    /**
     * User has clicked the read/unread button
     *
     * @param markAsRead - mark item as read or unread, which changes its styling
     */
    onChangeReadStatusClick(markAsRead: boolean) {
        this.allNotifications = [
            ...this.allNotifications.map((notification) => {
                if (notification.id === this.notification.id) {
                    return { ...notification, isRead: markAsRead };
                }
                return notification;
            }),
        ];
        this.notification = { ...this.notification, isRead: markAsRead };
        this.notificationService.setNotificationAsRead([{ id: this.notification.id }], markAsRead);
    }

    /**
     * User has clicked the type filter button
     *
     * @param event - Event of the click
     */
    async onTypeFilterClick(event) {
        const popover = await this.popoverService.create({
            component: SelectPopoverComponent,
            componentProps: {
                options: NotificationTypes.map((type) => ({ ...type, title: type.value })),
                selectedKey: this.selectedType.id,
            },
            event,
            translucent: true,
            cssClass: 'popover-auto-width',
        });

        popover.onDidDismiss().then((result) => {
            if (result && result.data && result.data.id) {
                this.isLoadingDetails = true;
                this.notifications = [];
                this.bulkSelectedItems = [];
                this.onBulkOptionChange(null);
                this.selectedType = NotificationTypes.find((type) => type.id === result.data.id);
                this.loadNotificationsCount();
            }
        });

        return await this.popoverService.present(popover);
    }

    /**
     * User has clicked the "go to entry" button
     */
    async onToEntryClick() {
        await this.notificationGoToEntryService.goToEntry(this.notification as NotificationInterface);
    }

    /**
     * Handle additional actions click
     */
    async onAdditionalActionsClick() {
        const isAvailable = await this.notificationGoToEntryService.checkGoToEntry(this.notification as NotificationInterface);
        if (!isAvailable) {
            return;
        }

        switch (this.notification.type) {
            case NotificationsEnum.ORDER_DISPO_DIRECT_OUT_OF_DATE:
                await this.notificationGoToEntryService.goToEntry(this.notification as NotificationInterface);
                await this.orderService.presentAcknowledgeAlert(this.order);
                break;
            case NotificationsEnum.RETURNS_PRODUCT_MISSING:
            case NotificationsEnum.RETURNS_STOCK_ERROR:
                await this.modalService.dismiss();
                const modal = await this.modalService.create(CommunicationZoneFormComponent, {
                    // To prevent errors, we have to make sure the id is a number
                    id: parseInt(this.notification.payload?.returnsId.toString(), 10),
                    isReadOnly: true,
                    type: CommunicationZoneType.RETURN,
                    replaceArray: getReturnsReplacementArray(true, this.returns),
                });
                await this.modalService.present(modal);
                break;
        }
    }

    /**
     * Complete mark as read of selected notifications
     */
    completeMarkAsRead = () => {
        this.notificationService.setNotificationAsRead(
            this.markAsReadNotificationIds.map((n) => ({ id: n })),
            true
        );
        this.markAsReadNotificationIds = [];
    };
}
