import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { Conversation } from '../../../essentials/types/src/conversation';
import { ChatPartnerMetadataServiceInterface } from '../../../essentials/types/src/service-interfaces/chat-partner-metadata.service.interface';
import {
  selectChatPartnerLoadStatus,
  selectChatPartnerMetadataDictionary,
  selectChatPartnerSuspendedStatus,
} from '../../../store/src/pharmacy/pharmacy-chat-partner-store/pharmacy-chat-partner.selectors';
import { MeaPharmacyState } from '../store/mea-pharmacy.state';

@Injectable()
export class PharmacyChatPartnerMetadataService implements ChatPartnerMetadataServiceInterface {
  public readonly chatPartnerMetadataDictionary$ = this.store.select(selectChatPartnerMetadataDictionary);
  public readonly chatPartnerLoadStatus$ = this.store.select(selectChatPartnerLoadStatus);

  constructor(private store: Store<MeaPharmacyState>) {}

  isChatPartnerSuspended(conversation: Conversation) {
    return this.store.select(selectChatPartnerSuspendedStatus(conversation.chatPartner));
  }

  isSuspendedByChatPartner(_conversation: Conversation) {
    return of(false);
  }
}
