import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';
import { CustomToastController } from '../../../../common/ui-components/src/ionic/controllers/custom-toast.controller';
import { ChatStatusUtil } from '../../../../essentials/util/src/chat-status.util';
import { Logger } from '../../../../essentials/util/src/logger';
import { rehydrateUser, setUserOnLogin } from '../../../../store/src/common-store/user-store/actions/user.actions';
import { ConversationPopoverOptionsService } from '../../services/conversation-popover-options.service';
import { AppsyncShopUserMessageService } from './appsync-shop-user-message.service';
import {
  loadAllShopUserMessages,
  loadAllShopUserMessagesFailure,
  loadAllShopUserMessagesSuccess,
  loadRecentShopUserMessages,
  loadRecentShopUserMessagesFailure,
  loadRecentShopUserMessagesSuccess,
  revertProgressUpdateForShopUserMessage,
  updateProgressHistoryForShopUserMessage,
} from './shop-user-messages.actions';
import { ShopUserMessagesService } from './shop-user-messages.service';

const logger = new Logger('ShopUserMessagesEffects');

@Injectable()
export class ShopUserMessagesEffects {
  private actions$ = inject(Actions);
  private store = inject(Store);
  private appsyncShopUserMessageService = inject(AppsyncShopUserMessageService);
  private conversationPopoverOptionsService = inject(ConversationPopoverOptionsService);
  private shopUserMessagesService = inject(ShopUserMessagesService);
  private toastController = inject(CustomToastController);

  loadShopUserMessagesOnLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setUserOnLogin),
      map(() => loadRecentShopUserMessages())
    )
  );

  loadRecentShopUserMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecentShopUserMessages),
      switchMap(async () => {
        try {
          const shopUserMessages = await this.shopUserMessagesService.loadRecentShopUserMessages();
          return loadRecentShopUserMessagesSuccess({ shopUserMessages });
        } catch (e) {
          logger.error('error loading recent shop user messages', e);
          return loadRecentShopUserMessagesFailure();
        }
      })
    )
  );

  refreshShopUserMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRecentShopUserMessagesSuccess, loadRecentShopUserMessagesFailure, rehydrateUser),
      map(() => loadAllShopUserMessages())
    )
  );

  loadAllShopUserMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllShopUserMessages),
      switchMap(async () => {
        try {
          const shopUserMessages = await this.shopUserMessagesService.loadAllShopUserMessages();
          return loadAllShopUserMessagesSuccess({ shopUserMessages });
        } catch (e) {
          logger.error('error loading all shop user messages', e);
          return loadAllShopUserMessagesFailure();
        }
      })
    )
  );

  updateProgressHistoryForShopUserMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateProgressHistoryForShopUserMessage),
        switchMap(async ({ shopUserMessage, newProgressEvent }) => {
          try {
            const wasArchivedByPharmacy = !!shopUserMessage.archivedByPharmacy;
            const newArchiveStatus = ChatStatusUtil.isCompleted(newProgressEvent.status);
            await this.appsyncShopUserMessageService.updateProgressStatus(shopUserMessage.id, newProgressEvent);
            if (wasArchivedByPharmacy !== newArchiveStatus) {
              await this.conversationPopoverOptionsService.setArchiveTagInShopUserMessage(
                shopUserMessage.id,
                newArchiveStatus
              );
            }
          } catch (e) {
            logger.error('Error while updating progress history for shop user message', e);
            this.store.dispatch(
              revertProgressUpdateForShopUserMessage({
                shopUserMessageId: shopUserMessage.id,
                progressEventToRemove: newProgressEvent,
              })
            );
            await this.toastController.createAndPresentToast({ message: 'CHAT_UPDATES.PROGRESS_STATUS_CHANGE_FAILED' });
          }
        })
      ),
    { dispatch: false }
  );

  subscribeToShopUserMessages$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAllShopUserMessages),
        tap(async () => {
          try {
            await this.shopUserMessagesService.subscribeToNewShopUserMessages();
            await this.shopUserMessagesService.subscribeToUpdatedShopUserMessages();
            await this.shopUserMessagesService.subscribeToDeletedShopUserMessages();
          } catch (error) {
            logger.error('error subscribing to shop user messages', error);
          }
        })
      ),
    { dispatch: false }
  );
}
