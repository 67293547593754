import { Location } from '@angular/common';
import { inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import isEqual from 'lodash-es/isEqual';
import { BehaviorSubject, firstValueFrom, Subject, SubscriptionLike } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { CONFIG } from '../../../../../essentials/types/src/mea-config';

const meaModalState = {
  type: 'mea-modal',
};

interface DismissMetaData {
  data?: any;
  role?: string;
  id?: string;
}

interface CustomModalControllerOptions {
  skipPushToBrowserHistory?: boolean;
}

@Injectable({ providedIn: 'root' })
export class CustomModalController implements OnDestroy {
  private modalCtrl = inject(ModalController);
  private location = inject(Location);
  private router = inject(Router);
  private config = inject(CONFIG);

  private popStateSubscription: SubscriptionLike | undefined;
  private readonly closeModalOnBrowserBack: boolean;

  private dismissMetaData: DismissMetaData = {};

  private unsubscribe$ = new Subject<void>();
  private isNavigating$ = new BehaviorSubject<boolean>(false);
  private modalDismissed$ = new BehaviorSubject<boolean>(false);

  constructor() {
    this.closeModalOnBrowserBack = this.config.featureFlags.enableBrowserBackOnCustomModalClosing;
    if (this.closeModalOnBrowserBack) {
      this.dismissModalOnNavigation();
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
          map((event) => !(event instanceof NavigationEnd)),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((isNavigating) => this.isNavigating$.next(isNavigating));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.closeModalOnBrowserBack && this.popStateSubscription) {
      this.popStateSubscription.unsubscribe();
    }
  }

  async getTop(): Promise<HTMLIonModalElement | undefined> {
    return this.modalCtrl.getTop();
  }

  async create(opts: ModalOptions, customOptions: CustomModalControllerOptions = {}): Promise<HTMLIonModalElement> {
    this.modalDismissed$.next(false);
    if (this.closeModalOnBrowserBack && !customOptions.skipPushToBrowserHistory) {
      await this.waitForRouterNavigationToFinish();
      if (!this.historyIsInMeaModalState()) {
        this.pushMeaModalStateToHistory();
      }
      const modal = await this.modalCtrl.create(opts);
      modal.onWillDismiss().then(() => {
        if (this.historyIsInMeaModalState()) {
          this.navigateBack();
        }
      });
      return modal;
    } else {
      return this.modalCtrl.create(opts);
    }
  }

  async dismiss(data?: any, role?: string, id?: string) {
    if (this.closeModalOnBrowserBack && this.historyIsInMeaModalState()) {
      await this.waitForRouterNavigationToFinish();
      this.dismissMetaData = { data, role, id };
      this.navigateBack();
      return firstValueFrom(this.modalDismissed$.pipe(filter((dismissed) => dismissed)));
    } else {
      return this.modalCtrl.dismiss(data, role, id);
    }
  }

  private dismissModalOnNavigation() {
    this.popStateSubscription = this.location.subscribe(async ({ state }) => {
      const modalIsPresent = await this.modalIsPresent();
      if (state !== meaModalState && modalIsPresent) {
        const { data, role, id } = this.dismissMetaData;
        await this.modalCtrl.dismiss(data, role, id);
        this.dismissMetaData = {};
        this.modalDismissed$.next(true);
      }
    });
  }

  private async waitForRouterNavigationToFinish() {
    await firstValueFrom(
      this.isNavigating$.pipe(
        takeUntil(this.unsubscribe$),
        filter((isNavigating) => !isNavigating)
      )
    );
  }

  private async modalIsPresent(): Promise<boolean> {
    return !!(await this.modalCtrl.getTop());
  }

  private historyIsInMeaModalState() {
    return isEqual(this.location.getState(), meaModalState);
  }

  private navigateBack(): void {
    this.location.back();
  }

  private pushMeaModalStateToHistory(): void {
    history.pushState(meaModalState, document.title, document.URL);
  }
}
