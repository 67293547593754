import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
    subscription updatedShopUserMessage($pharmacyCognitoId: ID!) {
        updatedShopUserMessage(pharmacyCognitoId: $pharmacyCognitoId) {
            ${shopUserMessageAttributes}
        }
    }
`;
