import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
  query getRecentShopUserMessages($lastDays: Int, $nextToken: String) {
      getRecentShopUserMessages(lastDays: $lastDays, nextToken: $nextToken) {
      __typename
      shopUserMessages {
        ${shopUserMessageAttributes}
      }
      nextToken
    }
  }
`;
