import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import { tap } from 'rxjs/operators';
import { getIsSmallDevice } from '../../../../../essentials/util/src/getIsSmallDevice';
import { CommonState } from '../../common.state';
import { setIsSmallDevice, setMinimiseEmailNotification, setShowEmailNotification } from '../actions/device.actions';
import { MINIMISE_EMAIL_NOTIFICATION, SHOW_EMAIL_NOTIFICATION } from '../reducers/device.reducer';

@Injectable()
export class DeviceEffects {
  setShowEmailNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setShowEmailNotification),
        tap(({ showEmailNotification }) => localStorage.setItem(SHOW_EMAIL_NOTIFICATION, `${showEmailNotification}`))
      ),
    { dispatch: false }
  );

  setMinimiseEmailNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setMinimiseEmailNotification),
        tap(({ minimiseEmailNotification }) =>
          localStorage.setItem(MINIMISE_EMAIL_NOTIFICATION, `${minimiseEmailNotification}`)
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<CommonState>
  ) {
    fromEvent(window, 'resize').subscribe(() => {
      this.setIsSmallDevice();
    });
  }

  private setIsSmallDevice() {
    const isSmallDevice = getIsSmallDevice();
    this.store.dispatch(setIsSmallDevice({ isSmallDevice }));
  }
}
