import { inject, Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { StorageService } from '../../../common/services/src/storage.service';
import { PharmacyLoginDetailsWithCustomerId } from '../../../essentials/types/src/service-interfaces/single-sign-on.service.interface';
import { assertUnreachable } from '../../../essentials/util/src/typescript.util';

export enum UnreadMessagesStorage {
  Enduser = 'enduser',
  Pharmacy = 'pharmacy',
  Shop = 'shop',
  Total = 'total',
}

const UNREAD_ENDUSER_MESSAGES_IDS_STORAGE_KEY = 'unreadEnduserMessagesIdsStorageKey';
const UNREAD_PHARMACY_MESSAGES_IDS_STORAGE_KEY = 'unreadPharmacyMessagesIdsStorageKey';
const UNREAD_SHOP_MESSAGES_IDS_STORAGE_KEY = 'unreadShopMessagesIdsStorageKey';
const TOTAL_UNREAD_MESSAGES_IDS_STORAGE_KEY = 'totalUnreadMessagesIdsStorageKey';
const SELECTED_SSO_PHARMACY_STORAGE_KEY = 'storedSelectedSsoPharmacy';

@Injectable({
  providedIn: 'root',
})
export class SsoPharmacyStorageService {
  private storage = inject(StorageService);

  async getSelectedSsoPharmacy(): Promise<PharmacyLoginDetailsWithCustomerId | null> {
    return this.storage.get(SELECTED_SSO_PHARMACY_STORAGE_KEY);
  }

  async setSelectedSsoPharmacy(selectedSsoPharmacy: PharmacyLoginDetailsWithCustomerId) {
    await this.storage.set(SELECTED_SSO_PHARMACY_STORAGE_KEY, selectedSsoPharmacy);
  }

  async getStoredUnreadMessagesIds(cognitoId: string, storage: UnreadMessagesStorage): Promise<Set<string>> {
    const storageKey = this.getUnreadMessagesStorageKey(cognitoId, storage);
    return (await this.storage.get(storageKey)) || new Set<string>();
  }

  async setUnreadMessagesIdsInStorage(
    cognitoId: string,
    unreadMessagesIds: Set<string>,
    storage: UnreadMessagesStorage
  ) {
    const storageKey = this.getUnreadMessagesStorageKey(cognitoId, storage);
    await this.storage.set(storageKey, unreadMessagesIds);
  }

  async updateTotalUnreadMessagesIdsInStorage({
    cognitoId,
    messageId,
    isNew,
  }: {
    cognitoId: string;
    messageId: string;
    isNew: boolean;
  }) {
    const storageKey = this.getUnreadMessagesStorageKey(cognitoId, UnreadMessagesStorage.Total);
    const storedUnreadMessagesIds = await this.getStoredUnreadMessagesIds(cognitoId, UnreadMessagesStorage.Total);
    const updatedUnreadMessagesIds = cloneDeep(storedUnreadMessagesIds);
    if (isNew) {
      updatedUnreadMessagesIds.add(messageId);
    } else if (updatedUnreadMessagesIds.has(messageId)) {
      updatedUnreadMessagesIds.delete(messageId);
    }
    await this.storage.set(storageKey, updatedUnreadMessagesIds);
  }

  private getUnreadMessagesStorageKey(cognitoId: string, storage: UnreadMessagesStorage): string {
    switch (storage) {
      case UnreadMessagesStorage.Enduser:
        return `${UNREAD_ENDUSER_MESSAGES_IDS_STORAGE_KEY}_${cognitoId}`;
      case UnreadMessagesStorage.Pharmacy:
        return `${UNREAD_PHARMACY_MESSAGES_IDS_STORAGE_KEY}_${cognitoId}`;
      case UnreadMessagesStorage.Shop:
        return `${UNREAD_SHOP_MESSAGES_IDS_STORAGE_KEY}_${cognitoId}`;
      case UnreadMessagesStorage.Total:
        return `${TOTAL_UNREAD_MESSAGES_IDS_STORAGE_KEY}_${cognitoId}`;
      default:
        return assertUnreachable(storage);
    }
  }
}
