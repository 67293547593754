export const CalendarColors = {
    sanacorp: {
        primary: '#fff',
        secondary: 'var(--ion-color-cal-sanacorp-bg)',
        secondaryText: 'var(--ion-color-cal-sanacorp-color)',
    },
    user: {
        primary: '#fff',
        secondary: 'var(--ion-color-cal-user-bg)',
        secondaryText: 'var(--ion-color-cal-user-color)',
    },
    pharmacy: {
        primary: '#fff',
        secondary: 'var(--ion-color-cal-pharmacy-bg)',
        secondaryText: 'var(--ion-color-cal-pharmacy-color)',
    },
    common: {
        primary: '#fff',
        secondary: 'var(--ion-color-cal-common-bg)',
        secondaryText: 'var(--ion-color-cal-common-color)',
    },
};
