import { InjectionToken } from '@angular/core';
import { ChatOrder, ChatOrderWithLastMessage, ShopOrder } from '../order';

export const CONVERSATION_POPOVER_OPTIONS_SERVICE = new InjectionToken<ConversationPopoverOptionsServiceInterface>(
  'CONVERSATION_POPOVER_OPTIONS_SERVICE'
);

export interface ConversationPopoverOptionsServiceInterface {
  getPopoverOptions(
    order: ChatOrder | ChatOrderWithLastMessage | ShopOrder,
    deleteOption?: boolean
  ): { text: string; handler: () => any }[];
  setArchiveTagInConversation(conversationId: string, newStatus: boolean): Promise<void>;
  setArchiveTagInShopUserMessage(shopUserMessageId: string, newStatus: boolean): Promise<void>;
}
