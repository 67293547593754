import gql from 'graphql-tag';

export default gql`
  subscription pharmacyChatUserUpdated($cognitoId: ID!) {
    pharmacyChatUserUpdated(cognitoId: $cognitoId) {
      __typename
      cognitoId
      customAppointmentTypes
      hiddenDefaultAppointmentTypes
      migratedToAppointmentsV2
      addedHasPrescriptionsToConversations
      emailNotification
      initialMessages {
        askQuestion
        askQuestionClosed
        preorder
        preorderClosed
        productQuery
        productQueryClosed
      }
      userStatus
      addons {
        __typename
        type
        status
        subscriptionTimestamp
        expirationTimestamp
      }
      favoritedByAppUserIds
      favoritedByAppUserCountAtStartOfMonth
      holidays {
        state
        customHolidays
      }
      vacation {
        from
        until
      }
      emergencyOpeningStatus
      customPickupTimes
    }
  }
`;
