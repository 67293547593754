import { EventEmitter, Injectable } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {
  BackendMessage,
  BackendMessageUpdate,
  PaginatedBackendMessages,
} from '../../../../essentials/types/src/backendMessage';
import Message from '../../../../essentials/types/src/message';
import { UnreadMessagesUtil } from '../../../../essentials/util/src/unread-messages.util';
import createMessage, { CreateMessageInput } from '../../../resources/src/graphql/mutations/createMessage';
import deleteMessage from '../../../resources/src/graphql/mutations/deleteMessage';
import setMessageAsRead from '../../../resources/src/graphql/mutations/setMessageAsRead';
import setOwnMessageAsRead from '../../../resources/src/graphql/mutations/setOwnMessageAsRead';
import startTyping, { Typing } from '../../../resources/src/graphql/mutations/startTyping';
import getConversationMessages from '../../../resources/src/graphql/queries/getConversationMessages';
import getUnreadMessagesForPharmacy from '../../../resources/src/graphql/queries/getUnreadMessagesForPharmacy';
import createdMessage from '../../../resources/src/graphql/subscriptions/createdMessage';
import createdOwnMessage from '../../../resources/src/graphql/subscriptions/createdOwnMessage';
import selfStartedTyping from '../../../resources/src/graphql/subscriptions/selfStartedTyping';
import startedTyping from '../../../resources/src/graphql/subscriptions/startedTyping';
import updatedReceivedMessage from '../../../resources/src/graphql/subscriptions/updatedReceivedMessage';
import updatedSentMessage from '../../../resources/src/graphql/subscriptions/updatedSentMessage';
import { AppsyncService, AppsyncServiceClient } from './appsync.service';

@Injectable({ providedIn: 'root' })
export class AppsyncMessageService {
  onOwnMessage = new EventEmitter<BackendMessage>();

  constructor(private appSync: AppsyncService) {}

  // ************* Query *************

  async loadMessagesForSegmentFromBackend(segmentId: string, nextToken?: string): Promise<PaginatedBackendMessages> {
    const client = await this.appSync.getClient();
    const variables: any = { conversationId: segmentId };
    if (nextToken) {
      variables.nextToken = nextToken;
    }
    const options = {
      query: getConversationMessages,
      variables,
    };

    const { data } = await client.query(options);
    if (data) {
      return {
        messages: cloneDeep(data.getMessagesByConversation.messages).reverse(),
        nextToken: data.getMessagesByConversation.nextToken,
      };
    } else {
      return { messages: [], nextToken: null };
    }
  }

  async getTotalUnreadMessagesIdsForSsoPharmacy(cognitoId: string): Promise<Set<string>> {
    const client = await this.appSync.getClient();
    let next: string | null | undefined;
    const unreadMessages: BackendMessage[] = [];
    do {
      const { messages, nextToken } = await this.getPaginatedUnreadMessagesForSsoPharmacy({
        client,
        cognitoId,
        nextToken: next,
      });
      unreadMessages.push(...messages);
      next = nextToken;
    } while (next);
    const filteredUnreadMessagesIds = UnreadMessagesUtil.getUnreadMessagesIds(cognitoId, unreadMessages);
    return new Set<string>(filteredUnreadMessagesIds);
  }

  async getPaginatedUnreadMessagesForSsoPharmacy({
    client,
    cognitoId,
    nextToken,
  }: {
    client: AppsyncServiceClient;
    cognitoId: string;
    nextToken: string | null | undefined;
  }): Promise<PaginatedBackendMessages> {
    const variables: any = { cognitoId };
    if (nextToken) {
      variables.nextToken = nextToken;
    }
    const options = { query: getUnreadMessagesForPharmacy, variables };
    const { data } = await client.query(options);
    if (data) {
      return {
        messages: data.getUnreadMessagesForPharmacy.messages,
        nextToken: data.getUnreadMessagesForPharmacy.nextToken,
      };
    } else {
      return { messages: [], nextToken: null };
    }
  }

  // ************* Mutations *************

  async createMessage(message: CreateMessageInput): Promise<void> {
    const client = await this.appSync.getClient();
    const {
      data: { createMessage: response },
    } = await client.mutate({
      mutation: createMessage,
      variables: message,
    });
    this.onOwnMessage.emit(response);
  }

  async emitStartTyping(variables: Typing): Promise<Typing> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: startTyping,
      variables,
    });
    return data.startTyping;
  }

  async markMessageAsRead(message: Message): Promise<BackendMessageUpdate> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: setMessageAsRead,
      variables: { messageId: message.id },
    });
    return data.setMessageAsRead;
  }

  async markOwnMessageAsRead(message: Message): Promise<BackendMessageUpdate> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: setOwnMessageAsRead,
      variables: { messageId: message.id },
    });
    return data.setOwnMessageAsRead;
  }

  async deleteMessage(messageId: string): Promise<BackendMessageUpdate> {
    const client = await this.appSync.getClient();
    const { data } = await client.mutate({
      mutation: deleteMessage,
      variables: { messageId },
    });
    return data.deleteMessage;
  }

  // ************* Subscriptions *************

  newOwnBackendMessages(senderId: string): Observable<BackendMessage> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdOwnMessage,
            variables: { senderId },
          })
          .pipe(map(({ data: { createdOwnMessage: message } }) => message))
      )
    );
  }

  newMessages(recipientId: string): Observable<BackendMessage> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdMessage,
            variables: { recipientId },
          })
          .pipe(map(({ data: { createdMessage: message } }) => message))
      )
    );
  }

  updatedSentMessages(senderId: string): Observable<BackendMessageUpdate> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedSentMessage,
            variables: { senderId },
          })
          .pipe(map(({ data: { updatedSentMessage: message } }) => message))
      )
    );
  }

  updatedReceivedMessages(recipientId: string): Observable<BackendMessageUpdate> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedReceivedMessage,
            variables: { recipientId },
          })
          .pipe(map(({ data: { updatedReceivedMessage: message } }) => message))
      )
    );
  }

  startedTyping(cognitoId: string): Observable<{ conversationId: string; participantId: string }> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: startedTyping,
            variables: { otherParticipantId: cognitoId },
          })
          .pipe(map(({ data }) => data.startedTyping))
      )
    );
  }

  selfStartedTyping(cognitoId: string): Observable<Typing> {
    return from(this.appSync.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: selfStartedTyping,
            variables: { participantId: cognitoId },
          })
          .pipe(map(({ data }) => data.selfStartedTyping))
      )
    );
  }
}
