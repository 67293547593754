import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UtilService {
    debounce(func, wait) {
        let timeout;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    debouncePromise(func, wait) {
        let timeout;
        return function() {
            const context = this;
            const args = arguments;
            clearTimeout(timeout);
            return new Promise((resolve, reject) => {
                timeout = setTimeout(() => {
                    timeout = null;
                    func.apply(context, args)
                        .then(resolve)
                        .catch(reject);
                }, wait);
            });
        };
    }

    /**
     * Sorts an array of objects based on a certain property.
     * If a value is provided, objects with that value are prioritized and put at
     * the beginning of the sorted array regardless.
     * @param value - the array to sort
     * @param priorityProperty - the property to sort by
     * @param priorityValue - the optional value that gets prioritized when being sorted
     */
    prioritySortArray<T>(value: Array<T>, priorityProperty: any, priorityValue?: any): T[] {
        if (!value) {
            return [];
        }
        if (!priorityProperty) {
            return value;
        }
        if (value.length === 0) {
            return [];
        }

        // Return if the property doesn't exist on T
        if (value[0] && !value[0].hasOwnProperty(priorityProperty)) {
            return value;
        }

        return value.sort((a, b) : number => {
            if (priorityValue) {
                if (a[priorityProperty] === priorityValue) {
                    return -1;
                }
                if (b[priorityProperty] === priorityValue) {
                    return 1;
                }
            }

            return a[priorityProperty].localeCompare(
                b[priorityProperty],
                undefined,
                {
                    numeric: true,
                    sensitivity: 'base'
                });
        });
    }

}
