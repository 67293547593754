import { Component, HostBinding, HostListener, inject, Input } from '@angular/core';

import { AppointmentInterface } from '../../core.interfaces';
import { CalendarService } from '../../../pages/calendar/calendar.service';

@Component({
    selector: 'app-appointment-badge',
    templateUrl: './appointment-badge.component.html',
    styleUrls: ['./appointment-badge.component.scss'],
    providers: [CalendarService]
})
export class AppointmentBadgeComponent {
    private _calendarService = inject(CalendarService);

    @Input() appointment: AppointmentInterface;
    @Input() showActions = false;
    @Input() showAppointmentTitle = false;
    @Input() smallVersion = false;
    @Input() showPopoverLeft = true;

    @HostBinding('class') get class() {
        if(this.appointment.lockedBySanacorp) {
            return 'is-sanacorp-appointment';
        } else if(this.appointment.isPharmacy) {
            return 'is-pharmacy-appointment';
        } else {
            return 'is-user-appointment';
        }
    }

    /**
     * User has pressed a appointment item
     *
     * @param event : Event - Click Event
     */
    @HostListener('click', ['$event']) async onClick(event: Event) {
        await this._calendarService.openCalendarPopover(
            {
                dateFrom: new Date(this.appointment.dateTimeFrom),
                dateTo: new Date(this.appointment.dateTimeTo),
                id: this.appointment.id,
                title: this.appointment.title,
                description: this.appointment.description,
                icon: this.appointment.payload?.icon,
                formId: this.appointment.formId,
                formButton: this.appointment.formButton,
                isPharmacy: this.appointment.isPharmacy,
                lockedBySanacorp: this.appointment.lockedBySanacorp,
                isAllDay: this._calendarService.checkIfIsAllDay(this.appointment.dateTimeFrom, this.appointment.dateTimeTo),
                event,
            },
            false,
            true,
            !this.showPopoverLeft ? 'right' : 'left'
        );
    }
}
