import { NotificationsEnum } from '../core.enums';

export const NotificationMessageConfig: Record<NotificationsEnum, string> = {
    [NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR]:
        'mea® Kooperation: Herzlichen Glückwunsch, Sie haben den Jahreszielumsatz erreicht und sich damit den Kooperationsbonus fürs Jahr {{currentYear}} gesichert.',
    [NotificationsEnum.COMMON_NOTIFICATION]: '',
    [NotificationsEnum.MEAMIND_NEW_ANSWER]: '',
    [NotificationsEnum.MEAMIND_NEW_COMMENT]: '',
    [NotificationsEnum.MEAMIND_NEW_QUESTION]: '',
    [NotificationsEnum.MEAMIND_UPDATE_ANSWER]: '',
    [NotificationsEnum.MEAMIND_UPDATE_COMMENT]: '',
    [NotificationsEnum.MEAMIND_UPDATE_QUESTION]: '',
    [NotificationsEnum.MEASHOP_NEW_ORDER]: '',
    [NotificationsEnum.NEW_ARCHIVE]: '',
    [NotificationsEnum.NEW_ASSORTMENT_STATISTIC]:
        'Die von Ihnen beantragte Bezugsstatistik {{type}} für den Zeitraum {{timespan}} ist verfügbar.\n\nSie können diese wie gewohnt im Statistikbereich abrufen oder direkt hier herunterladen oder ausdrucken:\n',
    [NotificationsEnum.NEW_DOCUMENT]:
        'Eine neue Rechnung für {{yearMonth}} ist verfügbar.\n\n Sie können diese wie gewohnt in der Warenlogistik abrufen oder direkt hier herunterladen oder ausdrucken:\n',
    [NotificationsEnum.NEW_IMPORTANT_NEWS]: '"Wichtig: {{newsTitle}}',
    // Not used because written in strapi entry
    [NotificationsEnum.NEW_MAINTENANCE]: '',
    [NotificationsEnum.NEW_MEACHATMESSAGE]: '',
    [NotificationsEnum.NEW_NEWS]: '{{newsTitle}}',
    [NotificationsEnum.NEW_OFFER]:
        'Nutzen Sie die Bestellmöglichkeit zu den angegebenen Preisen\nGültigkeit: {{offerFromDate}} bis {{offerToDate}}',
    [NotificationsEnum.NEW_RELEASE_NOTE]: 'Neuer Versionshinweis {{publishDate}} - {{version}} verfügbar.',
    [NotificationsEnum.NEW_STATIC_PAGE]: '',
    [NotificationsEnum.NEW_SURVEY]: 'Es ist eine neue Umfrage verfügbar. Gültig von {{fromDate}} bis {{toDate}}.\n{{introductionText}}',
    [NotificationsEnum.NEW_USER_DATA_POLICY]: '',
    [NotificationsEnum.NEW_USER_WELCOME]: '',
    [NotificationsEnum.ORDER_ADDITIONAL_DEADLINE]: '',
    [NotificationsEnum.ORDER_CANCEL_ERROR]: '',
    [NotificationsEnum.ORDER_DISPO_DIRECT_OUT_OF_DATE]: '',
    [NotificationsEnum.ORDER_EXTEND_ERROR]: '',
    [NotificationsEnum.ORDER_QUANTITY_CHANGE]: '',
    [NotificationsEnum.ORDER_STATUS_CANCELLED]: '',
    [NotificationsEnum.ORDER_STATUS_ENROUTE]: '',
    [NotificationsEnum.RETURNS_PRODUCT_MISSING]: '',
    [NotificationsEnum.RETURNS_STOCK_ERROR]: '',
    [NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED]:
        'Sie haben mit den übermittelten Bestellungen Ihr Umsatzziel für Warenbezüge (aller Auftragsarten, ohne Hochpreiser und ohne Gutschriften) erreicht.\n\nVielen Dank für Ihr Vertrauen.',
    [NotificationsEnum.TOUR_NOTIFICATION]: '',
};
