import { inject, Pipe, PipeTransform } from '@angular/core';
import { DownloadTypesInterface } from '../interfaces/mea.interface';
import { DownloadsService } from '../services/downloads.service';

@Pipe({
    name: 'removeDownloadTypes',
})
export class RemoveDownloadTypesPipe implements PipeTransform {
    private _downloadsService = inject(DownloadsService);

    transform(value: DownloadTypesInterface[], ...types: string[]): DownloadTypesInterface[] {
        return this._downloadsService.removeDownloadTypes(value, ...types);
    }
}
