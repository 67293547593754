import { inject, Injectable } from '@angular/core';
import { Appointment } from '../../../../essentials/types/src/appointment';
import { EncryptionService } from './encryption.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentEncryptionService {
  private encryptionService = inject(EncryptionService);

  decryptAppointmentsV1(appointments: Appointment[], privateKey?: string): Appointment[] {
    if (!privateKey) {
      return appointments;
    }
    return appointments.map((appointment) => {
      if (appointment.bookedByName && appointment.bookedByNameIsEncrypted) {
        const decryptedName = this.decryptStringV1(appointment.bookedByName, appointment, privateKey);
        appointment.bookedByName = decryptedName || 'Name konnte nicht entschlüsselt werden';
      }
      if (appointment.encryptedConversationId) {
        const decryptedConversationId = this.decryptStringV1(
          appointment.encryptedConversationId,
          appointment,
          privateKey
        );
        appointment.decryptedConversationId = decryptedConversationId || undefined;
      }
      if (appointment.encryptedNote) {
        const decryptedNote = this.encryptionService.decryptUsingPrivateKey(appointment.encryptedNote, privateKey);
        appointment.decryptedNote = decryptedNote || 'Notiz konnte nicht entschlüsselt werden';
      }
      return appointment;
    });
  }

  encryptMandatoryCustomerFields(bookedByName: string, pharmacyPublicKey: string, enduserPublicKey?: string) {
    const nameEncryptedForPharmacy = this.encryptionService.encryptUsingPublicKey(bookedByName, pharmacyPublicKey);
    if (!nameEncryptedForPharmacy) {
      return { name: { pharmacy: '' } };
    }

    if (enduserPublicKey) {
      return {
        name: {
          pharmacy: nameEncryptedForPharmacy,
          enduser: this.encryptionService.encryptUsingPublicKey(bookedByName, enduserPublicKey) || '',
        },
      };
    } else {
      return { name: { pharmacy: nameEncryptedForPharmacy } };
    }
  }

  // not used in production anymore, but still useful for unit tests
  encryptStringV1(string: string, appointment: Appointment, publicKey: string): string | false {
    const saltedName = this.saltStringV1(string, appointment);
    return this.encryptionService.encryptUsingPublicKey(saltedName, publicKey);
  }

  private decryptStringV1(encryptedString: string, appointment: Appointment, privateKey: string): string | false {
    const saltedName = this.encryptionService.decryptUsingPrivateKey(encryptedString, privateKey);
    return saltedName && this.extractStringV1(saltedName, appointment);
  }

  private saltStringV1(name: string, appointment: Appointment): string {
    return `${appointment.pharmacyCognitoId}_${appointment.dateTime}_${name}`;
  }

  private extractStringV1(saltedString: string, appointment: Appointment): string {
    return saltedString.replace(`${appointment.pharmacyCognitoId}_${appointment.dateTime}_`, '');
  }
}
