import { ThemeColorInterface } from '../interfaces/theme-color.interface';
import { ThemeColorEnum } from '../enums/color.enum';

export const ThemeColorConfig: ThemeColorInterface = {
    primary: {
        mainColor: ThemeColorEnum.PRIMARY,
        secondaryColor: ThemeColorEnum.PRIMARY_SHADE,
        shadeColor: ThemeColorEnum.PRIMARY_SHADE,
        textColor: ThemeColorEnum.PRIMARY_CONTRAST,
    },
    primaryWhite: {
        mainColor: ThemeColorEnum.PRIMARY_CONTRAST,
        secondaryColor: ThemeColorEnum.LIGHT_SHADE,
        shadeColor: ThemeColorEnum.LIGHT_SHADE,
        textColor: ThemeColorEnum.PRIMARY_SHADE,
    },
    primaryInverse: {
        mainColor: ThemeColorEnum.PRIMARY_CONTRAST,
        secondaryColor: ThemeColorEnum.PRIMARY,
    },
    mea: {
        mainColor: ThemeColorEnum.MEA,
        secondaryColor: ThemeColorEnum.LIGHT,
    },
    meaNotification: {
        mainColor: ThemeColorEnum.PRIMARY_CONTRAST,
        secondaryColor: ThemeColorEnum.MEA,
        textColor: ThemeColorEnum.MEA,
    },
    blue: {
        mainColor: ThemeColorEnum.BLUE,
        shadeColor: ThemeColorEnum.BLUE_SHADE,
    }
};
