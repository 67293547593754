import { gql } from '@apollo/client/core';
import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map, Observable, Subscription, switchMap, tap } from 'rxjs';

import { QueryFetchPolicy } from '../../../enums/api.enum';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import {
    OffersBannerInterface,
    OffersFilterInterface,
    OfferDetailsInCartInterface,
    OffersInterface,
    OffersInfoInterface
} from '../../../interfaces/offers.interface';
import { today } from '../../../formatting/date.formatting';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';
import { DataChangedStateVar } from '../../locals/dataChangeState.var';
import { formatStrapiOffersConnectionData, getFiltersStrapi } from '../utils';
import { unsubscribeAll } from '../../../core.utils';
import { QueryWrapper } from '../query.wrapper';
import { FetchPolicyKeys as FPK } from '../../../enums/fetch-policy-keys.enum';
import { DataChangedKeys as DCK } from '../../../enums/data-changed-keys.enum';
import { PharmacyStoreInterface } from '../../../interfaces/customer.interface';
import { StaticAppLocations } from '../../../enums/app-location.enum';
import { getLocationFromOffersUrl, OffersLocationRouteMap } from '../../../config/navigation.config';

export const offerFields = `
    id : documentId
    enableForAllPharmacies
    customerNumbers
    name
    appLocations {
      url
      isMea
      type
    }
    fromDate
    toDate
    publishedAt
    isExtended
    image {
        url
    }
    bannerContentRight {
        interfererHead
        interfererContent
    }
    actionBadge {
        text
        backgroundColor
        textColor
    }
    imageType
    bannerTexts {
        headline
        subheadline
        content
    }
    offerFilters(pagination: {limit: $offerFiltersLimit}) {
        membershipTypes {
            name
        }
    }`;
export const GetOffersWithDetails = (queryName) => gql`
    query ${queryName}($filters: OfferFiltersInput, $offerProductsLimit: Int, $offerGraduatedPriceLimit: Int, $offerFiltersLimit: Int, $status: PublicationStatus) {
        offers(filters: $filters, pagination: {limit: 1}, status: $status) {
            id : documentId
            name
            orderType
            appLocations {
                url
                isMea
                type
            }
            fromDate
            toDate
            description
            descriptionHtml
            checkoutAggreement
            publishedAt
            image {
                url
            }
            enableForAllPharmacies
            customerNumbers
            offerFilters(pagination: {limit: $offerFiltersLimit}) {
                membershipTypes {
                    name
                }
            }
            offerProducts(pagination: {limit: $offerProductsLimit}) {
                id
                isActive
                graduatedPrice(pagination: {limit: $offerGraduatedPriceLimit}) {
                    quantity
                    price
                    discount
                }
                offerProduct {
                    id : documentId
                    productName
                    unit
                    vaccineDoses
                    dosageForm
                    pzn
                    producer
                    aep
                    contingent
                    productDescription
                    image {
                        url
                    }
                }
            }
        }
    }
`;

export const GetOffersBannerByOfferId = (queryName) => gql`
    query ${queryName}($filters: OfferFiltersInput, $offerNewsLimit: Int, $offerFiltersLimit: Int, $status: PublicationStatus) {
        offers(filters: $filters, pagination: {limit: 1}, status: $status) {
            id : documentId
            enableForAllPharmacies
            customerNumbers
            name
            appLocations {
                url
                isMea
                type
            }
            description
            descriptionHtml
            publishedAt
            image {
                url
            }
            imageType
            bannerContentRight {
                interfererHead
                interfererContent
            }
            bannerTexts {
                headline
                subheadline
                content
            }
            offerNews(pagination: {limit: $offerNewsLimit}) {
                news
            }
            offerFilters(pagination: {limit: $offerFiltersLimit}) {
                membershipTypes {
                    name
                }
            }
        }
    }
`;

export const GetOffersBanner = (queryName) => gql`
    query ${queryName}($limit: Int, $filters: OfferFiltersInput, $sort: [String], $offerFiltersLimit: Int, $status: PublicationStatus) {
        offers(pagination: {limit: $limit}, filters: $filters, sort: $sort, status: $status) {
            ${offerFields}
        }
    }
`;

export const GetOffersInfo = (queryName) => gql`
    query ${queryName}($limit: Int, $filters: OfferFiltersInput, $sort: [String], $offerFiltersLimit: Int, $status: PublicationStatus) {
        offers(pagination: {limit: $limit}, filters: $filters, sort: $sort, status: $status) {
            id : documentId
            enableForAllPharmacies
            customerNumbers
            fromDate
            toDate
            publishedAt
            image {
                url
            }
            imageType
            bannerTexts {
                headline
            }
            offerFilters(pagination: {limit: $offerFiltersLimit}) {
                membershipTypes {
                    name
                }
            }
        }
    }
`;

export const GetOffersFilter = (queryName) => gql`
    query ${queryName}($filters: OfferFiltersInput, $offerFiltersLimit: Int, $offerProductsLimit: Int, $status: PublicationStatus) {
        offers(filters: $filters, pagination: {limit: 1}, status: $status) {
            id : documentId
            name
            appLocations {
                url
                isMea
                type
            }
            orderType
            offerCanBuy
            enableForAllPharmacies
            customerNumbers
            fromDate
            toDate
            publishedAt
            offerFilters(pagination: {limit: $offerFiltersLimit}) {
                membershipTypes {
                    name
                }
            }
            offerProducts(pagination: {limit: $offerProductsLimit}) {
                offerProduct {
                    id: documentId
                    contingent
                }
            }
        }
    }
`;

export const getOfferQuote = (queryName) => gql`
    query ${queryName}($limit: Int, $offerId: String) {
        offerQuote(limit: $limit, where: {offerId: {_eq: $offerId}}) {
            id
            offerId
            offerQuoteItems {
                productId
                quantity
            }
        }
    }
`;

export const GetOrderedQuantities = (queryName) => gql`
    query ${queryName}($limit: Int, $productIds: _varchar) {
        getProductOrderedQuantities(limit: $limit, args: { product_ids: $productIds }) {
            productId
            totalOrderedQuantity
        }
    }
`;


export const AllOffersQueries = [
    GetOffersWithDetails('test'),
    GetOffersBannerByOfferId('test'),
    GetOffersBanner('test'),
    GetOffersInfo('test'),
    GetOffersFilter('test'),
    getOfferQuote('test'),
    GetOrderedQuantities('test')
];

@Injectable()
export class OffersQueries extends QueryWrapper {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    fetchPolicies = {
        [FPK.getOffersWithDetails]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersBanner]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersBannerByOfferId]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersInfo]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOffersFilter]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOfferQuote]: QueryFetchPolicy.NETWORK_ONLY,
        [FPK.getOrderedQuantities]: QueryFetchPolicy.NETWORK_ONLY
    };

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    constructor(
        private apollo: Apollo,
        private dataChangedVar: DataChangedStateVar
    ) {
        super(apollo, dataChangedVar, {
            [DCK.offerChanged]: [FPK.getOffersBanner, FPK.getOffersBannerByOfferId, FPK.getOffersFilter, FPK.getOffersInfo, FPK.getOffersWithDetails],
            [DCK.offerProductChanged]: [FPK.getOffersWithDetails],
            [DCK.offerQuoteChanged]: [FPK.getOfferQuote],
            [DCK.offerOrderContingentChanged]: [FPK.getOrderedQuantities]
        });
    }

    offersListSubscription: Subscription;

    public unsubscribeAll() {
        unsubscribeAll([
            this.offersListSubscription
        ]);
    }

    public getOffersWithDetails(offerId): Observable<OffersInterface> {
        const fetchPolicyKey = FPK.getOffersWithDetails;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersWithDetails(fetchPolicyKey),
                        variables: {
                            ...this.getOffersVariables(pharmacy, offerId),
                            offerProductsLimit: GraphQLLimits.offerProducts,
                            offerGraduatedPriceLimit: GraphQLLimits.offerGraduatedPrice,
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(
                        map((d) => d?.data && d?.data['offers'] && d?.data['offers']?.length > 0 && d?.data['offers'][0])
                    )
                    .pipe(map((offersWithDetailsData) => formatStrapiOffersConnectionData(offersWithDetailsData, null)))
                    .pipe(map((offer: OffersInterface) => this.sortGraduatedPrices(offer)))
                    .pipe(map((offer: OffersInterface) => this.padPZN(offer)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersBanner(appLocationUrl: string): Observable<OffersBannerInterface[]> {
        const fetchPolicyKey = FPK.getOffersBanner;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersBanner(fetchPolicyKey),
                        variables: {
                            ...this.getOffersVariables(pharmacy, null, GraphQLLimits.offers, appLocationUrl),
                            sort: ['fromDate:desc', 'toDate:asc'],
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(map((d) => d?.data && d?.data['offers']))
                    .pipe(map((offersBannerData) => formatStrapiOffersConnectionData(offersBannerData, appLocationUrl)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersBannerByOfferId(offerId): Observable<OffersBannerInterface> {
        const fetchPolicyKey = FPK.getOffersBannerByOfferId;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersBannerByOfferId(fetchPolicyKey),
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                        variables: {
                            ...this.getOffersVariables(pharmacy, offerId),
                            offerNewsLimit: GraphQLLimits.offerNews,
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                    })
                    .valueChanges.pipe(
                        map((d) => d?.data && d?.data['offers'] && d?.data['offers']?.length > 0 && d?.data['offers'][0])
                    )
                    .pipe(map((offersBannerData) => formatStrapiOffersConnectionData(offersBannerData, null)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersInfo(): Observable<OffersInfoInterface[]> {
        const fetchPolicyKey = FPK.getOffersInfo;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersInfo(fetchPolicyKey),
                        variables: {
                            ...this.getOffersVariables(pharmacy),
                            sort: ['fromDate:desc', 'toDate:asc'],
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(map((d) => d?.data && d?.data['offers']))
                    .pipe(map((offersInfoData) => formatStrapiOffersConnectionData(offersInfoData, null)))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOffersFilter(offerId: string): Observable<OffersFilterInterface> {
        const fetchPolicyKey = FPK.getOffersFilter;
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) =>
                this.apollo
                    .watchQuery({
                        query: GetOffersFilter(fetchPolicyKey),
                        variables: {
                            ...this.getOffersVariables(pharmacy, offerId),
                            offerFiltersLimit: GraphQLLimits.offerFilters,
                            offerProductsLimit: GraphQLLimits.offerProducts
                        },
                        fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
                    })
                    .valueChanges.pipe(
                        map((d) => d?.data && d?.data['offers'] && d?.data['offers']?.length > 0 && d?.data['offers'][0])
                    )
                    .pipe(map((offersFilterData) => formatStrapiOffersConnectionData(offersFilterData, null)))
                    .pipe(map(offer => {
                        if (offer && offer.offerProducts) {
                            return {
                                ...offer,
                                offerProducts: offer.offerProducts.map(product => ({
                                    id: product.offerProduct.id,
                                    contingent: product.offerProduct.contingent
                                }))
                            };
                        }
                        return offer;
                    }))
                    .pipe(
                        map((d) => {
                            if (d) this.updateFetchPolicy(fetchPolicyKey);
                            return d;
                        })
                    )
            )
        );
    }

    public getOfferQuote(offerId: string): Observable<OfferDetailsInCartInterface[]> {
        const fetchPolicyKey = FPK.getOfferQuote;
        return this.apollo.watchQuery({
            query: getOfferQuote(fetchPolicyKey),
            variables: {offerId, limit: GraphQLLimits.offers},
            fetchPolicy:this.getFetchPolicy(fetchPolicyKey),
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['offerQuote']))
            .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<OfferDetailsInCartInterface[]>;
    }

    public getOrderedQuantities(productIds: string[]): Observable<Array<{productId: string, totalOrderedQuantity: number}>> {
        const fetchPolicyKey = FPK.getOrderedQuantities;

        // Format the array into the required string format: "{id1,id2,id3}"
        const formattedIds = `{${productIds.join(',')}}`;

        return this.apollo.watchQuery({
            query: GetOrderedQuantities(fetchPolicyKey),
            variables: { limit: GraphQLLimits.offers, productIds: formattedIds },
            fetchPolicy: this.getFetchPolicy(fetchPolicyKey),
        })
        .valueChanges
        .pipe(map(d => d?.data['getProductOrderedQuantities'] || []))
        .pipe(map((d) => {if (d) this.updateFetchPolicy(fetchPolicyKey); return d;})) as Observable<Array<{productId: string, totalOrderedQuantity: number}>>;
    }

    private sortGraduatedPrices = (offer: OffersInterface): OffersInterface => {
        return (offer?.offerProducts && offer.offerProducts.length > 0) ? {
                    ...offer,
                    offerProducts: offer.offerProducts.map(offerProduct => (
                        (offerProduct.graduatedPrice && offerProduct.graduatedPrice.length > 0) ? (
                            {
                                ...offerProduct,
                                graduatedPrice: offerProduct.graduatedPrice
                                    .slice().sort((a, b) => a.quantity > b.quantity ? 1 : -1)
                            }
                        ) : offerProduct
                    ))
            } : offer;
    }

    private padPZN = (offer: OffersInterface): OffersInterface => {
        return offer ? {
            ...offer,
            offerProducts: offer?.offerProducts ? offer.offerProducts.map(offerProduct => ({
                    ...offerProduct,
                    offerProduct: {...offerProduct.offerProduct, pzn: offerProduct.offerProduct.pzn?.padStart(8, '0')}
                }
            )) : []
        } : offer;
    }


    public getOffersVariables = (
        pharmacy: PharmacyStoreInterface,
        offerId = null,
        limit: number = GraphQLLimits.offers,
        appLocationUrl: string = OffersLocationRouteMap[StaticAppLocations.Default]
    ): {filters: {}, limit?: number, status?: string} => {
        const todayAsString = today();
        const additionalFilters = getFiltersStrapi(pharmacy);
        if (offerId) {
            return {
                status: 'PUBLISHED',
                filters: {
                    documentId: {eq: offerId},
                    fromDate: {
                        lte: todayAsString
                    },
                    toDate: {
                        gte: todayAsString
                    },
                    publishedAt: {notNull: true},
                    ...additionalFilters
                }
            };
        }

        const appLocationType = getLocationFromOffersUrl(appLocationUrl);
        const strapiUrl = (appLocationType === StaticAppLocations.DynamicPage && appLocationUrl) ? [{url: {eq: appLocationUrl}}] : [];

        return {
            status: 'PUBLISHED',
            limit,
            filters: {
                and: [
                    {fromDate: {lte: todayAsString}},
                    {toDate: {gte: todayAsString}},
                    {publishedAt: {notNull: true}},
                    (appLocationType ? {
                        appLocations: {
                            and: [
                                {type: {eq: appLocationType}},
                                ...strapiUrl
                            ]
                        }
                    } : {}),
                    additionalFilters
                ]
            }
        };
    };
}
