import { Component, Input } from '@angular/core';
import { NotificationInterface } from '../../../../../core/core.interfaces';
import { formatDateTimeToDateTime } from '../../../../../core/core.formatting';

@Component({
    selector: 'app-notification-maintenance-content',
    templateUrl: './notification-maintenance-content.component.html',
    styleUrls: ['./notification-maintenance-content.component.scss'],
})
export class NotificationMaintenanceContentComponent {
    formattedMessage: string;

    @Input() notificationPayloadId: string;

    private _notification: NotificationInterface;
    @Input()
    set notification(value: NotificationInterface) {
        this._notification = value;
        // reset formatted message
        this.formattedMessage = '';

        let message = this.notification?.payload['message'];
        if (message) {
            if (this.notification?.payload['duration'] && this.notification?.payload['startDate']) {
                const datetime = formatDateTimeToDateTime(this.notification.payload['startDate']);
                message = message.replace('{{duration}}', this.notification.payload['duration']);
                message = message.replace('{{datetime}}', datetime);
            } else {
                message = message.replace('{{duration}}', '');
                message = message.replace('{{datetime}}', '');
            }

            this.formattedMessage = message;
        }
    }
    get notification(): NotificationInterface {
        return this._notification;
    }
}
