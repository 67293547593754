import { createSelector } from '@ngrx/store';
import { SsoPharmaciesState } from './sso-pharmacies.state';

const selectSsoPharmaciesState = (state: { ssoPharmacies: SsoPharmaciesState }) => state.ssoPharmacies;

export const selectSsoPharmacies = createSelector(selectSsoPharmaciesState, ({ ssoPharmacies }) => ssoPharmacies);
export const selectUnreadMessagesForInactiveSsoPharmacies = createSelector(
  selectSsoPharmaciesState,
  ({ unreadMessagesForInactiveSsoPharmacies }) => unreadMessagesForInactiveSsoPharmacies
);
