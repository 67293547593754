import { Injectable } from '@angular/core';
import createTextblock from '../../../../../common/resources/src/graphql/mutations/textblock/createTextblock';
import deleteTextblock from '../../../../../common/resources/src/graphql/mutations/textblock/deleteTextblock';
import updateTextblock from '../../../../../common/resources/src/graphql/mutations/textblock/updateTextblock';
import { AppsyncService } from '../../../../../common/services/src/appsync/appsync.service';
import { Textblock, TextblockCreateInput, TextblockEditInput } from '../../../../../essentials/types/src/textblock';

@Injectable({
  providedIn: 'root',
})
export class TextblockMutationService {
  constructor(private appsync: AppsyncService) {}

  async createTextblock(textblock: TextblockCreateInput) {
    const client = await this.appsync.getClient();
    const { data } = await client.mutate({
      mutation: createTextblock,
      variables: { ...textblock },
    });
    return data.createTextblock;
  }

  async updateTextblock(textblock: TextblockEditInput): Promise<Textblock> {
    const client = await this.appsync.getClient();
    const { data } = await client.mutate({
      mutation: updateTextblock,
      variables: textblock,
    });
    return data.updateTextblock;
  }

  async deleteTextblock(textblockId: string): Promise<Partial<Textblock>> {
    const client = await this.appsync.getClient();
    const { data } = await client.mutate({
      mutation: deleteTextblock,
      variables: { id: textblockId },
    });
    return data.deleteTextblock;
  }
}
