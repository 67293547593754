import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { filter, mergeMap, take } from 'rxjs/operators';
import { CustomToastController } from '../../../../common/ui-components/src/ionic/controllers/custom-toast.controller';
import { Textblock, TextblockCreateInput } from '../../../../essentials/types/src/textblock';
import { Logger } from '../../../../essentials/util/src/logger';
import {
  createTextblock,
  deleteTextblock,
  deleteTextblockFailure,
  deleteTextblockSuccess,
  loadTextblocksIfNotPresent,
  restoreTextblock,
  restoreTextblockFailure,
  restoreTextblockSuccess,
  setTextblocks,
  updateTextblock,
  updateTextblockFailure,
  updateTextblockSuccess,
} from '../../../../store/src/pharmacy/textblocks/actions/textblock.actions';
import {
  selectTextblock,
  selectTextblocks,
} from '../../../../store/src/pharmacy/textblocks/selectors/textblock.selectors';
import { SettingsPage } from '../../pharmacy-routes';
import { MeaPharmacyState } from '../mea-pharmacy.state';
import { TextblockLoadService } from './services/textblock-load.service';
import { TextblockMutationService } from './services/textblock-mutation.service';

const logger = new Logger('TextblockEffects');

@Injectable()
export class TextblockEffects {
  loadTextblocksIfNotPresent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadTextblocksIfNotPresent),
      mergeMap(() =>
        this.store.select(selectTextblocks).pipe(
          take(1),
          filter((textblocks) => !textblocks || Object.keys(textblocks).length === 0),
          mergeMap(async () => {
            const textblocks = await this.textblockLoadService.getAllTextblocks();
            return setTextblocks({ textblocks });
          })
        )
      )
    )
  );

  createTextblock$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createTextblock),
        mergeMap(async ({ textblock }) => {
          await firstValueFrom(
            this.store.select(selectTextblock(textblock.id)).pipe(filter((createdTextblock) => !!createdTextblock))
          );
          await this.router.navigate([...SettingsPage.textblocks, textblock.id]);
        })
      ),
    { dispatch: false }
  );

  updateTextblock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTextblock),
      mergeMap(async ({ textblock }) => {
        try {
          let textblockResponse;
          if (textblockIsDefault(textblock)) {
            const customWithDefaultTextblock: TextblockCreateInput = {
              idOfDefaultTextblock: textblock.id,
              title: textblock.title,
              template: textblock.template,
              category: textblock.category,
              isDeactivated: !!textblock.isDeactivated,
            };
            textblockResponse = await this.textblockMutationService.createTextblock(customWithDefaultTextblock);
          } else {
            textblockResponse = await this.textblockMutationService.updateTextblock(textblock);
          }
          return updateTextblockSuccess({ textblock: textblockResponse });
        } catch (err) {
          logger.error('Error updating textblock', err);
          return updateTextblockFailure({ message: 'SETTINGS.TEXTBLOCK_SAVE_ERROR' });
        }
      })
    )
  );

  deleteTextblock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTextblock),
      mergeMap(async ({ textblockId }) => {
        try {
          const textblockResponse = await this.textblockMutationService.deleteTextblock(textblockId);
          return textblockResponse.id
            ? deleteTextblockSuccess({ textblockId: textblockResponse.id })
            : this.handleDeleteTextblockFailure('empty id of textblockResponse');
        } catch (err) {
          return this.handleDeleteTextblockFailure(err);
        }
      })
    )
  );

  restoreTextblock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreTextblock),
      mergeMap(async ({ textblockId }) => {
        try {
          const textblockResponse = await this.textblockMutationService.deleteTextblock(textblockId);
          return textblockResponse.id && textblockResponse.idOfDefaultTextblock
            ? restoreTextblockSuccess({
                textblockId: textblockResponse.id,
                idOfDefaultTextblock: textblockResponse.idOfDefaultTextblock,
              })
            : this.handleRestoreTextblockError('empty id or idOfDefaultTextblock of textblockResponse');
        } catch (err) {
          return this.handleRestoreTextblockError(err);
        }
      })
    )
  );

  deleteTextblockSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteTextblockSuccess),
        mergeMap(() => this.router.navigate(SettingsPage.textblocks))
      ),
    { dispatch: false }
  );

  redirectToDefaultTextblockOnRestoreSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(restoreTextblockSuccess),
        mergeMap(({ idOfDefaultTextblock }) => this.router.navigate([...SettingsPage.textblocks, idOfDefaultTextblock]))
      ),
    { dispatch: false }
  );

  notifyError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateTextblockFailure, deleteTextblockFailure, restoreTextblockFailure),
        mergeMap(async ({ message }) => {
          await this.toastController.createAndPresentToast({ message });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<MeaPharmacyState>,
    private textblockMutationService: TextblockMutationService,
    private textblockLoadService: TextblockLoadService,
    private toastController: CustomToastController
  ) {}

  private handleDeleteTextblockFailure(err: any) {
    logger.error('Error deleting textblock', err);
    return deleteTextblockFailure({ message: 'SETTINGS.TEXTBLOCK_DELETION_ERROR' });
  }

  private handleRestoreTextblockError(err: any) {
    logger.error('Error restoring textblock', err);
    return restoreTextblockFailure({ message: 'SETTINGS.TEXTBLOCK_RESTORE_ERROR' });
  }
}

function textblockIsDefault(textblock: Textblock) {
  return !textblock.cognitoId;
}
