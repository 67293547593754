import { createAction, props } from '@ngrx/store';
import { ActionType } from '../../../../../essentials/types/src/actionType';
import { SoundRepetition, WidgetFeatures, WidgetWebsiteMaintainer } from '../state/home.state';

export const setSoundNotification = createAction(
  `${ActionType.Settings}: Set sound notification`,
  props<{ soundNotification: boolean }>()
);

export const setSoundNotificationFromLocalStorage = createAction(
  `${ActionType.Settings}: Set the initial sound notification from local storage`,
  props<{ soundNotification: boolean }>()
);

export const setSoundRepetition = createAction(
  `${ActionType.Settings}: Set sound repetition rate`,
  props<{ soundRepetition: SoundRepetition }>()
);

export const setSoundRepetitionFromLocalStorage = createAction(
  `${ActionType.Settings}: Set the initial sound repetition rate from local storage`,
  props<{ soundRepetition: SoundRepetition }>()
);

export const setWidgetFeatures = createAction(
  `${ActionType.Extensions}: Set Widget Features`,
  props<{ widgetFeatures: WidgetFeatures }>()
);

export const setWidgetWebsiteMaintainer = createAction(
  `${ActionType.Extensions}: Set Widget Website Maintainer`,
  props<{ widgetWebsiteMaintainer: WidgetWebsiteMaintainer | null }>()
);

export const initHomeState = createAction(`${ActionType.User}: Init Home state`);

export const initAudioOnInteraction = createAction(`${ActionType.UiUx}: Init Audio on interaction`);

export const resetHomeState = createAction(`${ActionType.User}: Reset home state`);
