import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map, shareReplay } from 'rxjs';
import { UnreadMessagesCountService } from '../../../common/services/src/unread-messages-count.service';
import { ChatPartnerDecryptionService } from '../../../mea-pharmacy-only/src/services/chat-partner-decryption.service';
import { ConversationsService } from '../../../mea-pharmacy-only/src/services/conversations.service';
import { MeaPharmacyState } from '../../../mea-pharmacy-only/src/store/mea-pharmacy.state';
import { selectShopUserMessages } from '../../../mea-pharmacy-only/src/store/shop-user-messages/shop-user-messages.selectors';
import { SconnectConversation } from './types/sconnectConversation';
import { SconnectConversationsUtil } from './util/sconnect-conversations.util';

@Injectable({ providedIn: 'root' })
export class MeaChatConversationsService {
  private chatPartnerDecryptionService = inject(ChatPartnerDecryptionService);
  private conversationsService = inject(ConversationsService);
  private translateService = inject(TranslateService);
  private unreadMessagesCountService = inject(UnreadMessagesCountService);
  private store = inject<Store<MeaPharmacyState>>(Store);

  meaShopMessages$ = this.store.select(selectShopUserMessages).pipe(
    map((shopUserMessage) =>
      SconnectConversationsUtil.mapShopUserMessagesToSconnectConversations(shopUserMessage, this.translateService)
    ),
    shareReplay(1)
  );

  openMeaShopMessages$ = this.meaShopMessages$.pipe(
    map((shopMessages) => shopMessages.filter((message) => message.isConversationOpen)),
    shareReplay(1)
  );

  meaAppConversations$ = combineLatest([
    this.conversationsService.enduserConversationsWithLastMessage$,
    this.chatPartnerDecryptionService.decryptedChatPartnerMetadataDictionary$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(([conversationsWithLastMessage, decryptedChatPartnerMetadataDictionary, unreadMessagesCountPerConversation]) =>
      SconnectConversationsUtil.mapConversationsToSconnectConversations(
        conversationsWithLastMessage,
        decryptedChatPartnerMetadataDictionary,
        unreadMessagesCountPerConversation,
        this.translateService
      )
    ),
    shareReplay(1)
  );

  openMeaAppConversations$ = this.meaAppConversations$.pipe(
    map((enduserConversations) => enduserConversations.filter(({ isConversationOpen }) => isConversationOpen)),
    shareReplay(1)
  );

  enduserConversations$ = combineLatest([this.meaShopMessages$, this.meaAppConversations$]).pipe(
    map(([meaShopMessage, enduserConversations]) =>
      [...meaShopMessage, ...enduserConversations].sort(this.sortByTimestamp)
    ),
    shareReplay(1)
  );

  openEnduserConversations$ = this.enduserConversations$.pipe(
    map((orders) => orders.filter(({ isConversationOpen }) => isConversationOpen)),
    shareReplay(1)
  );

  pharmacyConversations$ = combineLatest([
    this.conversationsService.pharmacyConversationsWithLastMessage$,
    this.chatPartnerDecryptionService.decryptedChatPartnerMetadataDictionary$,
    this.unreadMessagesCountService.unreadMessageCountPerConversation$,
  ]).pipe(
    map(([conversationsWithLastMessage, decryptedChatPartnerMetadataDictionary, unreadMessagesCountPerConversation]) =>
      SconnectConversationsUtil.mapConversationsToSconnectConversations(
        conversationsWithLastMessage,
        decryptedChatPartnerMetadataDictionary,
        unreadMessagesCountPerConversation,
        this.translateService
      )
    ),
    shareReplay(1)
  );

  hasUnreadMessages$ = combineLatest([
    this.meaShopMessages$,
    this.meaAppConversations$,
    this.pharmacyConversations$,
  ]).pipe(
    map(([meaShopMessages, meaAppConversations, pharmacyConversations]) =>
      [...meaShopMessages, ...meaAppConversations, ...pharmacyConversations].some(
        (sconnectConversation) => sconnectConversation.unreadMessagesCount > 0
      )
    )
  );

  private sortByTimestamp = (a: SconnectConversation, b: SconnectConversation) =>
    b.lastMessageTimestamp - a.lastMessageTimestamp;
}
