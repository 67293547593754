import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SubscriptionManagementService } from '../../../../common/services/src/subscriptions/subscription-management.service';
import { ConsentType } from '../../../../essentials/types/src/consent';
import { LoadStatus } from '../../../../essentials/types/src/loadStatus';
import { Logger } from '../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { CommonState } from '../../../../store/src/common-store/common.state';
import { startAppsyncSubscriptions } from '../../../../store/src/common-store/other/actions/subscription.actions';
import { setUserOnLogin } from '../../../../store/src/common-store/user-store/actions/user.actions';
import { selectPharmacy } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import {
  loadUserConsents,
  loadUserConsentsFailure,
  loadUserConsentsSuccess,
  setUserConsent,
} from '../../../../store/src/pharmacy/user-consent/user-consent.actions';
import { selectConsentsLoadStatus } from '../../../../store/src/pharmacy/user-consent/user-consent.selectors';
import { UserConsentState } from '../../../../store/src/pharmacy/user-consent/user-consent.state';
import { AppsyncUserConsentService } from '../../services/appsync-user-consent.service';

const logger = new Logger('UserConsentEffects');

@Injectable()
export class UserConsentEffects {
  initUserConsent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setUserOnLogin),
      withLatestFrom(this.store.select(selectConsentsLoadStatus)),
      filter(([_, consentsLoadStatus]) =>
        [LoadStatus.Init, LoadStatus.Error, LoadStatus.Stale].includes(consentsLoadStatus)
      ),
      map(() => loadUserConsents())
    )
  );

  loadUserConsents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUserConsents),
      switchMap(async () => {
        try {
          const consents = await this.appsyncUserConsentService.getConsentsForPharmacy();
          return loadUserConsentsSuccess({ consents });
        } catch (e) {
          logger.error('Error while loading user consents', e);
          return loadUserConsentsFailure();
        }
      })
    )
  );

  subscribeToUserConsentsUpdates$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserConsentsSuccess),
        withLatestFrom(this.store.select(selectPharmacy).pipe(isNotNullOrUndefined())),
        tap(([_, pharmacy]) => this.subscribeToUserConsentsUpdates(pharmacy.id))
      ),
    { dispatch: false }
  );

  revalidateUserConsents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startAppsyncSubscriptions),
      withLatestFrom(this.store.select(selectConsentsLoadStatus)),
      filter(([_, consentsLoadStatus]) => LoadStatus.Stale === consentsLoadStatus),
      map(() => loadUserConsents())
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<CommonState & { userConsent: UserConsentState }>,
    private appsyncUserConsentService: AppsyncUserConsentService,
    private subscriptionManagementService: SubscriptionManagementService
  ) {}

  private subscribeToUserConsentsUpdates(pharmacyId: string) {
    this.subscriptionManagementService.subscribe(
      'updatedConsentForPharmacy',
      this.appsyncUserConsentService.updatedConsentForPharmacy(pharmacyId),
      (userConsent) => {
        if (userConsent.consentType === ConsentType.PHARMACY_DATA_CONSENT) {
          this.store.dispatch(setUserConsent({ userConsent }));
        }
      }
    );
  }
}
