import { Component, inject, Input } from '@angular/core';
import { take } from 'rxjs';
import { ReleaseNoteQueries } from '../../../../../core/core.store';
import { formatDateToCustomFormat } from '../../../../../core/core.formatting';
import { ReleaseNoteInterface } from '../../../../../core/core.interfaces';
import { NotificationInterface } from '../../../../../core/interfaces/notification.interface';
import { NotificationMessageConfig } from '../../../../../core/config/notification-center.config';

@Component({
    selector: 'app-notification-release-notes-content',
    templateUrl: './notification-release-notes-content.component.html',
    styleUrls: ['./notification-release-notes-content.component.scss'],
})
export class NotificationReleaseNotesContentComponent {
    private _releaseNoteQueries = inject(ReleaseNoteQueries);

    formattedMessage: string;
    releaseNote: ReleaseNoteInterface;
    detailsTitles: string[] = [];
    // used for markdown list styles
    markdownList: string;

    @Input()
    set notificationPayloadId(value: string) {
        this.releaseNote = null;
        this.formattedMessage = '';
        this.detailsTitles = [];
        this.markdownList = '';

        this._releaseNoteQueries
            .getReleaseNoteById(value)
            .pipe(take(1))
            .subscribe((releaseNote) => {
                this.releaseNote = releaseNote;
                this.detailsTitles = releaseNote.details ? releaseNote.details.map((detail) => detail.title) : [];
                this.markdownList = this.detailsTitles.map((title) => `- ${title}`).join('\n');
                this.updateFormattedMessage();
            });
    }
    @Input() notification: NotificationInterface;

    private updateFormattedMessage(): void {
        let message = NotificationMessageConfig[this.notification?.type];
        if (message) {
            if (this.releaseNote && this.releaseNote?.date && this.releaseNote?.version) {
                const publishedDate = formatDateToCustomFormat(this.releaseNote.date);
                message = message.replace('{{publishDate}}', publishedDate);
                message = message.replace('{{version}}', this.releaseNote.version);
            } else {
                message = message.replace('{{publishDate}}', '');
                message = message.replace('{{version}}', '');
            }

            this.formattedMessage = message;
        }
    }
}
