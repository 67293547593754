import {
    SortDirectionEnum,
    SortTableEnum,
    SortableOffersFieldsEnum,
    TableFieldsEnum
} from '../core.enums';
import {
    SortingDocumentInterface,
    SortingOrderInterface,
    SortingInterface,
    SortingReturnsInterface,
    SortingOffersInterface,
} from '../interfaces/sorting.interface';

export const defaultOrderSorting : SortingOrderInterface = {
    tableName: SortTableEnum.order,
    fieldName: TableFieldsEnum.recTime,
    sortDirection: SortDirectionEnum.desc
};
export const defaultDeliverySorting : SortingDocumentInterface = {
    tableName: SortTableEnum.delivery,
    fieldName: TableFieldsEnum.recDate,
    sortDirection: SortDirectionEnum.desc
};
export const defaultInvoiceSorting : SortingDocumentInterface = {
    tableName: SortTableEnum.invoice,
    fieldName: TableFieldsEnum.recDate,
    sortDirection: SortDirectionEnum.desc
};
export const defaultReturnsSorting : SortingReturnsInterface = {
    tableName: SortTableEnum.returns,
    fieldName: TableFieldsEnum.recTime,
    sortDirection: SortDirectionEnum.desc
};
export const defaultOffersSorting : SortingOffersInterface = {
    tableName: SortTableEnum.offers,
    fieldName: SortableOffersFieldsEnum.productName,
    sortDirection: SortDirectionEnum.asc
};
export const defaultUserAdministrationSorting : SortingReturnsInterface = {
    tableName: SortTableEnum.userAdministration,
    fieldName: TableFieldsEnum.firstName,
    sortDirection: SortDirectionEnum.asc
};

export const defaultSorting = (tableName: SortTableEnum) : SortingInterface => {
    switch (tableName) {
        case SortTableEnum.order:
            return defaultOrderSorting;
        case SortTableEnum.delivery:
            return defaultDeliverySorting;
        case SortTableEnum.invoice:
            return defaultInvoiceSorting;
        case SortTableEnum.returns:
            return defaultReturnsSorting;
        case SortTableEnum.offers:
            return defaultOffersSorting;
        case SortTableEnum.userAdministration:
            return defaultUserAdministrationSorting;
    }
};
