import { OrderDetailInterface } from '../interfaces/order.interface';
import { OrderTypesTranslationConfig } from './order-types.config';
import { formatDateTimeToDate, formatDateTimeToMoment, formatDateTimeToTime } from '../formatting/date.formatting';
import { DocumentInterface } from '../interfaces/document.interface';
import { DocumentNumberFormattedPipe } from '../pipes/document-type.pipe';
import { DocumentType } from '../enums/documents.enum';
import { ReturnsDetailInterface } from '../interfaces/returns.interface';
import { CommunicationZoneGroup, CommunicationZoneType } from '../core.enums';
import { CommunicationZoneGroupInterface, CommunicationZoneTypeSelectInterface } from '../interfaces/communication-zone.interface';
import { BadgeComponentInterface } from '../interfaces/badge.interface';

export const CommunicationZoneFormsSubstituteConfig = {
    customerEmail: '{{CUSTOMER_EMAIL}}',
    documentNumber: '{{DOCUMENT_NUMBER}}',
    documentRecDate: '{{DOCUMENT_REC_DATE}}',
    orderType: '{{ORDER_TYPE}}',
    orderIdOrg: '{{ORDER_ID_ORG}}',
    quantity: '{{QUANTITY}}',
    productName: '{{PRODUCT_NAME}}',
    dosageForm: '{{DOSAGE_FORM}}',
    packageSize: '{{PACKAGE_SIZE}}',
    producer: '{{PRODUCER}}',
    pzn: '{{PZN}}',
    recTime: '{{REC_TIME}}',
    returnsTitle: '{{RETURNS_TITLE}}',
    deliveryNoteNumber: '{{DELIVERY_NOTE_NUMBER}}',
    deliveryDate: '{{DELIVERY_DATE}}',
};


export const CommunicationZoneFormsConfig = [
    {
        formId: CommunicationZoneType.DOCUMENT_CH,
        values: {
            title: 'Rückfrage zu Chargenbeleg Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Chargenbeleg Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.DOCUMENT_LS,
        values: {
            title: 'Rückfrage zu Lieferschein Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Lieferschein Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.USER_DATA,
        values: {
            title: 'Änderung Nutzerprofil',
            content: 'Bitte ändern Sie folgende Daten des Nutzerprofils ' + CommunicationZoneFormsSubstituteConfig.customerEmail + ' \n\n[Ihre Änderungen]\n\n\n\n\nVielen Dank, Ihr Apothekenteam',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.PHARMACY_DATA,
        values: {
            title: 'Änderung Apothekenstammdaten',
            content: 'Bitte ändern Sie folgende Daten meiner Apothekenadresse:\n\n[Ihre Änderungen]\n\n\nBitte übernehmen Sie die Änderung ebenfalls in meine Digitalprodukte.\n\nVielen Dank Ihr Apothekenteam',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.MEA_COOP,
        values: {
            title: 'Interesse an mea Kooperation',
            content: 'Ich habe Interesse an der mea Kooperation und damit verbundenen Einkaufsvorteilen. Bitte kontaktieren Sie mich.\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.DOCUMENT_ESR,
        values: {
            title: 'Rückfrage zur Monatsrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Monatsrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.DOCUMENT_ETR,
        values: {
            title: 'Rückfrage zur Teilrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Teilrechnung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber + ' vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.DOCUMENT_SB,
        values: {
            title: 'Rückfrage zur Saldenbestätigung Nr. ' + CommunicationZoneFormsSubstituteConfig.documentNumber,
            content: 'Saldenbestätigung vom ' +
                CommunicationZoneFormsSubstituteConfig.documentRecDate +'\n\n',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.MEA_CHAT,
        values: {
            title: 'Interesse an mea App',
            content: 'Guten Tag,\n\nWir möchten mea App genauer kennenlernen und wünschen uns weitere Informationen.\n\nBitte kontaktieren Sie uns.',
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.ORDER,
        values: {
            title: `Rückfrage zu ${CommunicationZoneFormsSubstituteConfig.orderType} Nr. ${CommunicationZoneFormsSubstituteConfig.orderIdOrg}`,
            content: `${CommunicationZoneFormsSubstituteConfig.orderType} Nr. ${CommunicationZoneFormsSubstituteConfig.orderIdOrg}, ${CommunicationZoneFormsSubstituteConfig.quantity} * ${CommunicationZoneFormsSubstituteConfig.productName}, ${CommunicationZoneFormsSubstituteConfig.dosageForm}, ${CommunicationZoneFormsSubstituteConfig.packageSize}, ${CommunicationZoneFormsSubstituteConfig.producer}, PZN ${CommunicationZoneFormsSubstituteConfig.pzn}, Bestelldatum: ${CommunicationZoneFormsSubstituteConfig.recTime} \n\n`,
            contactPerson: ''
        }
    },
    {
        formId: CommunicationZoneType.RETURN,
        values: {
            title: `${CommunicationZoneFormsSubstituteConfig.returnsTitle}`,
            content: `Datum: ${CommunicationZoneFormsSubstituteConfig.recTime}, ${CommunicationZoneFormsSubstituteConfig.quantity} * ${CommunicationZoneFormsSubstituteConfig.productName}, ${CommunicationZoneFormsSubstituteConfig.dosageForm}, ${CommunicationZoneFormsSubstituteConfig.packageSize}, ${CommunicationZoneFormsSubstituteConfig.producer}, PZN ${CommunicationZoneFormsSubstituteConfig.pzn}, Lieferscheinnummer: ${CommunicationZoneFormsSubstituteConfig.deliveryNoteNumber} , Lieferscheindatum: ${CommunicationZoneFormsSubstituteConfig.deliveryDate} \n\n`,
            contactPerson: ''
        }
    },
];

export const getDocumentReplacementArray = (document: DocumentInterface) => {
    const documentNumberFormatted = document?.documentType === DocumentType.RE ?
        document?.documentNumber :
        new DocumentNumberFormattedPipe().transform(document?.documentNumber);
   return [
       {
           key: CommunicationZoneFormsSubstituteConfig.documentNumber,
           value: documentNumberFormatted || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.documentRecDate,
           value: document?.recDate ? formatDateTimeToMoment(document?.recDate).format('DD.MM.YYYY') : ''
       }
   ];
};


export const getReturnsReplacementArray = (statusChange = true, returns: ReturnsDetailInterface) => {
    const title = statusChange ?
        'Retoure im Status "'+ returns.status +'" ' + returns.productName :
        'Rückfrage zur RETOURE ' + returns.productName;
    const recTime = returns.recTime ? formatDateTimeToDate(returns.recTime, true) : 'unbekannt';
    const deliveryDate = returns.deliveryNoteDate ? formatDateTimeToDate(returns.deliveryNoteDate, false) : 'unbekannt';

   return [
       {
           key: CommunicationZoneFormsSubstituteConfig.returnsTitle,
           value: title || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.recTime,
           value: recTime
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.quantity,
           value: returns?.quantity || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.productName,
           value: returns?.productName || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.dosageForm,
           value: returns?.dosageForm || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.packageSize,
           value: returns?.packageSize || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.producer,
           value: returns?.producer || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.pzn,
           value: returns?.pzn?.padStart(8, '0') || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.deliveryNoteNumber,
           value: returns?.deliveryNoteNumber || ''
       },
       {
           key: CommunicationZoneFormsSubstituteConfig.deliveryDate,
           value: deliveryDate || ''
       },
   ];
};

export const getOrderReplacementArray = (order: OrderDetailInterface) => {
    const orderType = OrderTypesTranslationConfig[order?.type] ? OrderTypesTranslationConfig[order?.type] : '';

    return  [
        {
            key: CommunicationZoneFormsSubstituteConfig.orderType,
            value: orderType
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.orderIdOrg,
            value: order?.orderIdOrg ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.quantity,
            value: order?.quantity ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.productName,
            value: order?.productName ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.dosageForm,
            value: order?.dosageForm ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.packageSize,
            value: order?.packageSize ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.producer,
            value: order?.producer ?? ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.pzn,
            value: order?.pzn ? order.pzn.padStart(8, '0') : ''
        },
        {
            key: CommunicationZoneFormsSubstituteConfig.recTime,
            value: order?.recTime ?
                formatDateTimeToDate(order?.recTime, true) + ' ' +
                formatDateTimeToTime(order?.recTime, true) : ''
        }
    ];
};

export const CommunicationZoneTypeConfig: Array<CommunicationZoneTypeSelectInterface> = [
    {id: CommunicationZoneType.GENERAL, title: 'Allgemein', isSelectable: true},
    {id: CommunicationZoneType.ORDER, title: 'Auftrag', isSelectable: true},
    {id: CommunicationZoneType.RETURN, title: 'Retoure', isSelectable: true},
    {id: CommunicationZoneType.DOCUMENT_CH, title: 'Chargenebeleg', isSelectable: true},
    {id: CommunicationZoneType.DOCUMENT_LS, title: 'Lieferschein', isSelectable: true},
    {id: CommunicationZoneType.DOCUMENT_ESR, title: 'Monatsrechnung', isSelectable: true},
    {id: CommunicationZoneType.DOCUMENT_ETR, title: 'Teilrechnung', isSelectable: true},
    {id: CommunicationZoneType.DOCUMENT_SB, title: 'Saldenbestätigung', isSelectable: true},
    {id: CommunicationZoneType.DATA_CHANGE, title: 'Datenänderung', isSelectable: true},
    {id: CommunicationZoneType.REQUEST, title: 'Anfrage', isSelectable: true},
    {id: CommunicationZoneType.MEA_CHAT, title: 'MEA App', isSelectable: false},
    {id: CommunicationZoneType.MEA_COOP, title: 'MEA Coop', isSelectable: false},
    {id: CommunicationZoneType.USER_DATA, title: 'Benutzerdaten', isSelectable: false},
    {id: CommunicationZoneType.PHARMACY_DATA, title: 'Apothekendaten', isSelectable: false},
    {id: CommunicationZoneType.FORM, title: 'Formular', isSelectable: false},
    {id: CommunicationZoneType.OFFER, title: 'Angebot', isSelectable: false},
    {id: CommunicationZoneType.DISPO_ORDER, title: 'Disposition Bestellung', isSelectable: false},
    {id: CommunicationZoneType.DISPO_REORDER, title: 'Disposition Nachbestellung', isSelectable: false}
];

// Mapping configuration that groups CommunicationZoneTypes
export const CommunicationZoneGroupMapping: Record<CommunicationZoneGroup, CommunicationZoneGroupInterface> = {
  // All group just for filtering
  [CommunicationZoneGroup.ALL]: {
    id: CommunicationZoneGroup.ALL,
    color: '',
    value: 'Alle',
    group: []
  },
  [CommunicationZoneGroup.GENERAL]: {
    id: CommunicationZoneGroup.GENERAL,
    color: 'badge-color-grey ',
    value: 'Allgemein',
    group: [
      CommunicationZoneType.GENERAL
    ]
  },
  [CommunicationZoneGroup.REQUEST]: {
    id: CommunicationZoneGroup.REQUEST,
    color: 'badge-color-grey ',
    value: 'Anfrage',
    group: [
      CommunicationZoneType.REQUEST,
      CommunicationZoneType.MEA_CHAT,
      CommunicationZoneType.MEA_COOP
    ]
  },
  [CommunicationZoneGroup.DATA_CHANGE]: {
    id: CommunicationZoneGroup.DATA_CHANGE,
    color: 'badge-color-grey ',
    value: 'Datenänderung',
    group: [
      CommunicationZoneType.DATA_CHANGE,
      CommunicationZoneType.USER_DATA,
      CommunicationZoneType.PHARMACY_DATA
    ]
  },
  [CommunicationZoneGroup.INVOICE]: {
    id: CommunicationZoneGroup.INVOICE,
    color: '.badge-color-magenta',
    value: 'Rechnung',
    group: [
      CommunicationZoneType.DOCUMENT_ESR,
      CommunicationZoneType.DOCUMENT_ETR,
      CommunicationZoneType.DOCUMENT_SB
    ]
  },
  [CommunicationZoneGroup.DELIVERY]: {
    id: CommunicationZoneGroup.DELIVERY,
    color: '.badge-color-magenta',
    value: 'Lieferung',
    group: [
      CommunicationZoneType.DOCUMENT_CH,
      CommunicationZoneType.DOCUMENT_LS
    ]
  },
  [CommunicationZoneGroup.OFFER]: {
    id: CommunicationZoneGroup.OFFER,
    color: 'badge-color-purple',
    value: 'Angebot',
    group: [
      CommunicationZoneType.OFFER,
    ]
  },
  [CommunicationZoneGroup.FORM]: {
    id: CommunicationZoneGroup.FORM,
    color: 'badge-color-blue',
    value: 'Formular',
    group: [
      CommunicationZoneType.FORM
    ]
  },
  [CommunicationZoneGroup.RETURN]: {
    id: CommunicationZoneGroup.RETURN,
    color: 'badge-color-yellow',
    value: 'Retoure',
    group: [
      CommunicationZoneType.RETURN
    ]
  },
  [CommunicationZoneGroup.ORDER]: {
    id: CommunicationZoneGroup.ORDER,
    color: 'badge-color-orange',
    value: 'Auftrag',
    group: [
      CommunicationZoneType.ORDER,
      CommunicationZoneType.DISPO_ORDER,
      CommunicationZoneType.DISPO_REORDER
    ]
  },
};
export function getGroupFromType(type: CommunicationZoneType): {
  group: CommunicationZoneGroup;
  config: CommunicationZoneGroupInterface;
} {
  for (const [group, config] of Object.entries(CommunicationZoneGroupMapping)) {
    if (config.group.includes(type)) {
      return {
        group: group as CommunicationZoneGroup,
        config
      };
    }
  }
  return {
    group: CommunicationZoneGroup.ALL,
    config: CommunicationZoneGroupMapping[CommunicationZoneGroup.ALL]
  };
}
export function getCommunicationZoneBadgeConfig(type: CommunicationZoneType): BadgeComponentInterface {
  const groupInfo = getGroupFromType(type);

  return {
    color: groupInfo.config.color,
    value: groupInfo.config.value
  };
}
