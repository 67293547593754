import { Dictionary } from 'ts-essentials';
import { Conversation, ConversationWithLastMessage } from '../../../essentials/types/src/conversation';
import {
  ChatOrder,
  ChatOrderWithLastMessage,
  Order,
  orderIsArchived,
  orderIsShopOrder,
  OrderWithLastMessage,
  ShopOrder,
} from '../../../essentials/types/src/order';
import { DecryptedShopUserMessage, ShopUserMessage } from '../../../essentials/types/src/shopUserMessage';
import { ShopUser } from '../types/shop-user';
import { Tag } from '../types/tag';

export class OrderUtil {
  private static ORDER_TAGS = {
    PRESCRIPTION: { text: 'Rezept' },
    NOTE: { text: 'Interne Notiz', color: 'highlight' },
    NEW_MESSAGE: { text: 'Neue Nachricht', color: 'sanacorp-red' },
  } satisfies Dictionary<Tag>;

  public static getOrderTags(order: Order | OrderWithLastMessage): Tag[] {
    const tags: Tag[] = [];
    if (orderIsShopOrder(order)) {
      if (order.shopUserMessage.encryptedNoteHistory) {
        tags.push(this.ORDER_TAGS.NOTE);
      }
    } else {
      if (order.conversation.hasPrescriptions) {
        tags.push(this.ORDER_TAGS.PRESCRIPTION);
      }
      if (order.conversation.segments.some((segment) => segment.encryptedNoteHistory)) {
        tags.push(this.ORDER_TAGS.NOTE);
      }
    }
    return tags;
  }

  public static getUnreadMessageTag() {
    return this.ORDER_TAGS.NEW_MESSAGE;
  }

  public static getProgressHistory(order: Order | OrderWithLastMessage) {
    return orderIsShopOrder(order) ? order.shopUserMessage.progressHistory : order.conversation.progressHistory;
  }

  public static mapShopUserMessagesToShopUsers(shopUserMessages: ShopUserMessage[]): ShopUser[] {
    const shopUserMap = shopUserMessages.reduce(
      (acc, message) => {
        const { shopUserId } = message;
        if (!acc[shopUserId]) {
          acc[shopUserId] = [];
        }
        acc[shopUserId].push(message);
        return acc;
      },
      {} as Record<string, ShopUserMessage[]>
    );

    return Object.entries(shopUserMap)
      .map(([shopUserId, messages]) => {
        const sortedMessages = messages.sort((a, b) => b.createdAt - a.createdAt);
        const lastDecryptedMessage = sortedMessages.find(
          (message): message is DecryptedShopUserMessage => message.decryptionStatus === 'decrypted'
        );

        if (!lastDecryptedMessage) {
          return undefined;
        }
        const shopUser: ShopUser = {
          shopUserId,
          email: lastDecryptedMessage.email,
          telephone: lastDecryptedMessage.telephone,
          firstName: lastDecryptedMessage.firstName,
          lastName: lastDecryptedMessage.lastName,
          lastMessageTimestamp: lastDecryptedMessage.createdAt,
          orders: sortedMessages.map(this.mapShopUserMessageToShopOrder),
        };
        return shopUser;
      })
      .filter((shopUser) => !!shopUser);
  }

  public static mapShopUserMessageToShopOrder(shopUserMessage: ShopUserMessage): ShopOrder {
    return { type: 'ShopUserMessage', orderId: shopUserMessage.id, shopUserMessage };
  }

  public static mapConversationToChatOrder(conversation: Conversation): ChatOrder {
    return { type: 'Conversation', orderId: conversation.id, conversation };
  }

  public static mapConversationWithLastMessageToChatOrder(
    conversationWithLastMessage: ConversationWithLastMessage
  ): ChatOrderWithLastMessage {
    return {
      ...conversationWithLastMessage,
      type: 'ConversationWithLastMessage',
      orderId: conversationWithLastMessage.conversation.id,
    };
  }

  public static groupAndSortOrders<T extends ChatOrderWithLastMessage | OrderWithLastMessage>(orders: T[]) {
    const openOrders: OrderWithLastMessage[] = [];
    const archivedOrders: OrderWithLastMessage[] = [];
    orders.forEach((order) => {
      if (orderIsArchived(order)) {
        archivedOrders.push(order);
      } else {
        openOrders.push(order);
      }
    });
    return {
      openOrders: this.sortOrders(openOrders),
      archivedOrders: this.sortOrders(archivedOrders),
    };
  }

  public static sortOrders<T extends ChatOrderWithLastMessage | OrderWithLastMessage>(orders: T[]): T[] {
    return orders.toSorted((orderA, orderB) => OrderUtil.getSortTimestamp(orderB) - OrderUtil.getSortTimestamp(orderA));
  }

  private static getSortTimestamp(order: OrderWithLastMessage) {
    if (orderIsShopOrder(order)) {
      return order.shopUserMessage.createdAt;
    } else {
      return order.lastReceivedMessage?.createdAt ?? order.lastMessage.createdAt;
    }
  }
}
