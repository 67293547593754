@if (isBiggerPopover) {
    <div class="header">
        @if(isEditable) {
            Neuer Termin
        } @else {
            {{ title }}
        }
    </div>
}
<div class="content">
    @if(isEditable) {
        <div>
            <ion-input i18n-placeholder placeholder="Titel des Termins" class="title-input" [(ngModel)]="title"/>
            <ion-icon name="trash-outline" (click)="onRemove()" *ngIf="isEdit" />
        </div>
    }
    <div>
        @if(!isDateSelectExpanded) {
            <ion-button class="datetime-button" [class.datetime-button-limited]="isBiggerPopover" expand="block" fill="clear" color="dark" (click)="expandDateSelect()" [disabled]="!isEditable">
                <span slot="start" [innerHTML]="date"></span>
                <ion-icon name="caret-down-outline" slot="end" *ngIf="isEditable" />
            </ion-button>
        } @else {
            <div class="date-select">
                <ion-checkbox label-placement="end" justify="start" [checked]="isAllDay" [(ngModel)]="isAllDay">Ganztägig</ion-checkbox>
                <div>
                    <ion-label>Von: </ion-label>
                    <ion-datetime-button datetime="dateFrom"></ion-datetime-button>
                    @if(!isAllDay) {
                        <ion-input [(ngModel)]="timeFromValue" [maskito]="timeMask" [maskitoElement]="maskPredicate" placeholder="00:00"></ion-input>
                    }
                </div>
                <div>
                    <ion-label>Bis: </ion-label>
                    <ion-datetime-button datetime="dateTo"></ion-datetime-button>
                    @if(!isAllDay) {
                        <ion-input [(ngModel)]="timeToValue" [maskito]="timeMask" [maskitoElement]="maskPredicate" placeholder="00:00"></ion-input>
                    }
                </div>

                <ion-modal [keepContentsMounted]="true">
                    <ng-template>
                        <ion-datetime id="dateFrom" presentation="date" [(ngModel)]="dateFromValue" [showDefaultButtons]="true" first-day-of-week="1" doneText="Ok" cancelText="Abbrechen" (ionChange)="onDateChange()" ></ion-datetime>
                    </ng-template>
                </ion-modal>
                <ion-modal [keepContentsMounted]="true">
                    <ng-template>
                        <ion-datetime id="dateTo" presentation="date" [(ngModel)]="dateToValue" [showDefaultButtons]="true" first-day-of-week="1" doneText="Ok" cancelText="Abbrechen" (ionChange)="onDateChange(true)"></ion-datetime>
                    </ng-template>
                </ion-modal>

            </div>
        }
    </div>
    @if(isEditable) {
        <ion-textarea placeholder="Termin, Agenda oder weitere Infos"
                      i18n-placeholder
                      [rows]="description.length > 80 ? 10 : 3"
                      [(ngModel)]="description" [readonly]="!isEditable"

        />
    } @else {
        <markdown [data]="description"></markdown>
    }
    @if (isEditable) {
    <ion-radio-group [(ngModel)]="icon">
        @for(i of icons; track i) {
            <ion-radio mode="md" [value]="i">
                <ion-icon name="{{i}}"/>
            </ion-radio>
        }
    </ion-radio-group>
    <ion-checkbox
            label-placement="end"
            justify="start"
            [checked]="isPharmacy"
            [(ngModel)]="isPharmacy"
            appTooltip ttp="left" ttk="appointment_is_pharmacy"
    >für ganze Apotheke freigeben</ion-checkbox>
    }
    @if(isEditable && errorMsg) {
        <ion-text color="danger" class="error-text" i18n>{{ errorMsg }}</ion-text>
    }

</div>
<div class="footer">
    @if(isEditable) {
        <ion-button (click)="onSave()" i18n>Speichern</ion-button>
        <ion-button class="button-tertiary" (click)="onCancel()" i18n>Abbrechen</ion-button>
    } @else {
        @if(lockedBySanacorp && formId && formButton && !(dateFrom | isDateSameOrBeforeToday)) {
            <ion-button [disabled]="isFormAlreadySubmitted" (click)="showAppointmentDetailsModal()">
                <span [innerHTML]="formButton"></span>
            </ion-button>
        }
        <ion-button class="button-tertiary" (click)="onCancel()" i18n>Schließen</ion-button>
    }
</div>

<ion-modal class="modal-auto-height modal-medium modal-overflow-visible" [isOpen]="openModal" (didDismiss)="onAppointmentFormModalClose()" showBackdrop="true" mode="md">
    <ng-template>
        <app-form-modal
                formId="{{formId}}"
                buttonText="{{formButton}}"
                (isFormSubmitted)="onFormSubmitted($event)"
        >
        </app-form-modal>
    </ng-template>

</ion-modal>
