import { Pipe, PipeTransform } from '@angular/core';
import { formatInTimeZone } from 'date-fns-tz';
import { de } from 'date-fns/locale/de';

@Pipe({
    name: 'isAllDay'
})
export class IsAllDayPipe implements PipeTransform {
    transform(dateTimeFrom: string, dateTimeTo: string): boolean {
        const dateFrom = new Date(dateTimeFrom);
        const dateTo = new Date(dateTimeTo);

        const timeFrom = formatInTimeZone(dateFrom,
            'Europe/Berlin',
            'HH:mm:ss',
            { locale: de }
        );
        const timeTo = formatInTimeZone(dateTo,
            'Europe/Berlin',
            'HH:mm:ss',
            { locale: de }
        );
        return timeFrom === '00:00:00' && timeTo === '00:00:00';
    }
}
