import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageWithMedia } from '../../../../../../essentials/types/src/message';
import { areSameMedia, ImageMedia } from '../../../../../../essentials/types/src/messageMedia';
import { updateMessageMedia } from '../../actions/chat-message.actions';
import { CommonState } from '../../../common.state';
import updateMessageMediaUserOptions from '../../../../../../common/resources/src/graphql/mutations/updateMessageMediaUserOptions';
import { AppsyncService } from '../../../../../../common/services/src/appsync/appsync.service';

@Injectable({
  providedIn: 'root',
})
export class UserOptionsUpdateService {
  constructor(
    private appsyncService: AppsyncService,
    private store: Store<CommonState>
  ) {}

  async updateExifOrientation(image: ImageMedia, exifOrientation: number, messageId: string) {
    const updatedMessage = await this.updateExifOrientationInBackend(image, exifOrientation, messageId);
    if (updatedMessage) {
      const updatedMedia = updatedMessage.media.find(
        (media) => media.mediaType === 'IMAGE' && areSameMedia(image, media)
      ) as ImageMedia | undefined;
      if (updatedMedia) {
        this.store.dispatch(updateMessageMedia({ message: updatedMessage, media: updatedMedia }));
      }
    }
  }

  private async updateExifOrientationInBackend(
    image: ImageMedia,
    orientation: number,
    messageId: string
  ): Promise<MessageWithMedia> {
    const identifier = image.id ? { mediaId: image.id } : { encryptedLink: image.encryptedLink };
    const variables = {
      messageId,
      exifOrientation: orientation,
      ...identifier,
    };

    const client = await this.appsyncService.getClient();
    const { data } = await client.mutate({
      mutation: updateMessageMediaUserOptions,
      variables,
    });
    return data.updateMessageMediaUserOptions;
  }
}
