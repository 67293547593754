import { inject, Injectable } from '@angular/core';
import { from, map, mergeMap, Observable } from 'rxjs';
import addConversationProgressEventToShopUserMessage from '../../../../common/resources/src/graphql/mutations/addConversationProgressEventToShopUserMessage';
import addEncryptedNoteToShopUserMessage from '../../../../common/resources/src/graphql/mutations/addEncryptedNoteToShopUserMessage';
import deleteShopUserMessage from '../../../../common/resources/src/graphql/mutations/deleteShopUserMessage';
import setShopUserMessageAsRead from '../../../../common/resources/src/graphql/mutations/setShopUserMessageAsRead';
import updateArchiveShopUserMessage from '../../../../common/resources/src/graphql/mutations/updateArchiveShopUserMessage';
import { AppsyncService } from '../../../../common/services/src/appsync/appsync.service';
import { ConversationStatusOption } from '../../../../essentials/types/src/backendConversation';
import { BackendShopUserMessage, PaginatedShopUserMessages } from '../../../../essentials/types/src/shopUserMessage';
import getAllShopUserMessages from '../../graphql/queries/getAllShopUserMessages';
import getRecentShopUserMessages from '../../graphql/queries/getRecentShopUserMessages';
import getUnreadShopUserMessagesForPharmacy from '../../graphql/queries/getUnreadShopUserMessagesForPharmacy';
import createdShopUserMessage from '../../graphql/subscriptions/createdShopUserMessage';
import deletedShopUserMessage from '../../graphql/subscriptions/deletedShopUserMessage';
import updatedShopUserMessage from '../../graphql/subscriptions/updatedShopUserMessage';

@Injectable({ providedIn: 'root' })
export class AppsyncShopUserMessageService {
  private appsyncService = inject(AppsyncService);

  // ************* Queries *************

  async getRecentShopUserMessages(nextToken?: string): Promise<PaginatedShopUserMessages> {
    const client = await this.appsyncService.getClient();
    const variables: any = {};
    if (nextToken) {
      variables.nextToken = nextToken;
    }
    const options = {
      query: getRecentShopUserMessages,
      variables,
    };

    const { data } = await client.query(options);
    if (data) {
      return {
        shopUserMessages: data.getRecentShopUserMessages.shopUserMessages,
        nextToken: data.getRecentShopUserMessages.nextToken,
      };
    } else {
      return { shopUserMessages: [], nextToken: null };
    }
  }

  async getAllShopUserMessages(nextToken?: string): Promise<PaginatedShopUserMessages> {
    const client = await this.appsyncService.getClient();
    const variables: any = {};
    if (nextToken) {
      variables.nextToken = nextToken;
    }
    const options = {
      query: getAllShopUserMessages,
      variables,
    };

    const { data } = await client.query(options);
    if (data) {
      return {
        shopUserMessages: data.getAllShopUserMessages.shopUserMessages,
        nextToken: data.getAllShopUserMessages.nextToken,
      };
    } else {
      return { shopUserMessages: [], nextToken: null };
    }
  }

  async getUnreadShopUserMessagesForPharmacy(
    cognitoId: string,
    nextToken?: string
  ): Promise<PaginatedShopUserMessages> {
    const client = await this.appsyncService.getClient();
    const variables: any = { cognitoId };
    if (nextToken) {
      variables.nextToken = nextToken;
    }
    const options = {
      query: getUnreadShopUserMessagesForPharmacy,
      variables,
    };

    const { data } = await client.query(options);
    if (data) {
      return {
        shopUserMessages: data.getUnreadShopUserMessagesForPharmacy.shopUserMessages,
        nextToken: data.getUnreadShopUserMessagesForPharmacy.nextToken,
      };
    } else {
      return { shopUserMessages: [], nextToken: null };
    }
  }

  // ************* Mutations *************

  async updateProgressStatus(shopUserMessageId: string, status: ConversationStatusOption) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: addConversationProgressEventToShopUserMessage,
      variables: { id: shopUserMessageId, status: status.status, subStatus: status.subStatus },
    });
  }

  async addEncryptedNote(shopUserMessageId: string, encryptedText: string) {
    const variables = { shopUserMessageId, encryptedText };
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: addEncryptedNoteToShopUserMessage,
      variables,
    });
  }

  async markAsRead(id: string) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: setShopUserMessageAsRead,
      variables: { id },
    });
  }

  async updateArchivedByPharmacy(id: string, archivedByPharmacy: boolean) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: updateArchiveShopUserMessage,
      variables: { id, archivedByPharmacy },
    });
  }

  async deleteShopUserMessage(id: string) {
    const client = await this.appsyncService.getClient();
    await client.mutate({
      mutation: deleteShopUserMessage,
      variables: { id },
    });
  }

  // ************* Subscriptions *************

  createdShopUserMessage(cognitoId: string): Observable<BackendShopUserMessage> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: createdShopUserMessage,
            variables: { pharmacyCognitoId: cognitoId },
          })
          .pipe(map(({ data }) => data.createdShopUserMessage))
      )
    );
  }

  updatedShopUserMessage(cognitoId: string): Observable<BackendShopUserMessage> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: updatedShopUserMessage,
            variables: { pharmacyCognitoId: cognitoId },
          })
          .pipe(map(({ data }) => data.updatedShopUserMessage))
      )
    );
  }

  deletedShopUserMessage(cognitoId: string): Observable<BackendShopUserMessage> {
    return from(this.appsyncService.getClient()).pipe(
      mergeMap((client) =>
        client
          .subscribe({
            query: deletedShopUserMessage,
            variables: { pharmacyCognitoId: cognitoId },
          })
          .pipe(map(({ data }) => data.deletedShopUserMessage))
      )
    );
  }
}
