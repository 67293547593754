import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppsyncConversationService } from '../../../../../common/services/src/appsync/appsync-conversation.service';
import { EncryptionService } from '../../../../../common/services/src/encryption/encryption.service';
import { isPharmacyChatUser } from '../../../../../essentials/types/src/chatUser';
import { Conversation, ConversationAndLastMessage } from '../../../../../essentials/types/src/conversation';
import { LoadStatus } from '../../../../../essentials/types/src/loadStatus';
import { SubmittedShoppingCart } from '../../../../../essentials/types/src/shoppingCart';
import { Logger } from '../../../../../essentials/util/src/logger';
import { CommonState } from '../../common.state';
import { selectUser } from '../../user-store/selectors/user.selectors';
import {
  initConversations,
  loadConversations,
  loadConversationsFailure,
  newOrUpdateConversation,
  setActiveConversation,
  setConversations,
} from '../actions/chat-conversation.actions';
import {
  newAppointment,
  newShoppingCartInChat,
  updateAppointment,
  updatedBackendAppointment,
  updatedBackendShoppingCartInChat,
  updateShoppingCartInChat,
} from '../actions/chat-decryption.actions';
import { selectConversation, selectConversations, selectConversationsLoadStatus } from '../selectors/chat.selectors';

const logger = new Logger('ChatConversationEffects');

@Injectable()
export class ChatConversationEffects {
  initConversations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initConversations),
        withLatestFrom(this.store.select(selectConversationsLoadStatus)),
        map(([_, conversationsLoadStatus]) => {
          if (conversationsLoadStatus === LoadStatus.Init) {
            this.store.dispatch(loadConversations({ conversationsLoadStatus: LoadStatus.LoadingInitial }));
          } else if (conversationsLoadStatus === LoadStatus.Stale) {
            this.store.dispatch(loadConversations({ conversationsLoadStatus: LoadStatus.Revalidating }));
          }
        })
      ),
    { dispatch: false }
  );

  loadConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadConversations),
      withLatestFrom(this.store.select(selectUser)),
      switchMap(async ([_, user]) => {
        try {
          const conversationsAndLastMessages = await this.appsyncConversationService.getAllConversations();
          if (user && isPharmacyChatUser(user) && !user.addedHasPrescriptionsToConversations && user.privateKey) {
            logger.info('Migration: add hasPrescriptions field to existing conversations');
            const updatedConversationsAndLastMessages = await this.addHasPrescriptionsToConversations(
              conversationsAndLastMessages,
              user.privateKey
            );
            return setConversations({ conversationsAndLastMessages: updatedConversationsAndLastMessages });
          }
          return setConversations({ conversationsAndLastMessages });
        } catch (e) {
          logger.error('Error initializing conversations', e);
          return loadConversationsFailure();
        }
      })
    )
  );

  setActiveConversation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setActiveConversation),
        withLatestFrom(this.store.select(selectConversations)),
        mergeMap(async ([{ id }, conversations]) => {
          try {
            const conversationToUpdate = conversations.find((conversation) => conversation.id === id) as Conversation;
            if (conversationToUpdate) {
              const updatedConversation =
                await this.appsyncConversationService.getUpdatesForConversation(conversationToUpdate);
              if (updatedConversation) {
                this.store.dispatch(newOrUpdateConversation({ conversation: updatedConversation }));
              }
            }
          } catch (e) {
            logger.error('Error updating conversation', e);
          }
        })
      ),
    { dispatch: false }
  );

  updatedBackendAppointment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedBackendAppointment),
      mergeMap(async ({ conversationId, encryptedAppointment }) => {
        const existingConversation = await firstValueFrom(this.store.select(selectConversation(conversationId)));
        if (existingConversation && !existingConversation.encryptedAppointment) {
          this.store.dispatch(newAppointment());
        }
        return updateAppointment({ conversationId, encryptedAppointment });
      })
    )
  );

  updatedBackendShoppingCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updatedBackendShoppingCartInChat),
      mergeMap(async ({ conversationId, encryptedShoppingCart }) => {
        const existingConversation = await firstValueFrom(this.store.select(selectConversation(conversationId)));
        if (existingConversation && !existingConversation.encryptedShoppingCart) {
          this.store.dispatch(newShoppingCartInChat());
        }
        return updateShoppingCartInChat({ conversationId, encryptedShoppingCart });
      })
    )
  );

  constructor(
    private actions$: Actions,
    private appsyncConversationService: AppsyncConversationService,
    private encryptionService: EncryptionService,
    private store: Store<CommonState>
  ) {}

  private async addHasPrescriptionsToConversations(conversations: ConversationAndLastMessage[], privateKey: string) {
    const conversationsWithShoppingCarts = conversations.filter(
      ({ conversation }) => !!conversation.shoppingCart || !!conversation.encryptedShoppingCart
    );
    const decryptedConversations = await Promise.all(
      conversationsWithShoppingCarts.map(({ conversation }) => this.decryptConversation(conversation, privateKey))
    );
    const conversationIdsToMigrate = decryptedConversations
      .filter(({ shoppingCart }) => !!shoppingCart?.prescriptions && shoppingCart.prescriptions.length > 0)
      .map((conversation) => conversation.id);

    await this.appsyncConversationService.addHasPrescriptionsToConversations(conversationIdsToMigrate);
    return this.appsyncConversationService.getAllConversations();
  }

  private async decryptConversation(conversation: Conversation, privateKey: string) {
    if (!conversation.encryptedShoppingCart) {
      return conversation;
    }
    const encryptedPassword = conversation.segments?.[0]?.encryptedConversationPassword;
    const decryptedPassword =
      !!encryptedPassword && this.encryptionService.decryptUsingPrivateKey(encryptedPassword, privateKey);
    if (!decryptedPassword) {
      return conversation;
    }
    const decryptedShoppingCartString = this.encryptionService.decryptUsingPassword(
      conversation.encryptedShoppingCart,
      decryptedPassword
    );
    const shoppingCart: SubmittedShoppingCart = JSON.parse(decryptedShoppingCartString);
    return { ...conversation, shoppingCart };
  }
}
