import { Action, createReducer, on } from '@ngrx/store';
import update from 'immutability-helper';
import {
  resetHomeState,
  setSoundNotification,
  setSoundNotificationFromLocalStorage,
  setSoundRepetition,
  setSoundRepetitionFromLocalStorage,
  setWidgetFeatures,
  setWidgetWebsiteMaintainer,
} from '../actions/home.actions';
import { HomeState } from '../state/home.state';

export const initialHomeState: HomeState = {
  errorMessage: '',
  settings: { soundNotification: false, soundRepetition: -1 },
  extensions: { widget: { features: 'CHAT', websiteMaintainer: null } },
};

const _homeReducer = createReducer(
  initialHomeState,

  on(resetHomeState, () => initialHomeState),

  on(setSoundNotification, setSoundNotificationFromLocalStorage, (state: HomeState, { soundNotification }) =>
    update(state, { settings: { soundNotification: { $set: soundNotification } } })
  ),

  on(setSoundRepetition, setSoundRepetitionFromLocalStorage, (state: HomeState, { soundRepetition }) =>
    update(state, { settings: { soundRepetition: { $set: soundRepetition } } })
  ),

  on(setWidgetFeatures, (state: HomeState, { widgetFeatures }) =>
    update(state, {
      extensions: {
        widget: {
          features: { $set: widgetFeatures },
        },
      },
    })
  ),

  on(setWidgetWebsiteMaintainer, (state: HomeState, { widgetWebsiteMaintainer }) =>
    update(state, {
      extensions: {
        widget: {
          websiteMaintainer: { $set: widgetWebsiteMaintainer },
        },
      },
    })
  )
);

export function homeReducer(state: HomeState | undefined, action: Action): HomeState {
  return _homeReducer(state, action);
}
