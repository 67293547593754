import { gql } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { from, map } from 'rxjs';
import { ApiService } from '../../../services/api.service';

export const MarkNotificationAsReadMutation = gql`
    mutation Insert_readNotifications($notifications: [NotificationItem!]!, $isRead: Boolean!) {
        MarkNotificationAsRead(items: $notifications, isRead: $isRead) {
            status
            numberOfNotificationsMarkedAsRead
        }
    }
`;

export const ArchiveNotificationMutation = gql`
    mutation archiveNotification($notificationId: Int!) {
        update_notificationsByPharmacy(_set: {isArchive: true}, where: {
            id: {_eq: $notificationId}
        }) {
            affected_rows
        }
    }
`;
export const ArchiveAllNotificationsMutation = gql`
    mutation archiveAllNotifications($idGte: Int, $idLte: Int) {
        update_notificationsByPharmacy(_set: {isArchive: true}, where: {
            _and: [
                {id:{_gte: $idGte}},{id:{_lte: $idLte}}
            ]
        }) {
            affected_rows
        }
    }
`;
export const ArchiveMultipleNotificationsMutation = gql`
    mutation archiveAllNotifications($notificationIds: [Int!]) {
        update_notificationsByPharmacy(_set: {isArchive: true}, where: {
            _and: [
                {id:{_in: $notificationIds}}
            ]
        }) {
            affected_rows
        }
    }
`;


export const DeleteNotificationMutation = gql`
    mutation deleteNotification($notificationId: Int!) {
        update_notificationsByPharmacy(_set: {isDeleted: true}, where: {
            id: {_eq: $notificationId}
        }) {
            affected_rows
        }
    }
`;

export const DeleteMultipleNotificationsMutation = gql`
    mutation deleteMultipleNotifications($notificationIds: [Int!]) {
        update_notificationsByPharmacy(_set: {isDeleted: true}, where: {
            _and: [
                {id:{_in: $notificationIds}}
            ]
        }) {
            affected_rows
        }
    }
`;


export const AllInformationMutations = [
    MarkNotificationAsReadMutation,
    ArchiveNotificationMutation,
    ArchiveAllNotificationsMutation,
    DeleteNotificationMutation,
    DeleteMultipleNotificationsMutation
];

@Injectable()
export class InformationMutations {

    constructor(
        private apollo: Apollo,
        private apiService: ApiService
    ) {
    }


    upsertNotificationReadStatus(notificationId: number, isRead: boolean) {
        this.apollo.mutate({
            mutation: MarkNotificationAsReadMutation,
            variables: {notifications: [{notificationId}], isRead}
        }).subscribe({
            next: result => {
                from([result])
                  .pipe(map(d => d?.data && d?.data['MarkNotificationAsRead']
                    && d?.data['MarkNotificationAsRead']['status']))
                  .subscribe((status: string) => {
                      if (status.toLowerCase() === 'success') {
                          // Removed the truthy case -> showed up with every interaction -> distracting for the user
                      } else {
                          void this.apiService.presentErrorToast(result['errors'], isRead ?
                            'Benachrichtigung konnte nicht als gelesen markiert werden.' :
                            'Benachrichtigung konnte nicht als ungelesen markiert werden.');
                      }
                  }).unsubscribe();
            },
            error: error => this.apiService.presentErrorToast(error, isRead ? 'Benachrichtigung konnte nicht als gelesen markiert werden.' : 'Benachrichtigung konnte nicht als ungelesen markiert werden.')
        });
    }


    upsertMultipleNotificationReadStatus(notifications: Array<{notificationId: number}>, isRead: boolean) {
        this.apollo.mutate({
            mutation: MarkNotificationAsReadMutation,
            variables: {notifications, isRead}
        }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigungen konnten nicht als gelesen markiert werden.')
        });
    }


    archiveNotification(notificationId: number) {
        this.apollo.mutate({
            mutation: ArchiveNotificationMutation,
            variables: {notificationId}
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Die Benachrichtigung wurde erfolgreich archiviert.'),
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigung konnte nicht archiviert werden.')
        });
    }


    archiveMultipleNotifications(notificationIds: Array<number>) {
        this.apollo.mutate({
            mutation: ArchiveMultipleNotificationsMutation,
            variables: {notificationIds}
        }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigungen konnten nicht archiviert werden.')
        });
    }


    archiveAllNotifications(idGte: number, idLte: number) {
        this.apollo.mutate({
            mutation: ArchiveAllNotificationsMutation,
            variables: {idGte, idLte}
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Alle Benachrichtigungen wurden archiviert.'),
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigungen konnten nicht archiviert werden.')
        });
    }

    deleteNotification(notificationId: number) {
        this.apollo.mutate({
            mutation: DeleteNotificationMutation,
            variables: {notificationId}
        }).subscribe({
            next: () => this.apiService.presentSuccessToast('Die Benachrichtigung wurde erfolgreich gelöscht.'),
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigung konnte nicht gelöscht werden.')
        });
    }

    deleteMultipleNotifications(notificationIds: Array<number>) {
        this.apollo.mutate({
            mutation: DeleteMultipleNotificationsMutation,
            variables: {notificationIds}
        }).subscribe({
            error: error => this.apiService.presentErrorToast(error, 'Die Benachrichtigungen konnten nicht gelöscht werden.')
        });
    }

}

