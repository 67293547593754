import gql from 'graphql-tag';

export default gql`
  mutation addEncryptedNoteToUserConversation($userConversationId: ID!, $encryptedText: String!) {
    addEncryptedNoteToUserConversation(userConversationId: $userConversationId, encryptedText: $encryptedText) {
      __typename
      id
      ownerId
      conversationId
      conversation {
        firstSegmentId
      }
      encryptedNoteHistory {
        encryptedText
        id
        timestamp
      }
    }
  }
`;
