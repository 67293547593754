@if (!notificationType) {
    <ion-spinner name="crescent" color="primary"></ion-spinner>
} @else {
    @if (notificationType === NotificationsEnum.NEW_IMPORTANT_NEWS
        || notificationType === NotificationsEnum.NEW_NEWS
    ) {
        <app-notification-news-content [notificationPayloadId]="notificationPayloadId" [notification]="notification"/>
    }
    @else if (notificationType === NotificationsEnum.NEW_ASSORTMENT_STATISTIC
        || notificationType === NotificationsEnum.COOPERATION_REVENUE_CURRENT_YEAR
        || notificationType === NotificationsEnum.SALES_VOLUME_WITHOUT_HIGH_PRICED
    ) {
        <app-notification-statistic-content [notificationPayloadId]="notificationPayloadId" [notification]="notification"/>
    }
    @else if (notificationType === NotificationsEnum.NEW_OFFER) {
        <app-notification-offer-content [notificationPayloadId]="notificationPayloadId" [notification]="notification"/>
    }
    @else if (notificationType === NotificationsEnum.NEW_SURVEY) {
        <app-notification-survey-content [notificationPayloadId]="notificationPayloadId" [notification]="notification"/>
    }
    @else if (notificationType === NotificationsEnum.NEW_DOCUMENT) {
        <app-notification-document-content [notificationPayloadId]="notificationPayloadId" [notification]="notification"/>
    }
    @else if (notificationType === NotificationsEnum.NEW_MAINTENANCE) {
        <app-notification-maintenance-content [notificationPayloadId]="notificationPayloadId" [notification]="notification"/>
    }
    @else if (notificationType === NotificationsEnum.NEW_RELEASE_NOTE) {
        <app-notification-release-notes-content [notificationPayloadId]="notificationPayloadId" [notification]="notification"/>
    }
    @else {
        <p [innerHtml]="notification.message | convertLineBreak"></p>

        <ion-text *ngIf="notification?.payload['productName']"
              class="display-block ion-margin-vertical">
        <p><strong i18n>Artikel:</strong> {{ notification?.payload['productName'] }} {{ '-' }} {{
                notification?.payload['packageSize']
            }}</p>
        </ion-text>
        <ion-text *ngIf="notification?.payload['pzn']">
            <p><strong i18n>PZN:</strong> {{ notification?.payload['pzn'].padStart(8, '0') }}</p>
        </ion-text>
        <ion-text *ngIf="notification?.payload['cancellationReason']"
                class="display-block ion-margin-vertical">
            <p><strong i18n>Stornogrund:</strong> {{ notification?.payload['cancellationReason'] }}</p>
        </ion-text>
        <ion-text *ngIf="notification?.payload?.tour_message_text && notification?.payload.tour_message_text.length > 0"
                class="display-block span">
            <p><strong i18n>Details:</strong></p>
            <p innerHTML="{{notification?.payload.tour_message_text | convertLineBreak}}"></p>
        </ion-text>
        <ion-text *ngIf="notification?.payload['partner']"
                class="display-block ion-margin-vertical"
                i18n>
            <p>durchgeführt von: {{ notification?.payload['partner'] }}</p>
        </ion-text>
        <ng-container *ngIf="notification.type === 'MEASHOP_NEW_ORDER'">
            <ion-text *ngIf="notification?.payload?.createdAt"
                    class="display-block ion-margin-top">
            <span><strong i18n>Bestellzeitpunkt:</strong> {{
                    notification?.payload.createdAt | formatDateTimeToDateTime : false
                }}</span>
            </ion-text>
            <ion-text *ngIf="notification?.payload?.orderNumber"
                    class="display-block ">
                <span><strong i18n>Bestellnummer:</strong> {{ notification?.payload.orderNumber }}</span>
            </ion-text>
            <ion-text *ngIf="notification?.payload?.orderId"
                    class="display-block">
                <span><strong i18n>Bestell-ID:</strong> {{ notification?.payload.orderId }}</span>
            </ion-text>
            <ion-text *ngIf="notification?.payload?.customerNumber"
                    class="display-block ">
                <span><strong i18n>Kundennummer:</strong> {{ notification?.payload.customerNumber }}</span>
            </ion-text>
            <ion-text *ngIf="notification?.payload?.totalPrice"
                    class="display-block ">
                <span><strong i18n>Warenkorbwert:</strong> {{ notification?.payload.totalPrice | formatCurrency }}</span>
            </ion-text>
            <ion-text *ngIf="notification?.payload?.deploymentType"
                    class="display-block ">
                <span><strong i18n>Lieferart:</strong> {{ notification?.payload.deploymentType }}</span>
            </ion-text>
        </ng-container>
    }
}
