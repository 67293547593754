import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { AppsyncMessageService } from '../../../../common/services/src/appsync/appsync-message.service';
import { SubscriptionManagementService } from '../../../../common/services/src/subscriptions/subscription-management.service';
import { Logger } from '../../../../essentials/util/src/logger';
import { isNotNullOrUndefined } from '../../../../essentials/util/src/rxjs/isNotNullOrUndefined';
import { UnreadMessagesUtil } from '../../../../essentials/util/src/unread-messages.util';
import { checkUserAndRestartSubscriptions } from '../../../../store/src/common-store/other/actions/subscription.actions';
import { setUserOnLogin } from '../../../../store/src/common-store/user-store/actions/user.actions';
import { selectUser } from '../../../../store/src/common-store/user-store/selectors/user.selectors';
import { SsoPharmacyStorageService, UnreadMessagesStorage } from '../../services/sso-pharmacy-storage.service';
import { MeaPharmacyState } from '../mea-pharmacy.state';
import { AppsyncShopUserMessageService } from '../shop-user-messages/appsync-shop-user-message.service';
import { ShopUserMessagesService } from '../shop-user-messages/shop-user-messages.service';
import {
  setUnreadMessagesForInactiveSsoPharmacy,
  updateUnreadMessagesForInactiveSsoPharmacy,
} from './sso-pharmacies.actions';
import { selectSsoPharmacies } from './sso-pharmacies.selectors';

const logger = new Logger('SsoPharmaciesEffects');

@Injectable()
export class SsoPharmaciesEffects {
  private actions$ = inject(Actions);
  private appsyncMessageService = inject(AppsyncMessageService);
  private appsyncShopUserMessageService = inject(AppsyncShopUserMessageService);
  private shopUserMessagesService = inject(ShopUserMessagesService);
  private ssoPharmacyStorageService = inject(SsoPharmacyStorageService);
  private store: Store<MeaPharmacyState> = inject(Store);
  private subscriptionManagementService = inject(SubscriptionManagementService);

  setSelectedSsoPharmacyInStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUserOnLogin),
        switchMap(async ({ user }) => {
          const ssoPharmacies = await firstValueFrom(
            this.store.select(selectSsoPharmacies).pipe(filter((pharmacies) => !!pharmacies?.length))
          );
          const selectedSsoPharmacy = ssoPharmacies?.find((ssoPharmacy) => ssoPharmacy.cognitoId === user.cognitoId);
          if (selectedSsoPharmacy) {
            await this.ssoPharmacyStorageService.setSelectedSsoPharmacy(selectedSsoPharmacy);
          }
        })
      ),
    { dispatch: false }
  );

  trackUnreadMessagesForSsoPharmacies$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setUserOnLogin, checkUserAndRestartSubscriptions),
        switchMap(async () => {
          const user = await firstValueFrom(this.store.select(selectUser).pipe(isNotNullOrUndefined()));
          const ssoPharmacies = await firstValueFrom(
            this.store.select(selectSsoPharmacies).pipe(filter((pharmacy) => !!pharmacy?.length))
          );
          if (ssoPharmacies) {
            for (const pharmacy of ssoPharmacies) {
              const cognitoId = pharmacy.cognitoId;
              const isSelectedSsoPharmacy = cognitoId === user.cognitoId;
              if (cognitoId) {
                try {
                  const unreadChatMessagesIds =
                    await this.appsyncMessageService.getTotalUnreadMessagesIdsForSsoPharmacy(cognitoId);
                  const shopUserMessages =
                    await this.shopUserMessagesService.loadUnreadShopUserMessagesForPharmacy(cognitoId);
                  const unreadShopUserMessagesIds = shopUserMessages
                    .filter((message) => !message.readByRecipient)
                    .map((message) => message.id);
                  const unreadMessagesIds = new Set([...unreadChatMessagesIds, ...unreadShopUserMessagesIds]);
                  if (isSelectedSsoPharmacy) {
                    await this.ssoPharmacyStorageService.setUnreadMessagesIdsInStorage(
                      cognitoId,
                      unreadMessagesIds,
                      UnreadMessagesStorage.Total
                    );
                    this.startActiveSsoPharmacySubscriptions(cognitoId);
                  } else {
                    this.store.dispatch(setUnreadMessagesForInactiveSsoPharmacy({ cognitoId, unreadMessagesIds }));
                    this.startInactiveSsoPharmacySubscriptions(cognitoId);
                  }
                } catch (e) {
                  logger.error(`Error tracking unread messages for sso pharmacy ${cognitoId}`, e);
                }
              }
            }
          }
        })
      ),
    { dispatch: false }
  );

  private startInactiveSsoPharmacySubscriptions(cognitoId: string) {
    logger.info('Starting unread messages subscriptions for inactive sso pharmacy', cognitoId);
    this.subscriptionManagementService.subscribe(
      `newMessagesInactiveSsoPharmacy_${cognitoId}`,
      this.appsyncMessageService.newMessages(cognitoId),
      async (message) => {
        const countMessageAsUnread = UnreadMessagesUtil.countMessageAsUnread(cognitoId, message);
        if (countMessageAsUnread) {
          this.store.dispatch(
            updateUnreadMessagesForInactiveSsoPharmacy({ cognitoId, messageId: message.id, isNew: true })
          );
        }
      }
    );
    this.subscriptionManagementService.subscribe(
      `updatedMessagesInactiveSsoPharmacy_${cognitoId}`,
      this.appsyncMessageService.updatedReceivedMessages(cognitoId),
      async (message) => {
        if (message.readByRecipient) {
          this.store.dispatch(
            updateUnreadMessagesForInactiveSsoPharmacy({ cognitoId, messageId: message.id, isNew: false })
          );
        }
      }
    );
    this.subscriptionManagementService.subscribe(
      `createdShopUserMessageInactiveSsoPharmacy_${cognitoId}`,
      this.appsyncShopUserMessageService.createdShopUserMessage(cognitoId),
      async (message) => {
        this.store.dispatch(
          updateUnreadMessagesForInactiveSsoPharmacy({ cognitoId, messageId: message.id, isNew: true })
        );
      }
    );
    this.subscriptionManagementService.subscribe(
      `updatedShopUserMessageInactiveSsoPharmacy_${cognitoId}`,
      this.appsyncShopUserMessageService.updatedShopUserMessage(cognitoId),
      async (message) => {
        if (message.readByRecipient) {
          this.store.dispatch(
            updateUnreadMessagesForInactiveSsoPharmacy({ cognitoId, messageId: message.id, isNew: false })
          );
        }
      }
    );
  }

  private startActiveSsoPharmacySubscriptions(cognitoId: string) {
    logger.info('Starting unread messages subscription for active sso pharmacy', cognitoId);
    this.subscriptionManagementService.subscribe(
      `newMessagesActiveSsoPharmacy_${cognitoId}`,
      this.appsyncMessageService.newMessages(cognitoId),
      async (message) => {
        const countMessageAsUnread = UnreadMessagesUtil.countMessageAsUnread(cognitoId, message);
        if (countMessageAsUnread) {
          await this.ssoPharmacyStorageService.updateTotalUnreadMessagesIdsInStorage({
            cognitoId,
            messageId: message.id,
            isNew: true,
          });
        }
      }
    );
    this.subscriptionManagementService.subscribe(
      `updatedMessagesActiveSsoPharmacy_${cognitoId}`,
      this.appsyncMessageService.updatedReceivedMessages(cognitoId),
      async (message) => {
        if (message.readByRecipient) {
          await this.ssoPharmacyStorageService.updateTotalUnreadMessagesIdsInStorage({
            cognitoId,
            messageId: message.id,
            isNew: false,
          });
        }
      }
    );
    this.subscriptionManagementService.subscribe(
      `createdShopUserMessageActiveSsoPharmacy_${cognitoId}`,
      this.appsyncShopUserMessageService.createdShopUserMessage(cognitoId),
      async (message) => {
        await this.ssoPharmacyStorageService.updateTotalUnreadMessagesIdsInStorage({
          cognitoId,
          messageId: message.id,
          isNew: true,
        });
      }
    );
    this.subscriptionManagementService.subscribe(
      `updatedShopUserMessageActiveSsoPharmacy_${cognitoId}`,
      this.appsyncShopUserMessageService.updatedShopUserMessage(cognitoId),
      async (message) => {
        if (message.readByRecipient) {
          await this.ssoPharmacyStorageService.updateTotalUnreadMessagesIdsInStorage({
            cognitoId,
            messageId: message.id,
            isNew: false,
          });
        }
      }
    );
  }
}
