import gql from 'graphql-tag';
import { shopUserMessageAttributes } from '../../../../common/resources/src/graphql/attributes';

export default gql`
    subscription deletedShopUserMessage($pharmacyCognitoId: ID!) {
        deletedShopUserMessage(pharmacyCognitoId: $pharmacyCognitoId) {
            ${shopUserMessageAttributes}
        }
    }
`;
