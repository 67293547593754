import { gql } from '@apollo/client/core';
import { inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map, switchMap } from 'rxjs';

import {
    HelpSectionsInterface,
    ServiceBranchInterface
} from '../../../core.interfaces';
import {
    HelpSectionTypes,
    QueryFetchPolicy
} from '../../../core.enums';
import {
    GetDataChangedGlobalSubscription,
    GetDataChangedPharmacySubscription,
    GetDataChangedPharmacyUserSubscription,
    GetDataChangedUserSubscription
} from '../subscriptions/information.subscriptions';
import { GraphQLLimits } from '../../../config/graphql-limits.config';
import { environment } from '../../../../../environments/environment';
import {
    DataChangedStateInterface
} from '../../../interfaces/pharmacy-state.interface';
import { formatStrapiHelpSections, formatStrapiServiceBranches, getFiltersStrapi } from '../utils';
import { PharmacyStoreStateVar } from '../../locals/pharmacyStoreState.var';

export const GetServiceBranches = gql`
   query ServiceBranches($limit: Int, $filters: ServiceBranchFiltersInput) {
	    serviceBranches(pagination: {limit: $limit}, filters: $filters) {
            id : documentId
            title
            name
            street
            zip
            city
            phone
            fax
            email
            deliveryArea
            displayLocation
            googleMapsLink
            image {
                url
            }
            file {
                url
            }
            orderAcceptance
            Note
            teamMembers {
                name
                task
                image {
                    url
                }
            }
        }
    }
`;

export const GetHelpSections = gql`
    query getHelpSections($limit: Int) {
        helpSections(pagination: {limit: $limit}) {
            id : documentId
            title
            type
            faqs {
                id : documentId
                question
                answer
            }
            contents {
                id : documentId
                headlineLong
                headlineShort
                content
            }
        }
    }
`;
export const GetHelpSectionsFiltered = gql`
    query getHelpSectionsFiltered($limit: Int, $filters: HelpSectionFiltersInput) {
        helpSections(pagination: {limit: $limit}, filters:$filters, sort: ["type:desc"]) {
            id : documentId
            title
            type
            faqs {
                id : documentId
                question
                answer
            }
            contents {
                id : documentId
                headlineLong
                headlineShort
                content
            }
        }
    }
`;



export const GetFeedback = gql `
    query feedback($limit: Int) {
        feedbacks(pagination: {limit: $limit}, sort:["createdAt:desc"]) {
            url
        }
    }
`;

export const AllInformationQueries = [
    GetServiceBranches,
    GetHelpSections,
    GetHelpSectionsFiltered,
    GetFeedback,
];

@Injectable()
export class InformationQueries {
    private pharmacyStoreStateVar = inject(PharmacyStoreStateVar);

    activePharmacyStore$ = this.pharmacyStoreStateVar.activePharmacyStoreState$;

    constructor(
        private apollo: Apollo,
        private getDataChangedPharmacySubscription: GetDataChangedPharmacySubscription,
        private getDataChangedUserSubscription: GetDataChangedUserSubscription,
        private getDataChangedPharmacyUserSubscription: GetDataChangedPharmacyUserSubscription,
        private getDataChangedGlobalSubscription: GetDataChangedGlobalSubscription
    ) {
    }

    static getHelpTypes(type: string = null) : Array<string> {
        const types: Array<string> = [HelpSectionTypes.account];
        if (type) {
            types.push(type);
            let slashPosition = type.indexOf('/');
            while (slashPosition !== -1) {
                types.push(type.substring(0, slashPosition));
                slashPosition = type.indexOf('/', slashPosition + 1);
            }
        }
        return types;
    }

    /**
     * Return all service branches
     */
    public getServiceBranches(fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK): Observable<ServiceBranchInterface[]> {
        return this.apollo.watchQuery({
                query: GetServiceBranches,
                fetchPolicy,
                variables: {limit: GraphQLLimits.customerService},
                pollInterval: environment.pollingInterval.customerService
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['serviceBranches']))
            .pipe(map(serviceBranches => formatStrapiServiceBranches(serviceBranches))) as Observable<ServiceBranchInterface[]>;
    }

    /**
     * Return all service branches
     */
    public getServiceBranch(isCustomerService: boolean): Observable<ServiceBranchInterface> {
        return this.activePharmacyStore$.pipe(
            switchMap((pharmacy) => {
                const additionalFilters = getFiltersStrapi(pharmacy);
                return this.apollo.watchQuery({
                    query: GetServiceBranches,
                    fetchPolicy: QueryFetchPolicy.CACHE_AND_NETWORK,
                    variables: {
                        limit: GraphQLLimits.defaultLimit,
                        filters: {
                            displayLocation: {containsi: isCustomerService ? 'Niederlassung' : 'Mea_Service_Team'},
                            ...additionalFilters
                        }
                    },
                    pollInterval: environment.pollingInterval.customerService
                })
                    .valueChanges
                    .pipe(map(d => d?.data && d?.data['serviceBranches']))
                    .pipe(map(serviceBranches => {
                        const branches = formatStrapiServiceBranches(serviceBranches);
                        if(branches) {
                            return branches[0];
                        }
                        return null;
                    })) as Observable<ServiceBranchInterface>;
            })
        );
    }

    /**
     * Return all available Help Sections with questions and answers
     */
    public pollHelpSections(fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK): Observable<HelpSectionsInterface[]> {
        return this.apollo.watchQuery({
                query: GetHelpSections,
                variables: {limit: GraphQLLimits.defaultLimit},
                fetchPolicy,
                pollInterval: environment.pollingInterval.faq
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['helpSections']))
            .pipe(map(helpSections => formatStrapiHelpSections(helpSections))) as Observable<HelpSectionsInterface[]>;
    }


    /**
     * Return all available Help Sections with questions and answers
     */
    public getHelpSectionsByType(
        type: string,
        fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.CACHE_AND_NETWORK): Observable<HelpSectionsInterface[]> {
        return this.apollo.watchQuery({
                query: GetHelpSectionsFiltered,
                fetchPolicy,
                variables: {
                    limit: GraphQLLimits.defaultLimit,
                    filters: {
                        type: {in: InformationQueries.getHelpTypes(type)}
                    }
                }
            })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['helpSections']))
            .pipe(map(helpSections => formatStrapiHelpSections(helpSections))) as Observable<HelpSectionsInterface[]>;
    }

    /**
     * Returns the last data changed pharmacy datetime
     */
    public getDataChangedPharmacy(): Observable<Array<DataChangedStateInterface>> {
        return this.getDataChangedPharmacySubscription.subscribe()
            .pipe(map(d => d?.data && d?.data['dataChangePharmacy']));
    }

    /**
     * Returns the last data changed user datetime
     */
    public getDataChangedUser(): Observable<Array<DataChangedStateInterface>> {
        return this.getDataChangedUserSubscription.subscribe()
            .pipe(map(d => d?.data && d?.data['dataChangeUser']));
    }

    /**
     * Returns the last data changed user datetime
     */
    public getDataChangedPharmacyUser(): Observable<Array<DataChangedStateInterface>> {
        return this.getDataChangedPharmacyUserSubscription.subscribe()
            .pipe(map(d => d?.data && d?.data['dataChangePharmacyUser']));
    }

    /**
     * Returns the last data changed user datetime
     */
    public getDataChangedGlobal(): Observable<Array<DataChangedStateInterface>> {
        return this.getDataChangedGlobalSubscription.subscribe()
            .pipe(map(d => d?.data && d?.data['dataChangeGlobal']));
    }

    /**
     * Returns the last unread release note
     */
    public getFeedback(fetchPolicy: QueryFetchPolicy = QueryFetchPolicy.NETWORK_ONLY): Observable<string> {
        return this.apollo.watchQuery({
            query: GetFeedback,
            variables: {limit: GraphQLLimits.defaultLimit},
            fetchPolicy
        })
            .valueChanges
            .pipe(map(d => d?.data && d?.data['feedbacks']))
            .pipe(map((feedbacks) => (
                feedbacks?.length > 0 && feedbacks[0]?.url ? feedbacks[0].url : null))
            ) as Observable<string>;
    }
}
