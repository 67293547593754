import gql from 'graphql-tag';

export default gql`
  mutation updateArchivedByPharmacy($conversationId: ID!, $archivedByPharmacy: Boolean!) {
    updateArchivedByPharmacy(id: $conversationId, archivedByPharmacy: $archivedByPharmacy) {
      __typename
      id
      archivedByPharmacy
    }
  }
`;
