/**
 * ATTENTION: Add the enum in the specific enum like UserDataChangedKey
 * AND in the general enum DataChangedKey
 */

export enum UserDataChangedKeys {
    appointmentChanged = 'appointmentChanged',
    documentSearchChanged = 'documentSearchChanged',
    readNotificationChanged = 'readNotificationChanged',
    noteByUserChanged = 'noteByUserChanged',
    offerQuoteChanged = 'offerQuoteChanged',
    settingsNotificationChanged = 'settingsNotificationChanged',
    settingsSecurityChanged = 'settingsSecurityChanged',
    userSettingsChanged = 'userSettingsChanged',
    sortingChanged = 'sortingChanged',
    userStorageChanged = 'userStorageChanged',
    surveyUserChanged = 'surveyUserChanged',
    userDetailChanged = 'userDetailChanged',
}

export enum PharmacyUserDataChangedKeys {
    formResultChanged = 'formResultChanged',
    customizableGridChanged = 'customizableGridChanged',
}

export enum PharmacyUserDataChangedCountKeys {
    notificationReadNotArchivedCount = 'notificationReadNotArchivedCount'
}

export enum PharmacyDataChangedKeys {
    archiveChanged = 'archiveChanged',
    appointmentChanged = 'appointmentChanged',
    communicationZoneChanged = 'communicationZoneChanged',
    documentDeliveriesChanged = 'documentDeliveriesChanged',
    documentInvoicesChanged = 'documentInvoicesChanged',
    noteChanged = 'noteChanged',
    notificationChanged = 'notificationChanged',
    ordersChanged = 'ordersChanged',
    returnsChanged = 'returnsChanged',
    pharmacyStoreSettingsChanged = 'pharmacyStoreSettingsChanged',
    businessFigureChanged = 'businessFigureChanged',
    businessFigureLoadingStatusChanged = 'businessFigureLoadingStatusChanged',
    businessFigureDuettChanged = 'businessFigureDuettChanged',
    businessFigureSubscriptionsChanged = 'businessFigureSubscriptionsChanged',
    producerChanged = 'producerChanged',
    safetyDataSheetsChanged = 'safetyDataSheetsChanged'
}

export enum PharmacyDataChangedCountKeys {
    notificationNotArchivedCount = 'notificationNotArchivedCount',
}

export enum UserDataChangedCountKeys {
    showReleaseNoteHint = 'showReleaseNoteHint'
}

export enum GlobalDataChangedKeys {
    meamindChanged = 'meamindChanged',
    offerOrderContingentChanged = 'offerOrderContingentChanged',
}
export enum StrapiDataChangedKeys  {
    newsPostChanged = 'newsPostChanged',
    formChanged = 'formChanged',
    offerChanged = 'offerChanged',
    offerProductChanged = 'offerProductChanged',
    bannerChanged = 'bannerChanged',
    downloadChanged = 'downloadChanged',
    downloadTypeChanged = 'downloadTypeChanged',
    infoModalChanged = 'infoModalChanged',
    surveyChanged = 'surveyChanged',
    educationChanged = 'educationChanged',
    sanacorpAppointmentChanged = 'sanacorpAppointmentChanged',
}
export enum DataChangedKeys {
    archiveChanged = 'archiveChanged',
    bannerChanged = 'bannerChanged',
    businessFigureDuettChanged = 'businessFigureDuettChanged',
    businessFigureSubscriptionsChanged = 'businessFigureSubscriptionsChanged',
    customizableGridChanged = 'customizableGridChanged',
    pharmacyStoreSettingsChanged = 'pharmacyStoreSettingsChanged',
    settingsNotificationChanged = 'settingsNotificationChanged',
    settingsSecurityChanged = 'settingsSecurityChanged',
    userSettingsChanged = 'userSettingsChanged',
    appointmentChanged = 'appointmentChanged',
    privateAppointmentChanged = 'privateAppointmentChanged',
    communicationZoneChanged = 'communicationZoneChanged',
    documentDeliveriesChanged = 'documentDeliveriesChanged',
    documentInvoicesChanged = 'documentInvoicesChanged',
    documentSearchChanged = 'documentSearchChanged',
    meamindChanged = 'meamindChanged',
    offerOrderContingentChanged = 'offerOrderContingentChanged',
    newsPostChanged = 'newsPostChanged',
    formChanged = 'formChanged',
    formResultChanged = 'formResultChanged',
    noteChanged = 'noteChanged',
    noteByUserChanged = 'noteByUserChanged',
    notificationChanged = 'notificationChanged',
    readNotificationChanged = 'readNotificationChanged',
    offerChanged = 'offerChanged',
    offerProductChanged = 'offerProductChanged',
    offerQuoteChanged = 'offerQuoteChanged',
    ordersChanged = 'ordersChanged',
    returnsChanged = 'returnsChanged',
    settingsChanged = 'settingsChanged',
    userStorageChanged = 'userStorageChanged',
    sortingChanged = 'sortingChanged',
    businessFigureChanged = 'businessFigureChanged',
    businessFigureLoadingStatusChanged = 'businessFigureLoadingStatusChanged',
    downloadChanged = 'downloadChanged',
    downloadTypeChanged = 'downloadTypeChanged',
    infoModalChanged = 'infoModalChanged',
    surveyChanged = 'surveyChanged',
    surveyUserChanged = 'surveyUserChanged',
    educationChanged = 'educationChanged',
    sanacorpAppointmentChanged = 'sanacorpAppointmentChanged',
    producerChanged = 'producerChanged',
    dynamicPageChanged = 'dynamicPageChanged',
    cmsMenuItemChanged = 'cmsMenuItemChanged',
    safetyDataSheetsChanged = 'safetyDataSheetsChanged',
    userDetailChanged = 'userDetailChanged',
    newsletterSubscriptionsChanged = 'newsletterSubscriptionsChanged',
}

export enum DataChangedCountKeys {
    notificationNotArchivedCount = 'notificationNotArchivedCount',
    notificationReadNotArchivedCount = 'notificationReadNotArchivedCount',
    showReleaseNoteHint = 'showReleaseNoteHint'
}


