<div class="access-rights-badge-wrapper" [class.disabled]="disabled" [class.deactivated]="deactivated">
    <ion-badge
            [class]="badge.color"
            appTooltip
            ttk="{{badge.tooltip}}"
            ttp="bottom"
    >{{badge.label}}</ion-badge>
    <div class="access-rights-badge">
        <div *ngFor="let accessRight of badge.accessRights"
             class="badge-wrapper"
             [class.clickable]="!disabled && clickable && accessRight.right !== userAdministrationAccessRight"
             [class.disabled]="accessRight.right === userAdministrationAccessRight"
             (click)="onAccessRightClick(accessRight.right, accessRight.isChecked)"
             appTooltip
             ttk="{{accessRight.right | userAccessRightTooltip : accessRight.isChecked}}"
        >
            <ion-icon *ngIf="accessRight.isChecked === true" class="checkbox-icon" name="checkbox-outline" size="large"></ion-icon>
            <ion-icon *ngIf="accessRight.isChecked === false" class="checkbox-icon" src="assets/icons/checkbox-unchecked.svg" size="large"></ion-icon>
            <ion-badge>{{accessRight.name}}</ion-badge>
        </div>
    </div>
</div>
