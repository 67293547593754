import { CommonModule } from '@angular/common';
import { Component, computed, inject, input, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IonicModule, IonModal } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ModalHeaderButtonDirective } from '../../../../../../common/directives/src/modals/modal-header-button.directive';
import { AppsyncConversationService } from '../../../../../../common/services/src/appsync/appsync-conversation.service';
import { MeaButtonComponent } from '../../../../../../common/ui-components/src/buttons/mea-button/mea-button.component';
import { RemoveCommonChatDuettFields } from '../../../../../../essentials/types/src/common-chat-duett-fields';
import { DeleteConversation } from '../../../../../../essentials/types/src/duett-schema/de.meineapotheke.chat/delete_conversation/jsonschema/1-1-0';
import {
  ChatOrder,
  ChatOrderWithLastMessage,
  orderIsShopOrder,
  ShopOrder,
} from '../../../../../../essentials/types/src/order';
import { analyticsDuettEvent } from '../../../../../../store/src/common-store/other/actions/common-analytics.actions';
import { HomePage } from '../../../../pharmacy-routes';
import { MeaPharmacyState } from '../../../../store/mea-pharmacy.state';
import { AppsyncShopUserMessageService } from '../../../../store/shop-user-messages/appsync-shop-user-message.service';

@Component({
  selector: 'mea-delete-order-modal',
  standalone: true,
  imports: [CommonModule, IonicModule, ModalHeaderButtonDirective, TranslateModule, MeaButtonComponent],
  templateUrl: './delete-order-modal.component.html',
  styleUrls: ['./delete-order-modal.component.scss'],
})
export class DeleteOrderModalComponent {
  @Input() modal?: IonModal;
  order = input.required<ChatOrder | ChatOrderWithLastMessage | ShopOrder>();

  private appsyncConversationService = inject(AppsyncConversationService);
  private appsyncShopUserMessageService = inject(AppsyncShopUserMessageService);
  private router = inject(Router);
  private store: Store<MeaPharmacyState> = inject(Store);

  hasShoppingCart = computed(() => {
    const order = this.order();
    return !orderIsShopOrder(order) && !!order.conversation.encryptedShoppingCart;
  });

  protected async deleteConversation() {
    if (orderIsShopOrder(this.order())) {
      await this.appsyncShopUserMessageService.deleteShopUserMessage(this.order().orderId);
    } else {
      await this.appsyncConversationService.deleteConversation(this.order().orderId);
      this.trackDeleteConversationEvent();
    }
    await this.router.navigate([HomePage.enduserChat]);
    this.closeModal();
  }

  protected closeModal() {
    this.modal?.dismiss();
  }

  private trackDeleteConversationEvent() {
    const event: RemoveCommonChatDuettFields<DeleteConversation> = {
      event_type: 'delete_conversation',
      frontend_conversation_id: this.order().orderId,
    };
    this.store.dispatch(analyticsDuettEvent({ event }));
  }
}
